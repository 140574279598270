import React from "react";
import AddCaseType from "../CaseType/AddCaseType";
import ListCaseType from "../CaseType/ListCaseType";
import ViewCaseType from "../CaseType/ViewCaseType";

export default function CaseType({ mode, id }) {
  if (mode === "add") {
    return <AddCaseType />;
  } else if (mode === "edit") {
    return <AddCaseType id={id} />;
  } else if (mode === "view") {
    return <ViewCaseType id={id} />;
  }
  return <ListCaseType />;
}
