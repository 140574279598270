import React from "react";
import { CurrentUser } from "../../service/Auth";
import { Status } from "../../stories/Status";

const StatusText = ({ status, outline }) => {
  if (status === "pending")
    return (
      <Status type="orange-400" outline={outline}>
        Details Pending
      </Status>
    );

  if (CurrentUser.isAccessionist()) {
    return (
      <Status type="green-400" outline={outline}>
        Details filled
      </Status>
    );
  }

  // if (status === "accessioning_complete")
  //   return (
  //     <Status type="orange-400" outline={outline}>
  //       Grossing Pending
  //     </Status>
  //   );

  if (status === "accessioning_complete")
    return (
      <Status type="orange-400" outline={outline}>
        Grossing Pending
      </Status>
    );

  if (CurrentUser.isGrosser()) {
    return (
      <Status type="green-400" outline={outline}>
        Grossing Done
      </Status>
    );
  }

  const hasGrossed = status === "grossed";
  let type = `${hasGrossed ? "orange" : "green"}-400`;
  let displayText = hasGrossed ? "Diagnosis Pending" : "Signed Out";
  return (
    <Status type={type} outline={outline}>
      {displayText}
    </Status>
  );
};
export default StatusText;
