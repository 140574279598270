import React, { useState } from "react";
import { FormDataField } from "../../stories/FormDataField";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import ConfirmDialog from "../../stories/ConfirmDialog";
import { StatusTag } from "../../stories/StatusTag";
import { useStaff } from "../../service/Fetchers";
import { getRoleNames } from "../../service/Helper";
import { DashboardView } from "../../stories/DashboardView";
import { format } from "date-fns";

const ViewStaff = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTogglingActivation, setIsTogglingActivation] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const { user, loading, error, mutate } = useStaff({ user_id: id });

  const toggleActivationPatient = async (type) => {
    setIsTogglingActivation(true);
    const { data, error } = await API.put(
      `/auth/${user.uuid}/toggle-activation`
    );
    if (error) {
      if (error.message) toast.error(error.message);
    }
    if (data) {
      mutate();
      toast.info(
        `${user.first_name} is ${
          type === "activate" ? "Activated" : "Deactivated"
        } `
      );
    }
    setIsTogglingActivation(false);
  };

  const onToggleActivation = async (type) => {
    if (type === "activate") {
      setDialogData({
        description: "Once activated the staff will be seen across platform",
        construtctiveTitle: "Activate",
        onPressConstructive: () => confirmToggleActivation(type),
      });
    } else {
      setDialogData({
        description:
          "Once deactivated the staff can be activated again. All data will still be available, but cannot be edited",
        destrutctiveTitle: "Deactivate",
        onPressDestructive: () => confirmToggleActivation(type),
      });
    }
    setIsOpen(true);
  };

  const confirmToggleActivation = async (type) => {
    setIsOpen(false);
    await toggleActivationPatient(type);
  };

  const headerData = {
    constructiveCTA:
      !user || user.deactivated
        ? {
            onClick: () => onToggleActivation("activate"),
            title: "Activate",
            loading: isTogglingActivation,
          }
        : {
            link: `/dashboard/staff/edit/${id}`,
            title: "Edit",
          },
    destructiveCTA:
      !user || user.deactivated
        ? null
        : {
            onClick: () => onToggleActivation("deactivate"),
            title: "Deactivate",
            loading: isTogglingActivation,
          },
    states: [
      { title: "Staff List", link: "/dashboard/staff" },
      { title: `Staff #${id}` },
    ],
    title: user ? `${user.first_name} ${user.last_name}` : "Staff",
  };

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load staff details",
        ctaOnClick: mutate,
      }
    : null;

  const renderPathologist = () => {
    let name = "";
    if (user?.pathologist?.first_name) {
      name += user?.pathologist?.first_name;
    }
    if (user?.pathologist?.last_name) {
      name += ` ${user?.pathologist?.last_name}`;
    }
    return name;
  };

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Name"
          value={
            <span className="font-bold">{`${user?.first_name} ${user?.last_name}`}</span>
          }
        />
        <FormDataField
          className="w-1/4"
          title="Staff ID"
          value={<span className="font-bold">{user?.uuid}</span>}
        />
        <FormDataField
          className="w-1/4"
          title="DOB"
          value={user?.dob && format(new Date(user?.dob), "dd-MMM-yyyy")}
        />
        <FormDataField
          className="w-1/4"
          title="Role"
          value={getRoleNames(user?.role.name)}
        />
      </div>
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Email"
          value={
            <p title={user?.email} className="w-64 truncate">
              {user?.email}
            </p>
          }
        />
        <FormDataField className="w-1/4" title="Phone" value={user?.phone} />
        {user?.signature.length ? (
          <FormDataField
            className="w-1/4"
            title="Signature"
            value={user?.signature ?? "--"}
          />
        ) : null}
        <FormDataField
          className="w-1/4"
          title="Status"
          value={
            <StatusTag type={user?.deactivated ? "red" : "green"}>
              {user?.deactivated ? "Deactivated" : "Active"}
            </StatusTag>
          }
        />
        {!!user?.pathologist?.first_name && (
          <FormDataField
            className="w-1/4"
            title="Assigned Pathologist"
            value={renderPathologist()}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        title="Are you sure?"
        cancelTitle="Cancel"
        {...dialogData}
        onPressCancel={() => setIsOpen(false)}
      />
      <DashboardView
        headerData={headerData}
        loading={loading}
        content={user ? content : null}
        errorData={errorData}
      />
    </>
  );
};

export default ViewStaff;
