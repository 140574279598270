import { CurrentUser } from "../../service/Auth";

export default function Home() {
  if (CurrentUser.isAdmin()) {
    window.location.href = "/dashboard";
  } else if (CurrentUser.isClinicStaff()) {
    window.location.href = "/physician/cases";
  } else {
    window.location.href = "/case-view";
  }
}
