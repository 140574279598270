import React from "react";
import _ from "lodash";
import { usePhysicianVisitType } from "../../../service/Fetchers";
import LoadingStatus from "../../../components/Loader";
import { ErrorView } from "../../../stories/ErrorView";
import { Stepper } from "../../../stories/Stepper";
import { format } from "date-fns";

import CaseDetailView from "../../CaseView/CaseDetailView";
import { BreadcrumbLink } from "../../../stories/BreadcrumbLink";
import PDFViewer from "../../../components/PDFViewer";
import { GlobalVariablesContext } from "../../../providers/GlobalProvider";
import CONSTANTS from "../../../helpers/constants";

const DiagnosticsView = ({ visitDetails }) => {
  const { pdfViewer, setPdfViewer } = React.useContext(GlobalVariablesContext);
  const token = localStorage.getItem("user_auth_token");
  return (
    <>
      <CaseDetailView
        visitDetails={visitDetails}
        key={visitDetails.id}
        id={visitDetails.id}
        rightButtons={[
          {
            show: visitDetails.report_generated,
            type: "info",
            text: "View Report",
            onClick: () => setPdfViewer(true),
          },
          {
            show: visitDetails.report_generated,
            type: "primary",
            text: "Download",
            onClick: () =>
              window.open(
                `${CONSTANTS.BASE_API_URL}/visits/${visitDetails.id}/reports/download?Access-Token=${token}`,
                "_blank"
              ),
          },
        ]}
      />
      <PDFViewer
        open={pdfViewer}
        setOpen={setPdfViewer}
        url={`${CONSTANTS.BASE_API_URL}/visits/${visitDetails.id}/reports?Access-Token=${token}`}
      />
    </>
  );
};

const CaseView = ({ id: case_id }) => {
  const { caseInfo, loading, error, mutate } = usePhysicianVisitType({
    case_id,
  });

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load Case info",
        style: { height: "auto" },
        ctaOnClick: mutate,
      }
    : null;

  const contentToShow = () => {
    if (loading) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-between items-start">
            <LoadingStatus />
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-center items-center">
            <ErrorView {..._.omit(errorData, "show")} />
          </div>
        </div>
      );
    }
    return <DiagnosticsView visitDetails={caseInfo} />;
  };

  //Activity steps
  const steps = () => {
    function subActivity() {
      switch (caseInfo?.status) {
        case "pending":
          return [
            {
              title: "Grossing Pending",
              dateTime: format(new Date(caseInfo?.updated_at), "p dd-MMM-yyyy"),
            },
          ];
        case "accessioning_complete":
          return [
            {
              title: "Ready for Grossing",
              dateTime: format(new Date(caseInfo?.updated_at), "p dd-MMM-yyyy"),
            },
          ];
        case "grossed":
          return [
            {
              title: "Grossing completed",
              dateTime: format(new Date(caseInfo?.grossed_at), "p dd-MMM-yyyy"),
            },
          ];
        case "diagnosed":
          return [
            {
              title: "Grossing completed",
              dateTime: format(new Date(caseInfo?.grossed_at), "p dd-MMM-yyyy"),
            },
            caseInfo?.report_generated
              ? {
                  title: "Diagnosed & Report generated",
                  dateTime: format(
                    new Date(caseInfo?.updated_at),
                    "p dd-MMM-yyyy"
                  ),
                }
              : {
                  title: "Diagnosed",
                  dateTime: format(
                    new Date(caseInfo?.diagnosed_at),
                    "p dd-MMM-yyyy"
                  ),
                },
          ];
        default:
          return {
            title: "Pending",
            dateTime: format(new Date(caseInfo?.updated_at), "p dd-MMM-yyyy"),
          };
      }
    }

    let activities = [
      {
        title: `Package Recieved by ${caseInfo?.clinical_info?.primary_physician?.address?.location_name}`,
        dateTime: format(new Date(caseInfo?.created_at), "p dd-MMM-yyyy"),
      },
      ...subActivity(),
    ];

    let reportViewNames =
      caseInfo?.report?.viewers &&
      caseInfo?.report?.viewers.map(
        (view) => `${view.first_name}  ${view.last_name}`
      );
    let reportDownloadNames =
      caseInfo?.report?.downloaders &&
      caseInfo?.report?.downloaders.map(
        (down) => `${down.first_name}  ${down.last_name}`
      );

    let reportViewed = caseInfo?.report?.viewed_at && {
      title: "Report Viewed",
      dateTime: format(new Date(caseInfo?.report?.viewed_at), "p dd-MMM-yyyy"),
      doneBy: `by ${reportViewNames.join(", ")}`,
    };

    let reportDownloaded = caseInfo?.report?.downloaded_at && {
      title: "Report Downloaded",
      dateTime: format(
        new Date(caseInfo?.report?.downloaded_at),
        "p dd-MMM-yyyy"
      ),
      doneBy: `by ${reportDownloadNames.join(", ")}`,
    };

    if (reportViewed) {
      activities.push(reportViewed);
      if (reportDownloaded) {
        activities.push(reportDownloaded);
      }
    }

    return activities;
  };

  const stepperContentToShow = () => {
    if (loading) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-between items-start">
            <LoadingStatus />
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-center items-center">
            <ErrorView {..._.omit(errorData, "show")} />
          </div>
        </div>
      );
    }
    return <Stepper heading="Activity" steps={steps()} />;
  };

  return (
    <div className="flex flex-col">
      <BreadcrumbLink
        states={[
          { title: "All Cases", link: "/physician/cases", tabIndex: 1 },
          { title: `Case No.#${case_id}` },
        ]}
      />
      <div className="h-4" />
      <div className="flex justify-center w-full">
        <div className="flex container bg-white w-full">{contentToShow()}</div>
        <div className="ml-6 max-w-sm w-full">
          <div className="bg-white p-6">{stepperContentToShow()}</div>
        </div>
      </div>
    </div>
  );
};

export default CaseView;
