/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import { parse, isDate, isFuture } from "date-fns";
import _ from "lodash";

const CHECKALPHABETS = /^[A-Z]+$/i;
const SPECIALCHARANDALPHABETS = /^[A-Za-z]+(?:[-\s][A-Za-z]+)*$/;

const CHECK_NUMBERS = /^\d*$/;
const CHECK_ALPHANUMERIC = /^[a-z0-9]+$/i;
const CHECK_ALPHANUMERIC_SPACE = /^[a-z0-9\-_\s]+$/i;
const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const replaceSplCharFromPhone = (phone) => {
  return phone ? phone?.replace(/[^0-9]/gi, "") : "";
};

const parseDateString = (value, originalValue) => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
};
const today = new Date();
const eighteenYearsAgo = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is Required"),
  password: Yup.string().label("Password").required("Password is required"),
});

export const packageCreateSchema = Yup.object().shape({
  courierPartner: Yup.string().trim().required("Courier Partner is Required"),
  trackingNumber: Yup.string().when("courierPartner", {
    is: "In-House",
    then: Yup.string().optional(),
    otherwise: Yup.string()
      .min(12, "Tracking number should be 12 digits")
      .max(12, "Tracking number should be 12 digits")
      .trim()
      .required("Package Number is required"),
  }),
  receivedDate: Yup.string()
    .trim()
    .required("Received Date is required")
    .test(
      "received-match",
      "Package date cannot be a future date",
      function (value) {
        if (value) return !isFuture(parse(value, "yyyy-MM-dd", new Date()));
      }
    ),
  client_id: Yup.string().trim().required("Select Client"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().label("Password").required("New Password is required"),
  confirm_password: Yup.string()
    .required("Confirm New Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
});

export const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .label("Password")
    .required("Current Password is required"),
  new_password: Yup.string()
    .label("Password")
    .required("New Password is required")
    .test(
      "passwords-not-match",
      "New password can't be same as old password",
      function (value) {
        return this.parent.current_password !== value;
      }
    ),
  confirm_password: Yup.string()
    .required("Confirm New Password is required")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.new_password === value;
    }),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is Required"),
});

export const macroDescriptionSchema = (fieldName, total) =>
  Yup.object().shape({
    [fieldName]:
      total === 0
        ? Yup.array()
        : Yup.array()
            .required(`${_.startCase(fieldName)} is Required`)
            .min(total)
            .max(total),
  });

export const addAddendumSchema = Yup.object().shape({
  description: Yup.string().required("description is required"),
});

export const addGrossingDescriptionSchema = Yup.object().shape({
  description: Yup.string().required("Description is required"),
});

export const addCaseTypeSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .matches(CHECK_ALPHANUMERIC_SPACE, "Name should be alphabetic"),
  description: Yup.string(),
});

export const addGrossingMacroSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  code: Yup.string().trim().required("Code is required"),
  description: Yup.string(),
});

export const addDiagnosisMacroSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  code: Yup.string().trim().required("Code is required"),
  description: Yup.string(),
  icd_ids: Yup.array().of(Yup.string()),
  cpt_ids: Yup.array().of(Yup.string()),
  // visit_type_id: Yup.string().required("Case Type is required"),
});

export const addCPTCodeSchema = Yup.object().shape({
  code: Yup.string().trim().required("Code is required"),
  description: Yup.string().required("Description is required"),
});

export const diagnoseMacroSchema = Yup.object().shape({
  diagnosis_name: Yup.string(),
  diagnosis_date: Yup.date()
    .transform(parseDateString)
    .max(today, "Cannot select future date")
    .required("Diagnosis date cannot be Empty")
    .typeError("Diagnosis date cannot be Empty"),
  description: Yup.string()
    .required("Diagnosis description cannot be Empty")
    .typeError("Diagnosis description cannot be Empty"),
  notes: Yup.string(),
  grossing_description: Yup.string().required(
    "grossing description is required"
  ),
  code: Yup.string(),
  cpt_codes: Yup.array().of(Yup.string()).required("CPT code is required"),
  icd10_codes: Yup.array().of(Yup.string()).required("ICD code is required"),
});
export const diagnoseMacroSchemaSignedOut = (isAddendum = false) =>
  Yup.object().shape({
    diagnosis_name: Yup.string(),
    diagnosis_date: Yup.date()
      .transform(parseDateString)
      .max(today, "Cannot select future date")
      .required("Diagnosis date cannot be Empty")
      .typeError("Diagnosis date cannot be Empty"),
    description: Yup.string()
      .required("Diagnosis description cannot be Empty")
      .typeError("Diagnosis description cannot be Empty"),
    notes: Yup.string(),
    grossing_description: Yup.string().required(
      "grossing description is required"
    ),
    code: Yup.string(),
    cpt_codes: Yup.array().of(Yup.string()).required("CPT code is required"),
    icd10_codes: Yup.array().of(Yup.string()).required("ICD code is required"),
    ...(!isAddendum && {
      amendment_comment: Yup.string().trim().required("Please provide reason"),
    }),
    ...(isAddendum && {
      addendum_comment: Yup.string()
        .trim()
        .required("Please provide the comment"),
    }),
  });

export const addPatientSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(CHECKALPHABETS, "First name should be alphabetic"),
  last_name: Yup.string()
    .required("Last name is required")
    // .matches(SPECIALCHARANDALPHABETS, "Last name should be alphabetic")
    .matches(
      /^[^\s]+(\s[^\s]+)*$/,
      "No spaces allowed at the beginning or end"
    ),
  gender: Yup.mixed()
    .oneOf(["male", "female"], "Please select a gender")
    .defined("Please select a gender"),
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is Required"),
  phone: Yup.string()
    .nullable()
    .trim()
    .required("Phone number is required")
    .min(2, "Phone number is required")
    .test("valid-phone", "Enter valid phone number", function (value) {
      return (
        2 < replaceSplCharFromPhone(value)?.length &&
        replaceSplCharFromPhone(value)?.length === 10
      );
    }),
  dob: Yup.date()
    .transform(parseDateString)
    .max(eighteenYearsAgo, "Patient has to be minimum 18 years old")
    .required("Date of birth can't be empty")
    .typeError("Invalid date format. Use mm-dd-yyyy format"),
  patient_insurance_attributes: Yup.object().shape({
    provider_id: Yup.string().trim().required("Provider is required"),
    policy: Yup.string().trim().required("Policy # is required"),
    patient_notes: Yup.string().trim(),
  }),
});

export const addStaffSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is Required"),
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(CHECKALPHABETS, "First name should be alphabetic"),
  last_name: Yup.string()
    .trim()
    .required("Last name is required")
    .matches(CHECKALPHABETS, "Last name should be alphabetic"),
  phone: Yup.string()
    .nullable()
    .trim()
    .required("Phone number is required")
    .min(2, "Phone number is required")
    .test("valid-phone", "Enter valid phone number", function (value) {
      return (
        2 < replaceSplCharFromPhone(value)?.length &&
        replaceSplCharFromPhone(value)?.length === 10
      );
    }),
  dob: Yup.date()
    .transform(parseDateString)
    .max(eighteenYearsAgo, "Staff has to be minimum 18 years old")
    .required("Date of birth can't be empty")
    .typeError("Invalid date format. Use mm-dd-yyyy format"),
  role_id: Yup.string()
    .required("Please select a role for the staff")
    .defined()
    .required("Kindly select a role for this staff"),
  signature: Yup.string().when("role_id", (val) => {
    if (val === "3") {
      return Yup.string().required("Designation is required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  pathologist_id: Yup.string()
    .test(
      "pathologist-test",
      "Please assign a pathologist for this user",
      function (value) {
        if (Number(this.parent.role_id) === 81) {
          if (value?.trim()?.length > 0) {
            return true;
          } else
            return this.createError({
              message: "Please assign a pathologist for this user",
              path: "pathologist_id",
            });
        } else {
          return true;
        }
      }
    )
    .typeError("Please assign a pathologist for this user 2"),
  gender: Yup.mixed()
    .oneOf(["male", "female"], "Please select a gender")
    .defined(),
});

export const addPhysicianSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is Required"),
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(CHECKALPHABETS, "First name should be alphabetic"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[A-Za-z]+(?:[-\s][A-Za-z]+)*$/, "Last name should be alphabetic")
    .matches(
      /^[^\s]+(\s[^\s]+)*$/,
      "No spaces allowed at the beginning or end"
    ),
  npi_number: Yup.string()
    .min(10, "Minimum 10 digits required")
    .required("NPI number is required")
    .nullable(),
  phone: Yup.string()
    .nullable()
    .trim()
    .required("Phone number is required")
    .min(2, "Phone number is required")
    .test("valid-phone", "Enter valid phone number", function (value) {
      return (
        2 < replaceSplCharFromPhone(value)?.length &&
        replaceSplCharFromPhone(value)?.length === 10
      );
    }),
  // dob: Yup.date()
  //   .transform(parseDateString)
  //   .max(eighteenYearsAgo, "Physician has to be minimum 18 years old")
  //   .required("Date of birth can't be empty")
  //   .typeError("Invalid date format. Use mm-dd-yyyy format"),
  role_id: Yup.string()
    .required("Please select a role for the staff")
    .defined()
    .required("Kindly select a role for this staff"),
  // gender: Yup.string().oneOf(["male", "female"]).nullable(true),
  // accession_prefix: Yup.string()
  //   .trim()
  //   .required("Accession prefix is required")
  //   .min(3)
  //   .max(5)
  //   .matches(CHECKALPHABETS, "Accession prefix should be alphabetic"),
  // user_addresses_attributes: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       address_attributes: Yup.object().shape({
  //         location_name: Yup.string()
  //           .trim()
  //           .required("Location name is required"),
  //         street1: Yup.string().trim().required("Street 1 is required"),
  //         street2: Yup.string().trim().required("Street 2 is required"),
  //         city: Yup.string().trim().required("City is required"),
  //         state: Yup.string().trim().required("State is required"),
  //         zipcode: Yup.string("Please enter valid zipcode")
  //           .matches(ZIP_REGEX, "Please enter valid zipcode")
  //           .required("Zipcode is required"),
  //       }),
  //     })
  //   )
  //   .required("Must have address attribtues")
  //   .min(1, "Minimum of 1 address is needed"),
  client_id: Yup.string().required(),
});

export const filterVisitListSchema = Yup.object().shape({
  filter_start_date: Yup.date()
    .transform(parseDateString)
    .typeError("Invalid date format. Use mm-dd-yyyy format"),
  filter_end_date: Yup.date()
    .transform(parseDateString)
    .typeError("Invalid date format. Use mm-dd-yyyy format")
    .min(Yup.ref("filter_start_date"), "End date can't be before Start date"),
  status: Yup.string(),
  report_status: Yup.string(),
  physician_id: Yup.string(),
  visit_type_id: Yup.string(),
  sort_by: Yup.string(),
  ordering: Yup.string(),
  visit_kind: Yup.string()
    .oneOf(
      [
        "pending",
        "past",
        "both",
        "pending_amendment",
        "pending_addendum",
        null,
      ],
      "Invalid visit kind"
    )
    .nullable(),
});

export const filterReferListSchema = Yup.object().shape({
  filter_start_date: Yup.date()
    .transform(parseDateString)
    .typeError("Invalid date format. Use mm-dd-yyyy format"),
  filter_end_date: Yup.date()
    .transform(parseDateString)
    .typeError("Invalid date format. Use mm-dd-yyyy format")
    .min(Yup.ref("filter_start_date"), "End date can't be before Start date"),
  report_status: Yup.string(),
  sort_by: Yup.string(),
  ordering: Yup.string(),
});

export const pendingAccessionistSchema = () => {
  return Yup.object().shape(
    {
      // courier_tracking_num: Yup.string().required("Tracking number is missing"),
      client_address_id: Yup.string().required(
        "Primary physician address is required"
      ),
      ucid: Yup.string().optional(),
      visit_type_id: Yup.string().required("Case type is required"),
      primary_physician_id: Yup.string().required(
        "Primary physician is required"
      ),
      secondary_physician_id: Yup.string(),
      specimen_demographics_attributes: Yup.array().of(
        Yup.object().shape({
          procedure_id: Yup.string().required("Procedure name is required"),
          site_id: Yup.string().required("Site name is required"),
          collected_date: Yup.date()
            .transform(parseDateString)
            .max(today, "Collected date cannot be a future date")
            .required("Collected date is required")
            .typeError("Collected date is required"),
          received_date: Yup.date()
            .transform(parseDateString)
            .max(today, "Received date cannot be a future date")
            .min(
              Yup.ref("collected_date"),
              "Received date must be after or equal to collected date"
            )
            .required("Received date is required")
            .typeError("Received date is required"),
          clinical_notes: Yup.string().required("Clinical note is missing"),
          // specimen_acknowledge: Yup.bool().required(),
        })
      ),
      patient_id: Yup.string().when("patient_attributes", {
        is: (patient_attributes) => {
          const { patient_insurance_attributes } = patient_attributes;
          const patAttr = _.omit(
            _.pickBy(patient_attributes, _.negate(_.isEmpty)),
            "patient_insurance_attributes"
          );
          const patInsAttr = _.pickBy(
            patient_insurance_attributes,
            _.negate(_.isEmpty)
          );
          return _.isEmpty(patAttr) && _.isEmpty(patInsAttr);
        },
        then: Yup.string().required(
          "Select a patient from the list or create new patient"
        ),
        otherwise: Yup.string(),
      }),
      patient_attributes: Yup.object()
        .shape({})
        .when("patient_id", {
          is: (patient_id) => _.isEmpty(patient_id),
          then: addPatientSchema,
          otherwise: Yup.object().shape({}),
        }),
    },
    [
      ["patient_attributes", "patient_id"],
      ["patient_id", "patient_attributes"],
    ]
  );
};

export const addClinicLocationSchema = Yup.object().shape({
  location_name: Yup.string().trim().required("Location name is required"),
  street1: Yup.string().trim().required("Address Line 1 is required"),
  street2: Yup.string().trim().required("Address Line 2 is required"),
  city: Yup.string().trim().required("City is required"),
  state: Yup.string().trim().required("State is required"),
  zipcode: Yup.string("Please enter valid zipcode")
    .matches(ZIP_REGEX, "Please enter valid zipcode")
    .required("Zipcode is required"),
});

export const addClinicStaffSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email is Required"),
  first_name: Yup.string()
    .trim()
    .required("First name is required")
    .matches(CHECKALPHABETS, "First name should be alphabetic"),
  last_name: Yup.string()
    .trim()
    .required("Last name is required")
    .matches(CHECKALPHABETS, "Last name should be alphabetic"),
  phone: Yup.string()
    .nullable()
    .trim()
    .required("Phone number is required")
    .min(2, "Phone number is required")
    .test("valid-phone", "Enter valid phone number", function (value) {
      return (
        2 < replaceSplCharFromPhone(value)?.length &&
        replaceSplCharFromPhone(value)?.length === 10
      );
    }),
  dob: Yup.date()
    .transform(parseDateString)
    .max(eighteenYearsAgo, "Staff has to be minimum 18 years old")
    .required("Date of birth can't be empty")
    .typeError("Invalid date format. Use mm-dd-yyyy format"),
  location_id: Yup.string()
    .required("Please select a location for the staff")
    .defined()
    .required("Kindly select a location for this staff"),
  role_id: Yup.string()
    .required("Please select a role for the staff")
    .defined()
    .required("Kindly select a role for this staff"),
  gender: Yup.mixed()
    .oneOf(["male", "female"], "Please select a gender")
    .defined(),
});
