import React from "react";
import AddMacro from "../Macro/AddMacro";
import ListMacro from "../Macro/ListMacro";
import ViewMacro from "../Macro/ViewMacro";

export default function Macro({ mode, id, type }) {
  if (mode === "add") {
    return <AddMacro type={type} />;
  } else if (mode === "edit") {
    return <AddMacro id={id} type={type} />;
  } else if (mode === "view") {
    return <ViewMacro id={id} type={type} />;
  }
  return <ListMacro type={type} />;
}
