import React, { useState } from "react";
import Table from "../../stories/Table";
import { Link, navigate } from "@reach/router";
import { format } from "date-fns";
import { getErrorData, getURLParams, handleQuery } from "../../service/Helper";
import { useMacroList } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import _ from "lodash";

const perPageCount = 10;

const ListMacro = ({ type }) => {
  const currentPage = getURLParams("page") ?? 1;
  const searchQ = getURLParams("search") ?? "";

  const [searchQuery, setSearchQuery] = useState(searchQ || "");
  const [page, setPage] = useState(currentPage);

  const link = `${type}-macros`;

  const { macros, pageInfo, loading, error, isValidating, mutate } =
    useMacroList({
      type,
      currentPage: page,
      perPageCount,
      searchQuery: searchQ,
    });
  const loadingData = loading || isValidating;

  const content = (
    <Table
      headers={["Macro ID", "Code", "Name", "Date Added"]}
      pagination={{
        currentpage: pageInfo.current_page,
        itemsPerPage: pageInfo.per_page,
        totalItems: pageInfo.total_count,
        onPageChange: (page) => {
          handleQuery(navigate, page, searchQ, link);
        },
      }}
      loading={loadingData}
      rowContent={macros.map((macro) => [
        <p className="underline text-primary w-24 mx-auto">
          <Link to={`/dashboard/${link}/view/${macro.id}`}>{macro.id}</Link>
        </p>,
        <p className="uppercase w-40 truncate mx-auto">{macro.code ?? "--"}</p>,
        <p className="w-40 truncate mx-auto">
          {macro.name.length > 0 ? macro?.name : "--"}
        </p>,
        <p>{format(new Date(macro?.created_at), "dd-MMM-yyyy")}</p>,
      ])}
    />
  );

  const headerData = {
    constructiveCTA: {
      link: `/dashboard/${link}/add`,
      title: `Add Macro`,
    },
    states: [
      {
        title: `${_.startCase(type)} Macro List`,
      },
    ],
    title: `List of ${type} macros`,
    search: {
      show: true,
      searching: searchQuery.length && loadingData ? true : false,
      placeholder: "Search using Macro Code or Name...",
      onSearch: (values) => {
        setPage(1);
        handleQuery(navigate, 1, values.query, link);
        setSearchQuery(values.query);
      },
      onChange: (values) => {
        if (!values.query) {
          setSearchQuery("");
        }
      },
    },
  };

  const errorData = getErrorData({
    error,
    searchQuery,
    data: macros,
    mutate,
    title:
      type === "diagnosis"
        ? "Diagnosis Macros"
        : type === "grossing"
        ? "Grossing Macros"
        : "CPT Codes",
  });

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      errorData={errorData}
      loading={loadingData}
      loadingContent={null}
    />
  );
};

export default ListMacro;
