/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/accessible-emoji */
import { Formik, Form, FieldArray, Field } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  usePatientList,
  usePhysicianList,
  useCaseTypeList,
  useProviders,
  useProcedureList,
  useSiteList,
} from "../../service/Fetchers";
import { Loader, Search } from "../../service/Icon";
import { pendingAccessionistSchema } from "../../service/Validation";
import { Button } from "../../stories/Button";
import { FormInputField } from "../../stories/FormInputField";
import _ from "lodash";
import { FormikObserver } from "../../service/Helper";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import ConfirmDialog from "../../stories/ConfirmDialog";
import Table from "../../stories/Table";
import StatusText from "./StatusText";
import CreatableSelect from "react-select/creatable";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import { format, subDays } from "date-fns/esm";
import "./modal_styles.css";
import { parse } from "date-fns";
import { useGetClientDetails } from "../../service/Api_services/GetClientDetails/useGetClientDetails";
import { useLocation } from "@reach/router";

export default function AccessionistPending({
  loading,
  visitDetails,
  refetch,
  tracking_num,
  totalCases = 1,
  formikRef = { current: {} },
  addEmptyCase,
}) {
  const { clinical_info, patient, specimen_demographics } = visitDetails;
  const minTabIndex = 7 + totalCases + 1;
  const { visit_type, primary_physician, secondary_physician } =
    clinical_info ?? {};

  const physician_address = primary_physician?.address ?? null;

  const [trackIndex, setTrackIndex] = useState(0);
  const [selectedPrimaryPhysician, setSelectedPrimaryPhysician] = useState(
    primary_physician
      ? {
          ...primary_physician,
          user_addresses: physician_address
            ? [{ id: physician_address.id, address: physician_address }]
            : [],
        }
      : null
  );
  const [updating, setUpdating] = useState(false);
  const [createPatient, setCreatePatient] = useState(false);
  const [siteSearchQuery, setSiteSearchQuery] = useState(
    specimen_demographics && specimen_demographics.length > 0
      ? specimen_demographics?.map(
          (specimen) =>
            `${specimen?.site?.name} - ${specimen?.site?.description}`
        )
      : [""]
  );
  const [siteIndex, setSiteIndex] = useState(0);
  const [procedureSearchQuery, setProcedureSearchQuery] = useState(
    specimen_demographics && specimen_demographics.length > 0
      ? specimen_demographics?.map((specimen) => specimen.procedure.name)
      : [""]
  );

  const [procedureIndex, setProcedureIndex] = useState(0);
  const [patientSearchQuery, setPatientSearchQuery] = useState(
    patient ? `${patient.first_name} ${patient.last_name}` : ""
  );
  const [primPhysicianSearchQuery, setPrimPhysicianSearchQuery] = useState(
    primary_physician
      ? `${primary_physician.first_name} ${primary_physician.last_name}`
      : ""
  );
  const [secPhysicianSearchQuery, setSecPhysicianSearchQuery] = useState(
    secondary_physician
      ? `${secondary_physician.first_name} ${secondary_physician.last_name}`
      : ""
  );
  const { patients, loading: patientSearching } = usePatientList({
    searchQuery: patientSearchQuery,
  });

  const [patientHistory, setPatientHistory] = useState([]);
  const { openPatientHistory, setOpenPatientHistory } = useContext(
    GlobalVariablesContext
  );
  const disabled =
    visitDetails?.courier_info?.visit_completed ||
    visitDetails?.status !== "pending" ||
    false;

  const location = useLocation();
  const {
    client_info,
    loading: fetchingClientInfo,
    client_address,
  } = useGetClientDetails({
    id: location.state.value,
  });

  // console.log(client_info.courier.client.client_addresses);

  useEffect(() => {
    if (!patient?.id) return;
    const currentPatient = patients.find((p) => p.id === patient.id);
    if (currentPatient) {
      setPatientHistory(
        currentPatient?.patient_history?.visits.filter(
          (v) => v.ucid !== visitDetails.ucid
        ) ?? []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients]);

  const { users: primPhysicianList, loading: loadingPrimPhysicianList } =
    usePhysicianList({
      searchQuery: primPhysicianSearchQuery,
      clientId: client_info?.courier?.client?.id,
    });
  const { users: secPhysicianList, loading: loadingSecPhysicianList } =
    usePhysicianList({
      secPhysicianSearchQuery,
      clientId: client_info?.courier?.client?.id,
    });

  const { providers } = useProviders();
  const { procedures, loading: procedureLoading } = useProcedureList({
    searchQuery: procedureSearchQuery[procedureIndex],
  });
  const { sites, loading: siteLoading } = useSiteList({
    searchQuery: siteSearchQuery[siteIndex],
  });

  const { caseTypes } = useCaseTypeList({
    perPageCount: 100,
  });
  var addressSelectRef = useRef(null);
  const submitButton = useRef(null);
  const onFormChange = (values) => {
    // uncomment the below lines to print valdiation error
    // pendingAccessionistSchema()
    //   .validate(values)
    //   .catch(function (e) {
    //     console.log(e);
    //   });
    // if (!_.isEmpty(selectedPrimaryPhysician)) {
    //   if (
    //     _.find(selectedPrimaryPhysician?.user_addresses, [
    //       "id",
    //       values.client_address_id,
    //     ]) === undefined
    //   )
    //     if (addressSelectRef?.current?.select) {
    //       addressSelectRef.current.select.clearValue();
    //     }
    // }
  };

  // uncomment this code if any issue facing in the collected and received date

  // useEffect(() => {
  //   setTimeout(() => {
  //     const dobDiv = document.getElementById("patientDob");
  //     const collectedDateDiv = document.getElementById("collectedDate");
  //     const receivedDateDiv = document.getElementById("recievedDate");
  //     const dobInputs = dobDiv.getElementsByClassName(
  //       "react-date-picker__inputGroup__input"
  //     );
  //     const collectedInputs = collectedDateDiv.getElementsByClassName(
  //       "react-date-picker__inputGroup__input"
  //     );
  //     const receivedDateInputs = receivedDateDiv.getElementsByClassName(
  //       "react-date-picker__inputGroup__input"
  //     );
  //     var dobInputList = Array.prototype.slice.call(dobInputs);
  //     var collectedInputList = Array.prototype.slice.call(collectedInputs);
  //     var receivedDateInputList =
  //       Array.prototype.slice.call(receivedDateInputs);

  //     if (dobInputList?.length) {
  //       dobInputList.forEach((input, index) => {
  //         if (input) {
  //           input.tabIndex = getTabIndex(6 + index);
  //         }
  //       });
  //       const buttons = dobDiv.getElementsByClassName(
  //         "react-date-picker__calendar-button"
  //       );
  //       let buttonList = Array.prototype.slice.call(buttons);
  //       if (buttonList?.length) {
  //         buttonList[0].tabIndex = -1;
  //       }
  //     }
  //     if (collectedInputList?.length) {
  //       collectedInputList.forEach((input, index) => {
  //         if (input) {
  //           input.tabIndex = getTabIndex(17 + index);
  //         }
  //       });
  //       const buttons = collectedDateDiv.getElementsByClassName(
  //         "react-date-picker__calendar-button"
  //       );
  //       let buttonList = Array.prototype.slice.call(buttons);
  //       if (buttonList?.length) {
  //         buttonList[0].tabIndex = -1;
  //       }
  //     }
  //     if (receivedDateInputList?.length) {
  //       receivedDateInputList.forEach((input, index) => {
  //         if (input) {
  //           input.tabIndex = getTabIndex(21 + index);
  //         }
  //       });
  //       const buttons = receivedDateDiv.getElementsByClassName(
  //         "react-date-picker__calendar-button"
  //       );
  //       let buttonList = Array.prototype.slice.call(buttons);
  //       if (buttonList?.length) {
  //         buttonList[0].tabIndex = -1;
  //       }
  //     }
  //   }, 500);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // do not load the form until loading is API calls are completed

  if (loading) return <Loader />;
  if (!visitDetails) return null;

  const createNewPatient = (setFieldValue, e) => {
    const split_names = _.compact(e?.id?.split(" "));
    const first_name = split_names?.length > 0 ? split_names[0] : "";
    const last_name =
      split_names?.length > 1 ? split_names[split_names?.length - 1] : "";
    setFieldValue("patient_id", "");
    setPatientSearchQuery("");
    setFieldValue("patient_attributes", {
      first_name: first_name ?? "",
      last_name: last_name ?? "",
      gender: "",
      dob: "",
      email: "",
      phone: "",
      patient_insurance_attributes: {
        provider_id: "",
        policy: "",
        patient_notes: "",
      },
    });
  };

  const onTapViewHistory = () => {
    setOpenPatientHistory(true);
  };

  const createOrUpdateCase = async (values) => {
    // console.log({ values });
    if (visitDetails?.patient?.id)
      return API.put("/visits/progress-update", {
        visit: values,
      });
    return API.post("/visits", {
      visit: values,
    });
  };

  const settingPatientInformation = (
    e,
    action,
    setFieldValue,
    setFieldError
  ) => {
    switch (action) {
      case "select-option":
        // Due to bug, strictly checking if its a new creation.
        if (e.__isNew__) {
          createNewPatient(setFieldValue, e);
          setCreatePatient(true);
        } else {
          setFieldValue("patient_id", e?.id);
          setFieldValue("patient_attributes", {
            first_name: e?.first_name,
            last_name: e?.last_name,
            gender: e?.gender,
            dob: e?.dob,
            email: e?.email,
            phone: e?.phone,
            patient_insurance_attributes: {
              provider_id: e?.insurance_details?.provider?.id,
              policy: e?.insurance_details?.policy,
              patient_notes: e?.insurance_details?.patient_notes,
            },
          });
          setPatientHistory(e?.patient_history?.visits);
          setPatientSearchQuery("");
          setFieldError("patient_attributes", {});
        }
        break;
      case "create-option":
        createNewPatient(setFieldValue, e);
        setCreatePatient(true);
        const inputs = document.getElementsByTagName("input");
        const splitList = _.compact(e?.id?.split(" "));
        var inputList = Array.prototype.slice.call(inputs);
        const firstElement = inputList.find(
          (input) =>
            input?.name ===
            (splitList?.length > 1
              ? "patient_attributes.gender"
              : "patient_attributes.last_name")
        );
        setFieldError("patient_attributes", {});
        setTimeout(() => {
          if (firstElement?.focus) {
            firstElement.focus();
          }
        }, 500);
        break;
      case "clear":
        createNewPatient(setFieldValue, e);
        setCreatePatient(false);
        break;
      default:
        break;
    }
  };

  // For changing dropdown indicator to search icon
  const DropdownIndicator = () => {
    return (
      <div className="mx-2">
        <Search />
      </div>
    );
  };

  const getTabIndex = (count) => {
    if (disabled) {
      return -1;
    } else {
      return minTabIndex + count;
    }
  };

  function getPreviousWorkingDay() {
    const date = new Date();
    const today = format(date, "eeee");
    if (today === "Monday") {
      const previousWorkingDay = subDays(date, 3);
      return format(previousWorkingDay, "MM-dd-yyyy");
    } else {
      const previousWorkingDay = subDays(date, 1);
      return format(previousWorkingDay, "MM-dd-yyyy");
    }
  }

  const DisplayheaderSpecimen = (length) => {
    const alphabets = [];
    for (let i = 0; i < length; i++) {
      alphabets.push(String.fromCharCode(65 + i));
    }
    return alphabets;
  };

  const onUpdateAccNumnber = async (values) => {
    const data = await API.put(
      `/visits/${visitDetails?.id}/update-accession-number`,
      {
        ucid: values.case_number,
      }
    );

    if (data.data) {
      toast.success(data.data.message);
      refetch();
    } else {
      toast.error(data.error.message);
    }
  };

  return (
    <>
      <ConfirmDialog
        size="4xl"
        isOpen={openPatientHistory}
        title="Patient History"
        description={
          patientHistory && patientHistory.length > 0 ? (
            <Table
              headers={["CaseID", "Date Added", "Status", "Referred by"]}
              pagination={null}
              loading={loading}
              rowContent={patientHistory.map((visit) => [
                <a
                  className="text-primary underline"
                  href={`/case-view/${visit.id}`}
                  target="_blank"
                >
                  {visit.ucid}
                </a>,
                <p>{new Date(visit.created_at).toDateString()}</p>,
                <p className="text-orange-500 font-bold">
                  <StatusText outline={false} status={visit.status} />
                </p>,
                <p>
                  {visit.clinical_info.primary_physician.first_name}{" "}
                  {visit.clinical_info.primary_physician.last_name}
                </p>,
              ])}
            />
          ) : null
        }
        cancelTitle="Close"
        onPressCancel={() => setOpenPatientHistory(false)}
        icon={null}
      ></ConfirmDialog>

      {!visitDetails?.ucid.includes("XXX-XXXXXX") && (
        <Formik
          enableReinitialize
          initialValues={{
            case_number: visitDetails?.ucid ?? "",
          }}
          onSubmit={onUpdateAccNumnber}
        >
          <Form className="flex flex-col">
            <div className="mb-2 text-sm font-normal">
              Note : This will permanently update the selected case number.
            </div>
            <div className=" flex items-center gap-4">
              <FormInputField
                className="w-1/3"
                name="case_number"
                type="text"
                disabled={false}
              />
              <Button
                formButton
                size="small"
                type="submit"
                className="border p-2.5 px-4"
              >
                Update
              </Button>
            </div>
            <div className="h-0.5 border mb-4 -mt-2 border-gray-200" />
          </Form>
        </Formik>
      )}
      <Formik
        validateOnChange={false}
        innerRef={formikRef}
        // enableReinitialize
        initialValues={{
          id: visitDetails.id,
          // courier_tracking_num: courier_info?.courier_tracking_num ?? "",
          client_address_id: visitDetails?.client_address?.id ?? "",
          ucid: visitDetails?.ucid ?? "",
          visit_type_id: `${visit_type?.id ?? "1"}`,
          patient_id: visitDetails?.patient?.id ?? "",
          primary_physician_id: primary_physician?.id ?? "",
          secondary_physician_id: secondary_physician?.id ?? "",
          specimen_demographics_attributes:
            specimen_demographics && specimen_demographics?.length > 0
              ? specimen_demographics?.map((specimen) => {
                  return {
                    procedure_id: specimen?.procedure?.name,
                    clinical_notes: specimen?.clinical_notes,
                    site_id: `${specimen?.site?.name} - ${specimen?.site?.description}`,
                    collected_date: specimen?.collected_date,
                    received_date: specimen?.received_date,
                    // specimen_acknowledge:
                    //   specimen?.specimen_acknowledge || false,
                  };
                })
              : [
                  {
                    procedure_id: "",
                    clinical_notes: "",
                    site_id: "",
                    collected_date: parse(
                      getPreviousWorkingDay(),
                      "MM-dd-yyyy",
                      new Date()
                    ),
                    received_date: parse(
                      format(new Date(), "MM-dd-yyyy"),
                      "MM-dd-yyyy",
                      new Date()
                    ),
                    // specimen_acknowledge: false,
                  },
                ],
          patient_attributes: {
            first_name: patient?.first_name ?? "",
            last_name: patient?.last_name ?? "",
            gender: patient?.gender ?? "",
            dob: patient?.dob ?? "",
            email: patient?.email ?? "",
            phone: patient?.phone ?? "",
            patient_insurance_attributes: {
              provider_id: patient?.insurance_details?.provider?.id ?? "",
              policy: patient?.insurance_details?.policy ?? "",
              patient_notes: patient?.insurance_details?.patient_notes ?? "",
            },
            patient_name: `${patient?.first_name} ${patient?.last_name}` || "",
          },
        }}
        validationSchema={pendingAccessionistSchema(createPatient)}
        onSubmit={async (values) => {
          try {
            let updatedValues = {
              ...values,
              patient_attributes: {
                ...values.patient_attributes,
                dob: format(
                  new Date(values.patient_attributes.dob),
                  "dd-MM-yyyy"
                ),
              },
              specimen_demographics_attributes:
                values.specimen_demographics_attributes.map(
                  (specimen, index) => {
                    const label = String.fromCharCode(65 + (index % 26));
                    return {
                      ...specimen,
                      collected_date: format(
                        new Date(specimen.collected_date),
                        "dd-MM-yyyy"
                      ),
                      received_date: format(
                        new Date(specimen.received_date),
                        "dd-MM-yyyy"
                      ),
                      ...(values?.specimen_demographics_attributes.length >
                        1 && {
                        label: label,
                      }),
                    };
                  }
                ),
              courier_id: tracking_num,
            };

            if (values.id && values.id.includes("new-")) {
              delete updatedValues.id;
            }

            const update = _.omit(updatedValues, [
              createPatient ? "patient_id" : "patient_attributes",
              visitDetails?.patient?.id ? "" : "id",
              visitDetails?.patient?.id ? "" : "ucid",
            ]);

            // console.log({ update, updatedValues }, "data");
            setUpdating(true);

            const { error, data } = await createOrUpdateCase(update);

            if (error) {
              submitButton.current.shake();
              if (error.message) toast.error(error.message);
            }
            if (data) {
              if (data.message === "visit added successfully") {
                if (addEmptyCase) addEmptyCase();
                toast.success(`Case created successfully`);
              } else {
                toast.success(`Updated!`);
              }
              if (refetch) {
                refetch();
              } else {
                setTimeout(() => {
                  window.location.href = "/case-view";
                }, 1500);
              }
            }
            setUpdating(false);
          } catch (error) {
            console.error("Error occurred during form submission:", error);
            setUpdating(false);
            // Handle error accordingly, e.g., show error message to the user
          }
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          setFieldError,
        }) => {
          return (
            <Form>
              <FormikObserver
                value={values}
                onChange={(values) => onFormChange(values, setFieldValue)}
              />
              <div className="flex flex-row space-x-4">
                {/* <pre>
                  {JSON.stringify(
                    values.specimen_demographics_attributes,
                    null,
                    4
                  )}
                </pre> */}
                <div className="flex flex-col w-1/3 space-y-4">
                  {/* <div className="flex flex-col p-4 border border-gray-200">
                    <span className="text-primary mb-4">
                      Courier Information
                    </span>
                    <FormInputField
                      type="text"
                      title="FedEx Tracking #"
                      name="courier_tracking_num"
                      required
                    />
                  </div> */}
                  <div className="space-y-3 divide-y border">
                    <div className="py-2 px-4 flex flex-col">
                      <p className="capitalize underline font-semibold text-lg leading-7">
                        {!visitDetails?.ucid.includes("XXX-XXXXXX")
                          ? visitDetails?.ucid
                          : "New Case"}
                      </p>
                      {fetchingClientInfo ? (
                        <p className="mt-8">Fetching...</p>
                      ) : (
                        <div className="mt-6 grid grid-cols-2 gap-4">
                          <div>
                            <label className="font-light text-sm leading-5">
                              Client Name
                            </label>
                            <p className="capitalize text-base font-normal leading-6 mt-1">
                              {client_info?.courier?.client?.name}
                            </p>
                          </div>
                          <div>
                            <label className="font-light text-sm leading-5">
                              Client Type
                            </label>
                            <p className="text-base font-normal leading-6 mt-1">
                              {!client_info?.courier?.client?.interface
                                ? "Hand-Key"
                                : "Hand-Key, Interface"}
                            </p>
                          </div>
                          <div>
                            <label className="font-light text-sm leading-5">
                              Courier Partner
                            </label>
                            <p className="text-base font-normal leading-6 mt-1">
                              {client_info?.courier?.name}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col py-2 px-4">
                      <div className="flex flex-row justify-between items-start">
                        <span className="text-primary mb-1 text-sm">
                          Patient Information
                        </span>

                        {createPatient && (
                          <button
                            type="button"
                            className="mt-1 text-xxs text-gray-600 font-bold antialiased uppercase"
                            onClick={() => {
                              setCreatePatient(false);
                              setPatientSearchQuery("");
                            }}
                            tabIndex={getTabIndex(0)}
                          >
                            🔍 Search existing patient
                          </button>
                        )}
                      </div>

                      <div
                        className={`flex flex-col ${
                          !createPatient && "hidden"
                        }`}
                      >
                        <FormInputField
                          type="text"
                          name="patient_attributes.first_name"
                          title="first name"
                          disabled={disabled}
                          required
                          smVariant
                          inputProps={{ tabIndex: getTabIndex(1) }}
                        />
                        <FormInputField
                          type="text"
                          title="last name"
                          name="patient_attributes.last_name"
                          required
                          disabled={disabled}
                          smVariant
                          inputProps={{ tabIndex: getTabIndex(2) }}
                        />
                      </div>

                      {disabled ? (
                        <FormInputField
                          type="text"
                          title="Patient name"
                          name="patient_attributes.patient_name"
                          required
                          disabled
                          smVariant
                          inputProps={{ tabIndex: getTabIndex(3) }}
                        />
                      ) : (
                        <CreatableSelect
                          key={createPatient}
                          name="patient_id"
                          placeholder="Search Patient"
                          className={`flex flex-col mt-2 mb-4 ${
                            createPatient && "hidden"
                          }`}
                          tabIndex={getTabIndex(4)}
                          isClearable={true}
                          options={patients}
                          defaultOptions={patients}
                          isLoading={patientSearching}
                          getOptionLabel={(option) =>
                            `${option.last_name} ${option.first_name} ${
                              option?.dob
                                ? `( ${format(
                                    new Date(option.dob),
                                    "MM-dd-yyyy"
                                  )} )`
                                : ""
                            }`
                          }
                          getOptionValue={(option) => option.id}
                          onInputChange={setPatientSearchQuery}
                          onChange={(e, { action }) =>
                            settingPatientInformation(
                              e,
                              action,
                              setFieldValue,
                              setFieldError
                            )
                          }
                          components={{ DropdownIndicator }}
                          isDisabled={disabled}
                          menuIsOpen={disabled ? false : !!patientSearchQuery}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            first_name: optionLabel,
                            last_name: "",
                            __isNew__: true,
                          })}
                        />
                      )}
                      {!createPatient &&
                        Object.keys(errors?.patient_attributes || {})?.length >
                          0 && (
                          <div
                            style={{ marginTop: -10 }}
                            className="text-xxs text-red-500"
                          >
                            Patient Information is required
                          </div>
                        )}
                      <div
                        className={`flex flex-col ${
                          !values?.patient_id && !createPatient && "hidden"
                        }`}
                      >
                        <FormInputField
                          type="select"
                          title="Gender At Birth"
                          name="patient_attributes.gender"
                          options={[
                            { name: "Male", value: "male" },
                            { name: "Female", value: "female" },
                          ]}
                          smVariant
                          required
                          disabled={!createPatient}
                          inputProps={{
                            tabIndex: !createPatient ? "-1" : getTabIndex(5),
                          }}
                        />

                        <div id="keep-calendar-open" className="flex w-full">
                          <FormInputField
                            type="custom-date"
                            name="patient_attributes.dob"
                            title="dob"
                            required
                            disabled={!createPatient}
                            smVariant
                            inputProps={{}}
                            customDateProps={{
                              dateValue: values.patient_attributes.dob,
                              tabIndex: !createPatient ? "-1" : getTabIndex(6),
                              format: "MM-dd-yyyy",
                              dateOnChange: (val) => {
                                setFieldTouched("patient_attributes.dob", true);
                                setFieldValue("patient_attributes.dob", val);
                              },
                            }}
                          />
                        </div>
                        <FormInputField
                          title="Email"
                          type="email"
                          name="patient_attributes.email"
                          smVariant
                          required
                          disabled={!createPatient}
                          inputProps={{
                            tabIndex: !createPatient ? "-1" : getTabIndex(9),
                          }}
                        />

                        <FormInputField
                          title="Phone Number"
                          type="number"
                          name="patient_attributes.phone"
                          smVariant
                          required
                          disabled={!createPatient}
                          inputProps={{
                            tabIndex: !createPatient ? "-1" : getTabIndex(10),
                            onKeyDown: (e) => {
                              if (
                                values.patient_attributes.phone.toString()
                                  .length >= 10 &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            },
                          }}
                        />

                        <FormInputField
                          type="select"
                          name="patient_attributes.patient_insurance_attributes.provider_id"
                          title="Insurance Provider"
                          options={providers.map((p) => ({
                            name: p.name,
                            value: p.id,
                          }))}
                          smVariant
                          required
                          disabled={!createPatient}
                          inputProps={{
                            tabIndex: !createPatient ? "-1" : getTabIndex(11),
                          }}
                        />
                        <FormInputField
                          type="text"
                          name="patient_attributes.patient_insurance_attributes.policy"
                          title="Policy #"
                          required
                          smVariant
                          disabled={!createPatient}
                          inputProps={{
                            tabIndex: !createPatient ? "-1" : getTabIndex(12),
                          }}
                        />
                        <FormInputField
                          type="text"
                          name="patient_attributes.patient_insurance_attributes.patient_notes"
                          title="Patient Notes"
                          disabled={!createPatient}
                          inputProps={{
                            tabIndex: !createPatient ? "-1" : getTabIndex(13),
                          }}
                          smVariant
                        />
                      </div>

                      {values.patient_id &&
                        !createPatient &&
                        patientHistory?.length > 0 && (
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-gray-300 px-3 py-1 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                            onClick={onTapViewHistory}
                            tabIndex={getTabIndex(14)}
                          >
                            View history
                          </button>
                        )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-2/3">
                  <div className="flex flex-col pt-2 px-4 border border-gray-200">
                    <span className="text-primary mb-1 text-sm">
                      Clinical Information
                    </span>
                    <FormInputField
                      title="Case Type"
                      type="radio"
                      disabled={disabled}
                      name="visit_type_id"
                      options={_.map(caseTypes, (caseType) => ({
                        name: _.startCase(caseType.name),
                        value: `${caseType.id}`,
                      }))}
                      required
                      smVariant
                      hideError
                      inputProps={{ tabIndex: getTabIndex(13) }}
                    />
                    <div style={{ height: 5 }} />
                    <div className="flex flex-col">
                      <div className="flex flex-row w-full space-x-4">
                        <FormInputField
                          className="w-1/2"
                          type="autocomplete"
                          disabled={disabled}
                          name="primary_physician_id"
                          title="Referring Provider"
                          required
                          smVariant
                          autocompleteProps={{
                            setFieldValue,
                            valueKey: "id",
                            renderCustomLabel: (item) =>
                              `${item.first_name} ${item.last_name}`,
                            items: primPhysicianList || [],
                            loading: loadingPrimPhysicianList,
                            value: primPhysicianSearchQuery,
                            setValue: setPrimPhysicianSearchQuery,
                            onSelect: setSelectedPrimaryPhysician,
                            renderAfterType: false,
                          }}
                          inputProps={{
                            placeholder: "Search Physician",
                            tabIndex: getTabIndex(14),
                          }}
                        />
                        <FormInputField
                          className="w-1/2"
                          smVariant
                          type="autocomplete"
                          disabled={disabled}
                          name="secondary_physician_id"
                          title="Provider 2"
                          autocompleteProps={{
                            setFieldValue,
                            valueKey: "id",
                            renderCustomLabel: (item) =>
                              `${item.first_name} ${item.last_name}`,
                            items: secPhysicianList || [],
                            loading: loadingSecPhysicianList,
                            value: secPhysicianSearchQuery,
                            setValue: setSecPhysicianSearchQuery,
                            renderAfterType: false,
                          }}
                          inputProps={{
                            placeholder: "Search Physician",
                            tabIndex: getTabIndex(15),
                          }}
                        />
                      </div>
                      <div className="flex flex-row space-x-4">
                        {/* <FormInputField
                          className="w-1/2"
                          smVariant
                          type="readonly"
                          title="Accession Prefix"
                          value={selectedPrimaryPhysician?.accession_prefix}
                          disabled
                          required
                        ></FormInputField> */}
                        <FormInputField
                          className="w-1/2"
                          name="client_address_id"
                          type="custom-select"
                          smVariant
                          title="Client Location"
                          required
                          options={(client_address ?? []).map(
                            ({ id, location_name, city, state }) => {
                              return {
                                value: id,
                                id,
                                location_name,
                                label: `${location_name}`,
                              };
                            }
                          )}
                          selectProps={{
                            defaultValue: visitDetails?.client_address?.id
                              ? {
                                  ...visitDetails?.client_address,
                                  value: visitDetails?.client_address?.id,
                                  label: `${visitDetails?.client_address?.location_name}`,
                                }
                              : null,
                            ref: addressSelectRef,
                            isDisabled: disabled,
                            tabIndex: getTabIndex(16),
                          }}
                        ></FormInputField>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pt-2 pb-2 px-4 border border-gray-200">
                    <span className="text-primary mb-1 text-sm">
                      Basic demographics
                    </span>
                    <FieldArray
                      name="specimen_demographics_attributes"
                      render={(arrayHelpers) => {
                        return (
                          <div className="divide-y">
                            {values.specimen_demographics_attributes.map(
                              (specimen, index) => {
                                return (
                                  <div id={index}>
                                    {disabled && index > 0 && (
                                      <div className="mb-3 w-full h-px bg-gray-200" />
                                    )}
                                    <p className="capitalize my-4">
                                      {!visitDetails.ucid.includes("XXX-XXXXXX")
                                        ? visitDetails.ucid
                                        : "Specimen"}
                                      {values.specimen_demographics_attributes
                                        .length > 1 &&
                                        `${"_"}${
                                          DisplayheaderSpecimen(
                                            values
                                              .specimen_demographics_attributes
                                              .length
                                          )[index]
                                        }`}
                                    </p>
                                    <div className="flex flex-row space-x-4">
                                      <div
                                        id="keep-calendar-open"
                                        className="flex w-full"
                                      >
                                        <FormInputField
                                          type="custom-date"
                                          name={`specimen_demographics_attributes.${index}.collected_date`}
                                          title="collected date"
                                          required
                                          disabled={disabled}
                                          primaryVariant={true}
                                          inputProps={{}}
                                          customDateProps={{
                                            dateValue: specimen.collected_date,
                                            tabIndex: getTabIndex(17),
                                            maxDate: new Date(),
                                            format: "MM-dd-yyyy",
                                            dateOnChange: (val) => {
                                              setFieldTouched(
                                                `specimen_demographics_attributes.${index}.collected_date`,
                                                true
                                              );

                                              setFieldValue(
                                                `specimen_demographics_attributes.${index}.collected_date`,
                                                val
                                              );
                                            },
                                          }}
                                        />
                                      </div>

                                      <div
                                        id="keep-calendar-open"
                                        className="flex w-full"
                                      >
                                        <FormInputField
                                          type="custom-date"
                                          name={`specimen_demographics_attributes.${index}.received_date`}
                                          title="received date"
                                          required
                                          primaryVariant={true}
                                          disabled={disabled}
                                          customDateProps={{
                                            dateValue: specimen.received_date,
                                            tabIndex: getTabIndex(21),
                                            format: "MM-dd-yyyy",
                                            maxDate: new Date(),
                                            dateOnChange: (val) => {
                                              setFieldTouched(
                                                `specimen_demographics_attributes.${index}.received_date`,
                                                true
                                              );
                                              setFieldValue(
                                                `specimen_demographics_attributes.${index}.received_date`,
                                                val
                                              );
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex flex-row space-x-4">
                                      <FormInputField
                                        type="autocomplete"
                                        name={`specimen_demographics_attributes.${index}.procedure_id`}
                                        title="procedure name"
                                        className={`flex flex-col w-1/2`}
                                        required
                                        smVariant
                                        autocompleteProps={{
                                          setFieldValue,
                                          valueKey: "id",
                                          labelKey: "name",
                                          items:
                                            procedureSearchQuery[index]
                                              ?.length > 0
                                              ? procedures
                                              : [],
                                          loading: procedureLoading,
                                          creatable: true,
                                          value: procedureSearchQuery[index],
                                          setValue: (val) =>
                                            setProcedureSearchQuery((prev) =>
                                              prev.map((site, idx) =>
                                                index === idx ? val : site
                                              )
                                            ),
                                        }}
                                        inputProps={{
                                          placeholder: "Search Procedure Name",
                                          tabIndex: getTabIndex(25),
                                          onFocus: () =>
                                            setProcedureIndex(index),
                                          onBlur: () => {
                                            const data =
                                              values
                                                .specimen_demographics_attributes[
                                                index
                                              ].procedure_id;

                                            if (!Number(data)) {
                                              setFieldError(
                                                `specimen_demographics_attributes.${index}.procedure_id`,
                                                "Procedure name is required"
                                              );
                                              setFieldValue(
                                                `specimen_demographics_attributes.${index}.procedure_id`,
                                                ""
                                              );
                                            } else {
                                              setFieldError(
                                                `specimen_demographics_attributes.${index}.procedure_id`,
                                                ""
                                              );
                                            }
                                          },
                                        }}
                                        disabled={disabled}
                                      />
                                      <FormInputField
                                        type="autocomplete"
                                        name={`specimen_demographics_attributes.${index}.site_id`}
                                        title="site name"
                                        className={`flex flex-col w-1/2`}
                                        required
                                        smVariant
                                        autocompleteProps={{
                                          setFieldValue,
                                          valueKey: "id",
                                          labelKey: "name",
                                          items:
                                            siteSearchQuery[index]?.length > 0
                                              ? sites
                                              : [],
                                          loading: siteLoading,
                                          creatable: true,
                                          value: siteSearchQuery[index],
                                          setValue: (val) =>
                                            setSiteSearchQuery((prev) =>
                                              prev.map((site, idx) =>
                                                index === idx ? val : site
                                              )
                                            ),
                                          renderCustomLabel: (item) => {
                                            if (typeof item === "string") {
                                              return item;
                                            } else if (
                                              item?.name &&
                                              item?.description
                                            ) {
                                              return `${item.name} - ${item.description}`;
                                            } else if (item?.name) {
                                              return item.name;
                                            }
                                          },
                                        }}
                                        inputProps={{
                                          placeholder: "Search Site Name",
                                          tabIndex: getTabIndex(26),
                                          onFocus: () => setSiteIndex(index),
                                          onBlur: () => {
                                            const data =
                                              values
                                                .specimen_demographics_attributes[
                                                index
                                              ].site_id;

                                            if (!Number(data)) {
                                              setFieldError(
                                                `specimen_demographics_attributes.${index}.site_id`,
                                                "Site name is required"
                                              );
                                              setFieldValue(
                                                `specimen_demographics_attributes.${index}.site_id`,
                                                ""
                                              );
                                            } else {
                                              setFieldError(
                                                `specimen_demographics_attributes.${index}.site_id`,
                                                ""
                                              );
                                            }
                                          },
                                        }}
                                        disabled={disabled}
                                      />
                                    </div>
                                    <FormInputField
                                      type="custom-textarea"
                                      name={`specimen_demographics_attributes.${index}.clinical_notes`}
                                      title="Clinical Notes"
                                      required
                                      smVariant
                                      inputProps={{
                                        className: `${
                                          disabled
                                            ? "opacity-50 bg-gray-300 text-gray-800"
                                            : "opacity-100"
                                        }`,
                                        readOnly: disabled,
                                        rows: 2,
                                        tabIndex: getTabIndex(27),
                                      }}
                                    />
                                    <div className="flex items-center gap-4 my-3">
                                      {!disabled && index === trackIndex && (
                                        <button
                                          type="button"
                                          className=" ripple-bg-primary text-sm text-white rounded-md px-6 py-2 flex flex-row items-center"
                                          onClick={() => {
                                            setTrackIndex((prev) => prev + 1);
                                            setSiteSearchQuery((prev) => [
                                              ...prev,
                                              "",
                                            ]);
                                            setProcedureSearchQuery((prev) => [
                                              ...prev,
                                              "",
                                            ]);
                                            arrayHelpers.push({
                                              procedure_id: "",
                                              clinical_notes: "",
                                              site_id: "",
                                              collected_date: parse(
                                                getPreviousWorkingDay(),
                                                "MM-dd-yyyy",
                                                new Date()
                                              ),
                                              received_date: parse(
                                                format(
                                                  new Date(),
                                                  "MM-dd-yyyy"
                                                ),
                                                "MM-dd-yyyy",
                                                new Date()
                                              ),
                                              // specimen_acknowledge: false,
                                            });
                                          }}
                                        >
                                          + Add More
                                        </button>
                                      )}

                                      {!disabled &&
                                        values.specimen_demographics_attributes
                                          .length > 1 && (
                                          <button
                                            type="button"
                                            className="ripple-bg-primary text-sm text-white rounded-md px-6 py-2 flex flex-row items-center"
                                            onClick={() => {
                                              setTrackIndex((prev) => prev - 1);

                                              setSiteSearchQuery((prev) =>
                                                prev.filter(
                                                  (el, idx) => index !== idx
                                                )
                                              );
                                              setProcedureSearchQuery((prev) =>
                                                prev.filter(
                                                  (el, idx) => index !== idx
                                                )
                                              );
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            - Remove Specimen{" "}
                                            {
                                              DisplayheaderSpecimen(
                                                values
                                                  .specimen_demographics_attributes
                                                  .length
                                              )[index]
                                            }
                                          </button>
                                        )}
                                    </div>
                                    {/* <div className="flex items-center gap-2 my-4">
                                      <div className="flex items-center gap-2 my-4">
                                        <Field
                                          disabled={disabled}
                                          type="checkbox"
                                          name={`specimen_demographics_attributes.${index}.specimen_acknowledge`}
                                          checked={
                                            values
                                              ?.specimen_demographics_attributes[
                                              index
                                            ].specimen_acknowledge
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `specimen_demographics_attributes.${index}.specimen_acknowledge`,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                        Click here to acknowledge the received
                                        specimen
                                      </div>
                                    </div> */}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div>
                    {!disabled && (
                      <Button
                        className="h-10 "
                        style={{ fontSize: 14, marginTop: 14 }}
                        formButton
                        ref={submitButton}
                        loading={updating}
                        tabIndex={getTabIndex(28)}
                      >
                        {createPatient
                          ? "Save new patient and new case"
                          : "Save new case"}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
