import React from "react";
import { FormDataField } from "../../stories/FormDataField";
import _ from "lodash";
import { useCaseType } from "../../service/Fetchers";
import LoadingStatus from "../../components/Loader";
import { DashboardView } from "../../stories/DashboardView";

const ViewCaseType = ({ id: case_type_id }) => {
  const { caseType, loading, error, mutate } = useCaseType({
    case_type_id,
  });

  const headerData = {
    constructiveCTA: {
      link: `/dashboard/case-types/edit/${case_type_id}`,
      title: "Edit",
    },
    states: [
      { title: "Case Type List", link: "/dashboard/case-types" },
      { title: `Case Type #${case_type_id}` },
    ],
    title: caseType ? caseType.name : "Case Type",
  };

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Name"
          value={<span className="font-bold">{caseType?.name}</span>}
        />
      </div>
      <div className="flex flex-row">
        <FormDataField
          title="Description"
          value={
            _.isEmpty(caseType?.description) ? "NA" : caseType?.description
          }
        />
      </div>
    </div>
  );

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load case type",
        ctaOnClick: mutate,
      }
    : null;

  return (
    <DashboardView
      headerData={headerData}
      loading={loading}
      loadingContent={<LoadingStatus />}
      content={content}
      errorData={errorData}
    />
  );
};

export default ViewCaseType;
