import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "../assets/main.css";
import { Link } from "@reach/router";
import { Breadcrumb } from "../service/Icon";

/**
 * Primary UI component for user interaction
 */
export const BreadcrumbLink = ({ states, className, ...props }) => {
  return (
    <div {...props} className={`flex flex-row text-xxs  ${className}`}>
      {_.initial(states).map((state, idx) => (
        <div className="flex flex-row items-center" key={idx}>
          <Link
            tabIndex={state?.tabIndex ? state?.tabIndex : -1}
            to={state.link}
            className="text-primary font-bold antialiased uppercase tracking-wider"
          >
            {state.title}
          </Link>
          <span className="text-gray-300">
            <Breadcrumb />
          </span>
        </div>
      ))}
      <span className="uppercase font-semibold tracking-wider text-cool-gray-400">
        {_.last(states)?.title}
      </span>
    </div>
  );
};

BreadcrumbLink.propTypes = {
  /**
   * states
   */
  states: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Name of the breadcrumb
       */
      title: PropTypes.string,
      /**
       * link of breadcrumb
       */
      link: PropTypes.string,
    })
  ),
  /**
   * additional class name
   */
  className: PropTypes.string,
};

BreadcrumbLink.defaultProps = {
  states: [],
  className: "",
};
