import { useAPI } from "../../../network/SWR";

export function useGetClientDetails({ id = null }) {
  const key = id ? `/couriers/${id}` : "";
  const { data, error, loading, mutate, isValidating } = useAPI(
    key,
    {},
    {
      revalidateOnFocus: false,
    }
  );
  return {
    client_info: data,
    client_address: data?.courier?.client?.client_addresses || [],
    error,
    loading,
    mutate,
    isValidating,
  };
}
