import { Loader } from "../service/Icon";
import React from "react";

const LoadingStatus = () => (
  <div className="flex flex-row">
    <Loader className="text-black mr-3" />
    Loading ...
  </div>
);

export default LoadingStatus;
