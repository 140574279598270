import React from "react";
import AddStaff from "../Staff/AddStaff";
import ListStaff from "../Staff/ListStaff";
import ViewStaff from "../Staff/ViewStaff";

export default function Staff({ mode, id }) {
  if (mode === "add") {
    return <AddStaff />;
  } else if (mode === "edit") {
    return <AddStaff id={id} />;
  } else if (mode === "view") {
    return <ViewStaff id={id} />;
  }
  return <ListStaff />;
}
