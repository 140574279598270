import ClientForm from "./Client/ClientForm";
import ClientList from "./Client/ClientList";
import ClientView from "./Client/ClientView";

const Client = ({ mode, id }) => {
  if (mode === "add") {
    return <ClientForm id={id} />;
  } else if (mode === "edit") {
    return <ClientForm id={id} />;
  } else if (mode === "view") {
    return <ClientView id={id} />;
  }
  return <ClientList />;
};

export default Client;
