import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { usePresignedUrl } from "../../service/Fetchers";
import { API } from "../../network/API";
import { Edit, Loader, ProfilePlaceholder } from "../../service/Icon";
import { toast } from "../../components/Toast";
import { Button } from "../../stories/Button";

const UploadImage = ({ onvalueChange, imageUrl }) => {
  const [loading, setLoading] = useState(false);
  const { urlData, loading: presignLoading, error, mutate } = usePresignedUrl();
  const onDropAccepted = (acceptedFiles) => {
    handleChange(acceptedFiles[0]);
  };

  /**Function triggers when image change event happen */
  const handleChange = (file) => {
    setLoading(true);
    if (urlData?.success) {
      let url = urlData?.url;
      uploadToAws(url, file);
    } else if (error) {
      console.log(error);
      setLoading(false);
      mutate();
    }
  };

  /**
   * @description Function for uploading the file to aws
   */
  const uploadToAws = async (url, file) => {
    const { error } = await API.put(url, file);
    if (error) {
      console.log(error);
      toast.error("Failed to upload an image");
      setLoading(false);
    } else {
      const uploadedUrl = url?.split("?")[0];
      setLoading(false);
      onvalueChange(uploadedUrl);
    }
  };

  const onDropRejected = (rejectedFiles) => {
    if (rejectedFiles[0].file.size > 5000000) {
      toast.error("Image size cannot be greater than 5MB");
    } else {
      toast.error("Invalid image format");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "image/jpeg, image/png, image/jpg",
    disabled: loading,
    maxSize: 5000000,
  });

  return (
    <div className="focus:outline-none" {...getRootProps()}>
      <input className="focus:outline-none" {...getInputProps()} />
      <div className="profile-picture relative flex-shrink-0 w-32">
        {loading || presignLoading ? (
          <div className="bg-gray-200 rounded-full w-32 h-32 flex items-center justify-center">
            <Loader className="h-10 w-10" />
          </div>
        ) : (
          <>
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="uploaded-img"
                className="rounded-full w-32 h-32  shadow-xl"
              />
            ) : (
              <ProfilePlaceholder />
            )}

            <Button type="icon" className="absolute bottom-0 right-0">
              <Edit />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
