import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "../../stories/Button";
import { Formik, Form } from "formik";
import {
  addGrossingMacroSchema,
  addDiagnosisMacroSchema,
} from "../../service/Validation";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import { FormInputField } from "../../stories/FormInputField";
import {
  useCaseTypeList,
  useCPTCodeList,
  useICDCodesList,
  useMacro,
  useCaseType,
} from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import { navigate } from "@reach/router";
import _ from "lodash";

export default function AddMacro({ id: macro_id, type }) {
  const isGrossing = type === "grossing";
  const [loading, setLoading] = useState(false);
  const buttonTitle = macro_id ? "Update" : "Create Macro";
  const pageTitle = macro_id ? `Update Macro #${macro_id}` : "Create new Macro";
  const buttonRef = useRef(null);
  const [cptSearchQuery, setCPTSearchQuery] = useState("");
  const [icdSearchQuery, setICDSearchQuery] = useState("");
  const [caseTypeSearchQuery, setCaseTypeSearchQuery] = useState("");
  const icd_ref = useRef(null);
  const cpt_ref = useRef(null);

  const {
    macro,
    loading: macroLoading,
    mutate,
    isValidating,
    error,
  } = useMacro({
    macro_id,
    type,
  });

  const {
    caseType,
    loading: loadingCaseType,
    error: caseTypeError,
    mutate: mutateCaseType,
  } = useCaseType({
    case_type_id: !isGrossing && macro ? macro?.visit_type?.id : null,
  });

  const { cptCodes, loading: loadingCPTList } = useCPTCodeList({
    searchQuery: isGrossing ? null : cptSearchQuery,
  });

  const { icds, loading: loadingICDList } = useICDCodesList({
    searchQuery: isGrossing ? null : icdSearchQuery,
  });

  const { caseTypes, loading: loadingCaseTypeList } = useCaseTypeList({
    searchQuery: isGrossing ? null : caseTypeSearchQuery,
  });

  useEffect(() => {
    if (caseType) setCaseTypeSearchQuery(caseType.name);
  }, [caseType]);

  const initialFormValues = () => {
    const formValues = {
      name: macro?.name ?? "",
      code: macro?.code?.toUpperCase() ?? "",
      description: macro?.description ?? "",
    };
    if (isGrossing) return formValues;

    return {
      ...formValues,
      icd_ids: macro?.icds?.map((icd) => icd.id) ?? [],
      cpt_ids: macro?.cpts?.map((cpt) => cpt.id) ?? [],
      is_site_linked: macro?.is_site_linked ? ["true"] : [],
      // visit_type_id: macro?.visit_type?.id ?? "",
    };
  };

  const createOrUpdateMacro = async (values) => {
    if (macro_id)
      return API.put(`/macros/${macro_id}?macro_type=${type}`, {
        macro: values,
      });
    return API.post(`/macros?macro_type=${type}`, { macro: values });
  };

  const onSubmit = async (values, actions) => {
    let mofifiedValues = {};
    if (!isGrossing) {
      mofifiedValues = {
        ...values,
        code: values.code?.toUpperCase(),
        is_site_linked: values?.is_site_linked.length === 0 ? false : true,
      };
    } else {
      mofifiedValues = { ...values };
    }

    setLoading(true);
    const { data, error } = await createOrUpdateMacro(mofifiedValues);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (macro_id) {
        setLoading(false);
        toast.success(`Updated ${values.name}!`);
        setTimeout(() => {
          navigate(`/dashboard/${type}-macros/view/${macro_id}`);
        }, 1500);
      } else {
        setLoading(false);
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${values.name}!`);
        setCaseTypeSearchQuery("");
        icd_ref.current.state.value = [];
        cpt_ref.current.state.value = [];
      }
    }
  };

  const headerData = {
    states: macro_id
      ? [
          {
            title: `${_.startCase(type)} Macros List`,
            link: `/dashboard/${type}-macros`,
          },

          {
            title: `${type} Macro #${macro_id}`,
            link: `/dashboard/${type}-macros/view/${macro_id}`,
          },
          { title: "Edit" },
        ]
      : [
          {
            title: `${_.startCase(type)} Macros List`,
            link: `/dashboard/${type}-macros`,
          },
          { title: `${type} Macro` },
        ],
    title: pageTitle,
  };

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={
        isGrossing ? addGrossingMacroSchema : addDiagnosisMacroSchema
      }
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className="flex-1">
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row space-x-10">
                <FormInputField type="text" name="name" required />
                <FormInputField
                  type="text"
                  name="code"
                  required
                  inputProps={{ className: "uppercase" }}
                />
              </div>
              <FormInputField type="textarea" name="description" />
              {!isGrossing && (
                <div className="flex flex-row space-x-10">
                  <FormInputField
                    type="checkbox"
                    name="is_site_linked"
                    title="mapped to site"
                    options={[
                      {
                        name: "",
                        value: true,
                      },
                    ]}
                  />
                  <FormInputField
                    className="w-1/3"
                    type="custom-select"
                    name="cpt_ids"
                    title="CPT CODE"
                    options={cptCodes}
                    selectProps={{
                      styles: {
                        multiValueLabel: (style) => ({
                          ...style,
                          maxWidth: 90,
                        }),
                      },
                      placeholder: "Search CPT Code",
                      ref: cpt_ref,
                      defaultValue: macro?.cpts,
                      isMulti: true,
                      getOptionLabel: (option) =>
                        `${option.code} ${option.description}`,
                      getOptionValue: (option) => `${option.id}`,
                      onInputChange: setCPTSearchQuery,
                      onChange: (opt) =>
                        setFieldValue(
                          "cpt_ids",
                          opt.map((op) => op.id)
                        ),
                      isLoading: loadingCPTList,
                    }}
                  ></FormInputField>
                  <FormInputField
                    className="w-1/3"
                    type="custom-select"
                    name="icd_ids"
                    title="ICD CODE"
                    options={icds}
                    selectProps={{
                      styles: {
                        multiValueLabel: (style) => ({
                          ...style,
                          maxWidth: 90,
                        }),
                      },
                      placeholder: "Search ICD Code",
                      ref: icd_ref,
                      defaultValue: macro?.icds,
                      isMulti: true,
                      getOptionLabel: (option) =>
                        `${option.code} ${option.description}`,
                      getOptionValue: (option) => `${option.id}`,
                      onInputChange: setICDSearchQuery,
                      onChange: (opt) =>
                        setFieldValue(
                          "icd_ids",
                          opt.map((op) => op.id)
                        ),
                      isLoading: loadingICDList,
                    }}
                  ></FormInputField>

                  {/* <FormInputField
                    type="autocomplete"
                    name="visit_type_id"
                    title="Case Type"
                    required
                    autocompleteProps={{
                      setFieldValue,
                      valueKey: "id",
                      labelKey: "name",
                      loading: loadingCaseTypeList,
                      items: caseTypeSearchQuery?.length > 0 ? caseTypes : [],
                      value: caseTypeSearchQuery,
                      setValue: setCaseTypeSearchQuery,
                    }}
                    inputProps={{
                      placeholder: "Search Case Type",
                    }}
                  /> */}
                </div>
              )}
            </div>

            <div className="flex flex-row justify-between mt-10">
              <Button ref={buttonRef} formButton loading={false}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const errorData = (() => {
    if (isGrossing) {
      if (!error) return null;
      return {
        show: true,
        subtitle: "Unable to fetch macro details",
        ctaOnClick: mutate,
      };
    } else {
      if (caseTypeError) {
        return {
          show: true,
          subtitle: "Unable to fetch Case Type details",
          ctaOnClick: mutateCaseType(),
        };
      }
      return null;
    }
  })();

  const loadingData = useCallback(() => {
    if (!macro_id) return false;
    // if (isGrossing) return macroLoading || isValidating;
    return macroLoading && loadingCaseType;
  }, [macroLoading, loadingCaseType, macro_id]);

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      loading={loadingData()}
      errorData={errorData}
    />
  );
}
