import React, { useState } from "react";
import Header from "../../stories/Header";
import { Button } from "../../stories/Button";
import { FormDataField } from "../../stories/FormDataField";
import { useCurrentProfile } from "../../service/Fetchers";
import { getRoleNames } from "../../service/Helper";
import LoadingStatus from "../../components/Loader";
import { ErrorView } from "../../stories/ErrorView";
import _ from "lodash";
import { toast } from "../../components/Toast";
import UploadImage from "./UploadImage";
import { API } from "../../network/API";
import { CurrentUser, logout } from "../../service/Auth";
import { format } from "date-fns";
import { RiEditFill } from "react-icons/ri";
import { Field, Form, Formik } from "formik";
import { FormInputField } from "../../stories/FormInputField";

export default function Profile() {
  const [toggleField, setToggleField] = useState(false);
  const { user, loading, error: userError, mutate } = useCurrentProfile();

  /**
   * Function to update profile image
   */
  const UpdateProfileImg = async (url) => {
    const { error, data } = await API.put(
      CurrentUser.isAccessionist() ||
        CurrentUser.isGrosser() ||
        CurrentUser.isPathologist()
        ? `/users/${user?.id}/update_profile_pic`
        : `/users/${user?.id}`,
      {
        avatar_url: url,
      }
    );
    if (error) {
      if (error.message) toast.error(error.message);
    }
    if (data) {
      mutate();
      toast.info("Profile pic uploaded");
    }
  };
  const renderPathologist = () => {
    let name = "";
    if (user?.pathologist?.first_name) {
      name += user?.pathologist?.first_name;
    }
    if (user?.pathologist?.last_name) {
      name += ` ${user?.pathologist?.last_name}`;
    }
    return name;
  };
  const userData = CurrentUser.getDetails;

  const ProfileContent = (
    <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
      <div className="flex flex-row justify-between items-start">
        <UploadImage
          iconClass="w-10 h-10 mb-6"
          h="40"
          w="40"
          sizeLabel=""
          onvalueChange={(url) => UpdateProfileImg(url)}
          imageUrl={user?.s3_avatar_url}
          imageClassName="rounded-xl"
        />
        <div className="flex flex-row">
          <Button
            className="mr-3"
            loading={false}
            onClick={() => {
              window.location.href = "/change-password";
            }}
            size="default"
            type="info"
          >
            Change Password
          </Button>
          <Button
            loading={false}
            onClick={() => logout()}
            size="default"
            type="warning"
          >
            Logout
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <FormDataField
          title="name"
          value={`${user?.first_name} ${user?.last_name}`}
          className="w-1/5 break-words"
        />
        <FormDataField title="Staff ID" value={user?.uuid} className="w-1/5" />
        <FormDataField
          title="DOB"
          value={user?.dob && format(new Date(user?.dob), "dd-MMM-yyyy")}
          className="w-1/5"
        />
        <FormDataField
          title="Gender"
          value={_.startCase(user?.gender)}
          className="w-1/5"
        />
        <FormDataField
          title="Role"
          value={
            <span className="capitalize">{getRoleNames(user?.role.name)}</span>
          }
          className="w-1/5"
        />
      </div>
      <div className="flex flex-row">
        <FormDataField
          title="Email"
          value={user?.email}
          className="w-1/5 break-words"
        />
        <FormDataField
          title="Phone Number"
          value={user?.phone}
          className="w-1/5"
        />
        {userData?.user?.role?.name === "pathologist" && (
          <div className="w-1/5 relative">
            {!toggleField ? (
              <div>
                <FormDataField
                  title="Designation"
                  value={user?.signature ?? "--"}
                  className="w-full"
                />
                <button
                  className="absolute top-0"
                  style={{
                    right: "100px",
                  }}
                  onClick={() => {
                    setToggleField(!toggleField);
                  }}
                >
                  <RiEditFill />
                </button>
              </div>
            ) : (
              <Formik
                initialValues={{ signature: user?.signature ?? "" }}
                onSubmit={async (values) => {
                  const response = await API.put(
                    `users/${userData?.user?.id}/update_signature`,
                    {
                      user: {
                        signature: values.signature,
                      },
                    }
                  );
                  if (response.error) {
                    toast.error(response.error.message);
                  }
                  if (response.data) {
                    toast.success(response.data.message);
                    setToggleField(!toggleField);
                    mutate();
                  }
                }}
              >
                <Form>
                  <div style={{ position: "relative" }}>
                    <button
                      type="submit"
                      className="absolute top-0"
                      style={{
                        right: "100px",
                        zIndex: 999,
                      }}
                      onClick={() => {
                        setToggleField(!toggleField);
                      }}
                    ></button>
                    <Field
                      title="Designation"
                      name="signature"
                      type="text"
                      as={FormInputField} // Use your custom input component
                      inputProps={{
                        style: { height: "30px" },
                        placeholder: "Edit designation",
                      }}
                      hideError={true}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          e.stopPropagation();
                          e.target.form.dispatchEvent(
                            new Event("submit", { cancelable: true })
                          );
                        }
                      }}
                    />
                  </div>
                </Form>
              </Formik>
            )}
          </div>
        )}
        {!!user?.pathologist?.first_name && (
          <FormDataField
            title="Assigned Pathologist"
            value={renderPathologist()}
            className="w-1/5"
          />
        )}
      </div>
      {user?.address && (
        <div className="flex flex-row">
          <FormDataField
            title="Address"
            value={user?.address}
            className="w-full"
          />
        </div>
      )}
    </div>
  );

  const errorData = userError
    ? {
        show: true,
        subtitle: "Unable to load profile detail",
        ctaOnClick: mutate,
      }
    : null;

  const contentToShow = () => {
    if (loading) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-between items-start">
            <LoadingStatus />
          </div>
        </div>
      );
    } else if (userError) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-10 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-center items-center">
            <ErrorView {..._.omit(errorData, "show")} />
          </div>
        </div>
      );
    }
    return ProfileContent;
  };

  return (
    <div className="absolute bg-theme-blue  w-full h-full">
      <Header />
      <div className="flex flex-col bg-theme-gray p-8 w-full h-full">
        {contentToShow()}
      </div>
    </div>
  );
}
