import React, { useState, useRef } from "react";
import Header from "../../stories/Header";
import { Button } from "../../stories/Button";
import ConfirmDialog from "../../stories/ConfirmDialog";
import { Link } from "@reach/router";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { Formik, Form } from "formik";
import { forgotPasswordSchema } from "../../service/Validation";
import { CheckCircle } from "../../service/Icon";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const resetButton = useRef(null);
  const [dialog, setDialog] = useState(false);
  const forgotPassword = async ({ email }) => {
    setLoading(true);
    const { error, data } = await API.post("/auth/forgot-password", {
      email,
    });

    if (error) {
      resetButton.current.shake();
      if (error.message) toast.error(error.message);
    }
    if (data) {
      setDialog(true);
    }
    setLoading(false);
  };

  return (
    <div className="absolute bg-theme-gray flex flex-col w-full h-full">
      <Header className="flex-shrink-0" />
      <div className="flex flex-col items-center mt-24">
        <span className="text-gray-800 antialiased font-hairline text-2xl mb-4">
          Forgot your password?
        </span>
        <span className="font-hairline antialiased w-64 text-md mb-10">
          Enter your email and we'll send you a link to reset your password.
        </span>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={(data) => {
            forgotPassword(data);
          }}
        >
          {(props) => {
            return (
              <Form className="flex flex-col w-64">
                <ConfirmDialog
                  isOpen={dialog}
                  title="Reset Password Link sent"
                  description={
                    <>
                      <p>
                        We’ve sent you a password reset link to{" "}
                        <span className="font-bold">{props.values.email}.</span>
                      </p>
                      <br />
                      <p>
                        Please follow the link in your email to reset password
                      </p>
                    </>
                  }
                  cancelTitle="Close"
                  onPressCancel={() => {
                    setDialog(false);
                    window.location.href = "/login";
                  }}
                  success={true}
                  icon={<CheckCircle />}
                />
                <input
                  className="form-input m-0 flex-1 font-light text-sm"
                  type="email"
                  name="email"
                  required
                  placeholder="Email Address"
                  onChange={props.handleChange}
                  value={props.values.email}
                ></input>
                <Button
                  className="rounded-full mt-3 w-64 text-center antialiased font-light justify-center"
                  ref={resetButton}
                  formButton
                  loading={loading}
                >
                  Reset Password
                </Button>
              </Form>
            );
          }}
        </Formik>
        <span className="font-hairline text-sm text-gray-700 mt-6">
          Already have an account?
          <Link to="/login" className="ml-2 underline">
            Sign in
          </Link>
        </span>
      </div>
    </div>
  );
}
