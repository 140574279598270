/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext, lazy } from "react";
import { Tab } from "@headlessui/react";
import Header from "../../stories/Header";
import { CaseList } from "../../stories/CaseList";
import { Loader } from "../../service/Icon";
import AccessionistPending from "./AccessionistPending";
import { CurrentUser } from "../../service/Auth";
import { NoData } from "../../service/Illustration";
import { ErrorView } from "../../stories/ErrorView";
import CaseDetailView from "./CaseDetailView";
import ConfirmDialog from "../../stories/ConfirmDialog";
import { FormInputField } from "../../stories/FormInputField";
import { addAddendumSchema } from "../../service/Validation";
import { Form, Formik } from "formik";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import PDFViewer from "../../components/PDFViewer";
import CONSTANTS from "../../helpers/constants";
import QueryString from "qs";
import _, { startCase } from "lodash";
import { format } from "date-fns";
import FilterPane from "../../stories/FilterPane";
import classNames from "classnames";
import PackageCardDetails from "../../components/Card/PackageCardDetails";

const AddPackageModal = lazy(() => import("./AddPackageModal"));

// Selected Case View Component
function SelectedCaseView({
  selectedCase,
  loading,
  totalCases,
  rightButtons,
  setMacroEdited,
}) {
  if (!selectedCase) {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-full">
          <Loader />
        </div>
      );
    }
    if (totalCases == 0) {
      return (
        <div className="flex items-center justify-center">
          <ErrorView
            icon={<NoData />}
            title="Please select a case from the list"
            subtitle={`If no cases are listed, kindly ${
              CurrentUser.isAccessionist() && `add a new case or`
            } try for a different search parameters`}
          />
        </div>
      );
    }
    return null;
  } else {
    if (CurrentUser.isAccessionist() && selectedCase.status === "pending") {
      return (
        <AccessionistPending
          visitDetails={selectedCase}
          loading={loading}
          key={selectedCase.id}
        />
      );
    }
    return (
      <CaseDetailView
        visitDetails={selectedCase}
        key={selectedCase.id}
        id={selectedCase.id}
        rightButtons={rightButtons}
        setMacroEdited={setMacroEdited}
        visitsLoading={loading}
      />
    );
  }
}
// onmount filter values change here
const initialFilter = {
  physician_id: "",
  visit_status: "all",
  visit_kind: "pending",
  visit_type_id: "",
  // ordering: "asc",
  sort_by: "",
  assign_to_others: false,
  all_cases: false,
};

export default function CaseView() {
  const token = localStorage.getItem("user_auth_token");
  const [filters, setFilters] = useState(initialFilter);
  const [selectedCase, setSelectedCase] = useState(null);
  const [savingAddendum, setSavingAddendum] = useState(false);
  const [newVisits, setNewVisits] = useState(null);
  const [addingCase, setAddingCase] = useState(false);
  const [getPackages, setGetPackages] = useState();

  const {
    showAddPackageModal,
    setShowAddPackageModal,
    pdfViewer,
    setPdfViewer,
    openAddendum,
    setOpenAddendum,
    headerHeight,
    receivedDate,
  } = useContext(GlobalVariablesContext);

  const [visits, setVisits] = useState([]);
  const [visitsPageInfo, setVisitsPageInfo] = useState(null);
  const [loadingVisitList, setLoadingVisitList] = useState(false);
  const [loadingPackagesList, setLoadingPackagesList] = useState(false);
  const [macroEdited, setMacroEdited] = useState(false);

  useEffect(() => {
    async function getVisits() {
      const filterModify = {
        list: filters?.list === "no" ? "" : filters?.list || "default",
        ..._.omit(filters, ["list"]),
      };
      try {
        setLoadingVisitList(true);
        const params = QueryString.stringify(
          _.pickBy({ ...filterModify }, _.negate(_.isEmpty))
        );
        const endpoint = CurrentUser.isAdmin() ? `/visits` : `/visits/list`;

        const { data } = await API.get(`${endpoint}?${params}`);
        if (data?.visits) {
          setVisits(data?.visits);
          setVisitsPageInfo(data?.page_info);
        }
      } catch (error) {
      } finally {
        setLoadingVisitList(false);
      }
    }
    getVisits();
  }, [filters, macroEdited]);

  // let resetForm = () => {
  //   console.log("first");
  // };
  // const { visits, loading: loadingVisitList } = useVisitList(filters);

  function onChangeFilter(values) {
    setSelectedCase(null);
    // If we click past cases then add new case form closes
    if (values.visit_kind === "past") {
      setAddingCase(false);
    }
    // if (
    //   filters.visit_kind !== values?.visit_kind &&
    //   filters.assign_to_others !== values?.assign_to_others
    // ) {
    if (values?.all_cases) {
      let newValues = { ...values };
      newValues.ordering = "";
      newValues.sort_by = "";
      newValues.visit_kind = "all";
      newValues.list = "no";
      setFilters(newValues);
    }
    if (values?.visit_kind === "both") {
      let newValues = { ...values };
      newValues.ordering = "";
      newValues.list = values.assign_to_others
        ? "assign_to_others"
        : "assign_to_me";

      setFilters(newValues);
    }
    if (values?.visit_kind === "pending_addendum") {
      let newValues = { ...values };
      newValues.ordering = "";
      newValues.list = values.assign_to_others ? "assign_to_others" : "default";
      newValues.visit_kind = "pending_addendum";

      setFilters(newValues);
    }

    if (values?.visit_kind === "pending_amendment") {
      let newValues = { ...values };
      newValues.ordering = "";
      newValues.list = values.assign_to_others ? "assign_to_others" : "default";
      newValues.visit_kind = "pending_amendment";

      setFilters(newValues);
    }

    if (values?.visit_kind === "past") {
      let newValues = { ...values };
      newValues.ordering = "";
      newValues.list = values.assign_to_others ? "assign_to_others" : "default";

      setFilters(newValues);
    } else if (values?.visit_kind === "pending") {
      let newValues = { ...values };
      newValues.ordering = "";
      newValues.sort_by = "";
      newValues.list = values.assign_to_others ? "assign_to_others" : "default";
      setFilters(newValues);
    }

    // } else {
    //   // in case we want to search as and when we type
    //   setFilters(values);
    // }
  }

  const addAddendum = async (values, { setFieldValue, setFieldTouched }) => {
    setSavingAddendum(true);
    const { data, error } = await API.post(
      `/visits/${selectedCase.id}/addendums`,
      values
    );
    if (error) {
      toast.error(error?.message ?? "Network error");
    } else if (data) {
      if (selectedCase?.addendums?.length) {
        const newSelectedCase = {
          ...selectedCase,
          addendums: [...selectedCase?.addendums, { ...values }],
        };
        setSelectedCase(newSelectedCase);
      } else {
        const newSelectedCase = {
          ...selectedCase,
          addendums: [{ ...values }],
        };
        setSelectedCase(newSelectedCase);
      }
      toast.success(`Added Addendum`);
    }
    setTimeout(() => {
      setSavingAddendum(false);
      setOpenAddendum(false);
    }, 1000);
    setTimeout(() => {
      setFieldValue("description", "");
      setFieldTouched("description", false);
    }, 1200);
  };
  //filter packages based on package date filter
  useEffect(() => {
    const filterDate = receivedDate
      ? format(new Date(receivedDate), "MM-dd-yyyy")
      : "";
    const validYear = !receivedDate
      ? true
      : receivedDate?.getFullYear().toString().length === 4;
    const query = QueryString.stringify({
      filter_date: !receivedDate ? "" : filterDate,
    });
    async function getPackages() {
      try {
        setLoadingPackagesList(true);
        const { data } = await API.get(`/couriers?${query}`);
        const { completed_couriers, received_couriers } = data;
        // console.log(data, "data");
        setGetPackages({
          accepted_packages: completed_couriers,
          incoming_packages: received_couriers,
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoadingPackagesList(false);
      }
    }
    if (validYear && CurrentUser.isAccessionist()) getPackages();
  }, [receivedDate]);

  // For adding new case. Will change visit_kind and visits list
  useEffect(() => {
    if (addingCase && !loadingVisitList) {
      let cases = [...visits];
      let newCase = {
        status: "pending",
        ucid: "XXX-XXXXXX",
      };
      cases.unshift(newCase);
      setNewVisits(cases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingCase, visits]);

  const [dialogTitle, setDialogTitle] = useState("Add Addendum");
  const updateTitle = (title) => {
    setDialogTitle(title);
  };
  return (
    <div className="bg-theme-gray min-h-screen overflow-hidden">
      <Formik
        initialValues={{
          description: "",
        }}
        validationSchema={addAddendumSchema}
        onSubmit={addAddendum}
      >
        {(props) => {
          return (
            <Form className="flex flex-col w-64">
              <ConfirmDialog
                isOpen={openAddendum}
                title={dialogTitle}
                description={
                  <FormInputField
                    type="textarea"
                    title=" "
                    name="description"
                  />
                }
                cancelTitle="Close"
                onPressCancel={() => {
                  setOpenAddendum(false);
                }}
                construtctiveTitle="Save"
                constructiveLoading={savingAddendum}
                success={true}
                icon={null}
              />
            </Form>
          );
        }}
      </Formik>

      <Header
        rightLinks={
          CurrentUser.isAccessionist() && [
            {
              type: "link",
              text: (
                <button
                  tabIndex={-1}
                  className="text-base font-semibold bg-white text-black p-3"
                >
                  + Add Package
                </button>
              ),
              onClick: () => {
                // This is the old create case logic which is replaced by create package button
                // setFilters(initialFilter);
                // resetForm(initialFilter);
                // setAddingCase(true);
                // create package logic starts here
                setShowAddPackageModal(true);
              },
            },
          ]
        }
      />
      {/* Checkinng if the role is accessioner or not */}
      {!CurrentUser.isAccessionist() ? (
        <div
          className={`page-container ${
            CurrentUser?.isPathologist() ? "px-0 pr-4" : "px-10"
          } m-auto`}
        >
          <div
            className="pt-2 pb-2 flex flex-row space-x-4"
            style={{
              height: CurrentUser?.isPathologist
                ? `calc(100vh - ${headerHeight}px)`
                : "auto",
            }}
          >
            <CaseList
              filters={filters}
              caseType="pending"
              loading={loadingVisitList}
              cases={addingCase && newVisits ? newVisits : visits}
              pageInfo={visitsPageInfo}
              onSelectCase={setSelectedCase}
              updateTitle={updateTitle}
            />
            <div className="flex flex-col flex-1 bg-transparent">
              <SelectedCaseView
                selectedCase={selectedCase}
                loading={loadingVisitList}
                totalCases={visits?.length}
                setMacroEdited={setMacroEdited}
                rightButtons={[
                  {
                    show: selectedCase?.report_generated,
                    type: "info",
                    text: "View Report",
                    onClick: () => setPdfViewer(true),
                  },
                  {
                    show: selectedCase?.report_generated,
                    type: "primary",
                    text: "Download",
                    onClick: () =>
                      window.open(
                        `${CONSTANTS.BASE_API_URL}/visits/${selectedCase?.id}/reports/download?Access-Token=${token}`,
                        "_blank"
                      ),
                  },
                  {
                    show:
                      selectedCase?.status === "diagnosed" &&
                      (CurrentUser.isAdmin() || CurrentUser.isPathologist()),
                    type: "info",
                    text: "Add Addendum",
                    onClick: () => {
                      setOpenAddendum(true);
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="page-container px-10 bg-theme-gray h-full py-4 max-w-8xl mx-auto">
          <ReceivingPackagesTab getPackages={getPackages} />
        </div>
      )}
      <AddPackageModal
        open={showAddPackageModal}
        setOpen={setShowAddPackageModal}
        // refetch={refetchPackages}
      />
      <PDFViewer
        open={pdfViewer}
        setOpen={setPdfViewer}
        url={`${CONSTANTS.BASE_API_URL}/visits/${selectedCase?.id}/reports?Access-Token=${token}`}
      />
      <FilterPane
        onChangeFilter={onChangeFilter}
        initialFilter={initialFilter}
        resetForm={(setFieldValue) => {
          // setFilters(initialFilter);
          // resetForm(initialFilter);
          console.log(setFieldValue, "hello");
        }}
      />
    </div>
  );
}

const ReceivingPackagesTab = ({ getPackages = {} }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div className="w-full">
      <Tab.Group onChange={setSelectedTab}>
        <Tab.List className="flex  max-w-md space-x-1 rounded-xl bg-blue-900/20 p-1">
          {Object.keys(getPackages)
            .reverse()
            .map((header) => (
              <Tab
                key={header}
                className={({ selected }) =>
                  classNames(
                    "w-full  py-2.5 font-medium leading-5 text-base",
                    "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                    selected
                      ? "border-b border-black text-black font-semibold"
                      : "none text-gray-500 font-normal"
                  )
                }
              >
                {startCase(header)}
              </Tab>
            ))}
        </Tab.List>

        <FilterTable />

        <Tab.Panels className="mt-2">
          {Object.values(getPackages)
            .reverse()
            .map((info, idx) => (
              <Tab.Panel key={idx}>
                <ul>
                  {!info.length ? (
                    <div className="bg-white p-4 rounded-sm h-32 flex justify-center items-center">
                      {`No ${
                        selectedTab == 1 ? "accepted" : "incoming"
                      } packages available. Please create a new package`}
                    </div>
                  ) : (
                    info.map((information) => (
                      <li
                        key={information.id}
                        className="transition-all relative rounded-md bg-white mb-2 hover:shadow-md p-6"
                      >
                        <PackageCardDetails data={information} />
                      </li>
                    ))
                  )}
                </ul>
              </Tab.Panel>
            ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
const FilterTable = () => {
  const { setReceivedDate, receivedDate } = useContext(GlobalVariablesContext);
  return (
    <div className="pt-4">
      <Formik enableReinitialize={true} initialValues={{ collected_date: "" }}>
        <Form className="grid grid-cols-8">
          <div id="keep-calendar-open">
            <FormInputField
              type="custom-date"
              name="collected_date"
              disabled={false}
              smVariant
              inputProps={{}}
              customDateProps={{
                dateValue: receivedDate,
                format: "M-d-y",
                dateOnChange: (val) => {
                  setReceivedDate(val);
                },
              }}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
