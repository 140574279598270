/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from "react";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { useMacroList } from "../../service/Fetchers";
import { Loader, Search } from "../../service/Icon";
import { Button } from "../../stories/Button";
import { ErrorView } from "../../stories/ErrorView";
import Select from "react-select";
import EditableDialog from "../../stories/EditableDialog";
import { CurrentUser } from "../../service/Auth";

export default function MacroList({
  visitDetails,
  contentEditable = true,
  macroInfo,
  setMacroEdited,
  setEditGrossing,
  initialValues,
}) {
  const { specimen_demographics } = visitDetails;
  const [macroSearchQuery, setMacroSearchQuery] = useState();
  const macroSelectRef = useRef(null);
  const {
    macros: topMacro,
    loading: topMacroLoading,
    error,
    isValidating,
    mutate,
  } = useMacroList({
    type: "grossing",
    currentPage: 1,
    perPageCount: 12,
  });

  const { macros, loading } = useMacroList({
    type: "grossing",
    searchQuery: macroSearchQuery,
  });

  const [selectedMacro, setSelectedMacro] = useState(macroInfo);
  const [updating, setUpdating] = useState(false);
  const submitButton = useRef(null);

  const partOptions = Array.from(Array(specimen_demographics?.length))
    .map((e, i) => i + 65)
    .map((x) => {
      return { label: String.fromCharCode(x), value: x - 65 };
    });
  const [partIndex, setPartIndex] = useState(0);
  const [grossings, setGrossings] = useState([]);

  useEffect(() => {
    if (visitDetails?.grossings?.length > 0) {
      // Prefill in case of Edit
      const newGrossings = visitDetails?.grossings?.map((grossing, index) => {
        if (index === partIndex) {
          macroSelectRef?.current?.select?.setValue(
            {
              id: grossing?.id,
              name: grossing?.name,
              description: grossing?.description,
              code: grossing?.code,
              grosser_id: grossing?.grosser?.id,
            },
            "set-value"
          );
        }
        return {
          id: grossing?.id,
          name: grossing?.name,
          description: grossing?.description,
          code: grossing?.code,
          grosser_id: grossing?.grosser?.id,
        };
      });
      setGrossings(newGrossings);
    } else {
      specimen_demographics?.forEach(() => {
        setGrossings((prev) => [
          ...prev,
          {
            grosser_id: CurrentUser.getDetails.user.id,
            name: "",
            description: "",
            code: "",
          },
        ]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (macroSelectRef.current && grossings?.length > 0) {
      if (grossings[partIndex]?.code?.length > 0) {
        macroSelectRef?.current?.select?.setValue(
          grossings[partIndex],
          "set-value"
        );
      } else {
        macroSelectRef?.current?.select?.clearValue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partIndex]);

  const onSelectOption = (opt, { action }) => {
    if (
      macroSelectRef?.current?.select?.hasValue() ||
      opt?.code !== macroSelectRef?.current?.select?.getValue()?.code
    ) {
      if (action === "clear") {
        macroSelectRef?.current?.select?.clearValue();
        let newGrossings = grossings?.map((gross, index) => {
          if (index === partIndex) {
            return {
              ...gross,
              id: visitDetails?.grossings[index]?.id,
              name: "",
              description: "",
              code: "",
            };
          }
          return gross;
        });
        setGrossings(newGrossings);
      } else {
        let newGrossings = grossings?.map((gross, index) => {
          if (index === partIndex) {
            return {
              ...gross,
              id: visitDetails?.grossings[index]?.id,
              name: opt.name,
              description: opt.description,
              code: opt.code,
            };
          }
          return gross;
        });
        setGrossings(newGrossings);
      }
    }
  };

  const saveMacro = async () => {
    setUpdating(true);
    const payload = {
      visit: {
        ucid: visitDetails.ucid,
        grossings_attributes: grossings,
      },
    };
    const { error, data } = await API.put("/visits/progress-update", payload);

    if (error) {
      submitButton.current.shake();
      if (error.message) toast.error(error.message);
    }
    if (data) {
      toast.success(`Updated!`);
      if (setMacroEdited) {
        setEditGrossing(false);
        setMacroEdited((prevState) => {
          return !prevState;
        });
      } else {
        setTimeout(() => {
          window.location.href = "/case-view";
        }, 1500);
      }
    }
    setUpdating(false);
  };

  if (error || topMacroLoading)
    return (
      <div className="w-full flex items-center justify-center my-10">
        {(isValidating || topMacroLoading) && (
          <div className="flex flex-row  items-center h-44">
            <Loader /> &nbsp;Loading Macro
          </div>
        )}
        {error && (
          <ErrorView
            title="Failed to load macros"
            subtitle="Click on reload to try loading macros again"
            ctaOnClick={mutate}
            ctaTitle="Reload"
          ></ErrorView>
        )}
      </div>
    );

  // For changing dropdown indicator to search icon
  const DropdownIndicator = () => {
    return (
      <div className="mx-2">
        <Search />
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center">
      <span className="mb-2">Select a Part</span>
      <Select
        className="w-1/6 mx-auto mb-4"
        defaultValue={partOptions[0]}
        isDisabled={grossings?.length < 2}
        name="biopsy-part"
        options={partOptions}
        isSearchable={false}
        onChange={(opt) => setPartIndex(opt.value)}
      />
      <div className="text-center w-full font-medium text-xl text-cool-gray-600">
        Grossing Details for Sample from{" "}
        <span className="font-bold">
          {specimen_demographics[partIndex]?.site?.name} -{" "}
          {specimen_demographics[partIndex]?.site?.description}
        </span>
      </div>
      <span>Select a Macro</span>
      <div className="max-w-md mx-auto my-4 w-full flex justify-center items-center space-x-4">
        <Select
          ref={macroSelectRef}
          className="flex-1"
          options={macros}
          defaultOptions={macros}
          isLoading={loading}
          onChange={(opt) => {
            if (opt) {
              onSelectOption(opt, { action: "select" });
            }
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option}
          onInputChange={setMacroSearchQuery}
          autoFocus={true}
          components={{ DropdownIndicator }}
          menuIsOpen={!!macroSearchQuery}
        />
        <Button
          onClick={() => {
            onSelectOption(null, { action: "clear" });
            macroSelectRef?.current?.select?.clearValue();
          }}
          className="h-8"
          style={{ fontSize: 14 }}
          disabled={grossings[partIndex]?.code?.length === 0}
        >
          Clear
        </Button>
      </div>
      {selectedMacro?.id && !selectedMacro?.description && (
        <EditableDialog
          className={
            selectedMacro?.id && !selectedMacro?.description
              ? "visible  p-3 "
              : "invisible h-0 p-0"
          }
          initialValues={
            selectedMacro?.id === visitDetails?.grossing_macro?.id
              ? initialValues
              : []
          }
          description={selectedMacro?.templateDescription}
          onSubmit={(data, editableItems) => {
            setSelectedMacro({ ...selectedMacro, description: data });
            saveMacro({
              ...selectedMacro,
              description: data,
              grossing_tokens: editableItems.description,
            });
          }}
          contentEditable={contentEditable}
          fieldName="description"
          submitButton={
            <Button
              formButton
              className="h-8"
              style={{ fontSize: 14 }}
              disabled={!selectedMacro?.templateDescription}
            >
              Save
            </Button>
          }
        />
      )}

      {grossings[partIndex]?.code?.length === 0 && (
        <>
          <span>OR</span>
          <div className="flex flex-row flex-wrap  -mx-4">
            {topMacro
              ?.filter((t, i) => i < 6)
              ?.map((macro, i) => {
                return (
                  <div className="w-1/3 p-4">
                    <button
                      className={`flex flex-col items-center w-full h-24 justify-center border text-sm leading-5 font-medium ${
                        selectedMacro?.id === macro.id
                          ? "border-primary bg-primary bg-opacity-25"
                          : "border-cool-gray-200 bg-cool-gray-50"
                      } transition duration-150 ease-in-out`}
                      id={`grossing-macro-${i}`}
                      onKeyDown={(e) => {
                        if (i === 5 && e.keyCode == 9 && e.shiftKey == false) {
                          e.preventDefault();
                          const element =
                            document.getElementById("filter-beginning");
                          if (element) {
                            element.focus();
                          }
                        }
                      }}
                      onClick={() => {
                        onSelectOption(macro, { action: "select" });
                        macroSelectRef?.current?.select?.setValue(
                          macro,
                          "set-value"
                        );
                      }}
                    >
                      <span className="uppercase text-md text-gray-800 mb-1">
                        {macro.name}
                      </span>
                      <span className="text-gray-500 text-xs truncate-2-lines text-center px-2">
                        {macro.description}
                      </span>
                    </button>
                  </div>
                );
              })}
          </div>
        </>
      )}
      {grossings?.some((grossing) => grossing?.code?.length === 0) && (
        <p className="mb-2 text-orange-500 font-bold text-xs">
          Please provide grossing information for all parts
        </p>
      )}
      <Button
        onClick={() => saveMacro()}
        className="h-8 mx-auto mb-4"
        disabled={grossings?.some((grossing) => grossing?.code?.length === 0)}
        style={{ fontSize: 14 }}
        loading={updating}
      >
        Submit
      </Button>
    </div>
  );
}
