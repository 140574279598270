import React from "react";
import { useSingleClient } from "../../../service/Api_services/GetClients/useGetClients";
import { DashboardView } from "../../../stories/DashboardView";
import { FormDataField } from "../../../stories/FormDataField";
import { format } from "date-fns";
import { getRoleNames } from "../../../service/Helper";
import { StatusTag } from "../../../stories/StatusTag";

export default function ClientView({ id }) {
  const { data, loading, error, mutate } = useSingleClient({
    id,
  });

  const headerData = {
    constructiveCTA: {
      link: `/dashboard/clients/edit/${id}`,
      title: "Edit",
    },

    title: data?.name ? `${data?.name}` : "Client",
    states: [
      { title: "Client List", link: "/dashboard/clients" },
      { title: `Client #${id}` },
    ],
  };

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load Client details",
        ctaOnClick: mutate,
      }
    : null;

  const clientTypes = [
    data?.interface ? "Interface" : null,
    data?.hand_key ? "Hand-Key" : null,
  ];

  const content = (
    <div className="flex flex-col space-y-10">
      <div className="grid grid-cols-3 gap-8">
        <FormDataField
          // className="w-1/4"
          title="Name"
          value={<span className="font-bold">{`${data?.name}`}</span>}
        />
        <FormDataField
          // className="w-1/4"
          title="Client Type"
          value={
            <span>
              {clientTypes
                .filter((clientType) => clientType !== null)
                .join(", ")}
            </span>
          }
        />
        <FormDataField
          // className="w-1/4"
          title="Accession Prefix"
          value={<span>{data?.abbreviation ?? "--"}</span>}
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <FormDataField
          // className="w-1/4"
          title={`Address`}
          value={
            <div className="flex flex-col">
              {data?.client_addresses?.map((address, index) => {
                return (
                  <div key={index} className="rounded-md mb-4">
                    <p className="font-semibold">Address #{index + 1}</p>
                    <p className="mb-2">Location - {address.location_name}</p>
                    <p className="mb-2">Street 1 - {address.street1}</p>
                    {address.street2 && (
                      <p className="mb-2">Street 2 - {address.street2}</p>
                    )}
                    <div className="flex items-center mb-2">
                      <p className="mr-2">{address.city}</p>
                      <p className="mr-2">{address.state}</p>
                      <p>{address.zipcode}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        />
      </div>
    </div>
  );

  return (
    <div>
      {" "}
      <DashboardView
        headerData={headerData}
        loading={loading}
        content={content}
        errorData={errorData}
      />
    </div>
  );
}
