import React, { useState, useRef } from "react";
import { Button } from "../../stories/Button";
import { Link } from "@reach/router";
import Header from "../../stories/Header";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loginSchema } from "../../service/Validation";
import { CurrentUser } from "../../service/Auth";

export default function Login({ mode, submode }) {
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const loginButton = useRef(null);
  const login = async ({ email, password }) => {
    setLoading(true);
    const { error, data } = await API.post("/auth/login", {
      email,
      password,
    });

    if (error) {
      loginButton.current.shake();
      if (error.message) toast.error(error.message);
    }
    if (data) {
      toast.success(`Welcome ${data.first_name}!`);
      CurrentUser.setToken(data.token);
      CurrentUser.setDetails(data);
      setLoggedIn(true);
      setTimeout(
        (data) => {
          switch (data.role.name) {
            case "admin":
              window.location.href = "/dashboard";
              break;
            default:
              window.location.href = "/";
              break;
          }
        },
        1500,
        data
      );
    }
    setLoading(false);
  };

  const submit = (values) => {
    login(values);
  };
  return (
    <div className="absolute bg-theme-gray flex flex-col w-full h-full">
      <Header className="flex-shrink-0" />
      <div className="flex flex-col items-center mt-24">
        <span className="text-gray-800 antialiased font-hairline text-2xl mb-10">
          Sign in
        </span>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(data) => {
            submit(data);
          }}
        >
          {(props) => {
            return (
              <Form className="flex flex-col w-64">
                <Field
                  className="form-input rounded-b-none m-0 flex-1 font-light text-sm"
                  type="email"
                  name="email"
                  placeholder="Email"
                />

                <Field
                  className="form-input rounded-t-none m-0 border-t-0 flex-1 font-light text-sm"
                  type="password"
                  name="password"
                  placeholder="Password"
                />
                <ErrorMessage
                  name="email"
                  component="span"
                  className="text-red-500 text-xs font-hairline mt-1"
                />

                <ErrorMessage
                  name="password"
                  component="span"
                  className="text-red-500 text-xs font-hairline mt-1"
                />

                <Button
                  ref={loginButton}
                  className={`rounded-full mt-8 w-64 text-center antialiased font-light justify-center`}
                  formButton
                  loading={loading}
                  disabled={loggedIn}
                >
                  Login
                </Button>
              </Form>
            );
          }}
        </Formik>
        <Link
          to="/forgot-password"
          className="underline font-light text-sm text-gray-700 mt-6"
        >
          Forgot Password?
        </Link>
      </div>
    </div>
  );
}
