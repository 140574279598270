import React from "react";
import Table from "../../../stories/Table";
import { Link, navigate } from "@reach/router";
import { useClinicLocationList } from "../../../service/Fetchers";
import { getErrorData, getURLParams } from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";

const ListStaff = () => {
  const perPageCount = 10;
  const currentPage = getURLParams("page") ?? 1;
  const {
    locations,
    pageInfo,
    error,
    loading,
    isValidating,
    mutate,
  } = useClinicLocationList({
    currentPage,
    perPageCount,
  });

  const loadingData = loading || isValidating;

  const headerData = {
    constructiveCTA: {
      link: "/physician/location/add",
      title: "Add Location",
    },
    states: [{ title: "Location List" }],
    title: "Locations",
  };

  const content = (
    <Table
      headers={[
        "Location ID",
        "Location Name",
        "Total Cases",
        "Pending Cases",
        "Number of Staff",
      ]}
      pagination={{
        currentpage: pageInfo?.current_page,
        itemsPerPage: pageInfo?.per_page,
        totalItems: pageInfo?.total_count,
        onPageChange: (page) => {
          navigate("/physician/location?page=" + page);
        },
      }}
      loading={loadingData}
      rowContent={locations.map((location) => [
        <p className="underline text-primary">
          <Link to={`/physician/location/view/${location.id}`}>
            {location.id}
          </Link>
        </p>,
        <p>{location.location_name}</p>,
        <p>{location.total_visits}</p>,
        <p>{location.pending_visits}</p>,
        <p>{location.total_staff}</p>,
      ])}
    />
  );

  const errorData = getErrorData({
    error,
    data: locations,
    mutate,
    title: "Location",
  });

  return (
    <div className="w-full flex justify-center">
      <DashboardView
        headerData={headerData}
        content={content}
        errorData={errorData}
        loading={loadingData}
        loadingContent={null}
        className="w-full container py-10"
      />
    </div>
  );
};

export default ListStaff;
