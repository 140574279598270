import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";

const Tabs = ({
  tabs,
  tabContent,
  defaultTab,
  onTabSelect,
  overrideTabSelection,
  ...rest
}) => {
  const [openTab, setOpenTab] = React.useState(defaultTab);

  return (
    <div {...rest}>
      <div className="flex flex-wrap  h-full">
        <div className="w-full flex flex-row h-full">
          <ul
            className="flex w-64 list-none flex-col bg-gray-100 h-full"
            role="tablist"
          >
            {tabs.map((title, index) => (
              <li
                className="-mb-px last:mr-0 text-left flex flex-row"
                key={index}
              >
                <span
                  className={`w-2 h-full ${
                    openTab === index
                      ? "bg-gradient-to-t to-teal-300 from-blue-400"
                      : "bg-transparent"
                  }`}
                ></span>
                <a
                  className={
                    "flex-1 text-md font-bold px-5 py-3  block leading-normal " +
                    (openTab === index
                      ? "text-black bg-blue-300 bg-opacity-25"
                      : "text-gray-600 bg-transparent")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (!overrideTabSelection) setOpenTab(index);
                    onTabSelect(index, title);
                  }}
                  data-toggle="tab"
                  href={`#link${index}`}
                  role="tablist"
                >
                  {title}
                </a>
              </li>
            ))}
          </ul>

          <div className="flex-1 flex flex-col bg-white rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space h-full">
                {tabContent.map((content, index) => (
                  <div
                    className={`h-full ${
                      openTab === index ? "block" : "hidden"
                    }`}
                    id={`#link${index}`}
                    key={index}
                  >
                    {openTab === index && content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  /**
   * Tab title
   */
  tabs: PropTypes.arrayOf(PropTypes.string),
  /**
   * Tab content as node
   */
  tabContent: PropTypes.arrayOf(PropTypes.node),
  /**
   * Index of default tab to be selected
   */
  defaultTab: PropTypes.number,
  /**
   * Index of default tab to be selected
   */
  overrideTabSelection: PropTypes.bool,
};

Tabs.defaultProps = {
  tabs: ["Tab1", "Tab2"],
  tabContent: [<p>Tab 1 content</p>, <p>Tab 2 content</p>],
  defaultTab: 0,
  overrideTabSelection: false,
};

export default Tabs;
