/* eslint-disable no-unused-vars */
import { toast } from "../components/Toast";
import { API } from "../network/API";

const getCurrentUserDetails = () => {
  const curUserDetail = localStorage.getItem("current_user_details");
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

export const CurrentUser = {
  setToken: (token) => localStorage.setItem("user_auth_token", token),
  getToken: () => localStorage.getItem("user_auth_token"),
  logout: async () => {
    const { data, error } = await API.delete("/auth/logout");
    window.location.href = "/login";
    localStorage.removeItem("user_auth_token");
    localStorage.removeItem("current_user_details");
  },
  isLoggedIn: () => !!localStorage.getItem("user_auth_token"),
  isAdmin: () => getCurrentUserDetails()?.user?.role?.name === "admin",
  isAccessionist: () =>
    getCurrentUserDetails()?.user?.role?.name === "accessionist",
  isGrosser: () => getCurrentUserDetails()?.user?.role?.name === "grosser",
  isTranscriptionist: () =>
    getCurrentUserDetails()?.user?.role?.name === "transcriptionist",
  isPathologist: () =>
    getCurrentUserDetails()?.user?.role?.name === "pathologist",
  isClinicStaff: () =>
    [
      "physician",
      "clinic_admin",
      "clinic_assistant",
      "clinic_physician",
    ].includes(getCurrentUserDetails()?.user?.role?.name),
  isPhysicianOrCLinicAdmin: () =>
    ["physician", "clinic_admin"].includes(
      getCurrentUserDetails()?.user?.role?.name
    ),
  getDetails: getCurrentUserDetails(),
  setDetails: (data) =>
    localStorage.setItem("current_user_details", JSON.stringify(data)),
};

export const logout = async () => {
  // Logout from server
  const { data, error } = await API.delete("/auth/logout");
  localStorage.removeItem("current_user_details");
  localStorage.removeItem("user_auth_token");
  toast.success("Logout successfully");
  setTimeout(() => {
    window.location.href = "/login";
  }, 1500);
};
