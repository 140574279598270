import React from "react";
import { FormDataField } from "../../stories/FormDataField";
import _ from "lodash";
import { useMacro, useCaseType } from "../../service/Fetchers";
import LoadingStatus from "../../components/Loader";
import { DashboardView } from "../../stories/DashboardView";
import { checkEmpty } from "../../service/Helper";

const ViewMacro = ({ id: macro_id, type }) => {
  const isDiagnosis = type === "diagnosis";
  const { macro, loading, error, mutate } = useMacro({
    macro_id,
    type,
  });

  const {
    caseType,
    loading: loadingCaseType,
    error: caseTypeError,
    mutate: mutateCaseType,
  } = useCaseType({
    case_type_id: isDiagnosis && macro ? macro?.visit_type?.id : null,
  });

  const headerData = {
    constructiveCTA: {
      link: `/dashboard/${type}-macros/edit/${macro_id}`,
      title: "Edit",
    },
    states: [
      {
        title: `${_.startCase(type)} Macros List`,
        link: `/dashboard/${type}-macros`,
      },
      { title: `${type} Macro #${macro_id}` },
    ],
    title: macro ? macro.code : "Macro Code",
  };

  const loadingData = (() => {
    // if (!isDiagnosis) return loading;
    return loading;
  })();

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/3"
          title="Name"
          value={
            <span className="font-bold uppercase">
              {checkEmpty(macro?.name)}
            </span>
          }
        />
        <FormDataField
          className="w-1/3 pr-2"
          title="Code"
          value={
            <span className="font-bold uppercase break-all">
              {checkEmpty(macro?.code)}
            </span>
          }
        />
      </div>
      <div className="flex flex-row">
        <FormDataField
          title="Description"
          value={_.isEmpty(macro?.description) ? "NA" : macro?.description}
        />
      </div>
      {isDiagnosis && (
        <div className="flex flex-row">
          <FormDataField
            className="w-1/3"
            title="CPT code"
            value={
              <span className="font-bold uppercase">
                {checkEmpty(
                  _.join(
                    macro?.cpts.map((cpt) => cpt.code),
                    ","
                  )
                )}
              </span>
            }
          />
          <FormDataField
            className="w-1/3"
            title="ICD Code"
            value={
              <span className="font-bold uppercase">
                {checkEmpty(
                  _.join(
                    macro?.icds.map((icd) => icd.code),
                    ","
                  )
                )}
              </span>
            }
          />
          <FormDataField
            className="w-1/3"
            title="Is Site Linked"
            value={
              <span className="font-bold uppercase">
                {macro?.is_site_linked ? "Yes" : "No"}
              </span>
            }
          />
          {/* <FormDataField
            className="w-1/3"
            title="Visit type"
            value={
              <span className="font-bold uppercase">{caseType?.name}</span>
            }
          /> */}
        </div>
      )}
    </div>
  );

  const errorData = (() => {
    if (isDiagnosis) {
      if (caseTypeError) {
        return {
          show: true,
          subtitle: "Unable to fetch Visit Type details",
          ctaOnClick: mutateCaseType(),
        };
      }
      return null;
    } else {
      if (!error) return null;
      return {
        show: true,
        subtitle: "Unable to fetch macro details",
        ctaOnClick: mutate,
      };
    }
  })();

  return (
    <DashboardView
      headerData={headerData}
      loading={loadingData}
      loadingContent={<LoadingStatus />}
      content={content}
      errorData={errorData}
    />
  );
};

export default ViewMacro;
