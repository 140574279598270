import React, { useState, useRef } from "react";
import { Button } from "../../stories/Button";
import { Formik, Form } from "formik";
import { addCaseTypeSchema } from "../../service/Validation";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import { FormInputField } from "../../stories/FormInputField";
import { useCaseType } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import { navigate } from "@reach/router";

export default function AddCaseType({ id: case_type_id }) {
  const [loading, setLoading] = useState(false);
  const buttonTitle = case_type_id ? "Update" : "Create Case Type";
  const pageTitle = case_type_id
    ? `Update case type #${case_type_id}`
    : "Create new case type";
  const buttonRef = useRef(null);
  const {
    caseType,
    loading: caseLoading,
    mutate,
    isValidating,
    error,
  } = useCaseType({
    case_type_id,
  });

  const initialFormValues = () => {
    return {
      name: caseType?.name ?? "",
      description: caseType?.description ?? "",
    };
  };

  const createOrUpdateCaseType = async (values) => {
    if (case_type_id) return API.put(`/visit_types/${case_type_id}`, values);
    return API.post("/visit_types", values);
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    const { data, error } = await createOrUpdateCaseType(values);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (case_type_id) {
        toast.success(`Updated ${values.name}!`);
        setTimeout(() => {
          navigate(`/dashboard/case-types/view/${case_type_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${values.name}!`);
      }
    }
    setLoading(false);
  };

  const headerData = {
    states: case_type_id
      ? [
          { title: "Case Type List", link: "/dashboard/case-types" },
          {
            title: `Case Type #${case_type_id}`,
            link: `/dashboard/case-types/view/${case_type_id}`,
          },
          { title: "Edit" },
        ]
      : [
          { title: "Case Type List", link: "/dashboard/case-types" },
          { title: `Add` },
        ],
    title: pageTitle,
  };

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addCaseTypeSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <Form className="flex-1">
            <div className="flex flex-col space-y-2">
              <FormInputField type="text" name="name" required />
              <FormInputField type="textarea" name="description" />
            </div>

            <div className="flex flex-row justify-between mt-10">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to fetch case type details",
        ctaOnClick: mutate,
      }
    : null;

  const loadingData = case_type_id ? caseLoading || isValidating : false;

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      loading={loadingData}
      errorData={errorData}
    />
  );
}
