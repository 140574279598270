import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { FormInputField } from "../../../stories/FormInputField";
import { DashboardView } from "../../../stories/DashboardView";
import { Button } from "../../../stories/Button";
import { API } from "../../../network/API";
import { toast } from "../../../components/Toast";
import { useSingleClient } from "../../../service/Api_services/GetClients/useGetClients";
import _ from "lodash";
import { useNavigate } from "@reach/router";
import { adddressFieldItems } from "../../Physician/AddPhysician";

const ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  abbreviation: Yup.string()
    .required("Accession prefix is requierd")
    .max(3)
    .min(1),
  client_addresses_attributes: Yup.array()
    .of(
      Yup.object().shape({
        location_name: Yup.string()
          .trim()
          .required("Location name is required"),
        street1: Yup.string().trim().required("Street 1 is required"),
        street2: Yup.string().trim().nullable().optional(),
        city: Yup.string().trim().required("City is required"),
        state: Yup.string().trim().required("State is required"),
        zipcode: Yup.string("Please enter valid zipcode")
          .matches(ZIP_REGEX, "Please enter valid zipcode")
          .required("Zipcode is required"),
      })
    )
    .required("Must have address attribtues")
    .min(1, "Minimum of 1 address is needed"),
});

const AddClientForm = ({ id = null }) => {
  const { data } = useSingleClient({
    id,
  });

  const navigate = useNavigate();

  const createOrUpdateClient = async (values) => {
    if (id) {
      return API.put(`/clients/${id}.json`, { client: values });
    }
    return API.post("/clients.json", { client: values });
  };

  const onSubmit = async (values, actions) => {
    const modifiedObj = {
      ...values,
      abbreviation: values?.abbreviation?.toUpperCase(),
    };

    const { data, error } = await createOrUpdateClient(modifiedObj);
    if (data) {
      console.log(data, "data");
      if (id) {
        toast.success(`Updated ${values.name}!`);
        setTimeout(() => {
          navigate(`/dashboard/clients/view/${id}`);
        }, 1200);
      } else {
        // actions.resetForm({ values: initialFormValues() });
        setTimeout(() => {
          navigate(`/dashboard/clients`);
        }, 1200);
        toast.success(`Added ${values.name}!`);
      }
    } else {
      toast.error(error.message);
    }
    // You can add your submission logic here
  };

  return (
    <Formik
      initialValues={{
        name: data?.name ?? "",
        abbreviation: data?.abbreviation ?? "C",
        interface: data?.interface,
        client_addresses_attributes:
          data?.client_addresses?.length > 0
            ? data?.client_addresses?.map((add) => ({
                id: add.id ?? null,
                ...add,
              }))
            : [adddressFieldItems],
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ isValid, values, errors }) => (
        <Form>
          <div className="flex flex-col">
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
            <div className="grid grid-cols-3 gap-10">
              <FormInputField title="Name" type="text" name="name" required />
              <FormInputField
                title="accession prefix"
                type="text"
                name="abbreviation"
                disabled={id !== null}
                inputProps={{
                  className: "uppercase",
                  onKeyDown: (event) => {
                    // Check if the pressed key is a number or an alphabet
                    const isNumberOrAlphabet = /[a-z]/i.test(event.key);

                    // Check if the pressed key is the backspace key
                    const isBackspace = event.key === "Backspace";

                    if (
                      (values.abbreviation.length === 3 && !isBackspace) ||
                      (!isBackspace && !isNumberOrAlphabet)
                    ) {
                      event.preventDefault();
                    }
                  },
                }}
                required
              />

              <div className="flex flex-col justify-start gap-3">
                <label htmlFor="interface" style={{ fontSize: "16px" }}>
                  *Client Type
                </label>
                <div className="flex items-center space-x-4">
                  <div className="flex gap-4">
                    <Field
                      type="checkbox"
                      id="interface"
                      checked="true"
                      disabled
                      name="hand_key"
                      style={{ transform: "scale(1.5)", marginLeft: 10 }}
                    />
                    <p>Hand-Key</p>
                  </div>
                  <div className="flex gap-4">
                    <Field
                      type="checkbox"
                      id="interface"
                      name="interface"
                      style={{ transform: "scale(1.5)", marginLeft: 10 }}
                    />
                    <p>Interface</p>
                  </div>
                </div>
                <ErrorMessage name="interface" component="div" />
              </div>
            </div>
            <div className="w-full h-0.5 bg-gray-200 my-4"></div>
            <FieldArray
              name="client_addresses_attributes"
              render={(arrayHelpers) => (
                <div>
                  {values.client_addresses_attributes
                    ?.filter((e) => e?._destroy !== true)
                    .map((address, index) => (
                      <div
                        key={index}
                        className="flex flex-col bg-theme-gray p-6 mt-10 bg-opacity-50 rounded-lg"
                      >
                        <div className="flex flex-row justify-between">
                          <span>Address #{index + 1}</span>
                          {values?.client_addresses_attributes.filter(
                            (e) => e?._destroy !== true
                          ).length > 1 && (
                            <button
                              type="button"
                              className="mt-2 text-xs text-gray-700 font-normal antialiased"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                if (address.id)
                                  arrayHelpers.push({
                                    id: address.id,
                                    _destroy: true,
                                    location_name: address.location_name,
                                    street1: address.street1,
                                    street2: address.street2,
                                    city: address.city,
                                    state: address.state,
                                    zipcode: address.zipcode,
                                  });
                              }}
                            >
                              [ - remove this address ]
                            </button>
                          )}
                        </div>

                        <div className="flex flex-col  py-5">
                          <FormInputField
                            type="text"
                            title="Location Name"
                            name={`client_addresses_attributes[${index}].location_name`}
                            required
                          />
                          <FormInputField
                            type="text"
                            title="Street 1"
                            name={`client_addresses_attributes[${index}].street1`}
                            required
                          />
                          <FormInputField
                            type="text"
                            title="Street 2"
                            name={`client_addresses_attributes[${index}].street2`}
                          />
                          <div className="flex flex-row space-x-5">
                            <FormInputField
                              type="text"
                              title="City"
                              name={`client_addresses_attributes[${index}].city`}
                              required
                            />
                            <FormInputField
                              type="text"
                              title="State"
                              name={`client_addresses_attributes[${index}].state`}
                              required
                            />
                            <FormInputField
                              type="text"
                              title="Zipcode"
                              name={`client_addresses_attributes[${index}].zipcode`}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  <button
                    type="button"
                    className="text-primary my-4"
                    onClick={() => arrayHelpers.push(adddressFieldItems)}
                  >
                    + Add another address
                  </button>
                </div>
              )}
            />
          </div>
          <div className="mt-4">
            <Button formButton disabled={!isValid}>
              {id == null ? "Create Client" : "Update Client"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const ClientForm = ({ id = null }) => {
  const headerData = {
    states: id
      ? [
          { title: "Client List", link: "/dashboard/clients" },
          {
            title: `Client #${id}`,
            link: `/dashboard/clients/view/${id}`,
          },
          { title: "Edit" },
        ]
      : [
          { title: "Client List", link: "/dashboard/clients" },
          { title: `Add` },
        ],
    title: id ? `Update Client #${id}` : "Add New Client",
  };

  return (
    <DashboardView
      headerData={headerData}
      content={<AddClientForm id={id} />}
      loading={false}
      errorData={false}
    />
  );
};

export default ClientForm;
