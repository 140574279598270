import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";
import { DashboardHeader } from "./DashboardHeader";
import { ErrorView } from "./ErrorView";
import _ from "lodash";
import LoadingStatus from "../components/Loader";
/**
 * Primary UI component for user interaction
 */
export const DashboardView = ({
  headerData,
  errorData,
  content,
  loading,
  loadingContent,
  ...props
}) => {
  const contentToShow = () => {
    if (loading) {
      return loadingContent ? loadingContent : content;
    } else if (errorData && errorData.show) {
      return <ErrorView {..._.omit(errorData, "show")} />;
    }
    return content;
  };
  return (
    <div className="h-full p-4" {...props}>
      <DashboardHeader {...headerData} />
      {contentToShow()}
    </div>
  );
};

DashboardView.propTypes = {
  headerData: PropTypes.shape({
    /**
     * states for breadcrumb
     */
    states: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Name of the breadcrumb
         */
        title: PropTypes.string,
        /**
         * link of breadcrumb
         */
        link: PropTypes.string,
      })
    ),
    /**
     * Title of headerData
     */
    title: PropTypes.string,
    /**
     * constructive button details
     */
    constructiveCTA: PropTypes.shape({
      /**
       * Title of button
       */
      title: PropTypes.string,
      /**
       * link to redirect to
       */
      link: PropTypes.string,
    }),
    /**
     * desctructive action callback
     */
    destructiveCTA: PropTypes.shape({
      /**
       * Title of button
       */
      title: PropTypes.string,
      /**
       * if need to show loading info
       */
      loading: PropTypes.bool,
      /**
       * callback on click
       */
      onClick: PropTypes.func,
    }),
  }),
  errorData: PropTypes.shape({
    /**
     * title or view
     */
    title: PropTypes.string,
    /**
     * title or view
     */
    show: PropTypes.bool,
    /**
     * info about errorData
     */
    subtitle: PropTypes.node,
    /**
     * icon for error
     */
    icon: PropTypes.node,
    /**
     * TItle of button
     */
    ctaTitle: PropTypes.string,
    /**
     * callback function on click
     */
    ctaOnClick: PropTypes.func,
  }),
  /**
   * Content to show
   */
  content: PropTypes.node,
  /**
   * content to show while loading
   */
  loadingContent: PropTypes.node,
  /**
   * show loading view
   */
  loading: PropTypes.bool,
};

DashboardView.defaultProps = {
  headerData: {
    states: [],
    title: "",
  },
  errorData: {
    show: false,
    title: "Ooops!",
    subtitle: null,
    ctaTitle: "Reload",
    ctaOnClick: () => {},
  },
  loading: false,
  loadingContent: <LoadingStatus />,
};
