import React, { useContext } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { GlobalVariablesContext } from "../providers/GlobalProvider";
// Import styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./pdf.css";

function renderToolbar(Toolbar) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setPdfViewer } = useContext(GlobalVariablesContext);
  return (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,

          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              flex: 1,
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div className="flex-1 flex items-center">
              <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                <GoToPreviousPage />
              </div>
              <CurrentPageInput /> / <NumberOfPages />
              <div style={{ padding: "0px 2px" }}>
                <GoToNextPage />
              </div>
              <div
                style={{ padding: "0px 4px" }}
                onClick={() => setPdfViewer(false)}
              >
                <svg
                  className="cursor-pointer"
                  height="24"
                  viewBox="0 0 48 48"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#2c3e50"
                    d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"
                  />
                  <path d="M0 0h48v48h-48z" fill="none" />
                </svg>
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
}

export default function PDFViewer({ open, setOpen = () => {}, url }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // Thumbnails tab
    ],
    renderToolbar,
  });
  if (!open) return null;
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
      <div
        className="fixed z-30 inset-0 overflow-hidden overflow-y-scroll"
        key={`${open}`}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => setOpen(false)}
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div
            className="inline-block absolute align-bottom bg-white overflow-hidden overflow-y-scroll transition-all pb-5 rounded"
            role="dialog"
            aria-modal="true"
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              height: "95vh",
              width: "95vw",
            }}
            aria-labelledby="modal-headline"
          >
            <div className="relative"></div>
            <div className="flex justify-center">
              <Viewer
                defaultScale={0.9}
                plugins={[defaultLayoutPluginInstance]}
                fileUrl={url}
              />
            </div>
          </div>
        </div>
      </div>
    </Worker>
  );
}
