import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";

/**
 * Primary UI component for user interaction
 */
export const StatusTag = ({ type, children, ...props }) => {
  return (
    <div
      {...props}
      className={`bg-${type}-100 text-${type}-500 px-3 py-1 text-center rounded font-normal text-xxs inline-block tracking-wider uppercase`}
    >
      {children}
    </div>
  );
};

StatusTag.propTypes = {
  /**
   * tailwind color
   */
  type: PropTypes.string,
};

StatusTag.defaultProps = {
  type: "yellow-600",
};
