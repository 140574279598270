import React from "react";
import { Loader } from "../../service/Icon";
import { NoData } from "../../service/Illustration";
import { ErrorView } from "../../stories/ErrorView";
import AccessionistPending from "../CaseView/AccessionistPending";

export default function SelectedCaseView({
  selectedCase,
  loading,
  totalCases,
  refetch,
  tracking_num,
  formikRef,
  addEmptyCase,
}) {
  if (!selectedCase) {
    if (loading) {
      return <Loader />;
    }
    if (totalCases === 0) {
      return (
        <div className="flex items-center justify-center h-full">
          <ErrorView
            icon={<NoData />}
            title="Please select a case from the list"
            subtitle={`If no cases are listed, kindly add a new case`}
          />
        </div>
      );
    }
    return null;
  } else {
    return (
      <AccessionistPending
        visitDetails={selectedCase}
        loading={loading}
        refetch={refetch}
        tracking_num={tracking_num}
        key={selectedCase.id}
        totalCases={totalCases}
        formikRef={formikRef}
        addEmptyCase={addEmptyCase}
      />
    );
  }
}
