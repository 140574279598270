import React from "react";
import { useVisit } from "../../service/Fetchers";
import { Loader } from "../../service/Icon";
import Header from "../../stories/Header";
import CaseDetailView from "../CaseView/CaseDetailView";

const CaseDetailContent = ({ id }) => {
  const { visit, loading } = useVisit({ id });
  if (loading) return <Loader />;
  if (visit) {
    return <CaseDetailView visitDetails={visit} />;
  }
  return null;
};
export default function CaseDetail({ id }) {
  return (
    <div className="bg-theme-gray  w-full h-full">
      <Header />
      <div className="page-container p-10 flex items-center justify-center">
        <CaseDetailContent id={id} />
      </div>
    </div>
  );
}
