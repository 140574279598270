/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import "../assets/main.css";
import { FormDataField } from "./FormDataField";
import { Field, ErrorMessage, useField, useFormikContext } from "formik";
import _ from "lodash";
import Autocomplete from "react-autocomplete";
import { Loader, Search, Close } from "../service/Icon";
import DatePicker from "react-date-picker";
import { FaCalendarAlt } from "react-icons/fa";
import classnames from "classnames";
/**
 * Primary UI component for user interaction
 */

const PlaceholderField = ({ value, extraInputClass }) => {
  return (
    <input
      type="text"
      value={value}
      disabled={true}
      className={`form-input w-full opacity-50 pointer-events-none bg-gray-300 text-gray-800 rounded ${extraInputClass}`}
    />
  );
};
const InputField = (props) => {
  const {
    type,
    name,
    disabled,
    options,
    inputProps,
    autocompleteProps,
    customDateProps,
    selectProps,
    extraInputClass = "",
    primaryClass = "",
  } = props;
  const autoCompleteRef = useRef();
  const { className, col, onKeyDown = null, ...restInputProps } = inputProps;
  const { values } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const [open, close] = useState(false);

  const fieldClassName = `${className} ${
    disabled
      ? "opacity-50 pointer-events-none bg-gray-300 text-gray-800"
      : "opacity-100"
  }`;
  switch (type) {
    case "email":
    case "text":
    case "password":
    case "number":
      return (
        <Field
          type={type}
          name={name}
          onKeyDown={onKeyDown}
          className={`form-input w-full ${fieldClassName} rounded ${extraInputClass}`}
          {...restInputProps}
        />
      );
    case "date":
      return (
        <Field
          type={type}
          name={name}
          className={`form-input w-full ${fieldClassName} rounded ${extraInputClass}`}
          {...restInputProps}
          placeholder="yyyy-mm-dd"
        />
      );
    case "custom-date":
      const { dateValue, dateOnChange, format } = customDateProps;
      return (
        <DatePicker
          className={classnames(
            `form-input w-full ${fieldClassName} rounded  ${extraInputClass}`,
            primaryClass
          )}
          name={name}
          value={dateValue}
          onChange={dateOnChange}
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          format={format}
          clearIcon={dateValue ? <Close /> : null}
          calendarIcon={<FaCalendarAlt />}
          {...customDateProps}
        />
      );
    case "textarea":
      return (
        <Field
          as="textarea"
          name={name}
          className={`form-textarea mt-1 block w-full ${fieldClassName} rounded  ${extraInputClass}`}
          placeholder=""
          rows="3"
          {...restInputProps}
        />
      );
    case "custom-textarea":
      return (
        <Field
          as="textarea"
          name={name}
          className={`form-textarea mt-1 block w-full ${className} rounded  ${extraInputClass}`}
          placeholder=""
          rows="3"
          {...restInputProps}
        />
      );
    case "select":
      return (
        <Field
          as="select"
          className={`form-select w-full ${fieldClassName} rounded bg-gray-50 ${extraInputClass}`}
          name={name}
          {...restInputProps}
        >
          <option defaultValue value="">
            Select
          </option>
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.name}
            </option>
          ))}
        </Field>
      );
    case "custom-select":
      const { setValue: setSelectValue, setTouched, setError } = helpers;
      const {
        renderOption,
        valueKey: selectValueKey,
        ...otherSelectProps
      } = selectProps;
      const setFieldProps = (selectedOption) => {
        if (!selectedOption) return;
        setTouched(true);
        setError(null);
        setSelectValue(selectedOption[selectValueKey ?? "value"]);
      };
      const Option = ({ children, ...props }) => {
        return (
          <components.Option {...props}>
            {selectProps?.renderOption
              ? selectProps?.renderOption(props)
              : children}
          </components.Option>
        );
      };

      // console.log(otherSelectProps?.ref, "props");

      return (
        <Select
          name={name}
          defaultValue={otherSelectProps?.defaultValue}
          onChange={setFieldProps}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: "#f9fafb",
              minHeight: extraInputClass ? 30 : 42,
              borderColor: "#d2d6dc",
            }),
            valueContainer: (styles) => ({
              ...styles,
              paddingTop: extraInputClass ? 0 : 2,
              paddingBottom: extraInputClass ? 0 : 2,
            }),
          }}
          onBlur={() => {
            if (otherSelectProps?.onBlur) {
              otherSelectProps?.onBlur();
            }
            setTouched(true);
          }}
          options={options}
          components={{
            Option,
          }}
          {...otherSelectProps}
        />
      );
    case "radio":
      return (
        <div
          // className="grid grid-cols-2 gap-4"
          className={classnames(
            `flex`,
            col ? "flex-col space-y-1" : "flex-row flex-wrap"
          )}
        >
          {options.map((option) => (
            <div
              className={`flex-shrink-0 ${col ? "" : "mr-3"} `}
              key={option.value}
            >
              <label className="inline-flex items-center">
                <Field
                  type="radio"
                  className={`form-radio ${fieldClassName}  `}
                  name={name}
                  tabIndex={inputProps?.tabIndex || 0}
                  value={`${option.value}`}
                  {...inputProps}
                />
                <span className={"ml-2 " + extraInputClass}>{option.name}</span>
              </label>
            </div>
          ))}
        </div>
      );
    case "checkbox":
      return (
        <div
          className={`flex ${
            col ? "flex-col space-y-2" : "flex-row flex-wrap"
          }`}
        >
          {options.map((option) => (
            <div
              className={`flex-shrink-0 ${col ? "" : "mr-8"} `}
              key={option.value}
            >
              <label className="inline-flex items-center">
                <Field
                  type="checkbox"
                  className={`form-checkbox ${fieldClassName}  ${extraInputClass}`}
                  name={name}
                  value={`${option.value}`}
                />
                <span className="ml-2">{_.startCase(option.name)}</span>
              </label>
            </div>
          ))}
        </div>
      );
    case "autocomplete":
      const {
        setFieldValue,
        items,
        labelKey,
        valueKey,
        value,
        setValue,
        loading,
        onChange,
        onSelect,
        renderCustomLabel,
        creatable,
        shouldItemRender,
        renderAfterType = true,
      } = autocompleteProps;
      if (items?.length === 1 && autoCompleteRef.current?.state) {
        autoCompleteRef.current.state.highlightedIndex = 0;
      }
      const extraProps = renderAfterType
        ? {
            renderMenu: (items, value) => (
              <div
                style={{
                  zIndex: 5,
                  position: "absolute",
                  top: 50,
                  left: 0,
                  borderRadius: 4,
                  overflow: "hidden",
                  boxShadow:
                    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                }}
                className="menu w-full"
              >
                {items}
              </div>
            ),
          }
        : {
            menuStyle: {
              zIndex: 5,
              position: "absolute",
              top: 50,
              left: 0,
              borderRadius: 4,
              overflow: "hidden",
              boxShadow:
                "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
            },
          };

      return (
        <div className="relative w-full">
          <Autocomplete
            // open={open}
            ref={autoCompleteRef}
            getItemValue={(item) => JSON.stringify(item)}
            items={items ?? []}
            renderItem={(item, isHighlighted) => (
              <div
                className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                // style={{ background: isHighlighted ? "lightgray" : "white" }}
                style={{ background: isHighlighted ? "lightgray" : "white" }}
                id={`dropdown-${name}-item-${item?.[labelKey]}`}
                key={item?.[labelKey]}
              >
                {renderCustomLabel ? renderCustomLabel(item) : item?.[labelKey]}
              </div>
            )}
            inputProps={{
              className: `form-input w-full ${fieldClassName} rounded bg-gray-50  ${extraInputClass}`,
              name,
              autoComplete: "off",
              onBlur: () => {
                close(false);
              },
              onFocus: () => {
                close(true);
              },
              ...restInputProps,
            }}
            wrapperProps={{ className: "w-full" }}
            onChange={(e) => {
              setFieldValue(name, creatable ? e.target.value : "");
              setValue(e.target.value);
              if (onChange) onChange(e);
            }}
            value={value}
            {...extraProps}
            autoHighlight
            shouldItemRender={shouldItemRender}
            onSelect={(v) => {
              const parsedData = JSON.parse(v);
              setFieldValue(name, `${parsedData[valueKey]}`);
              setValue(
                renderCustomLabel
                  ? renderCustomLabel(parsedData)
                  : parsedData[labelKey]
              );
              if (onSelect) {
                onSelect(parsedData);
                close(false);
              }
            }}
          />
          <div
            // onClick={() => {
            //   close(!open);
            // }}
            className="absolute right-2 top-3"
          >
            {loading ? <Loader /> : <Search />}
          </div>
        </div>
      );

    default:
      return (
        <Field
          type="text"
          name={name}
          className={`form-input w-full ${fieldClassName} rounded  ${extraInputClass}`}
          {...restInputProps}
        />
      );
  }
};

export const FormInputField = (props) => {
  const {
    title,
    value,
    name,
    className,
    required,
    inputProps,
    errorField,
    autocompleteProps,
    selectProps,
    setFieldValue,
    customDateProps,
    labelClassName,
    addSpacing = false,
    smVariant = false,
    hideError = false,
    primaryVariant = false,
    note = "",
    ...rest
  } = props;
  let errorClass = "";
  let inputClass = "";
  let labelClass = "";
  let primaryClass = "";
  if (smVariant) {
    errorClass = "h-4 text-xxs text-red-500";
    inputClass = "text-xs";
    if (rest?.type === "custom-date") {
      inputClass += "form-input-small";
    }
    labelClass = "text-xs";
  } else if (primaryVariant) {
    primaryClass = "h-10";
  }

  return (
    <div
      {...rest}
      className={`flex flex-col ${className ? className : "flex-1"}`}
    >
      <FormDataField
        smVariant={smVariant}
        note={note}
        className={
          labelClassName
            ? labelClassName + " " + labelClass
            : "flex-1 " + labelClass
        }
        required={required}
        addSpacing={addSpacing}
        title={
          title && title.length
            ? title
            : _.chain(name)
                .words()
                .map((i) => _.startCase(i))
                .join(" ")
                .value()
        }
        value={
          name && !_.isEmpty(name) ? (
            <InputField
              {...props}
              autocompleteProps={autocompleteProps}
              customDateProps={customDateProps}
              extraInputClass={inputClass}
              primaryClass={primaryClass}
            />
          ) : (
            <PlaceholderField
              {...props}
              extraInputClass={inputClass}
              selectProps={selectProps}
            />
          )
        }
        hideError={hideError}
        errorClass={errorClass}
        errorField={name && <ErrorMessage name={name} />}
      />
    </div>
  );
};

FormInputField.propTypes = {
  /**
   * title of data
   */
  title: PropTypes.string,
  /**
   * value of data
   */
  value: PropTypes.node,
  /**
   * value of data
   */
  errorField: PropTypes.node,
  /**
   * is field mandatory
   */
  required: PropTypes.bool,
  /**
   * name of the field
   */
  name: PropTypes.string,
  /**
   * is field mandatory
   */
  inputProps: PropTypes.object,
  /**
   * if the field is not editable
   */
  disabled: PropTypes.bool,
  /**
   *
   */
  autocompleteProps: PropTypes.shape({
    /**
     * Key to render
     */
    labelKey: PropTypes.string,
    /**
     * to render custom label
     */
    renderCustomLabel: PropTypes.func,
    /**
     * value key to use
     */
    valueKey: PropTypes.string,
    /**
     * value of input
     */
    value: PropTypes.string,
    /**
     * set value at will (autocomplete)
     */
    setValue: PropTypes.func,
    /**
     * set value at will (autocomplete)
     */
    setFieldValue: PropTypes.func,
    /**
     * on character change
     */
    onChange: PropTypes.func,
    /**
     * on select option
     */
    onSelect: PropTypes.func,
    /**
     * if autccomplete is loading
     */
    loading: PropTypes.bool,
    /**
     * can auto create if not available
     */
    creatable: PropTypes.bool,
    /**
     * set value at will (autocomplete)
     */
    items: PropTypes.arrayOf(PropTypes.object),
  }),
  customDateProps: PropTypes.shape({
    /**
     * Date value
     */
    dateValue: PropTypes.any,
    /**
     * to change custom date value
     */
    dateOnChange: PropTypes.func,
    /**
     * custom date input format
     */
    format: PropTypes.string,
  }),
  /**
   * options for checkbpx, radio and select
   */
  selectProps: PropTypes.shape({
    /**
     * to render custom option
     */
    renderOption: PropTypes.func,
    /**
     * value key to use
     */
    valueKey: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * name to be displayed
       */
      name: PropTypes.string,
      /**
       * value of the option
       */
      value: PropTypes.string,
    })
  ),
};

FormInputField.defaultProps = {
  title: "",
  errorField: null,
  required: false,
  disabled: false,
  inputProps: { className: "" },
  autocompleteProps: { setFieldValue: null, loading: false, creatable: false },
};
