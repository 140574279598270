import React, { useState } from "react";
import { Filter } from "./Filter";
import { filterReferListSchema } from "../../../service/Validation";
import Table from "../../../stories/Table";
import { usePhysicianVisitList } from "../../../service/Fetchers";
import { Link, navigate } from "@reach/router";
import _ from "lodash";
import { getFilterErrorData, getURLParams } from "../../../service/Helper";
import { ErrorView } from "../../../stories/ErrorView";
import { Status } from "../../../stories/Status";
import { format } from "date-fns";

export default function CaseList() {
  const perPageCount = 20;
  const currentPage = getURLParams("page") ?? 1;
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState({});
  const { visits, pageInfo, loading, error, mutate } = usePhysicianVisitList(
    filters,
    { currentPage, perPageCount }
  );

  let resetForm = () => {};

  const loadingData = loading;

  // search as and when we type
  const onChangeValues = (data) => {
    setFilters(data);
    setIsEmpty(Object.values(data).some((x) => x !== ""));
  };

  const onSubmit = (data) => {
    setFilters(data);
    setIsEmpty(Object.values(data).some((x) => x !== ""));
  };

  const initialValues = {
    search_key: "",
    report_status: "all",
    sort_by: "status",
    ordering: "desc",
    location_ids: [],
  };

  const errorData = getFilterErrorData({
    error,
    data: visits,
    mutate,
    title: "Referring Provider List",
    removeSubTitle: true,
    reload: () => {
      setFilters(initialValues);
      resetForm(initialValues);
    },
    hasValus: isEmpty,
  });

  const StatusText = ({ status, outline, archived }) => {
    switch (status) {
      case "pending":
        return (
          <Status type="red-400" outline={outline}>
            In Receiving
          </Status>
        );
      case "accessioning_complete":
        return (
          <Status type="orange-400" outline={outline}>
            In Grossing
          </Status>
        );
      case "grossed":
        return (
          <Status type="orange-400" outline={outline}>
            In Processing
          </Status>
        );
      case "diagnosed":
        return (
          <Status type="green-400" outline={outline}>
            {archived ? "Archived" : "Done"}
          </Status>
        );
      default:
        return (
          <Status type="red-400" outline={outline}>
            In Receiving
          </Status>
        );
    }
  };

  const getDisplayValue = (value) =>
    _.isEmpty(value) ? (
      <span className="bg-cool-gray-300 text-white p-2 rounded-sm mt-4">
        NA
      </span>
    ) : (
      value
    );

  const content = (
    <Table
      theadClass="h-12"
      tbodyClass="h-12"
      headers={[
        "Case ID",
        "Patient Name",
        "Diagnosis Date",
        "Collection Date",
        "Last Activity",
        "Status",
      ]}
      pagination={{
        currentpage: pageInfo.current_page,
        itemsPerPage: pageInfo.per_page,
        totalItems: pageInfo.total_count,
        onPageChange: (page) => {
          navigate("/physician?page=" + page);
        },
      }}
      loading={loadingData}
      rowContent={visits.map((caseInfo) => [
        <p className="underline text-primary">
          <Link to={`/physician/cases/view/${caseInfo.id}`}>
            {caseInfo?.ucid}
          </Link>
        </p>,
        <p>
          {caseInfo?.patient?.first_name} {caseInfo.patient?.last_name}
        </p>,

        <p>
          {getDisplayValue(
            _.isEmpty(caseInfo?.diagnosed_at)
              ? ""
              : format(new Date(caseInfo?.diagnosed_at), "dd-MMM-yyyy")
          )}
        </p>,
        <p>
          {format(
            new Date(caseInfo?.basic_speciman_demo?.collected_date),
            "dd-MMM-yyyy"
          )}
        </p>,
        <p>{format(new Date(caseInfo?.updated_at), "dd-MMM-yyyy")}</p>,
        <p>
          <StatusText
            outline
            status={caseInfo?.status}
            archived={
              filters.report_status === "downloaded" &&
              caseInfo?.report_generated
            }
          />
        </p>,
      ])}
    />
  );

  const contentToShow = () => {
    if (loading) {
      return <div className="flex w-full h-full pl-5">{content}</div>;
    } else if (errorData && errorData.show) {
      return (
        <div className="bg-white flex justify-center flex-1 ml-5">
          <ErrorView {..._.omit(errorData, "show")} />
        </div>
      );
    }
    return (
      <div className="flex w-full h-full pl-5 justify-center">{content}</div>
    );
  };

  return (
    <>
      <Filter
        onChangeValues={onChangeValues}
        initialValues={initialValues}
        onSubmit={(data) => onSubmit(data)}
        validationSchema={filterReferListSchema}
        loading={loadingData}
        resetFormRef={(resetFormRef) => (resetForm = resetFormRef)}
      />
      {contentToShow()}
    </>
  );
}
