import React from "react";
import { Transition } from "@tailwindui/react";
import { Loader, Warning } from "../service/Icon";
import PropTypes from "prop-types";

const ConfirmDialog = ({
  title,
  description,
  cancelTitle,
  destrutctiveTitle,
  construtctiveTitle,
  constructiveLoading,
  onPressCancel,
  onPressDestructive,
  onPressConstructive,
  isOpen,
  success,
  size,
  icon,
}) => {
  return (
    <Transition show={isOpen}>
      <div className="fixed z-20 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {(ref) => (
              <div ref={ref} className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
            )}
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-90"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-90"
          >
            {(ref) => (
              <div
                ref={ref}
                className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-${size}`}
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {icon && (
                      <div
                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${
                          success
                            ? "bg-green-100 text-green-600"
                            : "bg-red-100 text-red-600 "
                        } sm:mx-0 sm:h-10 sm:w-10`}
                      >
                        {icon}
                      </div>
                    )}
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-headline"
                      >
                        {title}
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm leading-5 text-gray-500">
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  {destrutctiveTitle && (
                    <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={onPressDestructive}
                      >
                        {destrutctiveTitle}
                      </button>
                    </span>
                  )}
                  {construtctiveTitle && (
                    <span className="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto">
                      <button
                        type={onPressConstructive ? "button" : "submit"}
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5 flex flex-row"
                        onClick={onPressConstructive}
                      >
                        {constructiveLoading && <Loader className="mr-2" />}
                        {construtctiveTitle}
                      </button>
                    </span>
                  )}
                  {cancelTitle && (
                    <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={onPressCancel}
                      >
                        {cancelTitle}
                      </button>
                    </span>
                  )}
                </div>
              </div>
            )}
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

ConfirmDialog.propTypes = {
  /**
   *
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * Title od dialog
   */
  title: PropTypes.string,
  /**
   * size of content
   */
  size: PropTypes.string,
  /**
   * Description in dialog box
   */
  description: PropTypes.node,
  /**
   * Title of CTA to cancel the modal box
   */
  cancelTitle: PropTypes.string,
  /**
   * Title of destructive CTA
   */
  destrutctiveTitle: PropTypes.string,
  /**
   * Callback on pressing cancel button
   */
  onPressCancel: PropTypes.func.isRequired,
  /**
   * Callback on pressing destructive button
   */
  onPressDestructive: PropTypes.func.isRequired,
  /**
   * Title of constructive CTA
   */
  construtctiveTitle: PropTypes.string,
  /**
   * Callback on pressing constructive button
   */
  onPressConstructive: PropTypes.func.isRequired,
  /**
   * loading constructive status
   */
  constructiveLoading: PropTypes.bool,
  /**
   * Whether icon is success type
   */
  success: PropTypes.bool,
  /**
   * React element for icon
   */
  icon: PropTypes.element,
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  title: "Are you sure?",
  description: "",
  onPressCancel: () => {},
  onPressDestructive: () => {},
  success: false,
  size: "lg",
  icon: <Warning />,
  constructiveLoading: false,
};

export default ConfirmDialog;
