import React from "react";
import { FormDataField } from "../../../stories/FormDataField";
import { Form, Formik, Field } from "formik";
import { Loader, Search, Warning } from "../../../service/Icon";
import LoadingStatus from "../../../components/Loader";
import { FormInputField } from "../../../stories/FormInputField";
import { useClinicLocationList } from "../../../service/Fetchers";
import { CurrentUser } from "../../../service/Auth";
import { FormikObserver } from "../../../service/Helper";

export const Filter = ({
  initialValues,
  onSubmit,
  validationSchema,
  loading,
  resetFormRef,
  onChangeValues,
}) => {
  const {
    locations,
    error,
    loading: locationLoading,
    mutate,
  } = useClinicLocationList({});

  const isAdmin = () => {
    let { name } = CurrentUser.getDetails?.user.role;
    if (name === "physician" || name === "clinic_admin") {
      return true;
    }
  };

  // search as and when we type
  const onFormChange = async (values) => {
    try {
      await validationSchema.validate(values);
      onChangeValues(values);
    } catch (error) {}
  };

  const locationFilterList = () => {
    if (locationLoading) {
      return (
        <div className="flex flex-col bg-white max-w-5xl p-10 space-y-4 mx-auto w-full shadow-sm">
          <div className="flex flex-row justify-between items-start">
            <LoadingStatus />
          </div>
        </div>
      );
    } else if (error) {
      return (
        <button
          className="mb-3 p-1 text-red-400 bg-red-50 rounded-full flex flex-row px-4 space-x-2 items-center"
          onClick={mutate}
        >
          <Warning />
          <div className="flex flex-col">
            <span className="text-xs font-normal">
              Unable to load locations
            </span>
            <p className="text-xxs font-light text-gray-600">Click to retry</p>
          </div>
        </button>
      );
    }
    return (
      <FormInputField
        type="checkbox"
        name="location_ids"
        title="Location"
        options={locations.map((location) => {
          return {
            name: location.location_name,
            value: location.id,
          };
        })}
        inputProps={{ col: true }}
      />
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ resetForm, values, setFieldValue }) => {
        resetFormRef(() => {
          resetForm();
        });
        return (
          <Form>
            <div>
              <div className="w-72 h-full bg-white px-5 flex flex-col items-center space-y-2">
                <FormikObserver value={values} onChange={onFormChange} />
                <FormDataField
                  className="flex-1 w-full"
                  title=" "
                  value={
                    <div className="relative bg-white rounded border border-gray-300 focus:border-gray-400 flex flex-row overflow-hidden">
                      <Field
                        type="search"
                        name="search_key"
                        placeholder="Patient's name/Case ID"
                        autoComplete="off"
                        className="p-2 text-gray-700 flex-1 focus:outline-none bg-gray-50"
                      />
                      <button
                        type="submit"
                        className="bg-primary text-white rounded font-lg mt-1 mr-1 mb-1 px-5 font-semibold hover:bg-teal-300 focus:outline-none focus:shadow-outline"
                      >
                        {loading ? <Loader /> : <Search />}
                      </button>
                    </div>
                  }
                />
                <div className="flex flex-col">
                  <FormInputField
                    type="radio"
                    title="Status"
                    name="report_status"
                    options={[
                      { name: "All Cases ", value: "all" },
                      {
                        name: "Under Examination ",
                        value: "under_examination",
                      },
                      {
                        name: "Report Generated but not viewed",
                        value: "generated",
                      },
                      { name: "Report Viewed", value: "viewed" },
                      {
                        name: "Archived Cases",
                        value: "downloaded",
                      },
                    ]}
                    inputProps={{ col: true }}
                  />
                  <FormInputField
                    smVariant
                    type="date"
                    title="Date Range"
                    name="filter_start_date"
                    hideError
                  />
                  <FormInputField
                    smVariant
                    type="date"
                    title=" "
                    name="filter_end_date"
                    hideError
                  />
                  <div className="h-3" />
                  <FormInputField
                    type="radio"
                    smVariant
                    name="sort_by"
                    options={[
                      { name: "Collection Date", value: "collected_date" },
                      { name: "Received Date", value: "received_date" },
                      { name: "Latest Activity", value: "updated_at" },
                      { name: "Status", value: "status" },
                    ]}
                    inputProps={{ col: true }}
                  />
                  <FormInputField
                    type="radio"
                    name="ordering"
                    title="Order by"
                    smVariant
                    options={[
                      { name: "Ascending", value: "asc" },
                      {
                        name: "Descending",
                        value: "desc",
                      },
                    ]}
                    inputProps={{ col: true }}
                  />
                  {isAdmin() && locationFilterList()}
                  <div className="flex">
                    <span
                      onClick={() => resetForm()}
                      className="text-red-400 underline text-sm cursor-pointer pb-3"
                    >
                      Clear Filters
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
