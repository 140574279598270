import React, { useState } from "react";
import { Link, navigate } from "@reach/router";
import Table from "../../../stories/Table";
import {
  getErrorData,
  getURLParams,
  handleQuery,
} from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";
import { useGetClientList } from "../../../service/Api_services/GetClients/useGetClients";

const perPageCount = 10;

const ClientList = () => {
  const currentPage = getURLParams("page") ?? 1;
  const searchQ = getURLParams("search") ?? "";

  const [searchQuery, setSearchQuery] = useState(searchQ || "");
  const [page, setPage] = useState(currentPage);

  const { clients, error, isValidating, loading, mutate, pageInfo } =
    useGetClientList({
      currentPage: page,
      perPageCount,
      searchQuery: searchQ,
      list: "all",
      pagination: true,
    });

  const loadingData = loading || isValidating;

  const headerData = {
    constructiveCTA: {
      link: "/dashboard/clients/add",
      title: "Add Client",
    },
    states: [
      {
        title: "Client List",
      },
    ],
    title: "List of Clients",
    search: {
      show: true,
      searching: searchQuery?.length && loadingData,
      placeholder: "Search using Client Name...",
      onSearch: (values) => {
        setPage(1);
        handleQuery(navigate, 1, values.query, "clients");
        setSearchQuery(values.query);
      },
      onChange: (values) => {
        if (!values.query) {
          setSearchQuery("");
        }
      },
    },
  };

  const content = (
    <Table
      headers={[, "Client Name", "Client Type"]}
      pagination={{
        currentpage: pageInfo?.current_page,
        itemsPerPage: pageInfo?.per_page,
        totalItems: pageInfo?.total_count,
        onPageChange: (page) => {
          handleQuery(navigate, page, searchQ, "clients");
        },
      }}
      loading={loadingData}
      rowContent={clients?.map((client) => {
        const clientTypes = [
          client?.interface ? "Interface" : null,
          client?.hand_key ? "Hand-Key" : null,
        ];
        return [
          <p className="underline text-primary">
            <Link to={`/dashboard/clients/view/${client.id}`}>
              {client.name}
            </Link>
          </p>,
          <p>{clientTypes.filter((clnt) => clnt != null).join(", ")}</p>,
        ];
      })}
    />
  );

  const errorData = getErrorData({
    error,
    searchQuery,
    data: clients,
    mutate,
    title: "Client",
  });

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      errorData={errorData}
      loading={loadingData}
      loadingContent={null}
    />
  );
};

export default ClientList;
