import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";

/**
 * Stepper component for activity listing
 */
export const Stepper = ({ steps, heading, ...props }) => {
  return (
    <div {...props}>
      {heading && <div className="text-gray-300">{heading}</div>}
      <div className="mt-6">
        {steps.map((step, index) => (
          <div key={index} className="pr-4 flex flex-row justify-start">
            <div className="relative flex flex-col items-center">
              <div className="flex">
                <div className="w-3 h-3 bg-primary rounded-full"></div>
              </div>
              <div
                className={`relative border-l-2 border-dashed ${
                  index === steps.length - 1
                    ? "border-transparent"
                    : "border-primary"
                }  h-full`}
              ></div>
            </div>
            <div className="ml-5 w-full mb-8 flex flex-col">
              <label className="text-sm font-medium text-gray-800 leading-4">
                {step.title}
              </label>
              <div className="text-sm opacity-50 font-light leading-7">
                <p>{step.dateTime}</p>
                <p>{step.doneBy}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  /**
   * Heading of the component
   */
  heading: PropTypes.string,
  /**
   * Steps
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Title of the step
       */
      title: PropTypes.string,
      /**
       * Date and time of the step
       */
      dateTime: PropTypes.string,
      /**
       * Done by (person)
       */
      doneBy: PropTypes.string,
    })
  ).isRequired,
};

Stepper.defaultProps = {
  heading: "Activity",
};
