import React from "react";
import ListLocation from "./ListLocation";
import ViewLocation from "./ViewLocation";
import AddLocation from "./AddLocation";

export default function CaseType({ type, id }) {
  if (type === "view") {
    return <ViewLocation id={id} />;
  } else if (type === "edit") {
    return <AddLocation id={id} />;
  } else if (type === "add") {
    return <AddLocation />;
  }
  return <ListLocation />;
}
