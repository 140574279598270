import React, { useState, useRef } from "react";
import Header from "../../stories/Header";
import { Button } from "../../stories/Button";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { resetPasswordSchema } from "../../service/Validation";

export default function ResetPassword(props) {
  const [loading, setLoading] = useState(false);
  const resetButton = useRef(null);

  const resetPassword = async ({ password }) => {
    const token = props["*"].split("/")[0];
    setLoading(true);
    const { error, data } = await API.post("/auth/reset-password", {
      reset_password_token: token,
      password,
    });

    if (error) {
      resetButton.current.shake();
      if (error.message) toast.error(error.message);
    }
    if (data) {
      toast.success("Password reset successfully");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1500);
    }
    setLoading(false);
  };
  return (
    <div className="absolute bg-theme-gray flex flex-col w-full h-full">
      <Header className="flex-shrink-0" />

      <Formik
        initialValues={{
          password: "",
          confirm_password: "",
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={(data) => {
          resetPassword(data);
        }}
      >
        {(props) => {
          return (
            <Form>
              <div className="flex flex-col items-center mt-24">
                <p className="text-gray-800 antialiased font-hairline text-2xl mb-10">
                  Reset Password
                </p>
                <div className="flex flex-col w-64">
                  <Field
                    className="form-input rounded-b-none m-0 flex-1 font-light text-sm"
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <Field
                    className="form-input rounded-t-none m-0 border-t-0 flex-1 font-light text-sm"
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="text-red-500 text-xs font-hairline mt-1"
                  />
                  <ErrorMessage
                    name="confirm_password"
                    component="span"
                    className="text-red-500 text-xs font-hairline mt-1"
                  />
                </div>
                <Button
                  className="rounded-full mt-8 w-64 text-center antialiased font-light justify-center"
                  ref={resetButton}
                  formButton
                  loading={loading}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
