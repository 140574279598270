/* eslint-disable eqeqeq */
import { navigate, Redirect, useLocation } from "@reach/router";
import { Formik } from "formik";
import React, { lazy, useContext, useRef, useState } from "react";
import { CurrentUser } from "../../service/Auth";
import { BreadcrumbLink } from "../../stories/BreadcrumbLink";
import { Button } from "../../stories/Button";
import Header from "../../stories/Header";
import SelectedCaseView from "./SelectedCaseView";
import AccessionistCaseList from "./AccesionistCaseList";
import { usePackageCasesList } from "../../service/Fetchers";
import { useEffect } from "react";
import _ from "lodash";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import { HotKeys } from "react-hotkeys";

const AddPackageModal = lazy(() => import("../CaseView/AddPackageModal"));

let initialCaseObject = {
  status: "pending",
  ucid: "XXX-XXXXXX",
  id: _.uniqueId("new-"),
};

function PackageDetails() {
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedCase, setSelectedCase] = useState(initialCaseObject);
  const [casesList, setCasesList] = useState([initialCaseObject]);
  const { showAddPackageModal, setShowAddPackageModal } = useContext(
    GlobalVariablesContext
  );
  const [isCompleted, setIsCompleted] = useState();
  const submitButton = useRef();
  const formikRef = useRef();
  const { pathname } = useLocation();

  const packageId = pathname.split("/").pop();

  const { visits, page_info, loading, mutate } = usePackageCasesList({
    packageId,
  });

  useEffect(() => {
    if (page_info?.total_count > 0 && visits?.length > 0) {
      setIsCompleted(visits[0]?.courier_info?.visit_completed);
      if (submitCount === 0 && !visits[0]?.courier_info?.visit_completed) {
        setCasesList(visits);
        setSelectedCase(visits[0]);
      } else {
        const newVisits = [
          // { ...initialCaseObject, id: _.uniqueId("new-") },
          ...visits,
        ];
        setCasesList(newVisits);
        setSelectedCase(newVisits[0]);
      }
    } else if (page_info?.total_count === 0) {
      setIsCompleted(false);
    }
  }, [visits, page_info, submitCount]);

  if (!CurrentUser.isAccessionist()) {
    return <Redirect to="/" />;
  }
  const showButtons = typeof isCompleted === "boolean" && !isCompleted;

  async function handleCompletePackage() {
    const { error, data } = await API.put("/couriers/complete", {
      id: packageId,
    });

    if (error) {
      submitButton.current.shake();
      if (error.message) toast.error(error.message);
    }
    if (data) {
      toast.success(`Package is marked as complete!`);
      navigate("/case-view");
    }
  }
  const keyMap = {
    SAVE_CASE: "shift+s",
    COMPLETE_PACKAGE: "shift+c",
  };
  const handlers = {
    SAVE_CASE: () => {
      if (formikRef?.current?.handleSubmit) {
        formikRef.current.handleSubmit();
      }
    },
    COMPLETE_PACKAGE: handleCompletePackage,
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Formik>
        {() => (
          <div className="bg-theme-gray min-h-screen">
            <Header
              rightLinks={
                CurrentUser.isAccessionist() && [
                  {
                    type: "link",
                    text: (
                      <button
                        tabIndex={-1}
                        className="text-base font-semibold bg-white text-black p-3"
                      >
                        + Add Package
                      </button>
                    ),
                    onClick: () => {
                      setShowAddPackageModal(true);
                    },
                  },
                ]
              }
            />
            <div className="flex flex-row page-container px-10 py-3 justify-between items-center">
              <BreadcrumbLink
                states={[
                  { title: "All Packages", link: "/case-view" },
                  { title: `Tracking No.#${packageId}` },
                ]}
              />
              {showButtons && (
                <Button
                  className={`w-50 h-10 text-center antialiased font-medium justify-center`}
                  formButton
                  style={{ fontSize: 14 }}
                  loading={loading}
                  disabled={loading}
                  onClick={handleCompletePackage}
                  ref={submitButton}
                  tabIndex={7 + casesList?.length + 29}
                  id="complete-btn"
                  onKeyDown={(e) => {
                    if (e.keyCode == 9 && e.shiftKey == false) {
                      e.preventDefault();
                      const element = document.getElementById("add-case-btn");
                      if (element) {
                        element.focus();
                      } else {
                        const anotherElem =
                          document.getElementById("current-case-0");
                        if (anotherElem) anotherElem.focus();
                      }
                    }
                  }}
                >
                  Complete Package
                </Button>
              )}
            </div>
            <div className="flex flex-row space-x-4 page-container px-10">
              <AccessionistCaseList
                caseType="pending"
                loading={loading}
                cases={casesList}
                pageInfo={page_info}
                packageId={packageId}
                selectedCase={selectedCase}
                onSelectCase={setSelectedCase}
                showButtons={showButtons}
                onAddCase={() => {
                  const newCase = {
                    ...initialCaseObject,
                    id: _.uniqueId("new-"),
                  };
                  let newCasesList = [newCase, ...casesList];
                  setCasesList(newCasesList);
                  setSelectedCase(newCase);
                }}
              />
              <div className="flex flex-col flex-1 bg-transparent bg-white px-2 py-2 mb-10 h-full">
                <SelectedCaseView
                  key={`form-${selectedCase?.id}`}
                  selectedCase={selectedCase}
                  refetch={() => {
                    setSubmitCount((s) => s + 1);
                    mutate();
                  }}
                  loading={false}
                  totalCases={casesList?.length}
                  tracking_num={packageId}
                  formikRef={formikRef}
                />
              </div>
            </div>
            <AddPackageModal
              open={showAddPackageModal}
              setOpen={setShowAddPackageModal}
            />
          </div>
        )}
      </Formik>
    </HotKeys>
  );
}

export default PackageDetails;
