import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";
import { Warning, Refresh } from "../service/Icon";

/**
 * Primary UI component for user interaction
 */
export const ErrorView = ({
  title,
  subtitle,
  ctaTitle,
  ctaOnClick,
  icon,
  ...props
}) => {
  return (
    <div
      className="flex flex-col items-center justify-center space-y-2"
      {...props}
    >
      <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-gray-100 text-gray-500 h-32 w-32 mb-10">
        {icon}
      </div>
      <span className="text-gray-800 text-lg">{title}</span>
      <span className="text-gray-600 font-light text-sm w-64 text-center pb-3">
        {subtitle ?? ""}
      </span>
      {ctaOnClick && (
        <button
          type="button"
          onClick={ctaOnClick}
          className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out mt-4"
        >
          <Refresh className="h-5 w-5 mr-1" />
          {ctaTitle}
        </button>
      )}
    </div>
  );
};

ErrorView.propTypes = {
  /**
   * title or view
   */
  title: PropTypes.node,
  /**
   * icon for error
   */
  icon: PropTypes.node,
  /**
   * info about error
   */
  subtitle: PropTypes.node,
  /**
   * TItle of button
   */
  ctaTitle: PropTypes.string,
  /**
   * callback function on click
   */
  ctaOnClick: PropTypes.func,
};

ErrorView.defaultProps = {
  title: "Ooops!",
  subtitle: null,
  ctaTitle: "Reload",
  icon: <Warning className="h-16 w-16" />,
};
