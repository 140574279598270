import React from "react";

export const NoData = () => (
  <svg
    width="648px"
    height="633px"
    viewBox="0 0 648 633"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#4ED4DC" offset="0%"></stop>
        <stop stopColor="#53B0DE" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
        <stop stopColor="#4ED4DC" offset="0%"></stop>
        <stop stopColor="#53B0DE" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
        <stop stopColor="#4ED4DC" offset="0%"></stop>
        <stop stopColor="#53B0DE" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fillRule="nonzero">
        <g id="undraw_No_data_re_kwbl">
          <path
            d="M411.14603,142.17382 L236.63626,142.17382 C228.356164,142.183892 221.646332,148.893724 221.63626,157.17382 L221.63626,545.02382 L219.63626,545.63387 L176.8262,558.74387 C172.601697,560.031962 168.131976,557.656102 166.83646,553.43387 L39.49613,137.48382 C38.2057201,133.25901 40.5824223,128.787638 44.80619,127.49382 L110.77641,107.29382 L302.02641,48.75382 L367.99613,28.55382 C370.023741,27.929787 372.216294,28.137818 374.090363,29.1320451 C375.964433,30.1262722 377.366119,31.825049 377.98637,33.85382 L410.53617,140.17382 L411.14603,142.17382 Z"
            id="Path"
            fill="#F2F2F2"
          ></path>
          <path
            d="M449.22613,140.17382 L409.99613,12.03382 C408.677967,7.72185497 405.700184,4.11051637 401.718384,1.99489208 C397.736584,-0.120732215 393.077225,-0.567187408 388.76613,0.75382 L296.01613,29.14382 L104.7764,87.69384 L12.0264,116.09384 C3.05494442,118.848663 -1.99201966,128.347279 0.74612,137.32384 L134.8262,575.25384 C137.019134,582.397554 143.61374,587.276511 151.08646,587.283842 C152.771281,587.283842 154.446622,587.031782 156.05618,586.53384 L219.63626,567.07384 L221.63626,566.45384 L221.63626,564.36384 L219.63626,564.97384 L155.46634,584.62384 C147.547603,587.037702 139.169135,582.586781 136.73634,574.67384 L2.66651,136.73384 C1.4973134,132.930674 1.88924814,128.818599 3.75584916,125.304785 C5.62245019,121.79097 8.8103344,119.164154 12.61622,118.00384 L105.36622,89.60384 L296.60646,31.06384 L389.35646,2.66384 C390.785769,2.22763903 392.271733,2.00523267 393.76612,2.00384 C400.349392,2.01862167 406.155216,6.32018767 408.08644,12.61384 L447.13624,140.17384 L447.75636,142.17384 L449.83644,142.17384 L449.22613,140.17382 Z"
            id="Path"
            fill="#3F3D56"
          ></path>
          <path
            d="M122.68092,127.8208 C118.724433,127.817982 115.232087,125.235951 114.06959,121.4541 L101.18922,79.38232 C100.490197,77.0999424 100.726629,74.6333457 101.846488,72.5253143 C102.966347,70.417283 104.877871,68.840553 107.16042,68.14209 L283.09942,14.27832 C287.851732,12.8281441 292.880913,15.4994791 294.34014,20.24902 L307.22051,62.32129 C308.669726,67.0735097 305.998737,72.1020391 301.24981,73.56201 L125.31032,127.42578 C124.458324,127.687258 123.572137,127.8208 122.68092,127.8208 L122.68092,127.8208 Z"
            id="Path"
            fill="url(#linearGradient-1)"
          ></path>
          <circle
            id="Oval"
            fill="url(#linearGradient-2)"
            cx="190.15351"
            cy="24.95465"
            r="20"
          ></circle>
          <circle
            id="Oval"
            fill="#FFFFFF"
            cx="190.15351"
            cy="24.95465"
            r="12.66462"
          ></circle>
          <path
            d="M602.63649,582.17382 L264.63649,582.17382 C259.944311,582.168414 256.141896,578.365999 256.13649,573.67382 L256.13649,168.67382 C256.141731,163.981572 259.944242,160.179061 264.63649,160.17382 L602.63649,160.17382 C607.328667,160.179231 611.131079,163.981643 611.13649,168.67382 L611.13649,573.67382 C611.130908,578.365926 607.328596,582.168238 602.63649,582.17382 Z"
            id="Path"
            fill="#E6E6E6"
          ></path>
          <path
            d="M447.13626,140.17382 L236.63626,140.17382 C227.252925,140.1871 219.64954,147.790485 219.63626,157.17382 L219.63626,564.97382 L221.63626,564.36382 L221.63626,157.17382 C221.646332,148.893724 228.356164,142.183892 236.63626,142.17382 L447.75638,142.17382 L447.13626,140.17382 Z M630.63626,140.17382 L236.63626,140.17382 C227.252925,140.1871 219.64954,147.790485 219.63626,157.17382 L219.63626,615.17382 C219.649534,624.557157 227.252923,632.160546 236.63626,632.17382 L630.63626,632.17382 C640.019597,632.160546 647.622986,624.557157 647.63626,615.17382 L647.63626,157.17382 C647.62298,147.790485 640.019595,140.1871 630.63626,140.17382 L630.63626,140.17382 Z M645.63626,615.17382 C645.626188,623.453916 638.916356,630.163748 630.63626,630.17382 L236.63626,630.17382 C228.356164,630.163748 221.646332,623.453916 221.63626,615.17382 L221.63626,157.17382 C221.646332,148.893724 228.356164,142.183892 236.63626,142.17382 L630.63626,142.17382 C638.916356,142.183892 645.626188,148.893724 645.63626,157.17382 L645.63626,615.17382 Z"
            id="Shape"
            fill="#3F3D56"
          ></path>
          <path
            d="M525.63649,184.17382 L341.63649,184.17382 C336.668246,184.168226 332.642084,180.142064 332.63649,175.17382 L332.63649,131.17382 C332.642089,126.205578 336.668248,122.179419 341.63649,122.17382 L525.63649,122.17382 C530.604732,122.179419 534.630891,126.205578 534.63649,131.17382 L534.63649,175.17382 C534.630896,180.142064 530.604734,184.168226 525.63649,184.17382 Z"
            id="Path"
            fill="url(#linearGradient-3)"
          ></path>
          <circle
            id="Oval"
            fill="url(#linearGradient-2)"
            cx="433.63626"
            cy="105.17383"
            r="20"
          ></circle>
          <circle
            id="Oval"
            fill="#FFFFFF"
            cx="433.63626"
            cy="105.17383"
            r="12.18187"
          ></circle>
        </g>
      </g>
    </g>
  </svg>
);

export const EmptySearch = () => (
  <svg
    width="130px"
    height="127px"
    viewBox="0 0 130 127"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlinkHref="http://www.w3.org/1999/xlink"
  >
    <title>search-empty-icon</title>
    <defs>
      <linearGradient
        x1="0%"
        y1="20.1506654%"
        x2="87.9394932%"
        y2="82.3847073%"
        id="linearGradient-1"
      >
        <stop stopColor="#FFCB86" offset="0%"></stop>
        <stop stopColor="#FFCC4E" offset="100%"></stop>
      </linearGradient>
      <path
        d="M120,60 C120,76.0408594 113.70375,90.6147656 103.447266,101.377969 C100.094531,104.902031 96.3133594,108.017109 92.191875,110.64 C91.9689844,110.779687 91.7460938,110.919141 91.5232031,111.058828 C89.5223438,112.297969 87.4432031,113.422031 85.2930469,114.42375 C77.6057812,118.001719 69.0367969,120 60,120 C52.6921875,120 45.688125,118.692188 39.2107031,116.299688 C36.9796875,115.476797 34.8124219,114.524062 32.7159375,113.451562 C16.6017187,105.208125 4.704375,89.8921875 1.12171875,71.5982812 C0.671015625,69.3035156 0.350390625,66.9623438 0.1715625,64.5820312 C0.058828125,63.0686719 0,61.5403125 0,60 C0,57.6710156 0.1321875,55.3713281 0.391875,53.1110156 C0.548671875,51.7371094 0.751875,50.3779688 1.00171875,49.0333594 C6.151875,21.1347656 30.6072656,0 60,0 C70.8660938,0 81.05625,2.88726563 89.8457813,7.93710938 C91.0408594,8.62289063 92.2066406,9.35015625 93.3478125,10.1142188 C93.5927344,10.2782813 93.8376562,10.4449219 94.08,10.6139062 C94.0971094,10.62375 94.1167969,10.6359375 94.1339063,10.6507031 C96.0147656,11.9535937 97.8171094,13.3617187 99.5339063,14.8654687 C103.192734,18.07125 106.457109,21.7176563 109.248984,25.719375 C116.025234,35.4367969 120,47.255625 120,60 Z"
        id="path-2"
      ></path>
      <linearGradient
        x1="100%"
        y1="100%"
        x2="9.39621501%"
        y2="24.8709726%"
        id="linearGradient-4"
      >
        <stop stopColor="#E8E8F0" offset="0%"></stop>
        <stop stopColor="#F7F7FC" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="9.39621501%"
        y1="24.8709726%"
        x2="100%"
        y2="100%"
        id="linearGradient-5"
      >
        <stop stopColor="#F0E6DD" offset="0%"></stop>
        <stop stopColor="#F7EDDF" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Day-1036-Search-Empty-State-UI-Design"
        transform="translate(-730.000000, -478.000000)"
      >
        <g id="Group" transform="translate(643.000000, 478.000000)">
          <g id="search-empty-icon" transform="translate(87.000000, 0.000000)">
            <g id="Group-2" transform="translate(10.000000, 7.000000)">
              <mask id="mask-3" fill="white">
                <use xlinkHref="#path-2"></use>
              </mask>
              <use
                id="Mask"
                fill="url(#linearGradient-1)"
                xlinkHref="#path-2"
              ></use>
              <path
                d="M31,-5 C55.300529,-5 75,14.699471 75,39 C75,48.8012134 71.7953338,57.8539368 66.3766593,65.1675126 L73.068769,72.5995056 L65.6373207,79.2908116 L59.5251991,72.5020713 C51.8439616,79.0485631 41.8834346,83 31,83 C6.69947101,83 -13,63.300529 -13,39 C-13,14.699471 6.69947101,-5 31,-5 Z"
                id="Combined-Shape"
                fill="#DDA319"
                opacity="0.157435826"
                mask="url(#mask-3)"
              ></path>
              <g mask="url(#mask-3)" fillRule="nonzero" id="Path">
                <g transform="translate(61.999944, 64.999710)">
                  <path
                    d="M48.0000605,38.4549039 L48.0000605,38.4549039 C48.0000605,43.636701 43.6364666,48.0002949 38.4546695,48.0002949 L38.4546695,48.0002949 C35.7274819,48.0002949 33.2728724,46.9092792 31.3636536,45.0002948 L0.818261751,11.7274809 C-0.272753917,10.6364653 -0.272753917,9.00029333 0.818261751,7.90927766 L7.90927766,0.818261751 C9.00029333,-0.272753917 10.6364653,-0.272753917 11.7274809,0.818261751 L45.0000604,31.6367005 C46.9090448,33.2731068 48.0000605,36.0002944 48.0000605,38.4549039 Z"
                    fill="#B06328"
                  ></path>
                  <path
                    d="M44.7272479,31.363888 L43.0908416,30.0002942 C44.4544354,31.6367005 45.2726385,33.8184974 45.2726385,36.0002944 L45.2726385,36.0002944 C45.2726385,39.5456852 40.9090446,40.909279 38.4544351,38.4549039 L9.81826211,9.81849648 C8.18185579,8.18209017 5.72724632,8.18209017 4.09107438,9.81849648 L1.36388677,12.5456841 L31.0910755,44.4546697 C32.7274818,46.0910761 34.9092787,47.4546699 37.0910757,47.7274824 C40.0910758,48.0002949 43.0910759,46.9092792 45.0000604,45.0002948 C46.9090448,43.0913103 48.0000605,40.0913102 47.727248,37.0913101 C47.727248,34.9092787 46.3636542,33.0002943 44.7272479,31.363888 Z"
                    fill="#A35425"
                  ></path>
                  <path
                    d="M16.6364655,12.5456841 C16.363653,12.5456841 15.8182623,12.5456841 15.5454498,12.2728716 L8.72724644,5.45466819 C8.18185579,4.90927754 8.18185579,4.09107438 8.72724644,3.54568373 C9.27263709,3.00029309 10.0908402,3.00029309 10.6362309,3.54568373 L17.4544343,10.3638871 C17.9998249,10.9092778 17.9998249,11.7274809 17.4544343,12.2728716 C17.4546687,12.5456841 16.9090436,12.5456841 16.6364655,12.5456841 Z"
                    fill="#C97629"
                  ></path>
                </g>
              </g>
            </g>
            <g id="search-(11)" fillRule="nonzero">
              <rect
                id="Rectangle"
                fill="#F0F0F6"
                transform="translate(72.271724, 72.272672) rotate(135.000000) translate(-72.271724, -72.272672) "
                x="68.4536741"
                y="66.5455395"
                width="7.63609895"
                height="11.4542656"
              ></rect>
              <path
                d="M75.000003,75.000003 L76.3635968,76.3635968 L79.0907844,73.6364092 L70.9089872,65.454612 L65.4543776,70.9092216 L69.5453934,75.000003 L69.5453934,75.000003 C71.1817997,73.3635967 73.3635967,73.3635967 75.000003,75.000003 Z"
                id="Path"
                fill="#D8D8EB"
              ></path>
              <path
                d="M40.908986,0 C18.2728132,0 0,18.2728132 0,40.908986 C0,63.5451588 18.2728132,81.817972 40.908986,81.817972 C63.5451588,81.817972 81.817972,63.5451588 81.817972,40.908986 C81.817972,18.2728132 63.5453932,0 40.908986,0 Z M40.908986,73.6364092 C22.9089853,73.6364092 8.1817972,58.9092211 8.1817972,40.9092204 C8.1817972,22.9092197 22.9089853,8.1817972 40.908986,8.1817972 C58.9089867,8.1817972 73.6361748,22.9089853 73.6361748,40.908986 C73.6361748,58.9089867 58.9089867,73.6364092 40.908986,73.6364092 Z"
                id="Shape"
                fill="url(#linearGradient-4)"
              ></path>
              <circle
                id="Oval"
                fill="url(#linearGradient-5)"
                cx="40.908986"
                cy="40.908986"
                r="32.7271888"
              ></circle>
              <path
                d="M24.8182041,44.7271893 C26.1817979,34.3635951 34.3635951,26.1817979 44.7271893,24.8182041 L45.0000018,24.8182041 C49.6364082,24.2728135 49.9089864,17.7271882 45.5453924,16.9092194 C42.8182048,16.3638288 40.0907828,16.3638288 37.3635952,16.6364069 C26.7271886,18.2728132 18.2725789,26.7274229 16.908985,37.0910171 C16.3635944,39.8182047 16.6361725,42.8182048 17.1817976,45.2728143 C18.0000007,49.9092207 24.5453916,49.3638301 25.0907822,44.7274237 C24.8182041,45.0000018 24.8182041,44.7271893 24.8182041,44.7271893 Z"
                id="Path"
                fillOpacity="0.495274257"
                fill="#FFFFFF"
              ></path>
              <path
                d="M10.9089848,43.636408 C10.9089848,25.6364073 25.6361729,10.9092192 43.6361736,10.9092192 C52.0907833,10.9092192 59.4543774,14.1820316 65.4543776,19.0910164 C59.4543774,12.272813 50.7271895,8.18203158 40.908986,8.18203158 C22.9089853,8.18203158 8.1817972,22.9092197 8.1817972,40.9092204 C8.1817972,50.7274239 12.5453911,59.4546117 19.090782,65.454612 C14.1817972,59.4546117 10.9089848,52.0910177 10.9089848,43.636408 Z"
                id="Path"
                fill="#DBDBEB"
              ></path>
              <path
                d="M19.5835577,50.1079661 C19.5835577,32.1079654 34.5330615,17.8654453 52.5330623,17.8654453 C60.987672,17.8654453 69.2725802,22 75.2725804,26.9089848 C69.2725802,20.0907814 60.5453923,16 50.7271888,16 C32.7271881,16 18,30.7271881 18,48.7271888 C18,58.5453923 22.3635939,67.2725802 28.9089848,73.2725804 C24,67.2725802 19.5835577,58.5625758 19.5835577,50.1079661 Z"
                id="Path"
                fill="#FFFFFF"
                opacity="0.813290551"
                transform="translate(46.636290, 44.636290) rotate(171.000000) translate(-46.636290, -44.636290) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
