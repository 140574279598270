import { useEffect, useRef, useContext, useState } from "react";
import OpenSeadragon from "openseadragon";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BiFullscreen } from "react-icons/bi";
import initFabricOverlay from "./openseadragon-fabricjs-overlay";

import "./modal_styles.css";
import classNames from "classnames";

export function numberToAlphabet(number) {
  const letter = String.fromCharCode(number + "A".charCodeAt(0));
  return letter;
}

export default function SlideViewer({
  customClasses,
  tileSources = [],
  visitDetails,
}) {
  const [rotateGesure, setRotateGesture] = useState(false);
  const rotateBtnRef = useRef(null);
  const [origin, setOrigin] = useState();
  const [mouseOldPos, setMouseOldPos] = useState();
  const [rotation, setRotation] = useState(0);
  const imgRef = useRef(null);
  const containerRef = useRef(null);

  const handleRotate = ({ mouseViewportX, mouseViewportY }) => {
    const angle1 = getAngle({
      x: mouseViewportX - origin.x,
      y: origin.y - mouseViewportY,
    });
    const angle2 = getAngle({
      x: mouseOldPos.x - origin.x,
      y: origin.y - mouseOldPos.y,
    });
    const delta = angle2 - angle1;
    setRotation(rotation + delta);
  };

  const onMouseMove = (event) => {
    const flags = event.buttons !== undefined ? event.buttons : event.which;
    const primaryMouseButtonDown = (flags & 1) === 1;
    const mouseViewportX = event.pageX - window.scrollX;
    const mouseViewportY = event.pageY - window.scrollY;
    setMouseOldPos({ x: mouseViewportX, y: mouseViewportY });

    if (primaryMouseButtonDown) {
      if (rotateGesure) {
        handleRotate({ mouseViewportX, mouseViewportY });
        return;
      }

      const insideRotate = isPointInsideElement(
        {
          x: mouseViewportX,
          y: mouseViewportY,
        },
        rotateBtnRef.current
      );
      if (insideRotate) {
        setRotateGesture(true);
        return;
      }
    } else {
      setRotateGesture(false);
    }
  };

  const viewerRef = useRef(null);

  const { showNavControls, selectedSpecimenTab } = useContext(
    GlobalVariablesContext
  );

  useEffect(() => {
    var openseadragon_images =
      "https://cdn.jsdelivr.net/npm/openseadragon@4.1/build/openseadragon/images/";

    const viewer = OpenSeadragon({
      autoHideControls: false,
      blendTime: 0,
      id: "openseadragon",
      showSequenceControl: true,
      immediateRender: true,
      loadTilesWithAjax: true,
      navigatorPosition: "TOP_RIGHT",
      navigatorId: "openseadragon-nav-custom",
      prefixUrl: openseadragon_images,
      preserveViewport: true,
      referenceStripSizeRatio: 0.15,
      sequenceMode: true,
      showFlipControl: true,
      showNavigator: true,
      showReferenceStrip: true,
      showNavigationControl: true,
      showRotationControl: true,
      gestureSettingsTouch: {
        pinchRotate: false,
        pinchToZoom: false,
        dragToPan: false,
      },
      tileSources: tileSources,
      zoomPerScroll: 1.8,
      minZoomImageRatio: 0.8,
      defaultZoomLevel: 1, // Set the reset level
      minZoomLevel: 1.3,
    });

    // Allow OSD to show webp files
    OpenSeadragon.setImageFormatsSupported({
      bmp: false,
      jpeg: true,
      jpg: true,
      png: true,
      tif: false,
      wdp: false,
      webp: true,
    });

    // Hide the controls on page load
    viewer.navControl.parentElement.style.display = "none";
    viewer.paging.element.style.display = "none";

    // Fix reference strip to bottom
    if (viewer.referenceStrip && visitDetails?.specimen_images?.length > 1) {
      viewer.referenceStrip.element.style.position = "absolute";
      viewer.referenceStrip.element.style.bottom = 0;
      viewer.referenceStrip.element.style.left = 0;
    }

    // const OSDMeasure = window.OSDMeasure;

    // const plugin = new OSDMeasure(viewer, {
    //   conversionFactor: 4.54e-7 * 1e3,
    //   measurementColor: "#5bb7de",
    //   units: "mm",
    //   useBuiltInUI: false,
    // });

    // const scaleOverlayBtn = new OpenSeadragon.Button({
    //   clickTimeThreshold: OpenSeadragon.clickTimeThreshold,
    //   clickDistThreshold: OpenSeadragon.clickDistThreshold,
    //   tooltip: "Toggle Scale Overlay",
    //   // onClick: function () {
    //   //   plugin.numberOne = !plugin.numberOne;
    //   // },
    //   srcRest:
    //     "https://raw.githubusercontent.com/openseadragon/openseadragon/master/images/button_rest.png",
    //   srcGroup:
    //     "https://raw.githubusercontent.com/openseadragon/openseadragon/master/images/button_grouphover.png",
    //   srcHover:
    //     "https://raw.githubusercontent.com/openseadragon/openseadragon/master/images/button_hover.png",
    //   srcDown:
    //     "https://raw.githubusercontent.com/openseadragon/openseadragon/master/images/button_pressed.png",
    // });

    // viewer.buttonGroup.buttons.push(scaleOverlayBtn);
    // viewer.buttonGroup.element.appendChild(scaleOverlayBtn.element);

    viewerRef.current = viewer;

    viewer.canvas.addEventListener("contextmenu", (event) => {
      event.preventDefault();

      if (viewer.viewport.getZoom() === 10) {
        viewer.viewport.goHome();
      } else {
        const newCenter = viewer.viewport.windowToViewportCoordinates(
          new OpenSeadragon.Point(event.pageX, event.pageY)
        );

        viewer.viewport.zoomTo(10, newCenter);
      }
    });

    initFabricOverlay();

    viewer.fabricjsOverlay({ scale: 1000 });

    return () => {
      // plugin.clear();
      viewerRef.current.destroy();
    };
  }, [tileSources]);

  const handleZoomIn = () => {
    if (viewerRef.current) {
      const currentZoom = viewerRef.current.viewport.getZoom();
      const maxZoom = 10;
      const zoomIncrement = 1.2;

      const newZoom = currentZoom * zoomIncrement;

      if (newZoom <= maxZoom) {
        viewerRef.current.viewport.zoomBy(zoomIncrement);
      } else {
        viewerRef.current.viewport.zoomTo(maxZoom);
      }
    }
  };

  const handleZoomOut = () => {
    if (viewerRef.current) {
      const currentZoom = viewerRef.current.viewport.getZoom();
      const minZoom = 1.3;
      const zoomDecrement = 0.8;

      const newZoom = currentZoom * zoomDecrement;

      if (newZoom >= minZoom) {
        viewerRef.current.viewport.zoomBy(zoomDecrement);
      } else {
        viewerRef.current.viewport.zoomTo(minZoom);
      }
    }
  };

  const handleToggleFullScreen = () => {
    if (viewerRef.current) {
      viewerRef.current.setFullScreen(!viewerRef.current.isFullPage());
    }
  };

  useEffect(() => {
    if (viewerRef.current) {
      if (showNavControls) {
        viewerRef.current.navControl.parentElement.style.display = "none";
        viewerRef.current.paging.element.style.display = "none";
      } else {
        viewerRef.current.navControl.parentElement.style.display = "none";
        viewerRef.current.paging.element.style.display = "none";
      }
    }
  }, [showNavControls, viewerRef, tileSources]);

  useEffect(() => {
    const rect = imgRef.current.getBoundingClientRect();
    setOrigin({
      x: rect.left - window.scrollX + rect.width / 2,
      y: rect.top - window.scrollY + rect.height / 2,
    });
    if (viewerRef?.current) {
      viewerRef.current.viewport?.setRotation(rotation);
    }
  }, [rotation]);

  return (
    <div className={classNames(customClasses, "relative")}>
      <div id="openseadragon">
        <div
          className="container absolute right-10 bottom-10 w-40 h-40 border-2 border-black rounded-full opacity-10 hover:opacity-70 transition-opacity duration-150"
          ref={containerRef}
          onMouseMove={onMouseMove}
          style={{
            zIndex: 99,
            background: "#000",
            border: "2px solid black",
            borderRadius: "100%",
          }}
        >
          <button
            className="fullScreen p-1 absolute border shadow-sm bg-white text-black rounded-sm"
            style={{
              top: "-20px",
              right: "0px",
              zIndex: 999,
            }}
            onClick={handleToggleFullScreen}
          >
            <BiFullscreen />
          </button>
          <button
            className="zoomin p-1 absolute border shadow-sm bg-white text-black rounded-sm"
            style={{
              bottom: "-18px",
              zIndex: 999,
            }}
            onClick={handleZoomIn}
          >
            <AiOutlinePlus />
          </button>
          <button
            className="zoomout p-1 absolute border shadow-sm bg-white text-black rounded-sm"
            style={{
              right: "0px",
              bottom: "-18px",
              zIndex: 999,
            }}
            onClick={handleZoomOut}
          >
            <AiOutlineMinus />
          </button>
          <button
            ref={rotateBtnRef}
            className="rotating-button absolute p-1 bg-white text-black rounded-full"
            style={{
              left: "16px",
              top: "8px",
              zIndex: 999,
            }}
          >
            <FaArrowRotateLeft size={10} />
          </button>
          <div
            ref={imgRef}
            id="openseadragon-nav-custom"
            className="flex items-center justify-center"
            style={{
              width: "40%",
              height: "60%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
            }}
          ></div>
        </div>
      </div>
      <div className="absolute top-6 left-6 text-4xl">
        {numberToAlphabet(Number(selectedSpecimenTab))}
      </div>
    </div>
  );
}

function isPointInsideElement(point, element) {
  const rect = element.getBoundingClientRect();
  return (
    point.x > rect.left &&
    point.x < rect.right &&
    point.y > rect.top &&
    point.y < rect.bottom
  );
}

function getAngle(point) {
  if (point.x < 0 && point.y > 0) {
    return 180 + (Math.atan(point.y / point.x) * 180) / Math.PI;
  }
  if (point.x > 0 && point.y < 0) {
    return 360 + (Math.atan(point.y / point.x) * 180) / Math.PI;
  }
  if (point.x < 0 && point.y < 0) {
    return 180 + (Math.atan(point.y / point.x) * 180) / Math.PI;
  }
  return (Math.atan(point.y / point.x) * 180) / Math.PI;
}
