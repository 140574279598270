import React, { useContext } from "react";

import Filters from "../pages/CaseView/Filter";
import { GlobalVariablesContext } from "../providers/GlobalProvider";

/**
 * Filter Pane component
 */

function FilterPane({ onChangeFilter, resetForm, initialFilter }) {
  const { openFilter, setOpenFilter } = useContext(GlobalVariablesContext);

  return (
    <div
      className={`${
        openFilter ? "left-0" : "left-full"
      } fixed top-0 h-full w-full bg-black bg-opacity-20 z-50`}
      onClick={() => setOpenFilter(false)}
    >
      <div
        className="absolute top-0 h-full max-w-xl px-4 pb-4 bg-white overflow-y-scroll"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="pt-4 flex justify-between items-center">
          <p>Filters</p>
          <div onClick={() => setOpenFilter(false)} className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x text-black stroke-current"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>
        <Filters
          onChangeFilter={onChangeFilter}
          initialFilter={initialFilter}
          resetForm={resetForm}
          closeFilterPane={() => setOpenFilter(false)}
        />
      </div>
    </div>
  );
}

export default FilterPane;
