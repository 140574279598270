import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "../assets/main.css";

/**
 * Primary UI component for user interaction
 */
export const FormDataField = ({
  type,
  title,
  value,
  className,
  required,
  errorField,
  addSpacing = false,
  errorClass = "",
  hideError,
  smVariant,
  note,
  ...props
}) => {
  return (
    <div {...props} className={`relative flex flex-col ${className}`}>
      <span
        className={`relative ${
          smVariant ? "mb-1" : "mb-2"
        } text-xxs font-semibold antialiased uppercase tracking-wider text-gray-500 ${
          addSpacing ? "text-left" : ""
        } `}
      >
        {required && (
          <span
            className={`text-red-600 ${addSpacing ? "" : "inset-0 absolute"}`}
            style={{ marginLeft: addSpacing ? 0 : -6 }}
          >
            *
          </span>
        )}
        {title}
        {_.trim(title).length === 0 ? "‎" : ":"}
      </span>
      <span className="text-sm font-normal text-gray-800 w-full">{value}</span>
      {!hideError && errorField && (
        <div
          className={errorClass || `h-5 mt-1 text-red-500 text-xs font-medium`}
        >
          {errorField}
        </div>
      )}
      {note && (
        <div
          className={
            errorClass || `absolute bottom-0 text-gray-500 text-xs font-medium`
          }
        >
          {note}
        </div>
      )}
    </div>
  );
};

FormDataField.propTypes = {
  /**
   * title of data
   */
  title: PropTypes.string,
  /**
   * value of data
   */
  value: PropTypes.node,
  /**
   * value of data
   */
  errorField: PropTypes.node,
  /**
   * is field mandatory
   */
  required: PropTypes.bool,
};

FormDataField.defaultProps = {
  title: "Title",
  value: "Value",
  errorField: null,
};
