import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";
import { Link } from "@reach/router";
import { BreadcrumbLink } from "./BreadcrumbLink";
import { Button } from "./Button";
import { Loader, Search } from "../service/Icon";
import { Formik, Field, Form } from "formik";
import { FormikObserver, getURLParams } from "../service/Helper";

/**
 * Primary UI component for user interaction
 */
export const DashboardHeader = ({
  states,
  title,
  constructiveCTA,
  destructiveCTA,
  children,
  search,
  ...props
}) => {
  const searchQ = getURLParams("search") ?? "";
  return (
    <div className="flex flex-row justify-between mb-10">
      <div className="flex flex-col">
        <BreadcrumbLink states={states} />
        <span className="text-xl text-gray-800 mt-2 capitalize">{title}</span>
      </div>
      <div className="flex flex-row space-x-2 items-start">
        {search?.show && (
          <Formik initialValues={{ query: searchQ }} onSubmit={search.onSearch}>
            {({ values }) => (
              <div className="h-16 sm:pr-12 min-w-xl">
                <Form className="relative bg-white rounded-lg border border-gray-300 focus:border-gray-400 flex flex-row overflow-hidden">
                  {search?.onChange && (
                    <FormikObserver
                      value={values}
                      onChange={search?.onChange}
                    />
                  )}
                  <Field
                    type="search"
                    name="query"
                    placeholder={search.placeholder}
                    autoComplete="off"
                    className="p-4 text-gray-700 flex-1 focus:outline-none "
                  />
                  <button
                    type="submit"
                    className="bg-primary text-white rounded font-lg mt-1 mr-1 mb-1 px-8 font-semibold hover:bg-teal-300 focus:outline-none focus:shadow-outline"
                  >
                    {search.searching ? <Loader /> : <Search />}
                  </button>
                </Form>
              </div>
            )}
          </Formik>
        )}
        {constructiveCTA &&
          (constructiveCTA.link ? (
            <Link to={constructiveCTA.link}>
              <Button
                size="default"
                type="info"
                className="mr-3"
                disabled={destructiveCTA?.loading}
              >
                {constructiveCTA.title}
              </Button>
            </Link>
          ) : (
            <Button
              size="default"
              type="info"
              className="mr-3"
              disabled={constructiveCTA.loading}
              onClick={constructiveCTA.onClick}
            >
              {constructiveCTA.loading && <Loader />}
              {constructiveCTA.title}
            </Button>
          ))}
        {destructiveCTA && (
          <Button
            size="default"
            type="warning"
            className="mr-3"
            disabled={destructiveCTA.loading}
            onClick={destructiveCTA.onClick}
          >
            {destructiveCTA.loading && <Loader />}
            {destructiveCTA.title}
          </Button>
        )}
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {
  /**
   * states for breadcrumb
   */
  states: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Name of the breadcrumb
       */
      title: PropTypes.string,
      /**
       * link of breadcrumb
       */
      link: PropTypes.string,
    })
  ),
  /**
   * Title of header
   */
  title: PropTypes.string,
  /**
   * constructive button details
   */
  constructiveCTA: PropTypes.shape({
    /**
     * Title of button
     */
    title: PropTypes.string,
    /**
     * link to redirect to
     */
    link: PropTypes.string,
  }),
  /**
   * search details
   */
  search: PropTypes.shape({
    /**
     * to show searchbar or not
     */
    show: PropTypes.bool,
    /**
     * if search is in progress
     */
    searching: PropTypes.bool,
    /**
     * to show searchbar or not
     */
    placeholder: PropTypes.string,
    /**
     * function callback for search
     */
    onSearch: PropTypes.func,
  }),
  /**
   * desctructive action callback
   */
  destructiveCTA: PropTypes.shape({
    /**
     * Title of button
     */
    title: PropTypes.string,
    /**
     * if need to show loading info
     */
    loading: PropTypes.bool,
    /**
     * callback on click
     */
    onClick: PropTypes.func,
  }),
};

DashboardHeader.defaultProps = {
  states: [],
  title: "",
  search: { show: false, onSearch: () => {}, placeholder: "Search" },
};
