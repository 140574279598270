import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import _, { findIndex, isEmpty } from "lodash";
import QueryString from "qs";
import CaseCard from "./CaseCard";
import "../assets/main.css";
import { EmptySearch } from "../service/Illustration";
import "./assets/styles.scss";
import { CurrentUser } from "../service/Auth";
import { API } from "../network/API";
import { Loader } from "../service/Icon";
import { IoIosArrowBack } from "react-icons/io";
import { MdFormatListBulleted } from "react-icons/md";

import classNames from "classnames";
import { GlobalVariablesContext } from "../providers/GlobalProvider";
import { assignUser } from "../pages/CaseView/DiagnosisMacroPending";
import { AssigneeContex } from "../providers/TrackAssigneeProvider";
import { toast } from "../components/Toast";
import { navigate, useLocation } from "@reach/router";
import { clearQueryParams, getQueryParams } from "../service/Helper";

/**
 * Primary UI component for user interaction
 */
export const CaseList = ({
  cases,
  filters,
  caseType,
  loading,
  onSelectCase,
  pageInfo: initialPageInfo,
  updateTitle,
  ...props
}) => {
  const observerTarget = useRef(null);
  const [visits, setVisits] = useState(cases ? cases : []);
  const [page, setPage] = useState(2);
  const [pageInfo, setPageInfo] = useState(null);
  const [loadingVisitList, setLoadingVisitList] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [selectedCase, setSelectedCase] = useState();

  const location = useLocation();

  const { setShowDiagnosisPreview } = useContext(GlobalVariablesContext);
  const { setAssignedID } = useContext(AssigneeContex);

  useEffect(() => {
    setVisits(cases);
  }, [cases]);

  useEffect(() => {
    if (loading) {
      setPage(2);
      setPageInfo(null);
    }
  }, [loading]);

  useEffect(() => {
    if (page === 2) {
      setPageInfo(initialPageInfo);
    }
  }, [initialPageInfo, page]);

  const getSelectedId = useMemo(
    () => getQueryParams(location) || {},
    [location]
  );

  const onSelection = useCallback((caseDetails) => {
    if (
      Object.values(getSelectedId)[0] !== caseDetails?.ucid &&
      !isEmpty(getSelectedId)
    ) {
      clearQueryParams();
    }
    setSelectedCase(caseDetails?.ucid);
    setAssignedID(caseDetails?.ucid);
    if (onSelectCase) onSelectCase(caseDetails);
  }, []);

  useEffect(() => {
    const findIndexOfSelectedCase = cases.findIndex(
      (e) => e.ucid === Object.values(getSelectedId)[0]
    );
    if (findIndexOfSelectedCase !== -1) {
      onSelection(cases[findIndexOfSelectedCase]);
    } else {
      onSelection(null);
    }
  }, [cases, getSelectedId, onSelection]);

  useEffect(() => {
    const observerTargetRef = observerTarget.current;

    async function getVisits() {
      try {
        setLoadingVisitList(true);
        const params = QueryString.stringify(
          _.pickBy(filters, _.negate(_.isEmpty))
        );
        const endpoint = CurrentUser.isAdmin() ? `/visits` : `/visits/list`;

        if (
          pageInfo?.current_page <
            Math.ceil(pageInfo?.total_count / pageInfo?.per_page) &&
          !loading
        ) {
          const { data } = await API.get(`${endpoint}?${params}&page=${page}`);
          if (data?.visits) {
            setVisits((prev) => [...prev, ...data?.visits]);
            setPage((prev) => prev + 1);
            setPageInfo(data?.page_info);
          }
        }
      } catch (error) {
      } finally {
        setLoadingVisitList(false);
      }
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getVisits();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTargetRef) {
        observer.unobserve(observerTargetRef);
      }
    };
  }, [
    filters,
    loading,
    page,
    pageInfo?.current_page,
    pageInfo?.per_page,
    pageInfo?.total_count,
  ]);

  const role = JSON.parse(localStorage.getItem("current_user_details"))?.user
    ?.role?.name;

  const userdata = CurrentUser?.getDetails?.user;

  const handleStatusChange = async ({ id, title }) => {
    const data = await API.put(`/visits/${id}/update-status`, {
      status: title === "Amendment" ? "pending_amendment" : "pending_addendum",
    });

    if (data?.data?.message) {
      toast.success(data?.data?.message || "Case moved to pending");
      setTimeout(() => {
        window.location.reload(); // Reload the page
      }, 1000);
    } else {
      toast.error(data?.error?.message);
    }
  };

  return (
    <div className="relative">
      {role === "pathologist" && (
        <button
          className="bg-white shadow-sm p-2 text-sm font-normal flex items-center gap-2 rounded-sm mb-2 "
          onClick={() => setToggleSidebar(!toggleSidebar)}
        >
          {toggleSidebar ? <MdFormatListBulleted /> : <IoIosArrowBack />}
        </button>
      )}
      <div
        {...props}
        className={classNames(
          toggleSidebar ? "w-0" : "w-53",
          "transition-all duration-100"
        )}
      >
        {!loading && cases.length === 0 && (
          <div
            style={{
              height: "calc(100vh - 80px)",
            }}
            className="h-full bg-white pt-12 flex flex-col items-center space-y-2"
          >
            <div className="mx-auto flex-shrink-0 flex items-center justify-center rounded-full bg-gray-100 text-gray-500 h-32 w-32 mb-4">
              <EmptySearch />
            </div>
            <div className="mx-auto w-40 text-center font-normal text-gray-700">
              Could not find any visit for this filter
            </div>
          </div>
        )}

        <div
          style={{
            overflowY: "scroll",
            height: "calc(100vh - 100px)",
          }}
          className="custom-scrollbar w-full"
        >
          {(loading ? [1, 2, 3, 4, 5, 6] : visits).map((caseItem, idx) => {
            const isPrimaryAssignee =
              caseItem?.assignee?.user_id === userdata?.id &&
              caseItem?.assignee?.role === "primary";
            const isOtherPrimaryAssignee =
              caseItem?.assignee?.user_id != userdata?.id &&
              caseItem?.assignee?.role === "primary";

            const status = caseItem?.status;

            return (
              <CaseCard
                key={idx}
                status={status}
                indicator={isPrimaryAssignee ? "primary" : status}
                loading={loading}
                updateTitle={updateTitle}
                onIconButtonClick={async (value) => {
                  await handleStatusChange({
                    id: caseItem?.id,
                    title: value,
                  });
                }}
                className={classNames(
                  "transform duration-300",
                  isOtherPrimaryAssignee && CurrentUser.isAdmin() === false
                    ? "bg-gray-100"
                    : ""
                )}
                selected={selectedCase === caseItem?.ucid}
                caseID={caseItem?.ucid}
                navigate={navigate}
                dateReceived={caseItem?.basic_speciman_demo?.received_duration}
                patientName={
                  caseItem?.patient
                    ? `${caseItem?.patient?.last_name}, ${caseItem?.patient?.first_name}`
                    : "XXX-XXX"
                }
                tabIndex={14 + idx}
                onSelect={async () => {
                  onSelection(caseItem);
                  setShowDiagnosisPreview(() => false);

                  // TODO: Audit API make it global functions
                  await API.post(`/visits/${caseItem?.id}/add-view-audit`);

                  if (
                    isEmpty(caseItem?.assignee) &&
                    CurrentUser.isAdmin() === false
                  ) {
                    const data = await assignUser(
                      "primary",
                      userdata?.id,
                      caseItem?.id
                    );
                    if (data?.success) {
                      setAssignedID(caseItem?.id);
                      setVisits((prev) => {
                        const index = findIndex(prev, {
                          id: caseItem?.id,
                        });
                        const modifiedObject = {
                          ...prev[index],
                          assignee: {
                            first_name: "",
                            last_name: "",
                            user_id: userdata?.id,
                            role: "primary",
                          },
                        };
                        const updatedPrev = [
                          ...prev.slice(0, index),
                          modifiedObject,
                          ...prev.slice(index + 1),
                        ];
                        return updatedPrev;
                      });
                    }
                  }
                }}
                diagnosed={selectedCase?.status === "diagnosed"}
              />
            );
          })}
          {loadingVisitList && (
            <div className="mt-2 flex justify-center">
              <Loader />
            </div>
          )}
          <div
            ref={observerTarget}
            className={`${loadingVisitList ? "hidden" : "block"}`}
          ></div>
        </div>
      </div>
    </div>
  );
};

CaseList.propTypess = {
  /**
   * case list
   */
  cases: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * case id
       */
      caseID: PropTypes.string,
      /**
       * patient name
       */
      patientName: PropTypes.string,
      /**
       * selection state
       */
      selected: PropTypes.bool,
      /**
       * on select callback
       */
      onSelect: PropTypes.func,
    })
  ),
  /**
   * case type: either pending cases or past cases
   */
  caseType: PropTypes.oneOf(["pending", "past"]),
  /**
   * function call on case selection
   */
  onSelectCase: PropTypes.func,
  /**
   * if items are loading
   */
  loading: PropTypes.bool,
};

CaseList.defaultProps = {
  cases: [],
  loading: false,
};
