import React from "react";
import Table from "../../../stories/Table";
import { Link, navigate } from "@reach/router";
import { format } from "date-fns";
import { useClinicStaffList } from "../../../service/Fetchers";
import {
  getErrorData,
  getURLParams,
  getRoleNames,
} from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";
import { StatusTag } from "../../../stories/StatusTag";

const ListLocation = () => {
  const perPageCount = 10;
  const currentPage = getURLParams("page") ?? 1;
  const {
    staffs,
    pageInfo,
    error,
    loading,
    isValidating,
    mutate,
  } = useClinicStaffList({
    currentPage,
    perPageCount,
    list: "all",
  });

  const loadingData = loading || isValidating;

  const headerData = {
    constructiveCTA: {
      link: "/physician/staff/add",
      title: "Add Staff",
    },
    states: [{ title: "Staff List" }],
    title: "Staff",
  };

  const content = (
    <Table
      headers={[
        "Staff ID",
        "Staff Name",
        "Date Added",
        "Location",
        "Role",
        "Status",
      ]}
      pagination={{
        currentpage: pageInfo?.current_page,
        itemsPerPage: pageInfo?.per_page,
        totalItems: pageInfo?.total_count,
        onPageChange: (page) => {
          navigate("/physician/staff?page=" + page);
        },
      }}
      loading={loadingData}
      rowContent={staffs.map((staff) => [
        <p className="underline text-primary">
          <Link
            to={`/physician/staff/view/${staff?.address.id}/${staff?.user?.id}`}
          >
            {staff?.user?.uuid}
          </Link>
        </p>,
        <p>
          {staff?.user?.first_name} {staff?.user?.last_name}
        </p>,
        <p>{format(new Date(staff?.user?.created_at), "dd-MMM-yyyy")}</p>,
        <p>{staff?.address?.location_name}</p>,
        <p>{getRoleNames(staff?.user?.role?.name)}</p>,
        <StatusTag type={staff?.user?.deactivated ? "red" : "green"}>
          {staff?.user?.deactivated ? "Deactivated" : "Active"}
        </StatusTag>,
      ])}
    />
  );

  const errorData = getErrorData({
    error,
    data: staffs,
    mutate,
    title: "Staff",
  });

  return (
    <div className="w-full flex justify-center">
      <DashboardView
        headerData={headerData}
        content={content}
        errorData={errorData}
        loading={loadingData}
        loadingContent={null}
        className="w-full container py-10"
      />
    </div>
  );
};

export default ListLocation;
