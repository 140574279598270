import React, { useState } from "react";
import { FormDataField } from "../../stories/FormDataField";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import ConfirmDialog from "../../stories/ConfirmDialog";
import { usePatient } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";

const ViewPatient = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTogglingActivation, setIsTogglingActivation] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const { patient, loading, error, mutate } = usePatient({ patient_id: id });

  const toggleActivationPatient = async (type) => {
    setIsTogglingActivation(true);
    const { data, error } = await API.put(
      `/patients/${patient.uuid}/toggle-activation`
    );
    if (error) {
      if (error.message) toast.error(error.message);
    }
    if (data) {
      mutate();
      toast.info(
        `${patient.first_name} is ${
          type === "activate" ? "Activated" : "Deactivated"
        } `
      );
    }
    setIsTogglingActivation(false);
  };

  const onToggleActivation = async (type) => {
    if (type === "activate") {
      setDialogData({
        description: "Once activated the patient will be seen across platform",
        construtctiveTitle: "Activate",
        onPressConstructive: () => confirmToggleActivation(type),
      });
    } else {
      setDialogData({
        description:
          "Once deactivated the patient can be activated again. All data will still be available, but cannot be edited",
        destrutctiveTitle: "Deactivate",
        onPressDestructive: () => confirmToggleActivation(type),
      });
    }
    setIsOpen(true);
  };

  const confirmToggleActivation = async (type) => {
    setIsOpen(false);
    await toggleActivationPatient(type);
  };

  const headerData = {
    constructiveCTA:
      !patient || patient.deactivated
        ? {
            onClick: () => onToggleActivation("activate"),
            title: "Activate",
            loading: isTogglingActivation,
          }
        : {
            link: `/dashboard/patients/edit/${id}`,
            title: "Edit",
          },
    destructiveCTA:
      !patient || patient.deactivated
        ? null
        : {
            onClick: () => onToggleActivation("deactivate"),
            title: "Deactivate",
            loading: isTogglingActivation,
          },
    states: [
      { title: "Patient List", link: "/dashboard/patients" },
      { title: `Patient #${id}` },
    ],
    title: patient ? `${patient.first_name} ${patient.last_name}` : "Patient",
  };

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load patient details",
        ctaOnClick: mutate,
      }
    : null;

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Name"
          value={
            <span className="font-bold">{`${patient?.first_name} ${patient?.last_name}`}</span>
          }
        />
        <FormDataField
          className="w-1/4"
          title="Patient ID"
          value={<span className="font-bold">{patient?.id}</span>}
        />
        <FormDataField
          className="w-1/4"
          title="Gender At Birth"
          value={patient?.gender === "male" ? "Male" : "Female"}
        />
        <FormDataField className="w-1/4" title="Age" value={patient?.age} />
      </div>
      <div className="flex flex-row">
        <FormDataField className="w-1/2" title="Email" value={patient?.email} />
        <FormDataField
          className="w-1/2"
          title="Phone #"
          value={patient?.phone}
        />
        <FormDataField
          className="w-1/2"
          title="Payor"
          value={patient?.insurance_details?.provider?.name}
        />
        <FormDataField
          className="w-1/2"
          title="Policy #"
          value={patient?.insurance_details?.policy}
        />
      </div>

      <div className="flex flex-row">
        <FormDataField
          className="w-full"
          title="Policy Notes"
          value={patient?.insurance_details?.patient_notes}
        />
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        title="Are you sure?"
        cancelTitle="Cancel"
        {...dialogData}
        onPressCancel={() => setIsOpen(false)}
      />
      <DashboardView
        headerData={headerData}
        loading={loading}
        content={patient ? content : null}
        errorData={errorData}
      />
    </>
  );
};

export default ViewPatient;
