import React from "react";
import { FormDataField } from "../../stories/FormDataField";
import _ from "lodash";
import { useCPTCode } from "../../service/Fetchers";
import LoadingStatus from "../../components/Loader";
import { DashboardView } from "../../stories/DashboardView";

const ViewCPTCode = ({ id: cpts_id }) => {
  const { cptCode, loading, error, mutate } = useCPTCode({
    cpts_id,
  });

  const headerData = {
    // constructiveCTA: {
    //   link: `/dashboard/cpt-codes/edit/${cpts_id}`,
    //   title: "Edit",
    // },
    states: [
      { title: "CPT Code List", link: "/dashboard/cpt-codes" },
      { title: `CPT Code #${cpts_id}` },
    ],
    title: cptCode ? cptCode.code : "CPT Code",
  };

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Code"
          value={<span className="font-bold uppercase">{cptCode?.code}</span>}
        />
      </div>
      <div className="flex flex-row">
        <FormDataField
          title="Description"
          value={_.isEmpty(cptCode?.description) ? "NA" : cptCode?.description}
        />
      </div>
    </div>
  );

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load CPT Code",
        ctaOnClick: mutate,
      }
    : null;

  return (
    <DashboardView
      headerData={headerData}
      loading={loading}
      loadingContent={<LoadingStatus />}
      content={content}
      errorData={errorData}
    />
  );
};

export default ViewCPTCode;
