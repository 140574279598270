import React from "react";
import Header from "../../stories/Header";
import Cases from "./Case";
import Location from "./Location";
import Staff from "./Staff";
import { logout } from "../../service/Auth";

export default function PhysicalPortal(props) {
  const [type, subType, id, staffId] = props["*"].split("/");

  const CaseType = ({ type, subType, id }) => {
    if (type === "cases") {
      return <Cases type={subType} id={id} />;
    } else if (type === "location") {
      return <Location type={subType} id={id} />;
    } else if (type === "staff") {
      return <Staff type={subType} id={id} staffId={staffId} />;
    }
    return <Cases type={subType} id={id} />;
  };

  return (
    <div className="bg-theme-gray flex flex-col w-full h-full">
      <Header
        className="flex-shrink-0"
        rightLinks={[
          {
            type: "link",
            text: "Change Password",
            href: "/change-password",
          },
          {
            type: "link",
            text: <span className="text-red-500">Logout</span>,
            onClick: () => logout(),
          },
        ]}
      />
      <div className="page-container px-10 py-5 flex">
        <CaseType {...{ type, subType, id }} />
      </div>
    </div>
  );
}
