import React, { useState } from "react";
import { FormDataField } from "../../../stories/FormDataField";
import { API } from "../../../network/API";
import { toast } from "../../../components/Toast";
import ConfirmDialog from "../../../stories/ConfirmDialog";
import { useClinicStaff } from "../../../service/Fetchers";
import { getRoleNames } from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";
import { format } from "date-fns";

const ViewStaff = ({ id, location_id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTogglingActivation, setIsTogglingActivation] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const { staff, location, loading, error, mutate } = useClinicStaff({
    user_id: id,
    location_id,
  });

  const toggleActivationStaff = async (type) => {
    setIsTogglingActivation(true);
    const { data, error } = await API.put(
      `/auth/${staff.uuid}/toggle-activation`
    );
    if (error) {
      if (error.message) toast.error(error.message);
    }
    if (data) {
      mutate();
      toast.info(
        `${staff.first_name} is ${
          type === "activate" ? "Activated" : "Deactivated"
        } `
      );
    }
    setIsTogglingActivation(false);
  };

  const onToggleActivation = async (type) => {
    if (type === "activate") {
      setDialogData({
        description: "Once activated the staff will across the platform",
        construtctiveTitle: "Activate",
        onPressConstructive: () => confirmToggleActivation(type),
      });
    } else {
      setDialogData({
        description:
          "Once deactivated the Staff cannot be access portal again. All data will still be available, but can be edited",
        destrutctiveTitle: "Deactivate",
        onPressDestructive: () => confirmToggleActivation(type),
      });
    }
    setIsOpen(true);
  };

  const confirmToggleActivation = async (type) => {
    setIsOpen(false);
    await toggleActivationStaff(type);
  };

  const headerData = {
    constructiveCTA:
      !staff || staff.deactivated
        ? {
            onClick: () => onToggleActivation("activate"),
            title: "Activate",
            loading: isTogglingActivation,
          }
        : {
            link: `/physician/staff/edit/${location_id}/${id}`,
            title: "Edit",
          },
    destructiveCTA:
      !staff || staff.deactivated
        ? null
        : {
            onClick: () => onToggleActivation("deactivate"),
            title: "Deactivate",
            loading: isTogglingActivation,
          },
    states: [
      { title: "Staff List", link: "/physician/staff" },
      { title: `Staff #${id}` },
    ],
    title: staff ? `${staff.first_name} ${staff.last_name}` : "Staff",
  };

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load staff details",
        ctaOnClick: mutate,
      }
    : null;

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Name"
          value={
            <span className="font-bold">{`${staff?.first_name} ${staff?.last_name}`}</span>
          }
        />
        <FormDataField
          className="w-1/4"
          title="Staff ID"
          value={<span className="font-bold">{staff?.uuid}</span>}
        />
        <FormDataField
          className="w-1/4"
          title="DOB"
          value={staff?.dob && format(new Date(staff?.dob), "dd-MMM-yyyy")}
        />
        <FormDataField
          className="w-1/4"
          title="Role"
          value={getRoleNames(staff?.role.name)}
        />
      </div>
      <div className="flex flex-row">
        <FormDataField className="w-1/4" title="Email" value={staff?.email} />
        <FormDataField className="w-1/4" title="Phone" value={staff?.phone} />
        <FormDataField
          className="w-1/4"
          title="Location"
          value={location?.location_name}
        />
      </div>
    </div>
  );

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        title="Are you sure?"
        cancelTitle="Cancel"
        {...dialogData}
        onPressCancel={() => setIsOpen(false)}
      />
      <div className="w-full flex justify-center">
        <DashboardView
          headerData={headerData}
          loading={loading}
          content={staff ? content : null}
          errorData={errorData}
          className="w-full p-10 container bg-white"
        />
      </div>
    </>
  );
};

export default ViewStaff;
