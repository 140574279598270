import React, { useCallback, useEffect, useState } from "react";
import {
  Document,
  Page,
  PDFViewer,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { format, parseISO } from "date-fns";
import { usePhysicianList, useVisit } from "../service/Fetchers";
import LoadingStatus from "./Loader";
import { numberToAlphabet } from "../pages/CaseView/SlideViewer";
import _, { isEmpty, pick } from "lodash";
import { CurrentUser } from "../service/Auth";

const styles = StyleSheet.create({
  header: {
    width: "100%",
    marginBottom: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 20,
  },
  category: {
    width: "100%",
    border: "1px solid #000000",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 2,
    backgroundColor: "#b3fbff",
  },
  categoryHeading: {
    flex: 1,
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "center",
    color: "#000000",
  },
  categoryContent: {
    borderBottom: "1px solid #000000",
    borderLeft: "1px solid #000000",
    borderRight: "1px solid #000000",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  categoryContentHeading: {
    marginBottom: 10,
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    textTransform: "uppercase",
    color: "#000000",
  },
  categoryContentText: {
    marginBottom: 3,
    fontFamily: "Helvetica",
    fontSize: 10,
    // textTransform: "capitalize",
    color: "#000000",
  },
  diagnosisCategory: {
    marginTop: 4,
    width: "100%",
    border: "1px solid #6b7280",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#b3fbff",
    paddingVertical: 3,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Helvetica",
    // backgroundColor: "#b3fbff",
    // border: "1px solid #6b7280",
  },

  pageNumber: {
    padding: 4,
    position: "absolute",
    fontSize: 10,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const MyDocument = React.memo(
  ({ updateVisit, visitDetails, physicians, fetchPhysicians, title }) => {
    // useEffect(() => {
    //   updateVisit();
    //   fetchPhysicians();
    // }, []);

    const primaryAssignee = Object.values(
      _.pick(visitDetails?.assignee, ["first_name", "last_name"])
    ).join(" ");

    const userSignature = CurrentUser.getDetails?.user?.signature;

    const amendment = () => {
      if (
        visitDetails?.status.includes("amendment") &&
        !isEmpty(visitDetails?.amendment_comment)
      ) {
        return (
          <View>
            {visitDetails?.amendment || visitDetails?.amendment_comment ? (
              <View
                style={{
                  marginTop: 16,
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      marginBottom: 0,
                      fontSize: 11,
                    },
                  ]}
                >
                  Reason for Amendment (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")}) :
                </Text>
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      margin: 0,
                      fontSize: 10,
                    },
                  ]}
                >
                  {visitDetails?.amendment_comment}
                </Text>
              </View>
            ) : null}
          </View>
        );
      } else if (
        visitDetails?.status.includes("amendment") &&
        !isEmpty(visitDetails?.amendment)
      ) {
        return (
          <View
            style={{
              marginTop: 16,
              flexDirection: "row",
              gap: 4,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text
              style={[
                styles.categoryContentText,
                {
                  marginBottom: 0,
                  fontSize: 11,
                },
              ]}
            >
              Reason for Amendment (
              {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")}) :
            </Text>
            <Text
              style={[
                styles.categoryContentText,
                {
                  margin: 0,
                  fontSize: 10,
                },
              ]}
            >
              {visitDetails?.amendment?.description}
            </Text>
          </View>
        );
      } else if (
        visitDetails?.status === "diagnosed" &&
        !isEmpty(visitDetails?.amendment)
      ) {
        return (
          <View
            style={{
              marginTop: 16,
              flexDirection: "row",
              gap: 4,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text
              style={[
                styles.categoryContentText,
                {
                  marginBottom: 0,
                  fontSize: 11,
                },
              ]}
            >
              Reason for Amendment (
              {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")}) :
            </Text>
            <Text
              style={[
                styles.categoryContentText,
                {
                  margin: 0,
                  fontSize: 10,
                },
              ]}
            >
              {visitDetails?.amendment?.description}
            </Text>
          </View>
        );
      } else if (!isEmpty(visitDetails?.amendment)) {
        return (
          <View
            style={{
              marginTop: 16,
              flexDirection: "row",
              gap: 4,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Text
              style={[
                styles.categoryContentText,
                {
                  marginBottom: 0,
                  fontSize: 11,
                },
              ]}
            >
              Reason for Amendment (
              {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")}) :
            </Text>
            <Text
              style={[
                styles.categoryContentText,
                {
                  margin: 0,
                  fontSize: 10,
                },
              ]}
            >
              {visitDetails?.amendment?.description}
            </Text>
          </View>
        );
      }
    };

    const addendum = () => {
      if (
        visitDetails?.status.includes("addendum") &&
        !isEmpty(visitDetails?.addendum_comment)
      ) {
        return (
          <View>
            {visitDetails?.addendum || visitDetails?.addendum_comment ? (
              <View
                style={{
                  marginTop: 4,
                }}
              >
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      marginTop: 4,
                      marginBottom: 8,
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  Addendum (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      marginLeft: 12,
                      marginBottom: 8,
                    },
                  ]}
                >
                  {visitDetails?.addendum_comment}
                </Text>
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  {primaryAssignee ?? "--"}, {userSignature}, Electronically
                  Signed (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
              </View>
            ) : null}
          </View>
        );
      } else if (
        visitDetails?.status.includes("addendum") &&
        !isEmpty(visitDetails?.addendum)
      ) {
        return (
          <View>
            {visitDetails?.addendum || visitDetails?.addendum_comment ? (
              <View
                style={{
                  marginTop: 4,
                }}
              >
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      marginTop: 4,
                      marginBottom: 8,
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  Addendum (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      marginLeft: 12,
                      marginBottom: 8,
                    },
                  ]}
                >
                  {visitDetails?.addendum?.description}
                </Text>
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  {primaryAssignee ?? "--"}, {userSignature}, Electronically
                  Signed (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
              </View>
            ) : null}
          </View>
        );
      } else if (visitDetails?.status === "diagnosed") {
        return (
          <View>
            {visitDetails?.addendum || visitDetails?.addendum_comment ? (
              <View
                style={{
                  marginTop: 4,
                }}
              >
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      marginTop: 4,
                      marginBottom: 8,
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  Addendum (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      marginLeft: 12,
                      marginBottom: 8,
                    },
                  ]}
                >
                  {visitDetails?.addendum?.description}
                </Text>
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  {primaryAssignee ?? "--"}, {userSignature}, Electronically
                  Signed (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
              </View>
            ) : null}
          </View>
        );
      } else if (!isEmpty(visitDetails?.addendum)) {
        return (
          <View>
            {visitDetails?.addendum || visitDetails?.addendum_comment ? (
              <View
                style={{
                  marginTop: 4,
                }}
              >
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      marginTop: 4,
                      marginBottom: 8,
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  Addendum (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      marginLeft: 12,
                      marginBottom: 8,
                    },
                  ]}
                >
                  {visitDetails?.addendum?.description}
                </Text>
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  {primaryAssignee ?? "--"}, {userSignature}, Electronically
                  Signed (
                  {format(new Date(visitDetails?.updated_at), "MM-dd-yyyy")})
                </Text>
              </View>
            ) : null}
          </View>
        );
      }
    };

    return (
      <Document>
        <Page
          wrap={true}
          style={{
            // padding: 4,
            paddingTop: 4,
            paddingBottom: 230,
            paddingHorizontal: 4,
          }}
        >
          <View
            fixed
            style={{
              marginBottom: 5,
            }}
          >
            <View style={styles.header}>
              <Image
                src="./pdfLogo.png"
                style={{
                  height: 50,
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </View>

            <View style={styles.category}>
              <Text style={styles.categoryHeading}>
                Patient / Specimen Information
              </Text>
              <Text style={styles.categoryHeading}>Provider Information</Text>
            </View>

            <View style={styles.categoryContent}>
              <View style={{ flex: 1, paddingHorizontal: 4 }}>
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      marginHorizontal: 1,
                      marginVertical: 5,
                    },
                  ]}
                >
                  {visitDetails?.patient?.last_name},{" "}
                  {visitDetails?.patient?.first_name}
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>MR#: </Text>
                    {visitDetails?.patient?.uuid}
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>
                      Phone:{" "}
                    </Text>
                    {visitDetails?.patient?.phone || "-"}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    <Text
                      style={{
                        fontFamily: "Helvetica-Bold",
                      }}
                    >
                      Email:{" "}
                    </Text>
                    <Text
                      style={{
                        textTransform: "lowercase",
                      }}
                    >
                      {visitDetails?.patient?.email || "-"}
                    </Text>
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>DOB: </Text>
                    {format(
                      new Date(visitDetails?.patient?.dob),
                      "MM-dd-yyyy"
                    ) || "-"}
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>AGE: </Text>
                    {visitDetails?.patient?.age || "-"}
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    <Text style={{ fontFamily: "Helvetica-Bold" }}>SEX: </Text>
                    {visitDetails?.patient?.gender || "-"}
                  </Text>
                </View>

                <View
                  style={[
                    styles.categoryContent,
                    {
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "none",
                      borderRight: "none",
                      marginHorizontal: -4,
                      paddingVertical: 2,
                    },
                  ]}
                >
                  <Text style={styles.categoryHeading}>
                    ACCESSION #: {visitDetails?.ucid}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    Collected:
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    Received:
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    Reported:
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    {format(
                      new Date(
                        visitDetails?.specimen_demographics[0]?.collected_date
                      ),
                      "MM-dd-yyyy"
                    )}
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    {format(
                      new Date(
                        visitDetails?.specimen_demographics[0]?.received_date
                      ),
                      "MM-dd-yyyy"
                    )}
                  </Text>

                  <Text style={[styles.categoryContentText, { flex: 1 }]}>
                    {visitDetails?.diagnosed_at
                      ? format(
                          parseISO(visitDetails?.diagnosed_at),
                          "MM-dd-yyyy"
                        )
                      : "--"}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flex: 1,
                  paddingHorizontal: 4,
                  borderLeft: "1px solid #000000",
                }}
              >
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      marginHorizontal: 1,
                      marginVertical: 5,
                    },
                  ]}
                >
                  {visitDetails?.clinical_info?.primary_physician?.last_name},{" "}
                  {visitDetails?.clinical_info?.primary_physician?.first_name}
                </Text>

                <Text style={styles.categoryContentText}>
                  {visitDetails?.client_address?.street1},{" "}
                  {visitDetails?.client_address?.street2
                    ? `${visitDetails?.client_address?.street2}, `
                    : ""}
                  {visitDetails?.client_address?.city},{" "}
                </Text>

                <Text style={styles.categoryContentText}>
                  {visitDetails?.client_address?.state},{" "}
                  {visitDetails?.client_address?.zipcode},{" "}
                  {visitDetails?.client_address?.location_name}{" "}
                </Text>
                <Text style={styles.categoryContentText}>
                  P:{" "}
                  {physicians?.find(
                    (physician) =>
                      physician.id ===
                      visitDetails?.clinical_info?.primary_physician?.id
                  )?.phone ??
                    visitDetails?.clinical_info?.primary_physician
                      ?.phone_number ??
                    "-"}{" "}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={[
              styles.diagnosisCategory,
              {
                border: "none",
                backgroundColor: "#000000",
              },
            ]}
          >
            <Text style={[styles.categoryHeading, { color: "#ffffff" }]}>
              Dermatopathology Report
            </Text>
          </View>

          <View
            wrap={false}
            style={[
              styles.diagnosisCategory,
              {
                marginBottom: 4,
              },
            ]}
          >
            <Text style={styles.categoryHeading}>Diagnosis:</Text>
          </View>

          <View
            style={{
              flexDirection: "column",
              gap: 6,
            }}
          >
            {visitDetails?.diagnoses?.map((diagnose, index) => (
              <View>
                <Text
                  style={[
                    styles.categoryContentHeading,
                    {
                      textTransform: "uppercase",
                      marginBottom: 4,
                    },
                  ]}
                >
                  {visitDetails?.diagnoses?.length > 1 &&
                    String.fromCharCode(index + 65) + ". "}
                  {
                    visitDetails?.specimen_demographics[index]?.site
                      ?.description
                  }{" "}
                  -{" "}
                </Text>
                <View
                  style={{
                    marginLeft: visitDetails?.diagnoses?.length > 1 ? 80 : 65,
                  }}
                >
                  <Text
                    style={[
                      styles.categoryContentHeading,
                      {
                        textTransform: "uppercase",
                        lineHeight: 1.2,
                        marginBottom: 4,
                      },
                    ]}
                  >
                    {diagnose?.title || "-"}
                  </Text>
                  {diagnose?.notes ? (
                    <Text
                      style={[
                        {
                          fontSize: 10,
                          marginTop: -0,
                          fontFamily: "Helvetica",
                          fontSize: 10,
                          // textTransform: "capitalize",
                          lineHeight: "1.5px",
                          // width: 500,
                        },
                      ]}
                    >
                      NOTE: {diagnose?.notes}
                    </Text>
                  ) : null}
                </View>
              </View>
            ))}
          </View>

          <View
            wrap={false}
            style={[
              styles.diagnosisCategory,
              {
                marginBottom: 4,
              },
            ]}
          >
            <Text style={styles.categoryHeading}>Clinical History:</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              gap: 2,
              lineHeight: "1.3px",
            }}
          >
            {visitDetails?.specimen_demographics?.map((specimen, index) => (
              <Text
                style={[
                  styles.categoryContentText,
                  { textTransform: "uppercase" },
                ]}
              >
                {visitDetails?.specimen_demographics.length > 1 &&
                  String.fromCharCode(index + 65) + ". "}
                {specimen?.clinical_notes || "NA"}
              </Text>
            ))}
          </View>

          <View
            wrap={false}
            style={[
              styles.diagnosisCategory,
              {
                marginBottom: 4,
              },
            ]}
          >
            <Text style={styles.categoryHeading}>Microscopic Description:</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              gap: 2,
              lineHeight: "1.3px",
            }}
          >
            {visitDetails?.diagnoses?.map((diagnose, index) => (
              <Text style={styles.categoryContentText}>
                {visitDetails?.diagnoses.length > 1 &&
                  String.fromCharCode(index + 65) + ". "}
                {diagnose?.description}
              </Text>
            ))}
          </View>

          <View
            wrap={false}
            style={[
              styles.diagnosisCategory,
              {
                marginBottom: 4,
              },
            ]}
          >
            <Text style={styles.categoryHeading}>Gross Description:</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              gap: 2,
              lineHeight: "1.4px",
              marginBottom: 35,
            }}
          >
            {visitDetails?.diagnoses?.map((grossing, index) => (
              <Text style={styles.categoryContentText}>
                {visitDetails?.diagnoses.length > 1 &&
                  String.fromCharCode(index + 65) + ". "}
                {grossing?.grossing_description}
              </Text>
            ))}
          </View>

          {addendum()}

          {/* Addendum */}
          {/* {visitDetails?.addendums?.length > 0 ? (
            <>
              {visitDetails?.addendums?.map((addendum) => (
                <>
                  <Text
                    style={[
                      styles.categoryContentHeading,
                      { marginTop: 4, marginBottom: 3 },
                    ]}
                  >
                    Addendum (
                    {format(new Date(addendum.updated_at), "MM-dd-yyyy")})
                  </Text>
                  <Text style={[styles.categoryContentText]}>
                    Description: {addendum?.description}
                  </Text>
                  <Text style={[styles.categoryContentHeading]}>
                    {addendum?.added_by?.last_name},{" "}
                    {addendum?.added_by?.first_name}, Electronically Signed (
                    {format(new Date(addendum.updated_at), "MM-dd-yyyy")})
                  </Text>
                </>
              ))}
            </>
          ) : null} */}
          <View
            style={{
              flexDirection: "row",
              gap: 5,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              {/* <View style={[styles.diagnosisCategory]}>
                <Text style={styles.categoryHeading}>Codes:</Text>
              </View> */}

              {/* specimen table Table */}
              <View wrap={false}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 1,
                  }}
                >
                  <Text
                    style={{
                      flex: 0.2,
                      justifyContent: "center",
                      padding: 2,
                      border: "0.1px solid black",
                      borderRight: "none",
                      fontSize: 10,
                      fontFamily: "Helvetica-Bold",
                    }}
                  ></Text>
                  <Text
                    style={{
                      flex: 1,
                      justifyContent: "flex-start",
                      padding: 2,
                      border: "0.1px solid black",
                      fontSize: 10,
                      fontFamily: "Helvetica",
                      textAlign: "left",
                    }}
                  >
                    CPT
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      justifyContent: "flex-start",
                      padding: 2,
                      border: "0.1px solid black",
                      borderLeft: "none",
                      fontSize: 10,
                      fontFamily: "Helvetica",
                      textAlign: "left",
                    }}
                  >
                    ICD
                  </Text>
                </View>

                <View
                  style={{
                    border: "0.1px solid black",
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                >
                  {(visitDetails?.diagnoses).map((e, index) => {
                    return (
                      <View
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <Text
                          style={[
                            styles.categoryContentText,
                            {
                              flex: 0.2,
                              border: "0.1px solid black",
                              borderLeft: "none",
                              borderTop: "none",
                              padding: 2,
                            },
                          ]}
                        >
                          {numberToAlphabet(index)}
                        </Text>
                        <Text
                          style={[
                            styles.categoryContentText,
                            {
                              flex: 1,
                              borderBottom: "0.1px solid black",
                              padding: 2,
                            },
                          ]}
                        >
                          {e.cpts.map((e) => e.code).join(", ")}
                        </Text>
                        <Text
                          style={[
                            styles.categoryContentText,
                            {
                              flex: 1,
                              borderBottom:
                                visitDetails?.diagnoses.length === index
                                  ? ""
                                  : "0.1px solid black",
                              borderLeft: "0.1px solid black",
                              padding: 2,
                            },
                          ]}
                        >
                          {e.icds.map((e) => e.code).join(", ")}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <View
                style={{
                  borderTop: "1px solid black",
                  width: "90%",
                  position: "absolute",
                  bottom: -10,
                }}
              >
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      textAlign: "center",
                      lineHeight: "1.2px",
                      fontSize: 8.4,
                      paddingTop: 1,
                    },
                  ]}
                >
                  {primaryAssignee?.length > 1 ? primaryAssignee : "--"},{" "}
                  {userSignature},
                </Text>
                <Text
                  style={[
                    styles.categoryContentText,
                    {
                      textAlign: "center",
                      lineHeight: "1.3px",
                      fontSize: 8.4,
                      marginTop: -2.5,
                    },
                  ]}
                >
                  Board Certified in Pathology and Dermatopathology,
                  Electronically Signed (
                  {visitDetails?.diagnosed_at
                    ? format(parseISO(visitDetails?.diagnosed_at), "MM-dd-yyyy")
                    : "--"}
                  )
                </Text>
              </View>
            </View>
          </View>

          {/* End of specimen table */}

          {amendment()}

          <View fixed style={styles.pageNumber}>
            {/* Space */}

            <Text
              style={[
                styles.categoryContentHeading,
                {
                  alignSelf: "center",
                  fontWeight: "bold",
                  // fontSize: 16,
                  alignItems: "center",
                  height: 80,
                },
              ]}
              render={({ pageNumber, totalPages }) => {
                return pageNumber === totalPages
                  ? null
                  : ` ******** CONTINUED ON THE NEXT PAGE ********`;
              }}
            ></Text>

            <Text
              style={[
                styles.categoryContentText,
                {
                  textAlign: "center",
                  width: "75%",
                  alignSelf: "center",
                },
              ]}
            >
              6605 Nancy Ridge Drive San Diego California 92121 Phone:
              858-750-2983, Fax: 858-750-2984 Christopher Erickson, MD, Medical
              Director, CLIA 05D2018656, CAP 8041850, Lab ID CLF00340307
            </Text>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#b3fbff",
                border: "1px solid #6b7280",
                padding: 2,
                fontWeight: 600,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  flex: 1,
                }}
              >
                FINAL REPORT
              </Text>
              <Text
                render={({ pageNumber, totalPages }) => {
                  return `PAGE ${pageNumber} OF ${totalPages}`;
                }}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  }
);

function PDFPreviewer({ visitDetails, customClasses }) {
  const {
    users: physicians,
    mutate: fetchPhysicians,
    loading: fetchingPhysicians,
  } = usePhysicianList({
    currentPage: 1,
    perPageCount: 10,
    searchQuery: visitDetails?.clinical_info?.primary_physician?.uuid,
    list: "all",
  });

  const DocumentCallback = useCallback(() => {
    return (
      <MyDocument
        // updateVisit={updateVisit}
        visitDetails={visitDetails}
        physicians={physicians}
        // fetchPhysicians={fetchPhysicians}
      />
    );
  }, [visitDetails, physicians]);

  return (
    <>
      {fetchingPhysicians ? (
        <div className={`${customClasses} flex justify-center items-center`}>
          <LoadingStatus />
        </div>
      ) : (
        <PDFViewer className={customClasses}>
          <DocumentCallback />
        </PDFViewer>
      )}
    </>
  );
}

export default React.memo(PDFPreviewer);
