import React, { useState, useMemo } from "react";

export const GlobalVariablesContext = React.createContext();

function GlobalVariableProvider({ children }) {
  const [showAddPackageModal, setShowAddPackageModal] = useState(false);
  const [openPatientHistory, setOpenPatientHistory] = useState(false);
  const [pdfViewer, setPdfViewer] = useState(false);
  const [openAddendum, setOpenAddendum] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showNavControls, setShowNavControls] = useState(false);
  const [showSlideViewer, setShowSlideViewer] = useState(true);
  const [showPatientInfo, setShowPatientInfo] = useState(false);
  const [showDiagnosisPreview, setShowDiagnosisPreview] = useState(false);
  const [showDiagnosisPreviewBtn, setShowDiagnosisPreviewBtn] = useState(false);
  const [showMacroViewer, setShowMacroViewer] = useState(false);
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [selectedSpecimenTab, setSelectedSpecimenTabs] = useState(0);

  const value = useMemo(() => {
    return {
      showAddPackageModal,
      setShowAddPackageModal,
      openPatientHistory,
      setOpenPatientHistory,
      pdfViewer,
      setPdfViewer,
      openAddendum,
      setOpenAddendum,
      openFilter,
      setOpenFilter,
      headerHeight,
      setHeaderHeight,
      showNavControls,
      setShowNavControls,
      showSlideViewer,
      setShowSlideViewer,
      showPatientInfo,
      setShowPatientInfo,
      showDiagnosisPreview,
      setShowDiagnosisPreview,
      showDiagnosisPreviewBtn,
      setShowDiagnosisPreviewBtn,
      showMacroViewer,
      setShowMacroViewer,
      receivedDate,
      setReceivedDate,
      selectedSpecimenTab,
      setSelectedSpecimenTabs,
    };
  }, [
    showAddPackageModal,
    setShowAddPackageModal,
    openPatientHistory,
    setOpenPatientHistory,
    pdfViewer,
    setPdfViewer,
    openAddendum,
    setOpenAddendum,
    openFilter,
    setOpenFilter,
    headerHeight,
    setHeaderHeight,
    showNavControls,
    setShowNavControls,
    showSlideViewer,
    setShowSlideViewer,
    showPatientInfo,
    setShowPatientInfo,
    showDiagnosisPreview,
    setShowDiagnosisPreview,
    showDiagnosisPreviewBtn,
    setShowDiagnosisPreviewBtn,
    showMacroViewer,
    setShowMacroViewer,
    receivedDate,
    setReceivedDate,
    selectedSpecimenTab,
    setSelectedSpecimenTabs,
  ]);

  return (
    <GlobalVariablesContext.Provider value={value}>
      {children}
    </GlobalVariablesContext.Provider>
  );
}

export default GlobalVariableProvider;
