import React, { useState, useRef } from "react";
import { navigate } from "@reach/router";
import { Button } from "../../../stories/Button";
import { Formik, Form } from "formik";
import { addStaffSchema } from "../../../service/Validation";
import { API } from "../../../network/API";
import { toast } from "../../../components/Toast";
import _ from "lodash";
import { FormInputField } from "../../../stories/FormInputField";
import {
  useRoles,
  useClinicStaff,
  useClinicLocationList,
} from "../../../service/Fetchers";
import { getRoleNames } from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";

export default function AddStaff({ id: user_id, location_id }) {
  const [loading, setLoading] = useState(false);
  const buttonTitle = user_id ? "Update" : "Create Staff";
  const pageTitle = user_id ? `Update staff #${user_id}` : "Create new staff";
  const buttonRef = useRef(null);
  const { roles, error: roleError, mutate: mutateRole } = useRoles();
  const {
    staff,
    location,
    error: staffError,
    mutate: mutateStaff,
  } = useClinicStaff({
    user_id,
    location_id,
  });
  const [locationSearchQuery, setLocationSearchQuery] = useState(location_id);
  const { locations, loading: loadingLocationList } = useClinicLocationList({
    searchQuery: locationSearchQuery,
  });

  const initialFormValues = () => {
    return {
      first_name: staff?.first_name ?? "",
      last_name: staff?.last_name ?? "",
      email: staff?.email ?? "",
      phone: staff?.phone ?? "",
      gender: staff?.gender ?? "default",
      dob: staff?.dob ?? "",
      location_id: location?.id
        ? `${location?.id}, ${location?.location_name}`
        : null,
      role_id: staff?.role?.id ? `${staff?.role?.id}` : null,
    };
  };

  const createOrUpdateStaff = async (values) => {
    let newValues = { ...values };
    newValues.role_id = parseInt(newValues.role_id);
    delete newValues.location_id;
    let correctedSearchQuery = locationSearchQuery.split(",")[0];
    if (user_id)
      return API.put(
        `/addresses/${correctedSearchQuery}/clinic_users/${user_id}`,
        {
          user: newValues,
        }
      );
    return API.post(`/addresses/${correctedSearchQuery}/clinic_users`, {
      user: newValues,
    });
  };

  //for Matching location on input
  const matchLocationToTerm = (state, value) => {
    return (
      state.location_name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
      state.id.toString().indexOf(value.toLowerCase()) !== -1
    );
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    const { data, error } = await createOrUpdateStaff(values);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (user_id) {
        toast.success(`Updated ${values.first_name}!`);
        setTimeout(() => {
          navigate(`/physician/staff/view/${locationSearchQuery}/${user_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        setLocationSearchQuery("");
        toast.success(`Added ${values.first_name}!`);
      }
    }
    setLoading(false);
  };

  const rolesLoaded = () => roles && roles.length;
  const userLoaded = () => (user_id ? !!staff?.id : true);

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addStaffSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => {
        return (
          <Form className="flex-1">
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField type="text" name="first_name" required />
                <FormInputField type="text" name="last_name" required />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField title="DOB" type="date" name="dob" required />
                <FormInputField
                  title="Gender"
                  type="select"
                  name="gender"
                  options={[
                    { name: "Male", value: "male" },
                    { name: "Female", value: "female" },
                  ]}
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  type="email"
                  name="email"
                  required
                  disabled={!!user_id}
                />
                <FormInputField
                  title="Phone #"
                  type="number"
                  name="phone"
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10 w-1/2 pr-5">
                <FormInputField
                  type="autocomplete"
                  name="location_id"
                  title="Location"
                  required
                  autocompleteProps={{
                    setFieldValue,
                    renderCustomLabel: (location) =>
                      `${location.id}, ${location.location_name}`,
                    valueKey: "id",
                    shouldItemRender: matchLocationToTerm,
                    loading: loadingLocationList,
                    items: locationSearchQuery?.length > 0 ? locations : [],
                    value: locationSearchQuery,
                    setValue: setLocationSearchQuery,
                  }}
                  inputProps={{
                    placeholder: "Search Location",
                  }}
                />
              </div>
              <FormInputField
                title="Role"
                type="radio"
                name="role_id"
                options={_.map(roles, (role) => ({
                  name: getRoleNames(role.name),
                  value: `${role.id}`,
                }))}
                required
              />
            </div>

            <div className="flex flex-row justify-between mt-10">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const headerData = {
    states: user_id
      ? [
          { title: "Staff List", link: "/physician/staff" },
          {
            title: `Staff #${user_id}`,
            link: `/physician/staff/view/${location_id}/${user_id}`,
          },
          { title: "Edit" },
        ]
      : [{ title: "Staff List", link: "/physician/staff" }, { title: `Add` }],
    title: pageTitle,
  };

  const errorData =
    staffError || roleError
      ? {
          show: true,
          subtitle: roleError
            ? "Unable to fetch roles"
            : "Unbable to fetch staff details",
          ctaOnClick: () => (roleError ? mutateRole() : mutateStaff()),
        }
      : null;

  const loadingData =
    staffError || roleError ? false : !(rolesLoaded() && userLoaded());

  return (
    <div className="w-full flex justify-center">
      <DashboardView
        headerData={headerData}
        content={content}
        loading={loadingData}
        errorData={errorData}
        className="w-full container p-10 bg-white"
      />
    </div>
  );
}
