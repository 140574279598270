import React from "react";
import AddPatient from "../Patient/AddPatient";
import ListPatient from "../Patient/ListPatient";
import ViewPatient from "../Patient/ViewPatient";

export default function Patient({ mode, id }) {
  if (mode === "add") {
    return <AddPatient />;
  } else if (mode === "edit") {
    return <AddPatient id={id} />;
  } else if (mode === "view") {
    return <ViewPatient id={id} />;
  }
  return <ListPatient />;
}
