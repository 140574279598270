import { useAPI } from "../../../network/SWR";

export function useGetAudit(
  { perPageCount = 10, currentPage = 1 },
  config = {}
) {
  const key = `/visits/audits?page=${currentPage}&per_page_count=${perPageCount}`;

  const { data, error, loading, mutate, isValidating } = useAPI(
    key,
    (config = {})
  );

  return {
    data: data?.audits || [],
    error,
    loading,
    mutate,
    isValidating,
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: perPageCount,
    },
  };
}
