import React from "react";
import Header from "../../stories/Header";
import Tabs from "../../stories/Tabs";
import Patient from "./Patients";
import Physicians from "./Physicians";
import Macro from "./Macro";
import Staff from "./Staff";
import { toKeyCase } from "../../service/Helper";
import { navigate } from "@reach/router";
import CaseType from "./CaseType";
import CPTCode from "./CPTCode";
import Client from "./Client";
import Audit from "./Audit";

const tabHeaders = [
  "Patients",
  "Diagnosis Macros",
  "Staff",
  "Case Types",
  "Referring Providers",
  "CPT Codes",
  "Grossing Macros",
  "Clients",
  "Audit",
];

const availableModes = toKeyCase(tabHeaders);

const getTabToOpen = (mode) => {
  if (!mode) return 0;
  const selectedMode = availableModes.indexOf(mode);
  if (selectedMode !== -1) return selectedMode;
  return 0;
};
export default function Dashboard(props) {
  const [mode, submode, id] = props["*"].split("/");
  const onTabSelect = (index, title) => {
    navigate(`/dashboard/${availableModes[index]}`);
  };
  return (
    <div className="absolute bg-theme-gray flex flex-col w-full h-full">
      <Header className="flex-shrink-0" />
      <div className="page-container p-10 flex-1" key={props.location.href}>
        <Tabs
          className="shadow-xs h-full"
          overrideTabSelection={true}
          defaultTab={getTabToOpen(mode)}
          tabContent={[
            <Patient mode={submode} id={id} />,
            <Macro mode={submode} id={id} type="diagnosis" />,
            <Staff mode={submode} id={id} />,
            <CaseType mode={submode} id={id} />,
            <Physicians mode={submode} id={id} />,
            <CPTCode mode={submode} id={id} />,
            <Macro mode={submode} id={id} type="grossing" />,
            <Client mode={submode} id={id} />,
            <Audit mode={submode} id={id} />,
          ]}
          tabs={tabHeaders}
          onTabSelect={onTabSelect}
        />
      </div>
    </div>
  );
}
