import React, { useState } from "react";
import Table from "../../stories/Table";
import { Link, navigate } from "@reach/router";
import { format } from "date-fns";
import { StatusTag } from "../../stories/StatusTag";
import { usePhysicianList } from "../../service/Fetchers";
import { getErrorData, getURLParams, handleQuery } from "../../service/Helper";
import { DashboardView } from "../../stories/DashboardView";

const perPageCount = 10;

const ListPhysician = () => {
  const currentPage = getURLParams("page") ?? 1;
  const searchQ = getURLParams("search") ?? "";

  const [searchQuery, setSearchQuery] = useState(searchQ || "");
  const [page, setPage] = useState(currentPage);

  const { users, pageInfo, error, loading, isValidating, mutate } =
    usePhysicianList({
      currentPage: page,
      perPageCount,
      searchQuery: searchQ,
      list: "all",
    });

  const loadingData = loading || isValidating;

  const headerData = {
    constructiveCTA: {
      link: "/dashboard/referring-providers/add",
      title: "Add Provider",
    },
    states: [
      {
        title: "Provider List",
      },
    ],
    title: "List of Providers",
    search: {
      show: true,
      searching: searchQuery.length && loadingData,
      placeholder: "Search using Provider ID or Name...",
      onSearch: (values) => {
        setPage(1);
        handleQuery(navigate, 1, values.query, "referring-providers");
        setSearchQuery(values.query);
      },
      onChange: (values) => {
        if (!values.query) {
          setSearchQuery("");
        }
      },
    },
  };

  const content = (
    <Table
      headers={[
        "Provider ID",
        // "Accession Prefix",
        "Provider Name",
        "Linked Client",
        "Date Added",
        "Status",
        "No of Cases",
      ]}
      pagination={{
        currentpage: pageInfo?.current_page,
        itemsPerPage: pageInfo?.per_page,
        totalItems: pageInfo?.total_count,
        onPageChange: (page) => {
          handleQuery(navigate, page, searchQ, "referring-providers");
        },
      }}
      loading={loadingData}
      rowContent={users.map((user) => [
        <p className="underline text-primary">
          <Link to={`/dashboard/referring-providers/view/${user.id}`}>
            {user.uuid}
          </Link>
        </p>,
        // <p>{user?.accession_prefix}</p>,
        <p>{`${user?.first_name} ${user?.last_name}`}</p>,
        <p>{user?.client?.name ?? "--"}</p>,
        <p>{format(new Date(user.created_at), "dd-MMM-yyyy")}</p>,
        <StatusTag type={user.deactivated ? "red" : "green"}>
          {user.deactivated ? "Deactivated" : "Active"}
        </StatusTag>,
        <p>{user?.visit_count}</p>,
      ])}
    />
  );

  const errorData = getErrorData({
    error,
    searchQuery,
    data: users,
    mutate,
    title: "Physician",
  });

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      errorData={errorData}
      loading={loadingData}
      loadingContent={null}
    />
  );
};

export default ListPhysician;
