import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { macroDescriptionSchema } from "../service/Validation";

const EditableDialog = ({
  description,
  fieldName,
  submitButton,
  onSubmit,
  className,
  initialValues,
  contentEditable = true,
}) => {
  const contentRef = useRef(null);
  const formikRef = useRef(null);

  useEffect(() => {
    formikRef.current.resetForm({
      [fieldName]: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    function inputHandler(state, input) {
      if (state.pattern.test(input.value)) {
        state.value = input.value;
      } else {
        input.value = state.value;
        input.setSelectionRange(state.start, state.end);
      }
    }

    function keydownHandler(state, input) {
      state.start = input.selectionStart;
      state.end = input.selectionEnd;
    }

    function initialValues(inputs, i) {
      let input = inputs[i];
      let state = {
        value: input.value,
        start: input.selectionStart,
        end: input.selectionEnd,
        pattern: RegExp("^" + input.dataset.filter + "$"),
      };
      return { input, state };
    }

    const inputs = document.querySelectorAll("input[data-filter]");
    for (var i = 0; i < inputs.length; i++) {
      let { input, state } = initialValues(inputs, i);

      input.addEventListener("input", () => inputHandler(state, input));

      input.addEventListener("keydown", () => keydownHandler(state, input));
    }

    return () => {
      for (var i = 0; i < inputs.length; i++) {
        let { input, state } = initialValues(inputs, i);

        input.removeEventListener("input", () => inputHandler(state, input));

        input.addEventListener("keydown", () => keydownHandler(state, input));
      }
    };
  }, []);

  const filterDescription = (description) => {
    const split = _.split(` ${description} `, "??");
    const joined = _.map(split, (s, index) => {
      let returnData = [s];
      if (index !== split.length - 1) {
        returnData.push(
          <Field
            type="text"
            data-filter="[a-zA-Z0-9 ]*"
            step="0.01"
            name={`${fieldName}.${index}`}
            className="border outline-none focus:shadow-outline-gray focus:outline-none w-16 text-center bg-gray-50 h-6 rounded leading-2 text-sm macro-gross-inputs"
          />
        );
      }
      return returnData;
    });
    return joined;
  };

  const filteredDescription = filterDescription(description);
  return (
    <div
      className={`flex flex-col space-y-2 border border-orange-300 bg-orange-50 rounded border-dashed ${className}`}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          [fieldName]: initialValues ? initialValues : [],
        }}
        validationSchema={macroDescriptionSchema(
          fieldName,
          filteredDescription.length - 1
        )}
        onSubmit={(data) => {
          let inputIndex = -1;
          const stitchedArray = _.map(contentRef.current.childNodes, (node) => {
            if (node.nodeName === "INPUT") {
              inputIndex += 1;
              return data[fieldName][inputIndex];
            } else {
              return node.data;
            }
          });
          const finalDescription = _.join(stitchedArray, " ");
          onSubmit && onSubmit(finalDescription, data);
        }}
      >
        {(props) => {
          return (
            <Form>
              <div
                className="flex-row flex-wrap leading-7 items-center inline-block"
                ref={contentRef}
                contentEditable={contentEditable}
              >
                {_.map(_.flatten(filteredDescription))}
              </div>
              <div className="flex flex-col mt-4 items-center">
                <ErrorMessage
                  name={`${fieldName}`}
                  component="span"
                  className="text-red-500 text-xs font-hairline mt-1"
                />
                {submitButton}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditableDialog.propTypes = {
  description: PropTypes.string,
  fieldName: PropTypes.string,
};

EditableDialog.defaultProps = { description: "", fieldName: "field" };

export default EditableDialog;
