import React, { createContext, useMemo, useState } from "react";

const AssigneeContex = createContext();

function AssigneeProvider({ children }) {
  const [assingedID, setAssignedID] = useState(null);
  const value = useMemo(
    () => ({
      assingedID,
      setAssignedID,
    }),
    [assingedID, setAssignedID]
  );

  return (
    <AssigneeContex.Provider value={value}>{children}</AssigneeContex.Provider>
  );
}

export { AssigneeContex, AssigneeProvider };
