import { useContext } from "react";
import { GlobalVariablesContext } from "../providers/GlobalProvider";

export default function CustomModal({
  type = "patientInfo",
  customTitle = "Select Macro",
  customClasses = "",
  children,
}) {
  const { setShowPatientInfo, setShowMacroViewer } = useContext(
    GlobalVariablesContext
  );

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-70"
      style={{
        zIndex: 99,
      }}
      onClick={() => {
        if (type === "macro") {
          setShowMacroViewer(false);
        } else {
          setShowPatientInfo(false);
        }
      }}
    >
      <div
        className={`${customClasses ? customClasses + " " : ""}max-w-3xl w-2/3`}
        onClick={(event) => event.stopPropagation()}
      >
        <div className="p-2 w-full flex justify-between items-center bg-white border-b border-case-border-gray">
          <p className="font-bold">
            {type === "macro" ? customTitle : "Patient Information"}
          </p>
          <svg
            onClick={() => {
              if (type === "macro") {
                setShowMacroViewer(false);
              } else {
                setShowPatientInfo(false);
              }
            }}
            className="cursor-pointer"
            height="24"
            viewBox="0 0 48 48"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#2c3e50"
              d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"
            />
            <path d="M0 0h48v48h-48z" fill="none" />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
}
