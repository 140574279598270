import React, { useContext, Suspense } from "react";
import { HotKeys } from "react-hotkeys";
import Routes from "./main/Routes";
import GlobalVariableProvider, {
  GlobalVariablesContext,
} from "./providers/GlobalProvider";
import { CurrentUser } from "./service/Auth";
import { DiagnosisFormProvider } from "./providers/DiagnosisFormProvider";
import { AssigneeProvider } from "./providers/TrackAssigneeProvider";
import { Loader } from "./service/Icon";

function AppWithProviderContext() {
  const keyMap = {
    SHOW_DIALOG: "shift+k",
    HIDE_DIALOG: "esc",
  };

  const { setShowAddPackageModal, setOpenPatientHistory, setPdfViewer } =
    useContext(GlobalVariablesContext);

  const handlers = {
    SHOW_DIALOG: () =>
      CurrentUser.isAccessionist() && setShowAddPackageModal(true),
    HIDE_DIALOG: () => {
      if (CurrentUser.isAccessionist()) {
        setOpenPatientHistory(false);
        setShowAddPackageModal(false);
      }
      setPdfViewer(false);
    },
  };
  return (
    <Suspense
      fallback={
        <div className="h-screen flex w-full justify-center items-center">
          loading <Loader />
        </div>
      }
    >
      <HotKeys handlers={handlers} keyMap={keyMap}>
        <Routes />
      </HotKeys>
    </Suspense>
  );
}

function App() {
  return (
    <AssigneeProvider>
      <DiagnosisFormProvider>
        <GlobalVariableProvider>
          <AppWithProviderContext />
        </GlobalVariableProvider>
      </DiagnosisFormProvider>
    </AssigneeProvider>
  );
}

export default App;
