/* eslint-disable no-lone-blocks */
import _ from "lodash";
import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { format, parse } from "date-fns";
import { Form, Formik } from "formik";

import Table from "../../stories/Table";
import { FormDataField } from "../../stories/FormDataField";
import { Download, Loader, Print, Send } from "../../service/Icon";
import {
  useCaseTypeList,
  useCPTCodeList,
  useICDCodesList,
  useMacroList,
  usePatient,
  useVisit,
} from "../../service/Fetchers";
import { CurrentUser } from "../../service/Auth";
import SearchDiagnosisMacro from "./DiagnosisMacroPending";
import MacroList from "./GrossingMacroPending";
import { ErrorView } from "../../stories/ErrorView";
import StatusText from "./StatusText";
import { Button } from "../../stories/Button";
import CONSTANTS from "../../helpers/constants";
import PDFViewer from "../../components/PDFViewer";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import SlideViewer from "./SlideViewer";
import CustomModal from "../../stories/CustomModal";
import PDFPreviewer from "../../components/PDFPreviewer";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import { addDiagnosisMacroSchema } from "../../service/Validation";
import { FormInputField } from "../../stories/FormInputField";

const getDisplayValue = (value) =>
  _.isEmpty(value) ? (
    <span className="bg-cool-gray-300 text-white rounded-sm mt-2 p-1 text-xs">
      NA
    </span>
  ) : (
    <span className="text-xs" style={{ overflowWrap: "break-word" }}>
      {value}
    </span>
  );

function CourierInformation({ courier_info }) {
  return (
    <div className="flex flex-row ">
      <FormDataField
        title={`${courier_info?.name} Tracking #`}
        value={getDisplayValue(courier_info?.tracking_num)}
      />
    </div>
  );
}

function GrossingDetails({ grossing_macro, grossed_at, grossing_description }) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row space-x-5 mb-2">
        <FormDataField
          title="Grossing Macro"
          value={
            typeof grossing_macro === "string"
              ? grossing_macro
              : getDisplayValue(grossing_macro?.name)
          }
          smVariant
        />
        <FormDataField
          title="Grossing Date"
          smVariant
          value={getDisplayValue(
            _.isEmpty(grossed_at)
              ? ""
              : format(new Date(grossed_at), "MM-dd-yyyy")
          )}
        />
      </div>
      <FormDataField
        smVariant
        title="Gross Description"
        value={getDisplayValue(grossing_description)}
      />
    </div>
  );
}

function DiagnosisDetails({
  diagnosis_notes,
  diagnosis_macro,
  diagnosis_date,
  diagnoser,
  rightButtons,
}) {
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-3 gap-6 mb-3">
        <FormDataField
          title="Diagnosis"
          value={getDisplayValue(diagnosis_macro?.name)}
          smVariant
        />
        <FormDataField
          title="Diagnosis Code"
          value={getDisplayValue(diagnosis_macro?.code)}
          smVariant
        />
        <FormDataField
          title="Sign Out Date"
          smVariant
          value={getDisplayValue(
            _.isEmpty(diagnosis_date)
              ? ""
              : format(new Date(diagnosis_date), "MM-dd-yyyy")
          )}
        />
      </div>
      <div className="mb-3">
        <FormDataField
          smVariant
          title="Microscopic Description"
          value={getDisplayValue(diagnosis_notes)}
        />
      </div>
    </div>
  );
}

function ClinicalInformation({ clinical_info }) {
  return (
    <div className="flex flex-row flex-wrap pb-1 border-b border-case-border-gray space-y-1">
      <FormDataField
        title="Case Type"
        value={getDisplayValue(clinical_info?.visit_type?.name)}
        className="w-1/3"
        smVariant
      />
      <FormDataField
        className="w-1/3"
        title="Referring Physician"
        value={
          <span>
            {clinical_info?.primary_physician?.first_name
              ? `${clinical_info?.primary_physician?.first_name} ${clinical_info?.primary_physician?.last_name}`
              : "NA"}
          </span>
        }
        smVariant
      />
      {/* <FormDataField
        title="Accession Prefix"
        value={getDisplayValue(
          clinical_info?.primary_physician?.accession_prefix
        )}
        className="w-1/3"
        smVariant
      />
      <FormDataField
        title="Referring Physician Location"
        value={getDisplayValue(
          clinical_info?.primary_physician?.address?.location_name
        )}
        className="w-2/3"
        smVariant
      /> */}
    </div>
  );
}

function BasicSpecimenDemographics({ basic_speciman_demo }) {
  return (
    <div className="flex flex-col py-2 border-b border-case-border-gray">
      <div className="flex flex-row justify-between pr-5 mb-2 ">
        <FormDataField
          title="Date Collected"
          smVariant
          value={
            <span className="font-bold">
              {getDisplayValue(
                _.isEmpty(basic_speciman_demo?.collected_date)
                  ? ""
                  : format(
                      new Date(basic_speciman_demo?.collected_date),
                      "MM-dd-yyyy"
                    )
              )}
            </span>
          }
        />
        <FormDataField
          title="Date Received"
          smVariant
          value={
            <span className="font-bold">
              {getDisplayValue(
                _.isEmpty(basic_speciman_demo?.received_date)
                  ? ""
                  : format(
                      new Date(basic_speciman_demo?.received_date),
                      "MM-dd-yyyy"
                    )
              )}
            </span>
          }
        />
        <FormDataField
          title="Procedure"
          smVariant
          value={getDisplayValue(basic_speciman_demo?.procedure?.name)}
        />
        <FormDataField
          title="Site"
          smVariant
          value={getDisplayValue(basic_speciman_demo?.site?.name)}
        />
      </div>
      <FormDataField
        title="Clinical Notes"
        smVariant
        value={getDisplayValue(basic_speciman_demo?.clinical_notes)}
      />
    </div>
  );
}

function PatientInformation({ patient, currentCaseID }) {
  const {
    patient: patientDetails,
    loading,
    error,
    mutate,
    isValidating,
  } = usePatient({
    patient_id: patient?.id,
  });
  const patient_history = (patientDetails ?? []).patient_history?.visits.filter(
    (v) => v.ucid !== currentCaseID
  );
  if (loading || isValidating)
    return (
      <div className="flex justify-center items-center">
        <Loader />
      </div>
    );
  if (error) {
    return (
      <ErrorView
        title="Failed to load patient details"
        subtitle="Click on reload to try loading patient details again"
        ctaOnClick={mutate}
        ctaTitle="Reload"
      ></ErrorView>
    );
  }
  return (
    <div className="flex flex-col border-b pb-2 border-case-border-gray">
      <div className="flex flex-row justify-between mb-2">
        <FormDataField
          title="Name"
          smVariant
          value={
            <span>
              {patient?.first_name} {patient?.last_name}
            </span>
          }
        />
        <FormDataField
          title="Patient ID"
          value={<span>{getDisplayValue(patient?.uuid)}</span>}
          smVariant
        />
        <FormDataField
          className="w-1/3"
          title="Patient Notes"
          value={getDisplayValue(patient?.insurance_details?.patient_notes)}
          smVariant
        />
      </div>
      {patient_history.length > 0 && (
        <FormDataField
          title="Patient History"
          value={
            <Table
              headers={["Case ID", "Date Added", "Status", "Referred by"]}
              pagination={null}
              theadClass="h-5"
              tbodyClass={"h-7"}
              loading={loading}
              rowContent={patient_history
                ?.filter((p, i) => i === patient_history?.length - 1)
                .map((visit) => [
                  <a
                    className="text-primary underline text-xs"
                    href={`/case-view/${visit.id}`}
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    rel="noreferrer"
                  >
                    {visit.ucid}
                  </a>,
                  <p className="text-xs">
                    {format(new Date(visit.created_at), "MM-dd-yyyy")}
                  </p>,
                  <p className="text-orange-500 font-bold text-xs">
                    <StatusText outline={false} status={visit.status} />
                  </p>,
                  <p className="text-xs">
                    {visit.clinical_info.primary_physician.first_name}{" "}
                    {visit.clinical_info.primary_physician.last_name}
                  </p>,
                ])}
            />
          }
          smVariant
        />
      )}
      {/* <div className="insurance-container pt-2 pb-1 border-b border-case-border-gray">
        <Heading>Insurance Details</Heading>
        <div className="flex flex-row">
          <FormDataField
            title="Provider"
            value={getDisplayValue(patient?.insurance_details?.provider.name)}
            className="mr-20"
            smVariant
          />
          <FormDataField
            title="Policy #"
            value={getDisplayValue(patient?.insurance_details?.policy)}
            smVariant
          />
        </div>
      </div> */}
    </div>
  );
}

function InsuranceDetails({ patient, currentCaseID }) {
  const { loading, error, mutate, isValidating } = usePatient({
    patient_id: patient?.id,
  });
  if (loading || isValidating)
    return (
      <div className="flex justify-center items-center">
        <Loader />
      </div>
    );
  if (error) {
    return (
      <ErrorView
        title="Failed to load patient details"
        subtitle="Click on reload to try loading patient details again"
        ctaOnClick={mutate}
        ctaTitle="Reload"
      ></ErrorView>
    );
  }
  return (
    <div className="flex flex-col ">
      <div className="insurance-container pt-2 pb-1 border-b border-case-border-gray">
        <Heading>Insurance Details</Heading>
        <div className="flex flex-row">
          <FormDataField
            title="Provider"
            value={getDisplayValue(patient?.insurance_details?.provider.name)}
            className="mr-20"
            smVariant
          />
          <FormDataField
            title="Policy #"
            value={getDisplayValue(patient?.insurance_details?.policy)}
            smVariant
          />
        </div>
      </div>
    </div>
  );
}

const CaseDetailView = ({
  visitDetails,
  rightButtons,
  setMacroEdited,
  visitsLoading,
}) => {
  const {
    ucid,
    status,
    clinical_info,
    courier_info,
    patient,
    specimen_demographics,
    grossings,
  } = visitDetails;
  const [loading, setLoading] = useState(false);
  const [editGrossing, setEditGrossing] = useState(false);
  const shouldShowGrossing = visitDetails.status !== "accessioning_complete";
  const {
    pdfViewer,
    setPdfViewer,
    showSlideViewer,
    setShowSlideViewer,
    showPatientInfo,
    showDiagnosisPreview,
    setShowDiagnosisPreview,
    setShowDiagnosisPreviewBtn,
    showMacroViewer,
    setShowMacroViewer,
    selectedSpecimenTab,
  } = useContext(GlobalVariablesContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [macroSearchQuery, setMacroSearchQuery] = useState("");
  const {
    macros,
    mutate: fetchMacros,
    loading: fetchingMacros,
  } = useMacroList({
    type: "diagnosis",
    searchQuery: macroSearchQuery,
  });

  const {
    mutate: updateVisit,
    loading: updatingVisit,
    visit,
  } = useVisit({
    id: visitDetails?.id,
  });

  const [showAddMacro, setShowAddMacro] = useState(false);
  const [selectedMacro, setSelectedMacro] = useState(null);
  const [selectedDiagnosisMacro, setSelectedDiagnosisMacro] = useState(null);
  const [cptSearchQuery, setCPTSearchQuery] = useState("");
  const [icdSearchQuery, setICDSearchQuery] = useState("");
  const [caseTypeSearchQuery, setCaseTypeSearchQuery] = useState("");
  const [slideFetchError, setSlideFetchError] = useState(false);
  const [slidesFetching, setSlidesFetching] = useState(false);

  const buttonRef = useRef(null);
  const cpt_ref = useRef(null);
  const icd_ref = useRef(null);

  const { cptCodes, loading: loadingCPTList } = useCPTCodeList({
    searchQuery: cptSearchQuery,
  });

  const { icds, loading: loadingICDList } = useICDCodesList({
    searchQuery: icdSearchQuery,
  });

  const { caseTypes, loading: loadingCaseTypeList } = useCaseTypeList({
    searchQuery: caseTypeSearchQuery,
  });

  const [tileSources, setTileSources] = useState([]);
  const intervalRefs = useRef(null);

  const role = JSON.parse(localStorage.getItem("current_user_details"))?.user
    ?.role?.name;

  const fetchTileSources = useCallback(async () => {
    const slide = visitDetails?.slide_images[selectedSpecimenTab];
    setSlidesFetching(true);
    try {
      const fetchData = async () => {
        setSlidesFetching(true);
        const signedUrlData = await axios.get(
          `/presigned_url/slide-url?dzi_filename=${slide?.tile_source}&visit_id=${slide?.visit_id}`
        );

        if (signedUrlData?.data?.url) {
          setTileSources(() => [signedUrlData.data.url]);
          setSlidesFetching(false);
          setSlideFetchError(false);
        } else {
          const isSlideAvailable = async () => {
            const slideAvailableData = await axios.get(
              `/presigned_url/slide-available?dzi_filename=${slide?.tile_source}`
            );

            if (slideAvailableData.data.success) {
              const signedUrlSecondData = await axios.get(
                `/presigned_url/slide-url?dzi_filename=${slide?.tile_source}&visit_id=${slide?.visit_id}`
              );

              if (intervalRefs.current) {
                clearInterval(intervalRefs.current);
                intervalRefs.current = null;
                setSlideFetchError(false);
              }
              setTileSources((prev) => [signedUrlSecondData?.data?.url]);
              setSlidesFetching(false);
              setSlideFetchError(false);
            }
          };
          await isSlideAvailable();
          {
            /* Since it's array we need to create an array of interval refs. 
                In this way the clearInterval can able to clear individual ref intervals. */
          }

          intervalRefs.current = setInterval(isSlideAvailable, 5000);
        }
      };

      // Call fetchData initially
      await fetchData();

      setTimeout(() => {
        setSlidesFetching(false);
        setSlideFetchError(false);
        clearInterval(intervalRefs.current);
        intervalRefs.current = null;
      }, 10000);
    } catch (err) {
      // toast.error("500 internal server error");
      setSlideFetchError(true);
      setSlidesFetching(false);
    }
  }, [visitDetails?.slide_images, selectedSpecimenTab]);

  const SlideViewerCallBack = useCallback(
    () => (
      <SlideViewer
        customClasses="w-3/4 h-full"
        tileSources={tileSources}
        visitDetails={visitDetails}
      />
    ),
    [tileSources]
  );
  const PDFViewerCallback = useCallback(
    () => <PDFPreviewer visitDetails={visit} customClasses="w-3/4 h-full" />,
    [visit, updatingVisit]
  );

  useEffect(() => {
    if (visitDetails?.slide_images?.length > 0 && role === "pathologist") {
      fetchTileSources();
    }
    // Clear individual interval
    return () => {
      // intervalRefs.current.map(clearInterval);
      // intervalRefs.current = [];
      clearInterval(intervalRefs.current);
      intervalRefs.current = null;
    };
  }, [fetchTileSources, visitDetails, selectedSpecimenTab]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setMacroSearchQuery(searchQuery);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useEffect(() => {
    if (CurrentUser.isPathologist() && status === "grossed") {
      setShowDiagnosisPreviewBtn(true);
    } else {
      setShowDiagnosisPreviewBtn(false);
    }
  }, [setShowDiagnosisPreviewBtn, status]);

  useEffect(() => {
    if (macros?.length > 0) {
      setSelectedMacro(macros[0]);
    } else {
      setSelectedMacro(null);
    }
  }, [macros]);

  useEffect(() => {
    if (showMacroViewer) {
      fetchMacros();
    }
  }, [fetchMacros, showMacroViewer]);

  useEffect(() => {
    if (visitDetails?.status === "diagnosed") {
      setShowDiagnosisPreview(false);
      setShowSlideViewer(true);
    }
  }, [setShowDiagnosisPreview, setShowSlideViewer, visitDetails]);

  const DiagnosisContent = () => {
    // console.log("hello", {
    //   slideFetchError,
    //   slidesFetching,
    // });
    if (showDiagnosisPreview) {
      return <PDFViewerCallback />;
    }

    if (slidesFetching) {
      return (
        <div className="w-3/4 h-full flex justify-center items-center flex-col gap-4">
          Fetching...
        </div>
      );
    }

    if (slideFetchError) {
      return (
        <div className="w-3/4 h-full flex justify-center items-center flex-col gap-4">
          <p>Cannot fetch Slides from the server. Please re-try again.</p>
          <button
            onClick={fetchTileSources}
            className="px-6 py-1.5 bg-red-400 text-white rounded-sm hover:bg-red-500 transition-colors ease-in-out duration-75"
          >
            Retry
          </button>
        </div>
      );
    }

    if (
      !CurrentUser.isPathologist() ||
      !showSlideViewer ||
      tileSources?.length === 0
    ) {
      return (
        <div className="w-3/4 h-full flex justify-center items-center flex-col gap-4">
          No Slides Available
        </div>
      );
    }

    return <SlideViewerCallBack />;
  };

  return (
    <div className="flex-1 flex flex-col">
      <div
        className={`${
          CurrentUser?.isPathologist() ? "hidden" : "flex"
        } flex-row space-x-4`}
      >
        <div
          className={`flex flex-1 bg-white`}
          style={{
            width: CurrentUser.isPhysicianOrCLinicAdmin() ? "35vw" : "100%",
          }}
        >
          <div
            className="detail-container p-2 pb-20 w-full"
            style={{
              height: "calc(100vh - 200px)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="flex flex-row items-center justify-between w-full">
              <span className="text-xl mr-6 text-primary font-bold">
                {ucid}
              </span>
              <StatusText status={status} outline={true} />
            </div>
            <Heading>Patient Information</Heading>
            <PatientInformation
              patient={patient}
              currentCaseID={visitDetails.ucid}
            />
            <Heading className={"pt-1"}>Basic Specimen Demographics</Heading>
            {specimen_demographics?.map((specimen) => (
              <BasicSpecimenDemographics basic_speciman_demo={specimen} />
            ))}
            <Heading className={"pt-1"}>Clinical Information</Heading>
            <ClinicalInformation clinical_info={clinical_info} />
            {((shouldShowGrossing && visitDetails?.status !== "grossed") ||
              !CurrentUser.isGrosser()) &&
              status !== "accessioning_complete" && (
                <div className="pb-1 border-b border-case-border-gray">
                  <Heading className="mb-1 mt-2">Gross Description</Heading>
                  {grossings?.length > 0 ? (
                    grossings?.map((grossing, index) => (
                      <>
                        {index > 0 && (
                          <div className="my-2 h-px bg-case-border-gray" />
                        )}
                        <GrossingDetails
                          grossed_at={grossing?.grossed_at}
                          grosser={grossing?.grosser}
                          grossing_macro={grossing?.name}
                          grossing_description={grossing?.description}
                        />
                      </>
                    ))
                  ) : (
                    <GrossingDetails
                      grossed_at={visitDetails?.grossed_at}
                      grosser={visitDetails?.grosser}
                      grossing_macro={visitDetails?.grossing_macro}
                      grossing_description={visitDetails?.grossing_description}
                    />
                  )}
                </div>
              )}
            <InsuranceDetails
              patient={patient}
              currentCaseID={visitDetails.ucid}
            />
            {status === "diagnosed" && (
              <>
                <Heading className="mb-1 mt-2">Diagnosis Information</Heading>
                {visitDetails?.diagnoses?.map((diagnose, index) => (
                  <>
                    {index > 0 && (
                      <div className="mb-3 h-px bg-case-border-gray" />
                    )}
                    <DiagnosisDetails
                      diagnosis_notes={diagnose.description}
                      diagnosis_macro={{
                        name: diagnose.title,
                        code: diagnose.code,
                      }}
                      diagnosis_date={diagnose.diagnosis_date}
                      diagnoser={visitDetails.diagnoser}
                      rightButtons={rightButtons}
                    />
                  </>
                ))}
                {/* {visitDetails.addendums.map((addendum, idx) => (
                  <div className="mb-2">
                    <FormDataField
                      smVariant
                      title={`Addendum ${idx + 1}`}
                      value={getDisplayValue(addendum.description)}
                      className="flex-1"
                    />
                  </div>
                ))} */}
              </>
            )}
            <Heading className="pt-2 pb-1 border-t border-case-border-gray mb-1">
              Courier Info
            </Heading>
            <CourierInformation courier_info={courier_info} />
          </div>
        </div>
        <div
          className={`${
            CurrentUser.isAdmin()
              ? "hidden"
              : CurrentUser.isGrosser() && visitDetails.status === "diagnosed"
              ? "hidden"
              : "flex"
          } flex-1 bg-white flex-col ${
            CurrentUser.isPathologist() ? "px-2 pb-2" : "p-2"
          }`}
          style={{
            height: "calc(100vh - 200px)",
            overflowY: "scroll",
            overflowX: "hidden",
            width: CurrentUser.isPhysicianOrCLinicAdmin() ? "35vw" : "100%",
          }}
        >
          {CurrentUser.isGrosser() &&
            shouldShowGrossing &&
            visitDetails?.status === "grossed" && (
              <>
                <div className="flex flex-row justify-between items-center py-4">
                  <Heading className="mb-1 mt-2 min-w-max-content">
                    Gross Description
                  </Heading>
                  <div className="w-full flex items-center -mt-5">
                    <Button
                      className={
                        "flex w-16 justify-center items-center h-8 mt-5 text-xs md:text-sm ml-auto px-5"
                      }
                      style={{ fontSize: 14 }}
                      onClick={() => setEditGrossing(!editGrossing)}
                      disabled={
                        format(
                          new Date(visitDetails?.grossed_at),
                          "MM-dd-yyyy"
                        ) !== format(new Date(), "MM-dd-yyyy")
                      }
                    >
                      {editGrossing ? "Back" : "Edit"}
                    </Button>
                  </div>
                </div>
                {visitsLoading ? (
                  <div className="flex justify-center items-center">
                    <Loader />
                  </div>
                ) : !editGrossing ? (
                  grossings.length > 0 ? (
                    grossings?.map((grossing, index) => (
                      <>
                        {index > 0 && (
                          <div className="my-2 h-px bg-case-border-gray" />
                        )}
                        <GrossingDetails
                          grossed_at={grossing?.grossed_at}
                          grosser={grossing?.grosser}
                          grossing_macro={grossing?.name}
                          grossing_description={grossing?.description}
                        />
                      </>
                    ))
                  ) : (
                    <div />
                  )
                ) : (
                  <>
                    <MacroList
                      contentEditable={true}
                      visitDetails={visitDetails}
                      setMacroEdited={setMacroEdited}
                      initialValues={visitDetails?.grossing_tokens}
                      setEditGrossing={setEditGrossing}
                      macroInfo={{
                        id: visitDetails?.grossing_macro?.id,
                        templateDescription:
                          visitDetails?.grossing_macro?.description,
                        name: visitDetails?.grossing_macro?.name,
                        description: "",
                      }}
                    />
                  </>
                )}
              </>
            )}
          {(CurrentUser.isPathologist() || CurrentUser.isTranscriptionist()) &&
            status === "grossed" && (
              <div
                className="flex flex-col items-center pt-4 overflow-y-scroll"
                style={{
                  height: "calc(100vh - 180px)",
                }}
              >
                <SearchDiagnosisMacro
                  visitDetails={visitDetails}
                  selectedDiagnosisMacro={selectedDiagnosisMacro}
                  setSelectedDiagnosisMacro={setSelectedDiagnosisMacro}
                  updateVisit={updateVisit}
                  updatingVisit={updatingVisit}
                  visit={visit}
                />
              </div>
            )}

          {CurrentUser.isGrosser() && status === "accessioning_complete" && (
            <div className="pt-4">
              <MacroList visitDetails={visitDetails} contentEditable={false} />
            </div>
          )}
          {CurrentUser.isPathologist() && (
            <>
              <div className="mb-4 flex justify-between sticky top-0 py-2 bg-white z-10">
                <Button
                  type="icon"
                  title="Download"
                  onClick={() =>
                    window.open(
                      `${CONSTANTS.BASE_API_URL}/visits/${
                        visitDetails?.id
                      }/reports/download?Access-Token=${localStorage.getItem(
                        "user_auth_token"
                      )}`,
                      "_blank"
                    )
                  }
                >
                  <Download />
                </Button>

                <Button
                  type="icon"
                  title="Print"
                  onClick={() => setPdfViewer(true)}
                >
                  <Print />
                </Button>

                <Button
                  type="icon"
                  title="Send"
                  onClick={() => setPdfViewer(true)}
                >
                  <Send />
                </Button>
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: visitDetails?.pdf_report }}
              />

              <PDFViewer
                open={pdfViewer}
                setOpen={setPdfViewer}
                url={`${CONSTANTS.BASE_API_URL}/visits/${
                  visitDetails?.id
                }/reports?Access-Token=${localStorage.getItem(
                  "user_auth_token"
                )}`}
              />
            </>
          )}
        </div>
      </div>
      <div
        className={`${
          CurrentUser?.isPathologist() ? "flex-1 flex space-x-4" : "hidden"
        }`}
      >
        {DiagnosisContent()}
        <div className="w-2/4 h-full">
          {(CurrentUser.isPathologist() || CurrentUser.isTranscriptionist()) &&
            (status === "grossed" ||
              status === "accessioning_complete" ||
              status === "diagnosed_and_pending_addendum" ||
              status === "diagnosed_and_pending_amendment" ||
              status === "diagnosed") && (
              <div
                className="flex flex-col items-center pt-4 overflow-y-scroll"
                style={{
                  height: "calc(100vh - 80px)",
                }}
              >
                <SearchDiagnosisMacro
                  visitDetails={visitDetails}
                  selectedDiagnosisMacro={selectedDiagnosisMacro}
                  setSelectedDiagnosisMacro={setSelectedDiagnosisMacro}
                  updateVisit={updateVisit}
                  updatingVisit={updatingVisit}
                  visit={visit}
                  status={status}
                />
              </div>
            )}
          {/* {CurrentUser.isPathologist() && status === "diagnosed" && (
            <>
              <div className="mb-4 px-1 flex justify-end space-x-1 sticky top-0 py-2 bg-primary bg-opacity-25 rounded-t-md z-10">
                <button
                  type="button"
                  title="Download"
                  className="ripple-bg-white text-primary rounded-full p-2"
                  onClick={() =>
                    window.open(
                      `${CONSTANTS.BASE_API_URL}/visits/${
                        visitDetails?.id
                      }/reports/download?Access-Token=${localStorage.getItem(
                        "user_auth_token"
                      )}`,
                      "_blank"
                    )
                  }
                >
                  <Download className="w-3 h-3" />
                </button>

                <button
                  type="button"
                  title="Print"
                  className="ripple-bg-white text-primary rounded-full p-2"
                  onClick={() => setPdfViewer(true)}
                >
                  <Print className="w-3 h-3" />
                </button>

                <button
                  type="button"
                  title="Send"
                  className="ripple-bg-white text-primary rounded-full p-2"
                  onClick={() => setPdfViewer(true)}
                >
                  <Send className="w-3 h-3" />
                </button>
              </div>

              <PDFViewer
                open={pdfViewer}
                setOpen={setPdfViewer}
                url={`${CONSTANTS.BASE_API_URL}/visits/${
                  visitDetails?.id
                }/reports?Access-Token=${localStorage.getItem(
                  "user_auth_token"
                )}`}
              />

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={`${CONSTANTS.BASE_API_URL}/visits/${
                    visitDetails?.id
                  }/reports?Access-Token=${localStorage.getItem(
                    "user_auth_token"
                  )}`}
                />
              </Worker>
            </>
          )} */}
        </div>
      </div>
      {CurrentUser.isPathologist() && showPatientInfo ? (
        <CustomModal>
          <div className="flex flex-row space-x-4">
            <div
              className="flex flex-1 bg-white"
              style={{
                width: CurrentUser.isPhysicianOrCLinicAdmin() ? "35vw" : "100%",
              }}
            >
              <div
                className="detail-container p-2 pb-20 w-full"
                style={{
                  height: "calc(100vh - 200px)",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="flex flex-row items-center justify-between w-full">
                  <span className="text-xl mr-6 text-primary font-bold">
                    {ucid}
                  </span>
                  <StatusText status={status} outline={true} />
                </div>
                <Heading>Patient Information</Heading>
                <PatientInformation
                  patient={patient}
                  currentCaseID={visitDetails.ucid}
                />
                <Heading className={"pt-1"}>
                  Basic Specimen Demographics
                </Heading>
                {specimen_demographics?.map((specimen) => (
                  <BasicSpecimenDemographics basic_speciman_demo={specimen} />
                ))}
                <Heading className={"pt-1"}>Clinical Information</Heading>
                <ClinicalInformation clinical_info={clinical_info} />
                {((shouldShowGrossing && visitDetails?.status !== "grossed") ||
                  !CurrentUser.isGrosser()) &&
                  status !== "accessioning_complete" && (
                    <div className="pb-1 border-b border-case-border-gray">
                      <Heading className="mb-1 mt-2">Gross Description</Heading>
                      {grossings.length > 0 ? (
                        grossings?.map((grossing, index) => (
                          <>
                            {index > 0 && (
                              <div className="my-2 h-px bg-case-border-gray" />
                            )}
                            <GrossingDetails
                              grossed_at={grossing?.grossed_at}
                              grosser={grossing?.grosser}
                              grossing_macro={grossing?.name}
                              grossing_description={grossing?.description}
                            />
                          </>
                        ))
                      ) : (
                        <GrossingDetails
                          grossed_at={visitDetails?.grossed_at}
                          grosser={visitDetails?.grosser}
                          grossing_macro={visitDetails?.grossing_macro}
                          grossing_description={
                            visitDetails?.grossing_description
                          }
                        />
                      )}
                    </div>
                  )}
                <InsuranceDetails
                  patient={patient}
                  currentCaseID={visitDetails.ucid}
                />
                {status === "diagnosed" && (
                  <>
                    <Heading className="mb-1 mt-2">
                      Diagnosis Information
                    </Heading>
                    {visitDetails?.diagnoses?.map((diagnose, index) => (
                      <>
                        {index > 0 && (
                          <div className="mb-3 h-px bg-case-border-gray" />
                        )}
                        <DiagnosisDetails
                          diagnosis_notes={diagnose.description}
                          diagnosis_macro={{
                            name: diagnose.title,
                            code: diagnose.code,
                          }}
                          diagnosis_date={diagnose.diagnosis_date}
                          diagnoser={visitDetails.diagnoser}
                          rightButtons={rightButtons}
                        />
                      </>
                    ))}
                    {/* {visitDetails.addendums.map((addendum, idx) => (
                      <div className="mb-2">
                        <FormDataField
                          smVariant
                          title={`Addendum ${idx + 1}`}
                          value={getDisplayValue(addendum.description)}
                          className="flex-1"
                        />
                      </div>
                    ))} */}
                  </>
                )}
                <Heading className="pt-2 pb-1 border-t border-case-border-gray mb-1">
                  Courier Info
                </Heading>
                <CourierInformation courier_info={courier_info} />
              </div>
            </div>
          </div>
        </CustomModal>
      ) : (
        <div />
      )}
      {CurrentUser.isPathologist() && showMacroViewer ? (
        <CustomModal
          type="macro"
          customClasses="h-2/3"
          customTitle={showAddMacro ? "Create New Macro" : "Select Macro"}
        >
          {showAddMacro ? (
            <>
              <div className="p-2 bg-white flex justify-between items-center">
                <button
                  className="border-none underline text-primary bg-none"
                  onClick={() => setShowAddMacro(false)}
                >
                  Go back to Macro List
                </button>
                <div />
              </div>
              <div className="px-2 py-4 h-full bg-white flex items-center">
                <Formik
                  initialValues={{
                    name: "",
                    code: "",
                    description: "",

                    icd_ids: [],
                    cpt_ids: [],
                    visit_type_id: "",
                  }}
                  validationSchema={addDiagnosisMacroSchema}
                  onSubmit={async (values, actions) => {
                    setLoading(true);
                    const { data, error } = await API.post(
                      `/macros?macro_type=diagnosis`,
                      { macro: values }
                    );
                    if (error) {
                      setLoading(false);
                      buttonRef.current.shake();
                      toast.error(error?.message ?? "Network error");
                    }
                    if (data) {
                      setLoading(false);
                      actions.resetForm({
                        values: {
                          name: "",
                          code: "",
                          description: "",
                          icd_ids: [],
                          cpt_ids: [],
                          visit_type_id: "",
                        },
                      });
                      toast.success(`Added ${values.name}!`);
                      setCaseTypeSearchQuery("");
                      icd_ref.current.select.clearValue();
                      cpt_ref.current.select.clearValue();
                    }
                  }}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form className="flex-1">
                        <div className="flex flex-col">
                          <div className="flex flex-row space-x-10">
                            <FormInputField type="text" name="name" required />
                            <FormInputField
                              type="text"
                              name="code"
                              required
                              inputProps={{ className: "uppercase" }}
                            />
                          </div>
                          <FormInputField
                            type="textarea"
                            name="description"
                            required
                          />
                          <div className="flex flex-row space-x-10">
                            <FormInputField
                              className="w-1/3"
                              type="custom-select"
                              name="cpt_ids"
                              title="CPT CODE"
                              required
                              options={cptCodes}
                              selectProps={{
                                styles: {
                                  multiValueLabel: (style) => ({
                                    ...style,
                                    maxWidth: 90,
                                  }),
                                },
                                placeholder: "Search CPT Code",
                                ref: cpt_ref,
                                isMulti: true,
                                getOptionLabel: (option) =>
                                  `${option.code} ${option.description}`,
                                getOptionValue: (option) => `${option.id}`,
                                onInputChange: setCPTSearchQuery,
                                onChange: (opt) =>
                                  setFieldValue(
                                    "cpt_ids",
                                    opt.map((op) => op.id)
                                  ),
                                isLoading: loadingCPTList,
                              }}
                            ></FormInputField>
                            <FormInputField
                              className="w-1/3"
                              type="custom-select"
                              name="icd_ids"
                              title="ICD CODE"
                              required
                              options={icds}
                              selectProps={{
                                styles: {
                                  multiValueLabel: (style) => ({
                                    ...style,
                                    maxWidth: 90,
                                  }),
                                },
                                placeholder: "Search ICD Code",
                                ref: icd_ref,
                                isMulti: true,
                                getOptionLabel: (option) =>
                                  `${option.code} ${option.description}`,
                                getOptionValue: (option) => `${option.id}`,
                                onInputChange: setICDSearchQuery,
                                onChange: (opt) =>
                                  setFieldValue(
                                    "icd_ids",
                                    opt.map((op) => op.id)
                                  ),
                                isLoading: loadingICDList,
                              }}
                            ></FormInputField>
                            <FormInputField
                              type="autocomplete"
                              name="visit_type_id"
                              title="Case Type"
                              required
                              autocompleteProps={{
                                setFieldValue,
                                valueKey: "id",
                                labelKey: "name",
                                loading: loadingCaseTypeList,
                                items:
                                  caseTypeSearchQuery?.length > 0
                                    ? caseTypes
                                    : [],
                                value: caseTypeSearchQuery,
                                setValue: setCaseTypeSearchQuery,
                              }}
                              inputProps={{
                                placeholder: "Search Case Type",
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row justify-between">
                          <Button ref={buttonRef} formButton loading={loading}>
                            Create Macro
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </>
          ) : (
            <>
              <div className="p-2 bg-white flex justify-between items-center">
                <input
                  autoFocus={true}
                  type="text"
                  placeholder="Search for a Diagnosis Macro"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  className="form-input w-1/2 rounded"
                />
                <Button
                  className="text-center h-10"
                  onClick={() => {
                    setShowAddMacro(true);
                  }}
                >
                  Add Macro
                </Button>
              </div>
              {fetchingMacros ? (
                <div className="py-4 h-1/2 flex justify-center items-center bg-white">
                  <div className="flex flex-row">
                    <Loader className="text-black mr-3" />
                    Loading ...
                  </div>
                </div>
              ) : (
                <div className="h-full flex bg-white">
                  <div className="w-1/4 h-full overflow-y-scroll border-r">
                    {macros.length > 0 ? (
                      macros?.map((macro, index) => (
                        <div
                          key={index}
                          className={`p-4 border-b cursor-pointer ${
                            selectedMacro === macro
                              ? "bg-primary text-white"
                              : "bg-white text-black"
                          }`}
                          onClick={() => setSelectedMacro(macro)}
                        >
                          {macro?.name}
                        </div>
                      ))
                    ) : (
                      <div className="h-full flex justify-center items-center">
                        <h3>No macros found</h3>
                      </div>
                    )}
                  </div>
                  <div className="w-3/4 h-full p-4 overflow-y-scroll">
                    {selectedMacro ? (
                      <div className="flex flex-col space-y-4 selected-macro w-full">
                        <div className="flex flex-row">
                          <FormDataField
                            smVariant
                            title="Macro Name"
                            value={selectedMacro?.name}
                          />
                        </div>
                        <FormDataField
                          title="Macro Details"
                          value={selectedMacro?.description}
                          smVariant
                        />
                        <div className="grid grid-cols-2 gap-4">
                          <FormDataField
                            className="w-full max-h-20"
                            title="CPT CODE"
                            value={selectedMacro?.cpts?.map((cpt) => cpt?.id)}
                            smVariant
                          />
                          <FormDataField
                            className="w-full"
                            title="ICD CODE"
                            value={selectedMacro?.cpts?.map((cpt) => cpt?.id)}
                            smVariant
                          />
                        </div>
                        <Button
                          className="text-center mx-auto flex-shrink-0 h-10"
                          onClick={async () => {
                            const { error, data } = await API.put(
                              "/visits/progress-update?save_as_draft=true",
                              {
                                visit: {
                                  cpt_codes: selectedMacro?.cpts,
                                  icd10_codes: selectedMacro?.icds,
                                  diagnosis_title: selectedMacro?.name,
                                  diagnosis_description:
                                    selectedMacro?.description,
                                  diagnosis_code: selectedMacro?.code,
                                  diagnosis_date: parse(
                                    format(new Date(), "MM-dd-yyyy"),
                                    "MM-dd-yyyy",
                                    new Date()
                                  ),
                                  diagnosis_macro_id: selectedMacro?.id,
                                  ucid: visitDetails.ucid,
                                },
                              }
                            );

                            if (error) {
                              if (error.message) toast.error(error.message);
                            }

                            if (data) {
                              toast.success(
                                data?.message || "Progress saved successfully!"
                              );
                              updateVisit();
                            }

                            setSelectedMacro(macros[0]);
                            // setSelectedDiagnosisMacro(null);
                            setShowMacroViewer(false);
                          }}
                        >
                          Select Macro
                        </Button>
                      </div>
                    ) : (
                      <div className="h-full flex justify-center items-center">
                        <h3>No macro selected</h3>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </CustomModal>
      ) : (
        <div />
      )}
    </div>
  );
};

const Heading = ({ children, className, ...props }) => (
  <div
    className={`font-semibold text-sm text-primary mb-1 ${className}`}
    {...props}
  >
    {children}
  </div>
);

export default CaseDetailView;
