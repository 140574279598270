/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

import { Button } from "../../stories/Button";
import { Loader } from "../../service/Icon";
import { API } from "../../network/API";

export default function AccessionistCaseList({
  loading,
  cases = [],
  pageInfo = {},
  packageId,
  onSelectCase = () => {},
  onAddCase = () => {},
  selectedCase,
  showButtons,
}) {
  const observerTarget = useRef(null);
  const [page, setPage] = useState(2);
  const [visits, setVisits] = useState([]);
  const [visitsPageInfo, setVisitsPageInfo] = useState(null);
  const [loadingVisitList, setLoadingVisitList] = useState(false);

  const noOfPendingCase = visits?.filter(
    (caseItem) => caseItem.status === "pending"
  )?.length;

  useEffect(() => {
    setVisits(cases);
  }, [cases]);
  // check this
  useEffect(() => {
    setVisitsPageInfo(pageInfo);
  }, []);

  useEffect(() => {
    const observerTargetRef = observerTarget.current;

    async function getVisits() {
      try {
        setLoadingVisitList(true);
        if (
          visitsPageInfo?.current_page <
          Math.ceil(visitsPageInfo?.total_count / visitsPageInfo?.per_page)
        ) {
          const { data } = await API.get(
            `/couriers/${packageId}/visits?page=${page}`
          );
          if (data?.visits) {
            setVisits((prev) => [...prev, ...data?.visits]);
            setPage((prev) => prev + 1);
            setVisitsPageInfo(data?.page_info);
          }
        }
      } catch (error) {
      } finally {
        setLoadingVisitList(false);
      }
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getVisits();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTargetRef) {
        observer.unobserve(observerTargetRef);
      }
    };
  }, [
    packageId,
    page,
    visitsPageInfo?.current_page,
    visitsPageInfo?.per_page,
    visitsPageInfo?.total_count,
  ]);

  return (
    <div className="w-64 h-full bg-transparent flex flex-col items-center space-y-2">
      {showButtons && noOfPendingCase < 1 && (
        <Button
          className={`w-full h-10 text-center antialiased font-medium justify-center mb-4`}
          formButton
          id="add-case-btn"
          style={{ fontSize: 14 }}
          loading={loading}
          tabIndex={6}
          disabled={loading}
          onClick={onAddCase}
          onKeyDown={(e) => {
            if (e.keyCode == 9 && e.shiftKey == true) {
              e.preventDefault();
              const element = document.getElementById("complete-btn");
              if (element) {
                element.focus();
              }
            }
          }}
        >
          Add Case
        </Button>
      )}
      <div className="flex flex-col w-full">
        {visits?.map((currentCase, index) => {
          const selected = selectedCase?.id === currentCase?.id;
          return (
            <button
              id={`current-case-${index}`}
              style={{
                minHeight: currentCase?.ucid.includes("XXX-XXXXXX") ? 0 : 75,
                background: selected ? "#e7f6fc" : "#fff",
              }}
              className={`bg-white py-2 px-3 w-full mb-2 flex flex-col justify-between cursor-pointer border-2  ${
                selected ? "border-primary" : "border-white"
              }`}
              tabIndex={7 + index}
              key={currentCase?.ucid + index}
              onClick={() => onSelectCase(currentCase)}
            >
              {currentCase?.ucid.includes("XXX-XXXXXX") ? null : (
                <span className={`text-primary text-base underline`}>
                  {currentCase?.ucid}
                </span>
              )}
              {!!currentCase?.patient?.first_name && (
                <span className={"text-black text-sm"}>
                  Patient name:{" "}
                  <u className="cursor-pointer">
                    {currentCase?.patient?.first_name}
                  </u>
                </span>
              )}
              {_.includes(currentCase?.id, "new-") && <span>New Case</span>}
            </button>
          );
        })}
        {loadingVisitList && (
          <div className="mt-2 flex justify-center">
            <Loader />
          </div>
        )}
        <div
          ref={observerTarget}
          className={`${loadingVisitList ? "hidden" : "block"}`}
        ></div>
      </div>
    </div>
  );
}
