import { useAPI } from "../network/SWR";
import _ from "lodash";
import qs from "qs";
import { CurrentUser } from "./Auth";

export function useStaffList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const key = _.isString(searchQuery)
    ? `/users?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
    : null;
  const { data, error, loading, mutate, isValidating } = useAPI(
    key,
    (config = {}),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    users: data?.users ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePatientList(
  { currentPage = 1, perPageCount = 10, searchQuery = "", list = "" },
  config = {}
) {
  const key = _.isString(searchQuery)
    ? `/patients?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}&list=${list}`
    : null;
  const { data, error, loading, mutate, isValidating } = useAPI(
    key,
    (config = {}),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    patients: data?.patients ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePhysicianList(
  {
    currentPage = 1,
    perPageCount = 10,
    searchQuery = "",
    clientId = null,
    list = "",
  },
  config = {}
) {
  const key = `/users/physicians?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}&list=${list}${
    clientId !== null ? `&client_id=${clientId}` : ""
  }`;

  const { data, error, loading, mutate, isValidating } = useAPI(
    key,
    (config = {}),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    users: data?.physicians ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useCaseTypeList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/visit_types?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    caseTypes: data?.visit_types ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useCPTCodeList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/cpts?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    cptCodes: data?.cpts ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useICDCodeList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/icds?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    icdCodes: data?.icds ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useMacroList(
  { type, currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/macros/${type}/?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    macros: data?.macros ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useRoles(config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    `/roles`,
    config
  );
  return {
    roles: data?.roles.filter((role) => role.name !== "physician"),
    error,
    loading,
    mutate,
    isValidating,
  };
}
export function usePathologists(
  query = "",
  secondary_user_id = "",
  config = {}
) {
  const queryString = `/users/pathologists?page=1${
    query ? `&search_key=${query}` : ""
  }${secondary_user_id ? `&secondary_user_id=${secondary_user_id}` : ""}`;

  const { data, error, loading, mutate, isValidating } = useAPI(
    queryString,
    config
  );

  return {
    pathologists: data?.pathologists || [],
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useProviders(config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    `/insurance_providers`,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    providers: data?.providers ?? [],
    error,
    loading,
    mutate,
    isValidating,
  };
}
export function useProcedureList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/procedures?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    procedures: data?.procedures ?? [],
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useSiteList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/sites?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    sites: data?.sites ?? [],
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useICDCodesList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    _.isString(searchQuery)
      ? `/icds?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
      : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    icds: data?.icds ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useICDCode({ icd_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    icd_id ? `/icds/${icd_id}` : null,
    config
  );
  return {
    icd: data?.icd,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePhysicianRole(config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    `/roles`,
    config
  );
  return {
    roles: data?.roles.filter((role) => role.name === "physician"),
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useStaff({ user_id, list = "" }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    user_id ? `/users/${user_id}?list=${list}` : null,
    config
  );
  return {
    user: data?.user,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePatient({ patient_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    patient_id ? `/patients/${patient_id}` : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    patient: data?.patient,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useCaseType({ case_type_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    case_type_id ? `/visit_types/${case_type_id}` : null,
    config
  );
  return {
    caseType: data?.visit_type,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useCPTCode({ cpts_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    cpts_id ? `/cpts/${cpts_id}` : null,
    config
  );
  return {
    cptCode: data?.cpt,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useMacro({ type, macro_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    macro_id ? `/macros/${macro_id}?macro_type=${type}` : null,
    config
  );
  return {
    macro: data?.macro,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useCurrentProfile(config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    CurrentUser.isLoggedIn() ? `/users/profile` : null,
    config,
    {
      revalidateOnFocus: false,
    }
  );

  const userDefault = CurrentUser.getDetails?.user;

  if (data) CurrentUser.setDetails(data);
  return {
    user: data?.user ? data.user : userDefault,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePresignedUrl(config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    `/presigned_url`,
    config
  );
  return {
    urlData: data,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useVisitList(values, config = {}) {
  const params = qs.stringify(_.pickBy(values, _.negate(_.isEmpty)));
  const endpoint = CurrentUser.isAdmin()
    ? `/visits?${params}`
    : `/visits/list?${params}`;
  const { data, error, loading, mutate, isValidating } = useAPI(
    endpoint,
    config
  );
  return {
    visits: data?.visits ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePackageList(config = {}) {
  if (!CurrentUser.isAccessionist()) {
    return {
      visits: [],
    };
  }
  const endpoint = `/couriers`;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, error, loading, mutate, isValidating } = useAPI(
    endpoint,
    config
  );
  return {
    received_couriers: data?.received_couriers ?? [],
    completed_couriers: data?.completed_couriers ?? [],
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePackageCasesList(
  { packageId, page = 1, query },
  config = {}
) {
  const endpoint = `/couriers/${packageId}/visits?page=${page}`;
  const { data, error, loading, mutate, isValidating } = useAPI(
    endpoint,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    visits: data?.visits || [],
    page_info: data?.page_info,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePhysicianVisitList(
  values,
  { currentPage = 1, perPageCount = 20 },
  config = {}
) {
  const params = qs.stringify(_.pickBy(values, _.negate(_.isEmpty)), {
    arrayFormat: "brackets",
  });

  const { data, error, loading, mutate, isValidating } = useAPI(
    `/clinic_users/visit-list?page=${currentPage}&per_page_count=${perPageCount}&${params}`,
    config
  );
  return {
    visits: data?.visits ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function usePhysicianVisitType({ case_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    `/visits/${case_id}`,
    config
  );
  return {
    caseInfo: data?.visit,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useClinicLocation({ location_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    location_id ? `/addresses/${location_id}` : null,
    config
  );
  return {
    location: data?.address,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useClinicLocationList(
  { currentPage = 1, perPageCount = 10, searchQuery = "" },
  config = {}
) {
  const key = _.isString(searchQuery)
    ? `/addresses?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}`
    : null;
  const { data, error, loading, mutate, isValidating } = useAPI(key, config);

  return {
    locations: data?.addresses ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useClinicStaff({ location_id, user_id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    user_id ? `/addresses/${location_id}/clinic_users/${user_id}` : null,
    config
  );
  return {
    staff: data?.user,
    location: data?.address,
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useClinicStaffList(
  { currentPage = 1, perPageCount = 10, searchQuery = "", list = "" },
  config = {}
) {
  const key = _.isString(searchQuery)
    ? `/clinic_users?page=${currentPage}&per_page_count=${perPageCount}&search_key=${searchQuery}&list=${list}`
    : null;
  const { data, error, loading, mutate, isValidating } = useAPI(key, config);

  return {
    staffs: data?.users ?? [],
    pageInfo: data?.page_info ?? {
      total_count: 0,
      current_page: 0,
      per_page: 10,
    },
    error,
    loading,
    mutate,
    isValidating,
  };
}

export function useVisit({ id }, config = {}) {
  const { data, error, loading, mutate, isValidating } = useAPI(
    `/visits/${id}`,
    config,
    {
      revalidateOnFocus: false,
    }
  );
  return {
    visit: data?.visit ?? {},
    error,
    loading,
    mutate,
    isValidating,
  };
}
