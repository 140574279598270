import React, { useEffect } from "react";
import _ from "lodash";
import qs from "qs";
import { EmptySearch, NoData } from "./Illustration";
import usePrevious from "../hooks/usePrevious";
import queryString from "query-string";

export const toKeyCase = (titles) => {
  if (typeof titles === "string") {
    return _.chain(titles).lowerCase().split(" ").join("-").value();
  }
  return _.map(titles, (i) =>
    _.chain(i).lowerCase().split(" ").join("-").value()
  );
};

export const getURLParams = (key = null) => {
  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return key ? params[key] : params;
};

export const getErrorData = ({
  searchQuery,
  data,
  error,
  title,
  mutate,
  removeSubTitle,
}) => {
  if (error) {
    return {
      show: true,
      subtitle: `Unable to load ${title}`,
      ctaOnClick: mutate,
    };
  }
  if (data?.length === 0) {
    if (searchQuery && searchQuery.length > 0) {
      return {
        show: true,
        title: (
          <p className="font-normal text-gray-600">
            Sorry we couldn't find any matches for{" "}
            <span className="font-bold text-black">{searchQuery}</span>
          </p>
        ),
        subtitle: "Please try searching with another term",
        icon: <EmptySearch />,
      };
    }
    return {
      show: true,
      title: `You don’t have any ${title} yet.`,
      subtitle: removeSubTitle
        ? ""
        : `Start creating ${title} by clicking on "Add ${title}" button`,
      icon: <NoData />,
    };
  }
  return null;
};

export const getFilterErrorData = ({
  data,
  error,
  title,
  mutate,
  loading,
  reload,
  hasValus,
}) => {
  if (error) {
    return {
      show: true,
      subtitle: `Unable to load ${title}`,
      ctaOnClick: mutate,
    };
  }
  if (!loading && data.length === 0) {
    if (hasValus) {
      return {
        show: true,
        title: (
          <p className="font-normal text-gray-600">
            Could not find any refers for this filter
          </p>
        ),
        subtitle: "Please try searching with another term",
        icon: <EmptySearch />,
        ctaOnClick: reload,
      };
    } else {
      return {
        show: true,
        title: `You don’t have any ${title} yet.`,
        icon: <NoData />,
      };
    }
  }
  return null;
};

export const FormikObserver = (props) => {
  const propValues = Object.values(props.value).join(", ");
  const prevValue = usePrevious(propValues);

  useEffect(() => {
    if (prevValue !== propValues) props.onChange(props.value);
  }, [props, propValues, prevValue]);

  return null;
};

export const getRoleNames = (roleName) => {
  switch (roleName) {
    case "accessionist":
      return "Accessioner";
    case "clinic_admin":
      return "Admin";
    case "clinic_assistant":
      return "Assistant";
    case "clinic_physician":
      return "Physician";
    case "physician":
      return "Referring Physician";

    default:
      return _.startCase(roleName);
  }
};

export function checkEmpty(value) {
  console.log(value, "value");
  if (_.isString(value)) {
    return value.length > 0 ? value : "--";
  } else {
    return !_.isEmpty(value) ? value : "--";
  }
}

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getQueryParams = (location) => {
  const queryParams = new URLSearchParams(location.search);

  const queryParamObj = {};

  queryParams.forEach((value, key) => {
    queryParamObj[key] = value;
  });

  return queryParamObj;
};

export const clearQueryParams = () => {
  if (window.location.search) {
    const urlWithoutParams = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, urlWithoutParams);
  }
};

export const handleQuery = (navigate, page, searchQuery, route = "") => {
  const queryParams = queryString.stringify(
    { page, search: searchQuery },
    {
      skipEmptyString: true,
    }
  );
  if (route) {
    navigate(`/dashboard/${route}?${queryParams}`);
  } else {
    navigate(`/dashboard`);
  }
};
