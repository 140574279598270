import React from "react";
import CaseList from "./CaseList";
import CaseView from "./CaseView";

export default function CaseType({ type, id }) {
  if (type === "view") {
    return <CaseView id={id} />;
  }
  return <CaseList />;
}
