import React from "react";
import AddStaff from "./AddStaff";
import ViewStaff from "./ViewStaff";
import ListStaff from "./ListStaff";

export default function CaseType({ type, id, staffId }) {
  if (type === "add") {
    return <AddStaff />;
  } else if (type === "edit") {
    return <AddStaff id={staffId} location_id={id} />;
  } else if (type === "view") {
    return <ViewStaff id={staffId} location_id={id} />;
  }
  return <ListStaff />;
}
