import { useNavigate } from "@reach/router";
import moment from "moment";

const PackageCardDetails = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div className="flex flex-col space-y-4">
        <h3
          onClick={() =>
            navigate("/packages/" + data.id, {
              state: { value: data?.id },
            })
          }
          className="capitalize cursor-pointer underline text-lg font-semibold leading-5 text-gray-900"
        >
          {data.tracking_num ? `Package No: #${data.tracking_num}` : "In-House"}
        </h3>
      </div>
      <div className="mt-8 grid grid-cols-5 gap-4">
        <div className="flex flex-col gap-2">
          <p className="capitalize text-sm font-light leading-5 text-gray-900">
            Client Name
          </p>
          <p className="capitalize text-base font-normal leading-6">
            {data?.client?.name}
          </p>
        </div>

        {data.name === "In-House" && data.tracking_num ? (
          <div className="flex flex-col gap-2">
            <p className="capitalize text-sm font-light leading-5 text-gray-900">
              Courier Partner
            </p>
            <p className="capitalize text-base font-normal leading-6">
              {data?.name ?? "--"}
            </p>
          </div>
        ) : data.name === "In-House" && !data.tracking_num ? null : (
          <div className="flex flex-col gap-2">
            <p className="capitalize text-sm font-light leading-5 text-gray-900">
              Courier Partner
            </p>
            <p className="capitalize text-base font-normal leading-6">
              {data?.name ?? "--"}
            </p>
          </div>
        )}

        <div className="flex flex-col gap-2">
          <p className="capitalize text-sm font-light leading-5 text-gray-900">
            Client Type
          </p>
          <p className=" text-base font-normal leading-6">
            {" "}
            {!data.client?.interface ? "Hand-Key" : "Hand-Key, Interface"}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="capitalize text-sm font-light leading-5 text-gray-900">
            Received date
          </p>
          <p className="capitalize text-base font-normal leading-6">
            {moment(data?.received_date).format("MMMM D, YYYY")}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="capitalize text-sm font-light leading-5 text-gray-900">
            No of Cases
          </p>
          <p className="uppercase text-base font-normal leading-6">
            {data?.visit_count}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PackageCardDetails;
