import React, { useState } from "react";
import Table from "../../stories/Table";
import { Link, navigate } from "@reach/router";
import { format } from "date-fns";
import { getErrorData, getURLParams, handleQuery } from "../../service/Helper";
import { useCPTCodeList } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import _ from "lodash";

const perPageCount = 10;

const ListCPTCode = () => {
  const currentPage = getURLParams("page") ?? 1;
  const searchQ = getURLParams("search") ?? "";

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(currentPage);

  const { cptCodes, pageInfo, loading, error, isValidating, mutate } =
    useCPTCodeList({
      currentPage: page,
      perPageCount,
      searchQuery: searchQ,
    });
  const loadingData = loading || isValidating;

  const getDisplayValue = (value) =>
    _.isEmpty(value) ? (
      <span className="bg-cool-gray-300 text-white p-2 rounded-sm mt-4">
        NA
      </span>
    ) : (
      value
    );

  const content = (
    <Table
      headers={[
        "CPT ID",
        "CPT Code",
        "Description",
        // "Date Added",
        // "No Of Associated cases",
      ]}
      pagination={{
        currentpage: pageInfo.current_page,
        itemsPerPage: pageInfo.per_page,
        totalItems: pageInfo.total_count,
        onPageChange: (page) => {
          handleQuery(navigate, page, searchQ, "cpt-codes");
        },
      }}
      loading={loadingData}
      rowContent={cptCodes.map((cptCode) => [
        <p className="underline text-primary w-24 mx-auto">
          <Link to={`/dashboard/cpt-codes/view/${cptCode.id}`}>
            {cptCode.id}
          </Link>
        </p>,
        <p className="uppercase">{cptCode.code}</p>,
        <p className="w-64 truncate mx-auto">
          {getDisplayValue(cptCode?.description)}
        </p>,
        // <p>{format(new Date(), "dd-MMM-yyyy")}</p>,
        // <p>{cptCode.visit_count}</p>,
      ])}
    />
  );

  const headerData = {
    // constructiveCTA: {
    //   link: "/dashboard/cpt-codes/add",
    //   title: "Add CPT Code",
    // },
    states: [
      {
        title: "CPT Code List",
      },
    ],
    title: "List of CPT Codes",
    search: {
      show: true,
      searching: searchQuery.length && loadingData ? true : false,
      placeholder: "Search using CPT Code...",
      onSearch: (values) => {
        setPage(1);
        handleQuery(navigate, 1, values.query, "cpt-codes");
        setSearchQuery(values.query);
      },
      onChange: (values) => {
        if (!values.query) {
          setSearchQuery("");
        }
      },
    },
  };

  const errorData = getErrorData({
    error,
    searchQuery,
    data: cptCodes,
    mutate,
    title: "CPT Codes",
  });

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      errorData={errorData}
      loading={loadingData}
      loadingContent={null}
    />
  );
};

export default ListCPTCode;
