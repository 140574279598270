import React from "react";

export const Loader = ({ className }) => (
  <svg
    className={`animate-spin -ml-1 h-5 w-5 ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export const Breadcrumb = ({ className }) => (
  <svg
    className={`fill-current w-3 h-3 mx-3 ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
  </svg>
);

export const Search = ({ className }) => (
  <svg
    className={`h-3 w-3 fill-current ${className}`}
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 56.966 56.966"
    width="512px"
    height="512px"
  >
    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
  </svg>
);

export const Warning = ({ className }) => (
  <svg
    className={className ? className : "h-6 w-6"}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    />
  </svg>
);

export const CheckCircle = () => (
  <svg
    className="h-6 w-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
);

export const Close = ({ className }) => (
  <svg
    className={`w-3 h-3 ${className}`}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
);

export const Info = () => (
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
);

export const Refresh = ({ className }) => (
  <svg
    className={className ? className : "h-6 w-6"}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
    ></path>
  </svg>
);

export const Edit = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`${className ? className : "h-5 w-5"}`}
  >
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
  </svg>
);

export const ProfilePlaceholder = ({ className }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 45.532 45.532"
      className={className ?? "h-32 w-32 opacity-50"}
    >
      <g>
        <path
          d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
		S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
		c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
		c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
		c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
        />
      </g>
    </svg>
  );
};

export const CaseList = () => (
  <svg
    className="w-6 h-6"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
  </svg>
);

export const Admin = ({ className }) => (
  <svg
    className={className ?? "w-6 h-6"}
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M10.496 2.132a1 1 0 00-.992 0l-7 4A1 1 0 003 8v7a1 1 0 100 2h14a1 1 0 100-2V8a1 1 0 00.496-1.868l-7-4zM6 9a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1zm3 1a1 1 0 012 0v3a1 1 0 11-2 0v-3zm5-1a1 1 0 00-1 1v3a1 1 0 102 0v-3a1 1 0 00-1-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const Download = ({ className }) => {
  return (
    <svg
      className={className ?? "w-6 h-6"}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Location = ({ className }) => (
  <svg
    className={className ?? "w-6 h-6"}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="11" r="3" />
    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
  </svg>
);

export const Staff = ({ className }) => (
  <svg
    className={className ?? "w-6 h-6"}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <circle cx="7" cy="5" r="2" />
    <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
    <circle cx="17" cy="5" r="2" />
    <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
  </svg>
);

export const WrittingTool = ({ className }) => (
  <svg
    className={className ?? "w-6 h-6"}
    viewBox="0 -46 511.99989 511"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="m60.273438 318.582031-14.074219 20.101563c-.925781 1.320312-1.625 2.78125-2.085938 4.332031l-13.523437 45.46875c-1.84375 6.183594.476562 12.855469 5.769531 16.558594 5.292969 3.707031 12.351563 3.609375 17.535156-.238281l37.925781-28.132813c-18.410156-13.03125-30.558593-34.105469-31.546874-58.089844zm0 0" />
    <path d="m497 180.515625h-45.003906v224.996094c0 11.152343 11.734375 18.40625 21.710937 13.417969l32.226563-23.902344c3.816406-2.828125 6.066406-7.300782 6.066406-12.046875v-187.464844c0-8.285156-6.714844-15-15-15zm0 0" />
    <path d="m299.410156 3.21875c-6.792968-4.757812-16.136718-3.109375-20.894531 3.683594l-25.8125 36.863281 49.152344 34.417969 25.8125-36.863282c4.757812-6.792968 3.109375-16.136718-3.683594-20.894531zm0 0" />
    <path d="m112.195312 244.433594c7.226563-2.324219 14.785157-3.917969 22.773438-3.917969h53.21875l96.460938-137.757813-49.152344-34.417968zm0 0" />
    <path d="m107.445312 118.078125c-8.292968 3.601563-15.222656 9.519531-20.070312 17.152344l-82.667969 130.164062c-10.359375 20.730469-3.007812 45.558594 16.074219 57.816407.347656-.546876.460938-1.191407.832031-1.722657l161.261719-230.3125c-6.019531-.363281-12.171875.019531-18.371094 2.113281zm0 0" />
    <path d="m119.96875 389.011719v9.019531c0 12.425781 10.074219 22.5 22.503906 22.5 12.425782 0 22.5-10.074219 22.5-22.5v-7.5h-30.003906c-5.132812 0-10.148438-.53125-15-1.519531zm0 0" />
    <path d="m194.976562 390.53125v7.5c0 12.425781 10.074219 22.5 22.5 22.5 11.007813 0 19.746094-8.058594 21.695313-18.503906-4.886719-2.023438-9.851563-3.851563-14.589844-6.195313l-10.402343-5.304687h-19.203126zm0 0" />
    <path d="m421.992188 180.515625h-27.53125l-95.324219-46.152344-73.398438 104.832031c18.25-2.835937 35.503907-11.148437 48.636719-24.285156 5.859375-5.859375 15.355469-5.859375 21.214844 0s5.859375 15.355469 0 21.214844c-21.859375 21.855469-52.125 34.398438-83.035156 34.398438-16.140626 0-82.199219 0-77.582032 0-24.03125 0-45.003906 19.515624-45.003906 45 0 24.90625 20.101562 45.003906 45.003906 45.003906h86.40625c7.203125 2.816406 47.28125 30.003906 108.609375 30.003906 31.039063 0 64.03125-7.253906 92.003907-20.707031zm0 0" />
  </svg>
);

export const Surgery = ({ className }) => (
  <svg
    className={className ?? "w-6 h-6"}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g id="Solid">
      <path d="M328,192h1.289L448.209,76.209A26.222,26.222,0,0,0,456,57.75,25.779,25.779,0,0,0,430.25,32h-.27a25.935,25.935,0,0,0-18.213,7.547L259.06,192.253A48.3,48.3,0,0,1,264,192ZM405.657,66.343l16,16L410.343,93.657l-16-16Zm-24,24,16,16-11.314,11.314-16-16Zm-24,24,16,16-11.314,11.314-16-16Zm-24,24,16,16-11.314,11.314-16-16Zm-24,24,16,16-11.314,11.314-16-16ZM409.737,136H488V343.788a152.469,152.469,0,0,1-105.855-50.917l-16.166-18.186A8,8,0,0,0,360,272H264a32,32,0,0,1,0-64H416V192H352.223ZM109.657,282.343a25.969,25.969,0,0,1,0-36.686l88.57-88.57A71.525,71.525,0,0,1,249.14,136h43.546L146.34,282.346a25.943,25.943,0,0,1-36.683,0ZM488,359.8v13.454A72.383,72.383,0,0,1,432,400H400a8,8,0,0,0-5.946,2.648l-62.1,69A26.04,26.04,0,0,1,313.692,480C299.564,480.26,288,468.319,288,454.188v-.428a25.217,25.217,0,0,1,7.387-17.833l.21-.21,70.06-70.06-11.314-11.314L284.283,424.4a25.944,25.944,0,0,1-8.4,5.62,26.606,26.606,0,0,1-24.733-2.628c-13.007-9.186-14.933-28.3-3.568-39.664l70.073-70.073-11.314-11.314L236.285,376.4c-11.214,11.214-30.124,9.551-39.429-3.206-5.391-7.389-7.845-22.265-.6-29.329l58.336-56.8A47.992,47.992,0,0,0,264,288h92.408l13.779,15.5A168.471,168.471,0,0,0,488,359.8ZM216,240a48.276,48.276,0,0,1,.253-4.939L32,419.313v60.441a126.267,126.267,0,0,0,81.943-37.011L152,404.687V368a8,8,0,0,1,2.419-5.731l83.936-81.725A48,48,0,0,1,216,240ZM96.307,429.361A133.062,133.062,0,0,1,50.53,455.59l-5.06-15.18a117.127,117.127,0,0,0,40.3-23.09l44.961-39.341,10.536,12.042Zm90.036-111.7-16-16,11.314-11.314,16,16Zm24-24-16-16,11.314-11.314,16,16Z" />
    </g>
  </svg>
);

export const Microscope = ({ className }) => (
  <svg
    className={className ?? "w-6 h-6"}
    viewBox="0 0 512.001 512.001"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g>
      <path
        d="M422.244,430.198c16.574-26.852,25.756-57.117,25.756-88.863c0-73.952-46.664-138.103-116.374-161.483
			c-9.379-15.152-24.776-26.092-42.94-29.37l9.98-19.96c2.635-5.271,0.5-11.677-4.771-14.312l9.542-19.083
			c5.271,2.615,11.656,0.5,14.313-4.771l22.458-44.917c2.635-5.271,0.5-11.677-4.771-14.313l-64-32
			c-5.281-2.625-11.667-0.51-14.313,4.771l-22.458,44.917c-2.635,5.271-0.5,11.677,4.771,14.313l-9.542,19.083
			c-5.26-2.615-11.677-0.5-14.313,4.771l-86.458,172.917c-2.635,5.271-0.5,11.677,4.771,14.313l64,32
			c1.531,0.76,3.156,1.125,4.76,1.125c3.917,0,7.677-2.156,9.552-5.896l21.694-43.382c11.431,10.632,26.625,17.277,43.431,17.277
			c14.004,0,26.863-4.65,37.411-12.307c29.22,14.289,47.922,43.49,47.922,76.307c0,47.052-38.281,85.333-85.333,85.333h-85.333
			v-21.333h32c5.896,0,10.667-4.771,10.667-10.667c0-5.896-4.771-10.667-10.667-10.667h-42.667h-64H74.667
			c-5.896,0-10.667,4.771-10.667,10.667c0,5.896,4.771,10.667,10.667,10.667h32v21.333c-23.531,0-42.667,19.135-42.667,42.667v32
			c0,5.896,4.771,10.667,10.667,10.667h362.667c5.896,0,10.667-4.771,10.667-10.667v-32
			C448.001,451.815,437.376,436.762,422.244,430.198z M277.334,234.668c-11.76,0-21.333-9.573-21.333-21.333
			c0-11.76,9.573-21.333,21.333-21.333c11.76,0,21.333,9.573,21.333,21.333C298.667,225.095,289.094,234.668,277.334,234.668z"
      />
    </g>
  </svg>
);

export const Print = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className ?? "w-6 h-6"}
    fill="currentColor"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
  </svg>
);

export const Send = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className ?? "w-6 h-6"}
    fill="currentColor"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
  </svg>
);

export const CheckCircleAlt = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className ?? "w-6 h-6"}
    fill="#FFFFFF"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
  </svg>
);
