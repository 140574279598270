import React from "react";
import AddCPTCode from "../CPTCode/AddCPTCode";
import ListCPTCode from "../CPTCode/ListCPTCode";
import ViewCPTCode from "../CPTCode/ViewCPTCode";

export default function CPTCode({ mode, id }) {
  if (mode === "add") {
    return <AddCPTCode />;
  } else if (mode === "edit") {
    return <AddCPTCode id={id} />;
  } else if (mode === "view") {
    return <ViewCPTCode id={id} />;
  }
  return <ListCPTCode />;
}
