import axios from "axios";
import CONSTANTS from "../helpers/constants";
import { CurrentUser } from "../service/Auth";

axios.defaults.baseURL = CONSTANTS.BASE_API_URL;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";

axios.interceptors.request.use(
  function (config) {
    if (["/login"].indexOf(config.url) === -1) {
      const token = CurrentUser.getToken();
      if (token) config.headers["Access-Token"] = token;
    } else {
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function makeReq(method, url, data, config) {
  let promiseResponse = { error: null, data: null };
  const onSuccess = (response) => {
    // console.log("Request Successful!", response);
    promiseResponse.data = response.data;
    return Promise.resolve(promiseResponse);
  };

  const onError = (error) => {
    // console.log("Request Failed:", error);
    const { response } = error;
    if (response) {
      const { data } = response;
      // console.log("Data:", data);
      if (data.status === "500") {
        promiseResponse.error = {
          message: "Unable to connect with server. Try again after sometime",
        };
      } else if (data.status === 401) {
        CurrentUser.logout();
        promiseResponse.error = {
          message: "Unauthorized user",
        };
        window.location.href = "/login";
      } else {
        promiseResponse.error = { message: data.message };
      }
      return Promise.resolve(promiseResponse);
    } else {
      const { message } = error;
      promiseResponse.error = { message };
      return Promise.resolve(promiseResponse);
    }
  };
  return axios({
    method,
    url,
    data,
  })
    .then(onSuccess)
    .catch(onError);
}

export const API = {
  post: (url, data) => makeReq("post", url, data),
  get: (url, data) => makeReq("get", url, data),
  put: (url, data) => makeReq("put", url, data),
  delete: (url, data) => makeReq("delete", url, data),
};

export const FETCHER = (url) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return axios({
    method: "get",
    url,
  }).then(onSuccess);
};
