import React from "react";
import AddPhysician from "../Physician/AddPhysician";
import ListPhysician from "../Physician/ListPhysician";
import ViewPhysician from "../Physician/ViewPhysician";

export default function Physician({ mode, id }) {
  if (mode === "add") {
    return <AddPhysician />;
  } else if (mode === "edit") {
    return <AddPhysician id={id} />;
  } else if (mode === "view") {
    return <ViewPhysician id={id} />;
  }
  return <ListPhysician />;
}
