import React, { useState, useRef } from "react";
import { Button } from "../../stories/Button";
import { Formik, Form } from "formik";
import { addStaffSchema } from "../../service/Validation";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import _ from "lodash";
import { FormInputField } from "../../stories/FormInputField";
import { usePathologists, useRoles, useStaff } from "../../service/Fetchers";
import { getRoleNames } from "../../service/Helper";
import { DashboardView } from "../../stories/DashboardView";
import { navigate } from "@reach/router";
import { useEffect } from "react";

export default function AddStaff({ id: user_id }) {
  const [loading, setLoading] = useState(false);
  const buttonTitle = user_id ? "Update" : "Create Staff";
  const pageTitle = user_id ? `Update staff #${user_id}` : "Create new staff";
  const buttonRef = useRef(null);
  const { roles, error: roleError, mutate: mutateRole } = useRoles();
  const [pathologistSearchQuery, setPathologistSearchQuery] = useState("");
  const { pathologists, loading: pathologistsLoading } = usePathologists(
    pathologistSearchQuery
  );
  const { user, error: userError, mutate: mutateStaff } = useStaff({ user_id });

  useEffect(() => {
    if (!_.isEmpty(user) && user?.pathologist?.id) {
      let name = "";
      if (user?.pathologist?.first_name) {
        name += user?.pathologist.first_name;
      }
      if (user?.pathologist?.last_name) {
        name += ` ${user?.pathologist.last_name}`;
      }
      setPathologistSearchQuery(name);
    }
  }, [user]);

  const initialFormValues = () => {
    return {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      gender: user?.gender ?? "default",
      dob: user?.dob ?? "",
      role_id: user?.role?.id ? `${user?.role?.id}` : null,
      pathologist_id: user?.pathologist?.id ?? "",
      signature: user?.signature ?? "",
    };
  };

  const createOrUpdateStaff = async (values) => {
    if (user_id) return API.put(`/users/${user_id}`, { user: values });
    return API.post("/users", { user: values });
  };

  const getSelectedRole = (role_id) => {
    try {
      const selectedRole = roles?.find(
        (role) => Number(role?.id) === Number(role_id)
      )?.name;
      return selectedRole || null;
    } catch (error) {
      return null;
    }
  };

  const onSubmit = async (values, actions) => {
    // setLoading(true);
    let payload = { ...values };
    const selectedRole = getSelectedRole(values.role_id);
    if (selectedRole !== "transcriptionist") {
      delete payload.pathologist_id;
    }
    const { data, error } = await createOrUpdateStaff(payload);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (user_id) {
        toast.success(`Updated ${payload.first_name}!`);
        setTimeout(() => {
          navigate(`/dashboard/staff/view/${user_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${payload.first_name}!`);
      }
    }
    setLoading(false);
  };

  const rolesLoaded = () => roles && roles.length;
  const userLoaded = () => (user_id ? !!user : true);

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addStaffSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors }) => {
        const selectedRole = getSelectedRole(values.role_id);
        return (
          <Form className="flex-1">
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField type="text" name="first_name" required />
                <FormInputField type="text" name="last_name" required />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField title="DOB" type="date" name="dob" required />
                <FormInputField
                  title="Gender"
                  type="select"
                  name="gender"
                  options={[
                    { name: "Male", value: "male" },
                    { name: "Female", value: "female" },
                  ]}
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  type="email"
                  name="email"
                  required
                  disabled={!!user_id}
                />
                <FormInputField
                  title="Phone #"
                  type="number"
                  name="phone"
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  title="Role"
                  type="radio"
                  name="role_id"
                  options={_.map(roles, (role) => ({
                    name: getRoleNames(role.name),
                    value: `${role.id}`,
                  }))}
                  required
                />
                {selectedRole === "pathologist" && (
                  <div className="flex flex-1">
                    <FormInputField
                      title="Designation"
                      inputProps={{
                        className: "",
                        placeholder: "Designation",
                        onChange: (e) => {
                          setFieldValue(
                            "signature",
                            e.target.value.toUpperCase()
                          );
                        },
                      }}
                      type="text"
                      name="signature"
                      required
                    />
                  </div>
                )}
                {selectedRole === "transcriptionist" && (
                  <div className="flex-1">
                    <FormInputField
                      type="autocomplete"
                      name="pathologist_id"
                      title="Assign a pathologist"
                      // className={`flex flex-col w-1/2`}
                      required
                      autocompleteProps={{
                        setFieldValue,
                        valueKey: "id",
                        labelKey: "first_name",
                        items:
                          pathologistSearchQuery?.length > 0
                            ? pathologists
                            : [],
                        loading: pathologistsLoading,
                        creatable: false,
                        value: pathologistSearchQuery,
                        setValue: setPathologistSearchQuery,
                        renderCustomLabel: (value) => {
                          let name = "";
                          if (value?.first_name) {
                            name += value.first_name;
                          }
                          if (value?.last_name) {
                            name += ` ${value.last_name}`;
                          }
                          return name;
                        },
                      }}
                      inputProps={{
                        placeholder: "Search Pathologist",
                      }}
                    />
                    <div className={`flex flex-col w-1/2`} />
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row justify-between mt-10">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const headerData = {
    states: user_id
      ? [
          { title: "Staff List", link: "/dashboard/staff" },
          {
            title: `Staff #${user_id}`,
            link: `/dashboard/staff/view/${user_id}`,
          },
          { title: "Edit" },
        ]
      : [{ title: "Staff List", link: "/dashboard/staff" }, { title: `Add` }],
    title: pageTitle,
  };

  const errorData =
    userError || roleError
      ? {
          show: true,
          subtitle: roleError
            ? "Unable to fetch roles"
            : "Unbable to fetch staff details",
          ctaOnClick: () => (roleError ? mutateRole() : mutateStaff()),
        }
      : null;

  const loadingData =
    userError || roleError ? false : !(rolesLoaded() && userLoaded());

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      loading={loadingData}
      errorData={errorData}
    />
  );
}
