/* eslint-disable eqeqeq */
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Select from "react-select";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { useMacroList, usePathologists } from "../../service/Fetchers";
import { format, parse } from "date-fns";
import { Loader, Search } from "../../service/Icon";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import classNames from "classnames";
import { Tab } from "@headlessui/react";
import DiagnosisForm, { autoSave } from "../../components/DiagnosisForm";
import { DiagnosisContext } from "../../providers/DiagnosisFormProvider";
import { debounce } from "../../service/Helper";
import { CurrentUser } from "../../service/Auth";
import { FaUserCheck } from "react-icons/fa";
import { isEmpty } from "lodash";
import { AssigneeContex } from "../../providers/TrackAssigneeProvider";

/**
 * @param {string} role
 * @param {string} userId
 * @param {string} visitId
 * @param {Function<void>} updateVisit
 * @returns {Promise<void>} The sum of x and y
 */

export const assignUser = async (
  role = "primary",
  userId,
  visitId,
  updateVisit
) => {
  const data = await API.post(`/visits/${visitId}/assign-pathologist`, {
    user_id: userId,
    role: role,
  });

  if (data?.error) {
    toast.error(data.error.message);
    return { error: true };
  } else {
    toast.success(data?.data?.message || "Assigned to you successfully!");
    updateVisit?.();
    return {
      success: true,
    };
  }
};

// For changing dropdown indicator to search icon
const DropdownIndicator = () => {
  return (
    <div className="mx-2">
      <Search />
    </div>
  );
};

function SelectedDiagnosisMacro({
  selectedDiagnosisMacro,
  visit,
  updateVisit,
  status = "",
  updatingVisit,
}) {
  const [assigned, setAssigned] = useState(false);
  const macroSelectRef = useRef(null);

  const { setShowMacroViewer, setSelectedSpecimenTabs, selectedSpecimenTab } =
    useContext(GlobalVariablesContext);
  const { assingedID } = useContext(AssigneeContex);

  // set the selected macro value
  const {
    setSelectedMacroCodeDetails,
    modifyDiagnosesArray,
    setNeedRefresh,

    diagnosesAtt,
  } = useContext(DiagnosisContext);

  const partOptions = Array.from(Array(visit?.specimen_demographics?.length))
    .map((e, i) => i + 65)
    .map((x) => {
      return { label: String.fromCharCode(x), value: x - 65 };
    });
  const [partIndex, setPartIndex] = useState(0);

  const [macroSearchQuery, setMacroSearchQuery] = useState();
  const { macros, loading } = useMacroList({
    type: "diagnosis",
    searchQuery: macroSearchQuery,
  });

  useEffect(() => {
    if (macroSelectRef.current) {
      macroSelectRef?.current?.select?.clearValue();
    }
  }, [partIndex]);

  // setting visit
  useEffect(() => {
    setSelectedMacroCodeDetails(visit);
  }, [visit]);

  // setting the selected index globally to make use of everywhere
  useEffect(() => {
    if (partIndex) setSelectedSpecimenTabs(partIndex);
    else setSelectedSpecimenTabs(0);
  }, [partIndex]);

  const debouncedAutoSaveDiagnosis = debounce(autoSave, 700); // 500ms delay

  // onSelect the macro options
  // const onSelectOption = async (opt, { action }) => {
  //   console.log(opt, "kishore");
  //   if (opt?.is_site_linked) {
  //     const data = await API.get(
  //       `/visits/${visit.id}/macro-icd-code?macro_code=${
  //         opt?.code
  //       }&label=${String.fromCharCode(selectedSpecimenTab + 65)}`
  //     );
  //     console.log(data.data?.icds, "data");
  //   }

  //   if (action === "select-option") {
  //     setNeedRefresh(true);
  //     const selectedAtt = {
  //       cpt_codes: opt?.cpts,
  //       icd10_codes: opt?.icds,
  //       title: opt?.name,
  //       description: opt?.description,
  //       code: opt?.code,
  //       diagnosis_date: parse(
  //         format(new Date(), "MM-dd-yyyy"),
  //         "MM-dd-yyyy",
  //         new Date()
  //       ),
  //       id: diagnosesAtt?.[partIndex].id ?? null,
  //     };
  //     modifyDiagnosesArray(selectedAtt, partIndex);
  //     setTimeout(() => {
  //       debouncedAutoSaveDiagnosis(
  //         selectedAtt,
  //         visit?.ucid,
  //         updateVisit,
  //         partIndex,
  //         modifyDiagnosesArray
  //       );
  //     }, 100);
  //   }
  // };

  const onSelectOption = async (opt, { action }) => {
    let newICDCodes = opt?.icds; // Default to the original ICD codes

    if (opt?.is_site_linked) {
      try {
        const response = await API.get(
          `/visits/${visit.id}/macro-icd-code?macro_code=${
            opt?.code
          }&label=${String.fromCharCode(selectedSpecimenTab + 65)}`
        );
        console.log(response.data?.icds, "data");

        // If the API call was successful, update the newICDCodes
        if (response.data?.icds) {
          newICDCodes = response.data.icds;
        }
      } catch (error) {
        console.error("Error fetching new ICD codes:", error);
      }
    }

    if (action === "select-option") {
      setNeedRefresh(true);
      const selectedAtt = {
        cpt_codes: opt?.cpts,
        icd10_codes: newICDCodes,
        title: opt?.name,
        description: opt?.description,
        code: opt?.code,
        diagnosis_date: parse(
          format(new Date(), "MM-dd-yyyy"),
          "MM-dd-yyyy",
          new Date()
        ),
        id: diagnosesAtt?.[partIndex].id ?? null,
      };

      modifyDiagnosesArray(selectedAtt, partIndex);

      setTimeout(() => {
        debouncedAutoSaveDiagnosis(
          selectedAtt,
          visit?.ucid,
          updateVisit,
          partIndex,
          modifyDiagnosesArray
        );
      }, 100);
    }
  };

  const userdata = CurrentUser?.getDetails;

  const isAssigness = isEmpty(visit?.assignee);
  const selfAssigned =
    visit?.assignee?.role === "primary" &&
    visit?.assignee?.user_id === userdata?.user?.id;

  // const secondarySelfAssigned = visit?.assignee?.some(
  //   (item) => item.role === "secondary" && item.user_id === userdata?.user?.id
  // );

  const disableField = useMemo(
    () => (!isAssigness && selfAssigned && status !== "diagnosed") || assigned,
    [visit]
  );

  useEffect(() => {
    if (assingedID !== null) updateVisit();
  }, [assingedID]);

  // const disableButtonSecondarypath = isAssigness && secondarySelfAssigned;
  // try passing diagnosisAtt
  const DiagnosisFormCallBack = useCallback(
    () => (
      <DiagnosisForm
        visit={visit}
        updateVisit={updateVisit}
        fieldDisable={disableField}
        status={status}
      />
    ),
    [visit, disableField, updateVisit, status]
  );

  const AssigneeActionsCallBack = useCallback(
    () => (
      <AssigneeActions
        visit={visit}
        setAssigned={setAssigned}
        updateVisit={updateVisit}
        assigned={assigned}
        loading={updatingVisit}
      />
    ),
    [visit, setAssigned, assigned, updateVisit, updatingVisit]
  );

  useEffect(() => {
    return () => {
      setAssigned(false);
    };
  }, []);

  return (
    <>
      {status !== "diagnosed" && <AssigneeActionsCallBack />}

      <div className="flex flex-row items-center justify-start flex-wrap">
        {partOptions.length > 1 && (
          <div className="bg-white rounded-md p-1">
            <Tab.Group defaultIndex={1} manual>
              <Tab.List className="flex">
                {partOptions.map((part, index) => (
                  <Tab
                    onClick={() => {
                      setPartIndex(index);
                    }}
                    key={index}
                    className={classNames(
                      `py-2 px-4 focus:outline-none rounded-md`,
                      partOptions.length === 1
                        ? "bg-white text-black font-normal text-sm"
                        : partIndex === index
                        ? "bg-blue-400 text-white font-semibold text-base"
                        : "bg-white text-black font-normal text-sm"
                    )}
                  >
                    {part.label}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
          </div>
        )}
      </div>

      <div className="mx-auto my-2">
        <Select
          isDisabled={!disableField || status.includes("addendum")}
          ref={macroSelectRef}
          placeholder="Search for a Diagnosis Macro"
          cacheOptions
          options={macros}
          defaultOptions={macros}
          isLoading={loading}
          selectOption={onSelectOption}
          getOptionLabel={(option) => `${option.code} - ${option.name}`}
          getOptionValue={(option) => option.id}
          onInputChange={(value) => {
            if (value === "`") {
              setShowMacroViewer(true);
              setMacroSearchQuery("");
            } else {
              setMacroSearchQuery(value);
            }
          }}
          onChange={onSelectOption}
          autoFocus={true}
          components={{ DropdownIndicator }}
          menuIsOpen={!!macroSearchQuery}
          onKeyDown={(e) => {
            if (e.keyCode == 9 && e.shiftKey == false) {
              e.preventDefault();
              if (!selectedDiagnosisMacro) {
                const element = document.getElementById("filter-beginning");
                if (element) {
                  element.focus();
                }
              }
            }
          }}
          // formate the display value
          formatOptionLabel={(option, { context }) => {
            if (context === "value") {
              return option.code;
            } else {
              return `${option.code} - ${option.name}`;
            }
          }}
        />
        <DiagnosisFormCallBack />
      </div>
    </>
  );
}

export default function SearchDiagnosisMacro({
  selectedDiagnosisMacro,
  setSelectedDiagnosisMacro,
  updateVisit,
  updatingVisit,
  visit,
  status = "",
}) {
  // const data = CurrentUser?.getDetails;
  // console.log(visit.id, "hello");
  return (
    <div className="search-for-macro w-full items-center">
      <SelectedDiagnosisMacro
        selectedDiagnosisMacro={selectedDiagnosisMacro}
        setSelectedDiagnosisMacro={setSelectedDiagnosisMacro}
        updateVisit={updateVisit}
        updatingVisit={updatingVisit}
        visit={visit}
        status={status}
      />
    </div>
  );
}

const AssigneeActions = React.memo(
  ({ visit, setAssigned, updateVisit, assigned, loading }) => {
    // const [pathologistSearchQuery, setPathologistSearchQuery] = useState("");
    // const [secondaryPathId, setSecondaryPathId] = useState(null);
    // const { pathologists, loading: pathologistsLoading } = usePathologists(
    //   pathologistSearchQuery,
    //   secondaryPathId
    // );
    const userdata = CurrentUser?.getDetails;

    const isAssigness = isEmpty(visit?.assignee);

    const selfAssigned =
      visit?.assignee?.role === "primary" &&
      visit?.assignee?.user_id === userdata?.user?.id;

    // const secondarySelfAssigned = visit?.assignee?.some(
    //   (item) => item.role === "secondary" && item.user_id === userdata?.user?.id
    // );

    // const primaryAvail = visit?.assignee?.role === "primary";

    const otherPrimaryAssigne =
      visit?.assignee?.role === "primary" &&
      visit?.assignee?.user_id !== userdata?.user?.id;

    // const getSecondaryPath = (role ) =>
    //   visit?.assignee?.filter((e) => e?.role === role) ?? [];

    const assignUser = async (role = "primary", id) => {
      // if (role === "secondary" && getSecondaryPath()[0]?.id) {
      //   const data = await API.put(`/visits/${visit?.id}/update-assignee`, {
      //     user_id: id,
      //   });

      //   if (data?.error) {
      //     toast.error(data.error.message);
      //     setAssigned(false);
      //   } else {
      //     setAssigned(true);
      //     toast.success(data?.data?.message || "Assigned to you successfully!");
      //     updateVisit();
      //   }
      // } else

      const data = await API.post(`/visits/${visit?.id}/assign-pathologist`, {
        user_id: id,
        role: role,
      });

      if (data?.error) {
        toast.error(data.error.message);
        setAssigned(false);
      } else {
        setAssigned(true);
        toast.success(data?.data?.message || "Assigned to you successfully!");
        updateVisit();
      }
    };

    // const disableField =
    //   (isAssigness && selfAssigned) ||
    //   (isAssigness && secondarySelfAssigned) ||
    //   assigned;

    // setting the secondary on mount to avoid the miss-searching of data
    // useEffect(() => {
    //   const secondaryid =
    //     visit?.assignee?.filter((e) => e.role === "secondary") || [];
    //   setSecondaryPathId(secondaryid[0]?.user_id ?? null);
    // }, [visit]);

    if (loading) {
      return (
        <div className="h-20 w-full p-4 flex items-center justify-start">
          <Loader />
        </div>
      );
    }

    return (
      <div
        className={classNames(
          "flex flex-wrap items-center gap-8",
          !selfAssigned && !otherPrimaryAssigne && isAssigness ? "h-0" : "h-20"
        )}
      >
        {(!isAssigness && selfAssigned) || assigned || otherPrimaryAssigne ? (
          <div
            style={{
              flex: 0.5,
            }}
            className=""
          >
            <button
              disabled
              className="flex items-center gap-4 py-2 px-5 rounded-md text-base bg-red-400 text-white font-normal"
            >
              Assigned
              <FaUserCheck />
            </button>
          </div>
        ) : isAssigness &&
          !selfAssigned &&
          otherPrimaryAssigne ? null : isAssigness ? (
          <></>
        ) : // <button
        //   style={{
        //     flex: 0.5,
        //   }}
        //   className="py-2 px-4 rounded-md text-base ripple-bg-primary text-white font-normal"
        //   onClick={() => assignUser("primary", userdata?.user?.id)}
        // >
        //   Assign case to me
        // </button>
        null}
        <div className="flex-1 relative">
          {/* {(selfAssigned || isAssigness === 0) && (
            <Select
              isDisabled={!disableField}
              placeholder="Search pathologist"
              cacheOptions
              options={pathologists}
              defaultOptions={pathologists}
              defaultValue={
                getSecondaryPath(
                  secondarySelfAssigned ? "primary" : "secondary"
                )[0]
              }
              isLoading={pathologistsLoading}
              getOptionLabel={(option) =>
                `${option.first_name} - ${option.last_name}`
              }
              getOptionValue={(option) => option.id}
              onInputChange={(value) => {
                setPathologistSearchQuery(value);
              }}
              onChange={(val) => {
                assignUser("secondary", val?.id);
              }}
              components={{ DropdownIndicator }}
              menuIsOpen={!!pathologistSearchQuery}
            />
          )} */}

          {(otherPrimaryAssigne || selfAssigned) && (
            <p
              className={classNames(
                " text-gray-500 font-bold",
                otherPrimaryAssigne || selfAssigned
                  ? "text-base w-60"
                  : "text-xs absolute -bottom-12 left-0"
              )}
            >
              {otherPrimaryAssigne || selfAssigned
                ? `Assigned to ${visit?.assignee?.first_name} ${
                    visit?.assignee?.last_name
                  } on
                  ${format(
                    new Date(visit?.assignee?.updated_at),
                    "MM-dd-yyyy hh:mm a"
                  )}`
                : "Note: re-assign secondary pathologist"}
            </p>
          )}
        </div>
      </div>
    );
  }
);
