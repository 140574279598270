/* eslint-disable eqeqeq */
import { Form, Formik, Field } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CurrentUser } from "../../service/Auth";
import { useCaseTypeList, usePhysicianList } from "../../service/Fetchers";
import { Loader } from "../../service/Icon";
import { filterVisitListSchema } from "../../service/Validation";
import { FormDataField } from "../../stories/FormDataField";
import { FormInputField } from "../../stories/FormInputField";
import { GlobalVariablesContext } from "../../providers/GlobalProvider";
import { useFormik } from "formik";

const Filters = ({
  onChangeFilter,

  initialFilter,
  closeFilterPane,
}) => {
  const search = { placeholder: "Patient Name/Case ID" };
  const [physicianSearchQuery, setPhysicianSearchQuery] = useState("");
  const { users, loading: loadingPhyList } = usePhysicianList({
    searchQuery: physicianSearchQuery,
  });
  const { caseTypes, loading: loadingCaseTypes } = useCaseTypeList({
    perPageCount: 100,
  });
  const fieldValueRef = useRef(null);

  const onFormChange = async (values) => {
    try {
      await filterVisitListSchema.validate(values);
      onChangeFilter(values);
      closeFilterPane();
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = (setFieldValue) => {
    setPhysicianSearchQuery("");
    setFieldValue("physician_id", "");
    setFieldValue("visit_kind", "pending");
    if (CurrentUser.isAdmin()) {
      setFieldValue("visit_status", "all");
    }
    setFieldValue("search_key", "");
    setFieldValue("filter_start_date", new Date());
    setFieldValue("filter_end_date", new Date());
    setFieldValue("ordering", "asc");
    setFieldValue("sort_by", "updated_at");
    setFieldValue("visit_type_id", "");
    setFieldValue("all_cases", false);
    setFieldValue("assign_to_others", false);
  };

  // useEffect(() => {
  //   if (openFilter === false) resetForm(fieldValueRef.current);
  // }, [openFilter, fieldValueRef]);

  return (
    <Formik
      initialValues={initialFilter}
      onSubmit={onFormChange}
      validationSchema={filterVisitListSchema}
    >
      {({ setFieldValue, values }) => {
        fieldValueRef.current = setFieldValue;

        return (
          <Form>
            <div className="flex flex-col pt-2 justify-between space-y-4">
              {!CurrentUser.isAdmin() ? (
                <div className="border rounded-md mt-4">
                  <div className="p-1 px-2">
                    <p className="text-sm font-medium p-1">Filter By Status</p>
                    <div className="flex items-center gap-2 mt-2 text-xs text-gray-700">
                      <Field
                        disabled={false}
                        type="checkbox"
                        name={"all_cases"}
                        checked={values?.all_cases}
                        onChange={(e) => {
                          setFieldValue(`all_cases`, e.target.checked);
                          if (e.target.checked) {
                            setFieldValue("visit_kind", null);
                            setFieldValue(`assign_to_others`, false);
                          } else {
                            setFieldValue("visit_kind", "pending");
                          }
                        }}
                      />
                      All Cases
                    </div>
                    {/* <div
                      className="w-full border-b border-gray-300"
                      style={{ paddingBottom: "1rem" }}
                    ></div> */}
                    <FormInputField
                      title=" "
                      disabled={values.all_cases}
                      type="radio"
                      smVariant
                      name="visit_kind"
                      inputProps={{
                        tabIndex: 9,
                        disabled: values.all_cases,
                        col: true,
                      }}
                      options={[
                        {
                          name: "Pending",
                          value: "pending",
                        },
                        {
                          name: "Signed Out",
                          value: "past",
                        },
                        {
                          name: "Both",
                          value: "both",
                        },
                        {
                          name: "Pending Addendum",
                          value: "pending_addendum",
                        },
                        {
                          name: "Pending Amendment",
                          value: "pending_amendment",
                        },
                      ]}
                    />
                    {/* <div
                      className="w-full border-b border-gray-300"
                      style={{ marginTop: "-0.5rem" }}
                    ></div> */}
                    <div className="flex items-center gap-2 text-xs pb-2 text-gray-700">
                      <Field
                        disabled={values.all_cases}
                        type="checkbox"
                        name={"assign_to_others"}
                        checked={values?.assign_to_others}
                        onChange={(e) => {
                          setFieldValue(`assign_to_others`, e.target.checked);
                        }}
                      />
                      Assign to others
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full border-b border-gray-300"></div>
              )}
              <FormInputField
                type="autocomplete"
                name="physician_id"
                title="Referred By"
                autocompleteProps={{
                  setFieldValue,
                  renderCustomLabel: (item) =>
                    `${item.first_name} ${item.last_name}`,
                  valueKey: "id",
                  loading: loadingPhyList,
                  items: physicianSearchQuery?.length > 0 ? users : [],
                  value: physicianSearchQuery,
                  setValue: setPhysicianSearchQuery,
                }}
                inputProps={{
                  className: "pr-6 pl-2 placeholder_small",
                  placeholder: "Search Physician",
                  tabIndex: 1,
                  id: "filter-beginning",
                  onKeyDown: (e) => {
                    if (e.keyCode == 9 && e.shiftKey == true) {
                      e.preventDefault();
                      const grossing =
                        document.getElementById("grossing-macro-5");
                      if (grossing) {
                        grossing.focus();
                      } else {
                        const pathologistSaveButton = document.getElementById(
                          "submit-pathologist-button"
                        );
                        if (pathologistSaveButton) {
                          pathologistSaveButton.focus();
                        } else {
                          const pathologistSearchbar = document.getElementById(
                            "react-select-3-input"
                          );
                          if (pathologistSearchbar) {
                            pathologistSearchbar.focus();
                          }
                        }
                      }
                    }
                  },
                }}
                smVariant
              />
              {CurrentUser.isAdmin() && (
                <FormInputField
                  type="select"
                  name="visit_status"
                  title="Status"
                  options={[
                    { name: "All", value: "all" },
                    {
                      name: "Ready for grossing",
                      value: "accessioning_complete",
                    },
                    { name: "Diagnosed", value: "diagnosed" },
                    { name: "Grossed", value: "grossed" },
                  ]}
                  inputProps={{ tabIndex: 2 }}
                  smVariant
                />
              )}
              <div className="flex flex-row justify-between space-x-4">
                <FormInputField
                  type="date"
                  title="Date Range"
                  name="filter_start_date"
                  smVariant
                  inputProps={{ tabIndex: 3 }}
                />
                <FormInputField
                  type="date"
                  title=" "
                  name="filter_end_date"
                  smVariant
                  inputProps={{ tabIndex: 4 }}
                />
              </div>
              {loadingCaseTypes ? (
                <Loader />
              ) : (
                <FormInputField
                  type="select"
                  name="visit_type_id"
                  title="Case Type"
                  smVariant
                  inputProps={{ tabIndex: 5 }}
                  options={caseTypes.map((c) => ({
                    name: c.name,
                    value: `${c.id}`,
                  }))}
                />
              )}

              <FormInputField
                type="select"
                disabled={true}
                name="sort_by"
                smVariant
                inputProps={{ tabIndex: 6 }}
                options={[
                  { name: "Received Date", value: "received_date" },
                  { name: "Collected Date", value: "collected_date" },
                ]}
              />
              <FormInputField
                type="select"
                name="ordering"
                title="Order By"
                disabled={true}
                smVariant
                inputProps={{ tabIndex: 7 }}
                options={[
                  { name: "DESC", value: "desc" },
                  { name: "ASC", value: "asc" },
                ]}
              />
              <FormDataField
                className="flex-1"
                title=" "
                smVariant
                value={
                  <div className="relative bg-white rounded border border-gray-300 focus:border-gray-400 flex flex-row overflow-hidden p-05">
                    <Field
                      type="search"
                      name="search_key"
                      placeholder={search?.placeholder}
                      autoComplete="off"
                      tabIndex={8}
                      className="p-2 text-gray-700 flex-1 focus:outline-none bg-gray-50 placeholder_small text-xs"
                    />
                  </div>
                }
              />
              <div className="flex justify-between items-center space-x-4">
                <button
                  type="submit"
                  className="bg-primary text-white rounded font-lg px-4 py-3"
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="bg-primary text-white rounded font-lg px-4 py-3"
                  onClick={() => {
                    resetForm(setFieldValue);
                  }}
                >
                  Reset
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filters;
