import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "../assets/main.css";

var lastLoadedContentLength = 0;
export function paginate(current, total) {
  const center = [current - 2, current - 1, current, current + 1, current + 2],
    filteredCenter = center.filter((p) => p > 1 && p < total),
    includeThreeLeft = current === 5,
    includeThreeRight = current === total - 4,
    includeLeftDots = current > 5,
    includeRightDots = current < total - 4;

  // Adjust logic for multiple ellipsis and disabling on the last page
  if (includeThreeLeft) {
    filteredCenter.unshift(2);
    if (current > 5) {
      filteredCenter.unshift("...");
    }
  }
  if (includeThreeRight) {
    filteredCenter.push(total - 1);
    if (current < total - 4) {
      filteredCenter.push("...");
    }
  }

  // Ensure only one ellipsis on either side and remove if on the last page
  if (current === total - 4) {
    const ellipsisIndex = filteredCenter.indexOf("...");
    if (ellipsisIndex !== -1) {
      filteredCenter.splice(ellipsisIndex, 1);
    }
  }

  return [1, ...filteredCenter, total];
}

const loadingRows = (rows, columns) => {
  return _.fill(
    _.times(rows, _.stubArray),
    _.fill(
      _.times(columns, _.stubObject),
      <div className="p-3">
        <p className="h-6 bg-theme-gray rounded"></p>
      </div>
    )
  );
};

const TablePaginationLoading = () => (
  <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between  animate-pulse">
      <div className="h-6 rounded bg-theme-gray w-48"></div>
      <div className="flex flex-row rounded overflow-hidden">
        <div className="h-10 w-10 bg-theme-gray border-r-2 border-white"></div>
        <div className="h-10 w-10 bg-theme-gray border-r-2 border-white"></div>
        <div className="h-10 w-10 bg-theme-gray border-r-2 border-white"></div>
        <div className="h-10 w-10 bg-theme-gray border-r-2 border-white"></div>
        <div className="h-10 w-10 bg-theme-gray border-r-2 border-white"></div>
      </div>
    </div>
  </div>
);

const TablePagination = ({
  totalItems,
  itemsPerPage,
  currentpage,
  onPageChange,
}) => {
  let totalPages = Math.ceil(totalItems / itemsPerPage);
  if (totalPages === 1) return null;
  const pages = paginate(currentpage, totalPages);
  const commonNavClass =
    "relative inline-flex items-center py-2 border border-gray-300 bg-white text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 transition ease-in-out duration-150";
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          onClick={() => {
            onPageChange(currentpage - 1);
          }}
          className={`${commonNavClass} px-4 rounded-md text-gray-700  hover:text-gray-500 active:text-gray-700 ${
            currentpage === "1"
              ? "pointer-events-none opacity-50"
              : "opacity-100"
          }`}
        >
          Previous
        </button>
        <button
          onClick={() => {
            onPageChange(currentpage + 1);
          }}
          className={`ml-3 ${commonNavClass} px-4 rounded-md text-gray-700  hover:text-gray-500 active:text-gray-700 `}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm leading-5 text-gray-700">
            Showing&nbsp;
            <span className="font-medium mx-1">
              {itemsPerPage * (currentpage - 1) + 1}{" "}
            </span>
            to
            <span className="ml-1 font-medium">
              {currentpage === totalPages
                ? totalItems
                : itemsPerPage * currentpage}{" "}
            </span>
            of
            <span className="font-medium"> {totalItems} </span>
            results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex shadow-sm">
            <button
              onClick={() => {
                onPageChange(currentpage - 1);
              }}
              className={`${commonNavClass} rounded-l-md px-2 text-gray-500 hover:text-gray-400 ${
                currentpage === 1
                  ? "pointer-events-none opacity-50"
                  : "opacity-100"
              }`}
              aria-label="Previous"
            >
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {pages.map((pageNumber, idx) => (
              <button
                key={pageNumber}
                onClick={() => {
                  onPageChange(pageNumber);
                }}
                className={`-ml-px ${commonNavClass} px-4 font-bold  ${
                  currentpage === pageNumber
                    ? "text-black"
                    : "text-gray-600 hover:text-gray-400 active:text-gray-700 bg-gray-100"
                } `}
              >
                {pageNumber}
              </button>
            ))}

            <button
              onClick={() => {
                onPageChange(currentpage + 1);
              }}
              className={`-ml-px ${commonNavClass} px-2 rounded-r-md text-gray-500 hover:text-gray-400 active:text-gray-500 ${
                currentpage === totalPages
                  ? "pointer-events-none opacity-50"
                  : "opacity-100"
              }`}
              aria-label="Next"
            >
              <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
const Table = ({
  headers,
  rowContent,
  pagination,
  loading,
  className,
  theadClass,
  tbodyClass,
  ...rest
}) => {
  if (rowContent && rowContent.length)
    lastLoadedContentLength = rowContent.length;
  return (
    <section
      className={`bg-gray-200 w-full container m-auto rounded border border-gray-400 overflow-hidden ${className}`}
      {...rest}
    >
      <table className="w-full bg-white">
        <thead className="bg-primary text-xxs text-white tracking-widest uppercase">
          <tr className={theadClass || "h-16"}>
            {headers.map((title, index) => (
              <th className="text-center" key={index}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          className={`text-sm text-center text-gray-900 tracking-wide ${
            loading ? "animate-pulse" : ""
          }`}
        >
          {(loading
            ? loadingRows(
                rowContent && rowContent.length
                  ? rowContent.length
                  : lastLoadedContentLength
                  ? lastLoadedContentLength
                  : pagination.itemsPerPage,
                headers.length
              )
            : rowContent
          ).map((rowData, idx) => (
            <tr
              className={`${
                tbodyClass ? tbodyClass : "h-20"
              } hover:bg-cool-gray-200 transform duration-300 ${
                idx % 2 === 1 ? "bg-cool-gray-50" : "bg-white"
              }`}
              key={idx}
            >
              {rowData.map((columnData, cidx) => (
                <td key={cidx}>{columnData}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {pagination && !loading && <TablePagination {...pagination} />}
      {pagination && loading && <TablePaginationLoading {...pagination} />}
    </section>
  );
};

Table.propTypes = {
  /**
   * Table headers
   */
  headers: PropTypes.arrayOf(PropTypes.string),
  /**
   * Table content as array or arrays
   */
  rowContent: PropTypes.arrayOf(PropTypes.node),
  /**
   * pagination details
   */
  pagination: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    currentpage: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  }),
  /**
   * if table is fetching data
   */
  loading: PropTypes.bool,
};

Table.defaultProps = {
  headers: ["Header", "Header2"],
  rowContent: [
    [<p>R1 Column 1 content</p>, <p>R1 Column 2 content</p>],
    [<p>R2 Column 1 content</p>, <p>R2 Column 2 content</p>],
  ],
  pagination: null,
  loading: true,
};

export default Table;
