import React, { useState, useRef } from "react";
import { navigate } from "@reach/router";
import { Button } from "../../../stories/Button";
import { Formik, Form } from "formik";
import { addClinicLocationSchema } from "../../../service/Validation";
import { API } from "../../../network/API";
import { toast } from "../../../components/Toast";
import { FormInputField } from "../../../stories/FormInputField";
import { useClinicLocation } from "../../../service/Fetchers";
import { DashboardView } from "../../../stories/DashboardView";

export default function AddLocation({ id: location_id }) {
  const [loading, setLoading] = useState(false);
  const buttonTitle = location_id ? "Update Location" : "Add Location";
  const pageTitle = location_id
    ? `Update location #${location_id}`
    : "Create new location";
  const buttonRef = useRef(null);
  const {
    location,
    error: locationError,
    mutate: mutateStaff,
  } = useClinicLocation({
    location_id,
  });

  const initialFormValues = () => {
    return {
      location_name: location?.location_name ?? "",
      street1: location?.street1 ?? "",
      street2: location?.street2 ?? "",
      city: location?.city ?? "",
      state: location?.state ?? "",
      zipcode: location?.zipcode ?? "",
    };
  };

  const createOrUpdateStaff = async (values) => {
    if (location_id)
      return API.put(`/addresses/${location?.id}`, { address: values });
    return API.post(`/addresses`, {
      address: values,
    });
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    const { data, error } = await createOrUpdateStaff(values);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (location_id) {
        toast.success(`Updated ${values.location_name}!`);
        setTimeout(() => {
          navigate(`/physician/location/view/${location_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${values.location_name}!`);
      }
    }
    setLoading(false);
  };

  const locationLoaded = () => (location_id ? !!location : true);

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addClinicLocationSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <Form className="flex-1">
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  title="Location Name"
                  type="text"
                  name="location_name"
                  required
                />
              </div>
              <div>
                <span className="text-primary text-sm font-medium">
                  Address
                </span>
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  title="Address Line 1"
                  type="text"
                  name="street1"
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  title="Address Line 2"
                  type="text"
                  name="street2"
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField type="text" title="City" name="city" required />
                <FormInputField
                  type="text"
                  title="State"
                  name="state"
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10 w-1/2 pr-5">
                <FormInputField
                  type="number"
                  title="Zipcode"
                  name="zipcode"
                  required
                />
              </div>
            </div>

            <div className="flex flex-row justify-end mt-10">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const headerData = {
    states: location_id
      ? [
          { title: "Location List", link: "/physician/location" },
          {
            title: `Location #${location_id}`,
            link: `/physician/location/view/${location_id}`,
          },
          { title: "Edit" },
        ]
      : [
          { title: "Location List", link: "/physician/location" },
          { title: "Add" },
        ],
    title: pageTitle,
  };

  const errorData = locationError
    ? {
        show: true,
        subtitle: locationError
          ? "Unable to fetch roles"
          : "Unbable to fetch staff details",
        ctaOnClick: () => mutateStaff(),
      }
    : null;

  const loadingData = locationError ? false : !locationLoaded();

  return (
    <div className="w-full flex justify-center">
      <DashboardView
        headerData={headerData}
        content={content}
        loading={loadingData}
        errorData={errorData}
        className="w-full container p-10 bg-white"
      />
    </div>
  );
}
