import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";
import classNames from "classnames";
import { capitalize } from "lodash";
import { TiTick } from "react-icons/ti";
import { Button } from "./Button";
import { Edit } from "../service/Icon";
import { FaPlus } from "react-icons/fa";
import { CurrentUser } from "../service/Auth";

/**
 * Primary UI component for user interaction
 */
const CaseCard = ({
  caseID,
  patientName,
  selected,
  onSelect,
  className,
  loading,
  diagnosed,
  dateReceived,
  updateTitle,
  indicator = "",
  status,
  onIconButtonClick,
  navigate,
  ...props
}) => {
  const commonClass =
    "p-0.5 w-52 border flex flex-col items-left justify-evenly pl-1 cursor-pointer";
  const defaultClass = `border-gray-100 bg-white ${commonClass}`;
  const selectedClass = `border-primary bg-theme-green bg-opacity-25  ${commonClass}`;
  if (loading) {
    return (
      <div className="bg-white w-64 h-20 pl-5 mb-1">
        <div {...props} className={`animate-pulse flex-col space-y-4 py-3`}>
          <div className="h-5 w-40 bg-theme-gray border-white"></div>
          <div className="flex flex-row items-center justify-start pb-1">
            <span className="text-xs text-gray-600 mr-2">
              <div className="h-4 w-24 bg-theme-gray border-white"></div>
            </span>
            <span className="text-xs underline text-black">
              <div className="h-4 w-24 bg-theme-gray border-white"></div>
            </span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <button
      {...props}
      className={[selected ? selectedClass : defaultClass, className].join(" ")}
      onClick={() => (onSelect ? onSelect(caseID) : console.log("clicked"))}
    >
      {selected ? (
        <div className="w-full flex justify-between items-center">
          <div className="flex-1">
            <div className="flex flex-row items-center justify-start">
              <span className="underline font-medium text-xs text-primary">
                {caseID}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between space-x-2 pb-1">
              <span className="text-xxxs text-black">{patientName}</span>

              <span className="text-xxxs text-black">{dateReceived}</span>
            </div>
          </div>
          <div className="mx-1 flex justify-end items-center space-x-1">
            {/* Addendum and Amendment callback */}
            {status === "diagnosed" && indicator === "primary" && (
              <>
                <Button
                  className="md:p-1"
                  type="icon"
                  title="Amendment"
                  onClick={() => {
                    onIconButtonClick("Amendment");
                    navigate(`/case-view?amendment_case_id=${caseID}`);
                  }}
                  disabled={
                    diagnosed &&
                    (CurrentUser.isAdmin() || CurrentUser.isPathologist())
                  }
                >
                  <Edit className="w-2 h-2" />
                </Button>
                <Button
                  className="md:p-1"
                  type="icon"
                  title="Addendum"
                  onClick={() => {
                    onIconButtonClick("Addendum");
                    navigate(`/case-view?addendum_case_id=${caseID}`);
                  }}
                  disabled={
                    diagnosed &&
                    (CurrentUser.isAdmin() || CurrentUser.isPathologist())
                  }
                >
                  <FaPlus className="w-2 h-2" />
                </Button>
              </>
            )}
            {indicator.length ? <PathRoleIndicator title={indicator} /> : null}
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center w-full">
          <div>
            <div className="w-full flex justify-between items-center">
              <p className="underline font-medium text-xs text-primary">
                {caseID}
              </p>
            </div>

            <div className="w-full flex flex-row items-center justify-between space-x-2 pb-1">
              <span className="text-xxxs text-black">{patientName}</span>
              <span className="text-xxxs text-black">{dateReceived}</span>
            </div>
          </div>
          {indicator?.length ? (
            <PathRoleIndicator title={indicator} className="mr-1" />
          ) : null}
        </div>
      )}
    </button>
  );
};

const PathRoleIndicator = React.memo(({ title = "primary", className }) => {
  if (title === "primary") {
    return (
      <p
        title={`${capitalize(title)} Pathologist`}
        style={{
          fontSize: "8px",
        }}
        className={classNames(
          "cursor-default rounded-full w-4 h-4 flex justify-center items-center ripple-bg-primary font-medium text-white",
          className
        )}
      >
        <TiTick size={10} />
      </p>
    );
  }
});

CaseCard.propTypes = {
  /**
   * case id
   */
  caseID: PropTypes.string,
  /**
   * patient name
   */
  patientName: PropTypes.string,
  /**
   * to show dummy loading data
   */
  loading: PropTypes.bool,
  /**
   * selection state
   */
  selected: PropTypes.bool,
  /**
   * on select callback
   */
  onSelect: PropTypes.func,
};

CaseCard.defaultProps = {
  selected: false,
};

export default React.memo(CaseCard);

// updateTitle("Amendment");
// setOpenAddendum(true);
// updateTitle("Add Addendum");
// setOpenAddendum(true);
