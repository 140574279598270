import React from "react";
import PropTypes from "prop-types";
import "../assets/main.css";

/**
 * Primary UI component for user interaction
 */
export const Status = ({ type, children, outline, ...props }) => {
  const transparentStyle = "bg-transparent text-xs px-0 py-0 font-medium";
  const outlinedStyle = `bg-${type} text-${type} bg-opacity-25 px-4 py-2 font-bold rounded-full text-sm  `;
  return (
    <span
      {...props}
      className={`text-center inline-block ${
        outline ? outlinedStyle : transparentStyle
      }`}
    >
      {children}
    </span>
  );
};

Status.propTypes = {
  /**
   * tailwind color
   */
  type: PropTypes.string,
};

Status.defaultProps = {
  type: "yellow-600",
  outline: true,
};
