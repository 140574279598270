import React, { useState, useRef } from "react";
import { Button } from "../../stories/Button";
import { Formik, Form } from "formik";
import { addCPTCodeSchema } from "../../service/Validation";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import { FormInputField } from "../../stories/FormInputField";
import { useCPTCode } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import { navigate } from "@reach/router";

export default function AddCPTCode({ id: cpts_id }) {
  const [loading, setLoading] = useState(false);
  const buttonTitle = cpts_id ? "Update" : "Create CPT Code";
  const pageTitle = cpts_id ? `Update CPT code #${cpts_id}` : "Create CPT Code";
  const buttonRef = useRef(null);
  const {
    cptCode,
    loading: caseLoading,
    mutate,
    isValidating,
    error,
  } = useCPTCode({
    cpts_id,
  });

  const initialFormValues = () => {
    return {
      code: cptCode?.code ?? "",
      description: cptCode?.description ?? "",
    };
  };

  const createOrUpdateCaseType = async (values) => {
    if (cpts_id) return API.put(`/cpts/${cpts_id}`, values);
    return API.post("/cpts", values);
  };

  const onSubmit = async (values, actions) => {
    console.log("submitted");
    setLoading(true);
    const { data, error } = await createOrUpdateCaseType(values);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (cpts_id) {
        toast.success(`Updated ${values.code}!`);
        setTimeout(() => {
          navigate(`/dashboard/cpt-codes/view/${cpts_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${values.code}!`);
      }
    }
    setLoading(false);
  };

  const headerData = {
    states: cpts_id
      ? [
          { title: "CPT Code List", link: "/dashboard/cpt-codes" },
          {
            title: `CPT Code #${cpts_id}`,
            link: `/dashboard/cpt-codes/view/${cpts_id}`,
          },
          { title: "Edit" },
        ]
      : [
          { title: "CPT Code List", link: "/dashboard/cpt-codes" },
          { title: `Add` },
        ],
    title: pageTitle,
  };

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addCPTCodeSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <Form className="flex-1">
            <div className="flex flex-col space-y-2">
              <FormInputField
                type="text"
                name="code"
                required
                inputProps={{ className: "uppercase" }}
                disabled={!!cpts_id}
              />
              <FormInputField type="textarea" name="description" required />
            </div>

            <div className="flex flex-row justify-between mt-10">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to fetch CPT Code details",
        ctaOnClick: mutate,
      }
    : null;

  const loadingData = cpts_id ? caseLoading || isValidating : false;

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      loading={loadingData}
      errorData={errorData}
    />
  );
}
