import { Form, Formik } from "formik";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormDataField } from "../stories/FormDataField";
import { FormInputField } from "../stories/FormInputField";
import { format } from "date-fns";
import classNames from "classnames";
import { toast } from "./Toast";
import { API } from "../network/API";
import { Button } from "../stories/Button";
import _ from "lodash";
import {
  diagnoseMacroSchema,
  diagnoseMacroSchemaSignedOut,
} from "../service/Validation";
import { DiagnosisContext } from "../providers/DiagnosisFormProvider";
import { useCPTCodeList, useICDCodeList } from "../service/Fetchers";
import { GlobalVariablesContext } from "../providers/GlobalProvider";
import { debounce } from "../service/Helper";
import { numberToAlphabet } from "../pages/CaseView/SlideViewer";

export const autoSave = async (
  diagnosesAttr,
  id,
  updateVisit,
  index,
  modifyDiagnosesArray,
  setIsSelect
) => {
  const { error, data } = await API.put("/visits/save-as-draft", {
    visit: {
      diagnoses_attributes:
        diagnosesAttr?.length > 0 ? diagnosesAttr : [diagnosesAttr],
      ucid: id,
      ...(diagnosesAttr?.length > 0 && {
        amendment_comment: diagnosesAttr[0]?.amendment_comment || "",
        addendum_comment: diagnosesAttr[0]?.addendum_comment || "",
      }),
    },
  });
  if (error) {
    // submitButton.current.shake();
    if (error.message) toast.error(error.message);
  }

  if (data && data?.message) {
    setIsSelect?.(false);
    updateVisit?.();
    // console.log(data?.diagnoses[index]?.id);
    modifyDiagnosesArray?.({
      id: data?.diagnoses[index]?.id,
    });
    toast.success(data?.message || "Progress saved successfully!");
    // setSelectedDiagnosisMacro(null);
  }
  if (error) {
    setIsSelect?.(false);
    toast.error("Update is not made, please try again");
  }
};

export default function DiagnosisForm({
  visit,
  updateVisit,
  fieldDisable,
  status = "",
}) {
  const [searchCpt, setSearchQuery] = useState("");
  const [searchIcd, setIcdSearchQuery] = useState("");
  const [addendumFocus, setAddendumFocus] = useState(false);

  const { diagnosesAtt, modifyDiagnosesArray, needRefresh, setIsSelect } =
    useContext(DiagnosisContext);
  const { selectedSpecimenTab: partIndex } = useContext(GlobalVariablesContext);

  const { cptCodes, loading: loadingCPTList } = useCPTCodeList({
    searchQuery: searchCpt,
  });
  const { icdCodes, loading: loadingICDList } = useICDCodeList({
    searchQuery: searchIcd,
  });

  const submitButton = useRef(null);

  const debouncedAutoSaveDiagnosis = debounce(autoSave, 100); // 500ms delay

  const isDiagnosed = useMemo(
    () =>
      status === "diagnosed_and_pending_addendum" ||
      status === "diagnosed_and_pending_amendment",
    [status]
  );
  const addendumField =
    status === "diagnosed_and_pending_addendum"
      ? "addendum_comment"
      : "amendment_comment";

  const initialValues = useMemo(() => {
    return diagnosesAtt?.[partIndex];
  }, [diagnosesAtt, partIndex]);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={
          isDiagnosed
            ? diagnoseMacroSchemaSignedOut(
                status === "diagnosed_and_pending_addendum"
              )
            : diagnoseMacroSchema
        }
        onSubmit={async (values) => {
          const key = "/visits/diagnosed";

          const { error, data } = await API.put(key, {
            visit: {
              diagnoses_attributes: diagnosesAtt,
              ucid: visit?.ucid,
              ...(values?.amendment_comment && {
                amendment_comment: values?.amendment_comment || "",
              }),
              ...(values?.addendum_comment && {
                addendum_comment: values?.addendum_comment || "",
              }),
            },
          });
          if (error) {
            submitButton.current.shake();
            if (error.message) toast.error(error.message);
          }
          if (data) {
            toast.success(data?.message || "Signed Out");
            updateVisit();
            setTimeout(() => {
              window.location.href = "/case-view";
            }, 1500);
          }
        }}
      >
        {({ values, setFieldTouched, setFieldValue }) => {
          return (
            <Form>
              {/* <pre>{JSON.stringify(values, null, 8)}</pre> */}
              <div className="my-2 p-3 border rounded-sm bg-gray-50 bg-opacity-50">
                <div className="flex flex-col space-y-2 selected-macro w-full">
                  <div
                    className={`${
                      visit && visit?.specimen_demographics?.length > 0
                        ? "flex"
                        : "hidden"
                    } flex-row space-x-4`}
                  >
                    <FormDataField
                      className="flex-1"
                      title="Procedure"
                      value={
                        visit && visit?.specimen_demographics?.length > 0
                          ? visit?.specimen_demographics[partIndex]?.procedure
                              ?.name
                          : "--"
                      }
                    />
                    <FormDataField
                      className="flex-1"
                      title="Location"
                      value={
                        visit && visit?.specimen_demographics?.length > 0
                          ? visit?.specimen_demographics[partIndex]?.site
                              ?.description
                          : "--"
                      }
                    />
                  </div>
                  <div
                    className={`${
                      visit && visit?.specimen_demographics?.length > 0
                        ? "flex"
                        : "hidden"
                    } flex-row space-x-4`}
                  >
                    <FormDataField
                      className="flex-1"
                      title="Grossed At"
                      value={
                        visit && visit?.grossings?.length > 0
                          ? format(
                              new Date(visit?.grossings[partIndex]?.grossed_at),
                              "MM-dd-yyyy"
                            )
                          : "--"
                      }
                    />

                    <FormDataField
                      className="flex-1"
                      title="Grosser"
                      value={
                        visit && visit?.grossings?.length > 0
                          ? `${visit?.grossings[partIndex]?.grosser?.last_name}, ${visit?.grossings[partIndex]?.grosser?.first_name}`
                          : "--"
                      }
                    />
                  </div>
                  <FormInputField
                    disabled={status?.includes("addendum") || !fieldDisable}
                    required
                    title="Grossing Description"
                    type="textarea"
                    name="grossing_description"
                    inputProps={{
                      onBlur: () => {
                        modifyDiagnosesArray(
                          {
                            grossing_description: values?.grossing_description,
                          },
                          partIndex
                        );
                        // if (!isDiagnosed)
                        debouncedAutoSaveDiagnosis(
                          [values],
                          visit?.ucid,
                          updateVisit,
                          partIndex,
                          modifyDiagnosesArray,
                          setIsSelect
                        );
                      },
                    }}
                    smVariant
                  />
                  <FormInputField
                    disabled={status?.includes("addendum") || !fieldDisable}
                    smVariant
                    required
                    title="Diagnosis"
                    name="title"
                    inputProps={{
                      className: "py-2.6",
                      onBlur: () => {
                        modifyDiagnosesArray(
                          { title: values?.title },
                          partIndex
                        );
                        // if (!isDiagnosed)
                        debouncedAutoSaveDiagnosis(
                          [values],
                          visit?.ucid,
                          updateVisit,
                          partIndex,
                          modifyDiagnosesArray,
                          setIsSelect
                        );
                      },
                    }}
                  />
                  <FormInputField
                    title="Microscopic Description"
                    type="textarea"
                    name="description"
                    disabled={status?.includes("addendum") || !fieldDisable}
                    inputProps={{
                      onBlur: () => {
                        modifyDiagnosesArray(
                          {
                            description: values?.description,
                          },
                          partIndex
                        );
                        debouncedAutoSaveDiagnosis(
                          [values],
                          visit?.ucid,
                          updateVisit,
                          partIndex,
                          modifyDiagnosesArray,
                          setIsSelect
                        );
                      },
                    }}
                    required
                    smVariant
                  />
                  <FormInputField
                    title="Diagnosis Notes"
                    type="textarea"
                    name="notes"
                    disabled={status?.includes("addendum") || !fieldDisable}
                    inputProps={{
                      onBlur: () => {
                        modifyDiagnosesArray(
                          { notes: values?.notes },
                          partIndex
                        );
                        // if (!isDiagnosed)
                        debouncedAutoSaveDiagnosis(
                          [values],
                          visit?.ucid,
                          updateVisit,
                          partIndex,
                          modifyDiagnosesArray,
                          setIsSelect
                        );
                      },
                    }}
                    smVariant
                  />
                  <div>
                    <FormDataField
                      className="mb-2.5"
                      title="Clinical history"
                      value={
                        visit?.specimen_demographics &&
                        visit.specimen_demographics[partIndex]?.clinical_notes
                          ?.length > 0
                          ? visit.specimen_demographics[partIndex]
                              ?.clinical_notes
                          : "--"
                      }
                    />
                  </div>
                  <div className="flex flex-row space-x-4">
                    <div
                      className={classNames("w-full")}
                      id="keep-calendar-open"
                    >
                      <FormInputField
                        type="custom-date"
                        name="diagnosis_date"
                        disabled={!fieldDisable || isDiagnosed}
                        required
                        smVariant
                        customDateProps={{
                          onCalendarClose: () => {
                            modifyDiagnosesArray(
                              {
                                diagnosis_date: values?.diagnosis_date,
                              },
                              partIndex
                            );
                            // if (!isDiagnosed)
                            if (values?.diagnosis_date) {
                              debouncedAutoSaveDiagnosis(
                                [values],
                                visit?.ucid,
                                updateVisit,
                                partIndex,
                                modifyDiagnosesArray,
                                setIsSelect
                              );
                            }
                          },

                          format: "MM-dd-yyyy",
                          dateValue: values?.diagnosis_date,
                          dateOnChange: (val) => {
                            setFieldTouched("diagnosis_date", true);
                            setFieldValue("diagnosis_date", val);
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div>
                      <FormInputField
                        className="w-full"
                        type="custom-select"
                        name="cpt_codes"
                        title="CPT CODE"
                        disabled={status?.includes("addendum") || !fieldDisable}
                        required
                        options={cptCodes}
                        selectProps={{
                          isDisabled:
                            status?.includes("addendum") || !fieldDisable,

                          onBlur: () => {
                            // if (!isDiagnosed)
                            debouncedAutoSaveDiagnosis(
                              [values],
                              visit?.ucid,
                              updateVisit,
                              partIndex,
                              modifyDiagnosesArray,
                              setIsSelect
                            );
                          },

                          styles: {
                            multiValueLabel: (style) => ({
                              ...style,
                              maxWidth: 90,
                            }),
                          },
                          value: diagnosesAtt?.[partIndex]?.cpt_codes,
                          isMulti: true,
                          label: (option) => `${option.code}`,
                          getOptionLabel: (option) => `${option.code}`,
                          getOptionValue: (option) => `${option.id}`,
                          onInputChange: setSearchQuery,
                          onChange: (opt) => {
                            if (opt === null) {
                              setFieldValue("cpt_codes", []);
                              modifyDiagnosesArray(
                                {
                                  cpt_codes: [],
                                },
                                partIndex
                              );
                            } else {
                              setFieldValue(
                                "cpt_codes",
                                opt?.map((e) => e)
                              );
                              modifyDiagnosesArray(
                                {
                                  cpt_codes: opt?.map((e) => e),
                                },
                                partIndex
                              );
                            }
                          },
                          isLoading: loadingCPTList,
                        }}
                      />
                    </div>

                    <FormInputField
                      className="w-full"
                      type="custom-select"
                      name="icd10_codes"
                      title="ICD CODE"
                      required
                      options={icdCodes}
                      selectProps={{
                        isDisabled:
                          status?.includes("addendum") || !fieldDisable,

                        onBlur: () => {
                          // if (!isDiagnosed)
                          debouncedAutoSaveDiagnosis(
                            [values],
                            visit?.ucid,
                            updateVisit,
                            partIndex,
                            modifyDiagnosesArray,
                            setIsSelect
                          );
                        },

                        styles: {
                          multiValueLabel: (style) => ({
                            ...style,
                            maxWidth: 90,
                          }),
                        },

                        value: diagnosesAtt?.[partIndex]?.icd10_codes,
                        isMulti: true,
                        label: (option) => `${option.code}`,
                        getOptionLabel: (option) => `${option.code}`,
                        getOptionValue: (option) => `${option.id}`,
                        onInputChange: setIcdSearchQuery,
                        onChange: (opt) => {
                          if (opt === null) {
                            setFieldValue("icd10_codes", []);
                            modifyDiagnosesArray(
                              {
                                icd10_codes: [],
                              },
                              partIndex
                            );
                          } else {
                            setFieldValue(
                              "icd10_codes",
                              opt?.map((e) => e.code)
                            );
                            modifyDiagnosesArray(
                              {
                                icd10_codes: opt?.map((e) => e),
                              },
                              partIndex
                            );
                          }
                        },
                        isLoading: loadingICDList,
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-8">
                    <FormInputField
                      disabled={status?.includes("addendum") || !fieldDisable}
                      smVariant
                      required
                      title="Macro Code"
                      name="code"
                      inputProps={{
                        className: "py-2.6 uppercase",

                        onBlur: () => {
                          modifyDiagnosesArray(
                            {
                              code: values?.code,
                            },
                            partIndex
                          );
                          // if (!isDiagnosed)
                          debouncedAutoSaveDiagnosis(
                            [values],
                            visit?.ucid,
                            updateVisit,
                            partIndex,
                            modifyDiagnosesArray,
                            setIsSelect
                          );
                        },
                      }}
                    />
                    <Button
                      className="text-center  h-11"
                      disabled={
                        (values?.code != values?.code?.length) === 0 ||
                        // values?.description?.length === 0 ||
                        values?.title?.length === 0 ||
                        !fieldDisable ||
                        status?.includes("addendum")
                      }
                      onClick={async () => {
                        const { error, data } = await API.post(
                          "/visits/save-macro",
                          {
                            visit: {
                              diagnoses_attributes: [
                                {
                                  ...diagnosesAtt[partIndex],
                                  label: numberToAlphabet(partIndex),
                                },
                              ],
                              ucid: visit.ucid,
                            },
                          }
                        );

                        if (error) {
                          submitButton.current.shake();
                          if (error.message) toast.error(error.message);
                        }
                        if (data) {
                          toast.success(`Macro Save Successfully!`);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode == 9 && e.shiftKey == false) {
                          e.preventDefault();
                          const element =
                            document.getElementById("filter-beginning");
                          if (element) {
                            element.focus();
                          }
                        }
                      }}
                    >
                      Save Macro
                    </Button>
                  </div>
                </div>
              </div>

              {isDiagnosed && (
                <div className="my-2 bg-gray-50 p-3 border">
                  <FormInputField
                    title={
                      addendumField === "amendment_comment"
                        ? "Reason for amendment"
                        : "addendum comment"
                    }
                    type="textarea"
                    name={addendumField}
                    disabled={!fieldDisable}
                    required
                    inputProps={{
                      onFocus: () => {
                        setAddendumFocus(true);
                      },
                      onBlur: () => {
                        modifyDiagnosesArray(
                          {
                            [addendumField]:
                              visit?.[addendumField] || values?.[addendumField],
                          },
                          partIndex,
                          true
                        );
                        if (values?.[addendumField].length > 0)
                          debouncedAutoSaveDiagnosis(
                            [values],
                            visit?.ucid,
                            updateVisit,
                            partIndex,
                            modifyDiagnosesArray,
                            setIsSelect
                          );

                        setAddendumFocus(false);
                      },
                    }}
                    smVariant
                  />
                </div>
              )}

              <div className="flex justify-center items-center">
                <Button
                  formButton
                  ref={submitButton}
                  loading={false}
                  id="submit-pathologist-button"
                  className="text-center flex-shrink-0 h-10"
                  disabled={
                    !fieldDisable ||
                    addendumFocus ||
                    diagnosesAtt.some(
                      (diagnose) =>
                        diagnose?.code?.length === 0 ||
                        diagnose.grossing_description?.length === 0 ||
                        diagnose.icd10_codes.length === 0 ||
                        diagnose.cpt_codes.length === 0 ||
                        diagnose.title?.length === 0 ||
                        diagnose.description?.length === 0
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode == 9 && e.shiftKey == false) {
                      e.preventDefault();
                      const element =
                        document.getElementById("filter-beginning");
                      if (element) {
                        element.focus();
                      }
                    }
                  }}
                >
                  Sign Out
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
