import React, { useState, useRef } from "react";
import Header from "../../stories/Header";
import { Button } from "../../stories/Button";
import { toast } from "../../components/Toast";
import { API } from "../../network/API";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { changePasswordSchema } from "../../service/Validation";
import { BreadcrumbLink } from "../../stories/BreadcrumbLink";

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const changeButton = useRef(null);
  const changePassword = async ({ current_password, new_password }) => {
    setLoading(true);
    const { error, data } = await API.post("/auth/change-password", {
      current_password,
      new_password,
    });

    if (error) {
      changeButton.current.shake();
      if (error.message) toast.error(error.message);
    }
    if (data) {
      toast.success("Password changed successfully");
      setTimeout(() => {
        window.location.href = "/profile";
      }, 1500);
    }
    setLoading(false);
  };

  return (
    <div className="absolute bg-theme-gray flex flex-col w-full h-full">
      <Header className="flex-shrink-0" />
      <div className="flex flex-col items-center mt-24 w-full">
        <Formik
          initialValues={{
            current_password: "",
            new_password: "",
            confirm_password: "",
          }}
          validationSchema={changePasswordSchema}
          onSubmit={(data) => {
            changePassword(data);
          }}
        >
          {(props) => {
            return (
              <Form className="flex flex-col max-w-xl">
                <span className="text-gray-800 antialiased font-bold text-lg mb-10">
                  {/* My Profile {">"} Change Password */}
                  <BreadcrumbLink
                    states={[
                      { title: "My Profile", link: "/profile" },
                      { title: `Change password` },
                    ]}
                  />
                </span>
                <div className="flex flex-col w-full">
                  <Field
                    className="form-input rounded-b-none m-0 flex-1 font-light text-sm"
                    type="password"
                    name="current_password"
                    placeholder="Current Password"
                    required={true}
                  />
                  <Field
                    className="form-input rounded-t-none rounded-b-none m-0 border-t-0 flex-1 font-light text-sm"
                    type="password"
                    name="new_password"
                    placeholder="New Password"
                    required={true}
                  />
                  <Field
                    className="form-input rounded-t-none m-0 border-t-0 flex-1 font-light text-sm"
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm New Password"
                    required={true}
                  />
                  <ErrorMessage
                    name="current_password"
                    component="span"
                    className="text-red-500 text-xs font-hairline mt-1"
                  />
                  <ErrorMessage
                    name="new_password"
                    component="span"
                    className="text-red-500 text-xs font-hairline mt-1"
                  />
                  <ErrorMessage
                    name="confirm_password"
                    component="span"
                    className="text-red-500 text-xs font-hairline mt-1"
                  />
                </div>
                <div className="mt-5 w-full flex justify-center">
                  <Button
                    className="rounded-full w-64 text-center antialiased text-sm font-bold justify-center"
                    ref={changeButton}
                    formButton
                    loading={loading}
                  >
                    Change Password
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
