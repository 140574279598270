import React, { useState } from "react";
import { FormDataField } from "../../../stories/FormDataField";
import { API } from "../../../network/API";
import { toast } from "../../../components/Toast";
import ConfirmDialog from "../../../stories/ConfirmDialog";
import { useClinicLocation } from "../../../service/Fetchers";
import { getRoleNames } from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";
import { CurrentUser } from "../../../service/Auth";
import Table from "../../../stories/Table";
import { format } from "date-fns";
import { Link } from "@reach/router";
import { StatusTag } from "../../../stories/StatusTag";

const ViewLocation = ({ id: location_id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const { location, loading, error, mutate } = useClinicLocation({
    location_id,
  });
  const deactivateLocation = async () => {
    setIsDeactivating(true);
    const { data, error } = await API.put(
      `/users/${CurrentUser.getDetails?.user.id}/toggle-activation-address/${location_id}`
    );
    if (error) {
      if (error.message) toast.error(error.message);
    }
    if (data) {
      mutate();
      toast.info(`${location.location_name} is Deactivated`);
    }
    setIsDeactivating(false);
  };

  const onDeactivate = () => {
    setIsOpen(true);
  };

  const confirmDeactivate = async () => {
    setIsOpen(false);
    await deactivateLocation();
  };

  const headerData = {
    constructiveCTA:
      !location || location.deactivated
        ? null
        : {
            link: `/physician/location/edit/${location_id}`,
            title: "Edit",
          },
    destructiveCTA:
      !location || location.deactivated
        ? null
        : {
            onClick: onDeactivate,
            title: "Deactivate",
            loading: isDeactivating,
          },
    states: [
      { title: "Location List", link: "/physician/location" },
      { title: `Location #${location_id}` },
    ],
    title: location ? `${location.location_name}` : "Location",
  };

  const errorData = error
    ? {
        show: true,
        subtitle: "Unable to load location details",
        ctaOnClick: mutate,
        style: { height: "auto" },
      }
    : null;

  const content = (
    <div className="flex flex-col space-y-5">
      <div className="flex flex-row">
        <FormDataField
          className="w-1/4"
          title="Name"
          value={
            <span className="font-bold">{`${location?.location_name}`}</span>
          }
        />
        <FormDataField
          className="w-1/4"
          title="PhyLoc"
          value={<span className="font-bold">{location?.id}</span>}
        />
        <FormDataField
          className="w-1/4"
          title="Date Added"
          value={
            location?.created_at &&
            format(new Date(location?.created_at), "dd-MMM-yyyy")
          }
        />
      </div>
      <div className="flex flex-row">
        <FormDataField
          className="w-1/3"
          title="Address"
          value={
            <div className="flex flex-col">
              <p>{location?.street1}</p>
              <p>{location?.street2}</p>
              <p>
                {location?.city}, {location?.state} {location?.zipcode}
              </p>
            </div>
          }
        />
      </div>
      {location?.staffs?.length > 0 && (
        <div className="flex flex-row">
          <Table
            headers={[
              "Staff ID",
              "Staff Name",
              "Date Added",
              "Role",
              "Added by",
              "Status",
            ]}
            loading={loading}
            rowContent={location.staffs.map((staff) => [
              <p className="underline text-primary">
                <Link to={`/physician/staff/view/${location?.id}/${staff.id}`}>
                  {staff.uuid}
                </Link>
              </p>,
              <p>{`${staff.first_name} ${staff.last_name}`}</p>,
              <p>{format(new Date(staff.created_at), "dd-MMM-yyyy")}</p>,
              <p>{getRoleNames(staff?.role.name)}</p>,
              <p>
                {staff?.added_by?.first_name} {staff?.added_by?.last_name}
              </p>,
              <StatusTag type={staff?.deactivated ? "red" : "green"}>
                {staff?.deactivated ? "Deactivated" : "Active"}
              </StatusTag>,
            ])}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        title="Are you sure?"
        description="Once deactivated the location cannot be activated again. All data will still be available, but cannot be edited"
        cancelTitle="Cancel"
        destrutctiveTitle="Deactivate"
        onPressDestructive={confirmDeactivate}
        onPressCancel={() => setIsOpen(false)}
      />
      <div className="w-full flex justify-center">
        <DashboardView
          headerData={headerData}
          loading={loading}
          content={location ? content : null}
          errorData={errorData}
          className="w-full p-10 container bg-white"
        />
      </div>
    </>
  );
};

export default ViewLocation;
