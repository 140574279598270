import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../stories/Button";
import { Formik, Form, FieldArray } from "formik";
import { addPhysicianSchema } from "../../service/Validation";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import _ from "lodash";
import { FormInputField } from "../../stories/FormInputField";
import { usePhysicianRole, useStaff } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import ConfirmDialog from "../../stories/ConfirmDialog";
import { navigate } from "@reach/router";
import { Loader } from "../../service/Icon";
import { useGetClientList } from "../../service/Api_services/GetClients/useGetClients";
import { getURLParams } from "../../service/Helper";

export const adddressFieldItems = {
  address_attributes: {
    location_name: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    zipcode: "",
  },
};

export default function AddPhysician({ id: user_id }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTogglingActivation, setIsTogglingActivation] = useState(0);
  const [dialogData, setDialogData] = useState({});

  const [primPhysicianSearchQuery, setPrimPhysicianSearchQuery] = useState("");
  const [selectedPrimaryPhysician, setSelectedPrimaryPhysician] =
    useState(null);

  const perPageCount = 10;
  const currentPage =
    primPhysicianSearchQuery?.length > 0 ? 1 : getURLParams("page") ?? 1;
  const {
    clients,
    error,
    isValidating,
    loading: clientLoading,
    mutate,
  } = useGetClientList({
    currentPage,
    perPageCount,
    searchQuery: primPhysicianSearchQuery,
    list: "all",
  });

  const buttonTitle = user_id ? "Update" : "Create Provider";
  const pageTitle = user_id
    ? `Update provider #${user_id}`
    : "Create new provider";
  const buttonRef = useRef(null);
  const {
    roles,
    error: roleError,
    mutate: mutateRole,
    loading: loadingPhysicianRole,
  } = usePhysicianRole();
  const {
    user,
    error: userError,
    mutate: mutatePhysician,
    loading: loadingPhysician,
  } = useStaff({
    user_id,
    list: "all",
  });

  const initialFormValues = () => {
    return {
      first_name: user?.first_name ?? "",
      last_name: user?.last_name ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      gender: user?.gender ?? "default",
      // dob: user?.dob ?? "",
      client_id: user?.client?.id ?? "",
      npi_number: user?.npi_number ?? "",
      role_id: user?.role?.idclient_id
        ? `${user?.role?.id}`
        : roles && `${roles[0]?.id}`,
      // accession_prefix: user?.accession_prefix ?? "",
      // user_addresses_attributes: user?.user_addresses?.map((add) => ({
      //   id: add.id,
      //   isDeactivated: add.address?.deactivated_at,
      //   address_attributes: {
      //     id: add.id,
      //     ...add.address,
      //   },
      // })) ?? [adddressFieldItems],
    };
  };

  // const toggleActivationAddress = async (address, type) => {
  //   const togglingAddressId = address.id;
  //   setIsTogglingActivation(togglingAddressId);
  //   const { data, error } = await API.put(
  //     `/users/${user_id}/toggle-activation-address/${togglingAddressId}`
  //   );
  //   if (error) {
  //     if (error.message) toast.error(error.message);
  //   }
  //   if (data) {
  //     // mutatePhysician();
  //     setTimeout(() => {
  //       navigate(`/dashboard/referring-providers/view/${user_id}`);
  //     }, 1500);
  //     toast.info(
  //       `Address is ${type === "activate" ? "Activated" : "Deactivated"} `
  //     );
  //   }
  //   setIsTogglingActivation(0);
  //   mutatePhysician();
  // };

  // const onToggleActivation = async (address, type) => {
  //   if (type === "activate") {
  //     setDialogData({
  //       description: "Once activated the physician can use this address again",
  //       construtctiveTitle: "Activate",
  //       onPressConstructive: () => confirmToggleActivation(address, type),
  //     });
  //   } else {
  //     setDialogData({
  //       description:
  //         "Once deactivated the physician cannot use this address again. All data will still be available to admin, but cannot be edited",
  //       destrutctiveTitle: "Deactivate",
  //       onPressDestructive: () => confirmToggleActivation(address, type),
  //     });
  //   }
  //   setIsOpen(true);
  // };

  // const confirmToggleActivation = async (address, type) => {
  //   setIsOpen(false);
  //   await toggleActivationAddress(address, type);
  // };

  const createOrUpdatePhysician = async (values) => {
    if (user_id) {
      // delete values.user_addresses_attributes[0].address_attributes
      //   .deactivated_at;
      // delete values.user_addresses_attributes[0].isDeactivated;
      return API.put(`/users/${user_id}`, { user: values });
    }
    return API.post("/users", { user: values });
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    const { data, error } = await createOrUpdatePhysician(values);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (user_id) {
        toast.success(`Updated ${values.first_name}!`);
        setTimeout(() => {
          navigate(`/dashboard/referring-providers/view/${user_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${values.first_name}!`);
      }
    }
    setLoading(false);
  };

  const rolesLoading = () => loadingPhysicianRole;
  const userLoading = () => (user_id ? loadingPhysician : false);

  // const numberOfSavedAddresses = (addresses) => {
  //   return _.sum(_.map(addresses, (addr) => !!addr?.id));
  // };

  // const actionButton = (addresses_attributes, address, index, arrayHelpers) => {
  //   if (addresses_attributes.length === 1) {
  //     return null;
  //   }

  //   if (address.id) {
  //     if (address.isDeactivated) {
  //       return (
  //         <button
  //           type="button"
  //           className="mt-2 text-xs text-green-500 font-normal antialiased"
  //           onClick={() => onToggleActivation(address, "activate")}
  //         >
  //           {isTogglingActivation === address.id ? (
  //             <Loader />
  //           ) : (
  //             "[ ⎋ activate this address ]"
  //           )}
  //         </button>
  //       );
  //     } else if (numberOfSavedAddresses(addresses_attributes) <= 1) {
  //       return null;
  //     } else {
  //       return (
  //         <button
  //           type="button"
  //           className="mt-2 text-xs text-red-700 font-normal antialiased"
  //           onClick={() => onToggleActivation(address, "deactivate")}
  //         >
  //           {isTogglingActivation === address.id ? (
  //             <Loader />
  //           ) : (
  //             "[ ⎋ deactivate this address ]"
  //           )}
  //         </button>
  //       );
  //     }
  //   }
  //   return (
  //     <button
  //       type="button"
  //       className="mt-2 text-xs text-gray-700 font-normal antialiased"
  //       onClick={() => arrayHelpers.remove(index)}
  //     >
  //       [ - remove this address ]
  //     </button>
  //   );
  // };

  useEffect(() => {
    if (user?.client) {
      setPrimPhysicianSearchQuery(user?.client?.name);
    }
  }, [user?.client]);

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addPhysicianSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form className="flex-1 divide-y divide-gray-200 flex- flex-col">
            {/* <pre>{JSON.stringify(props.errors, null, 4)}</pre> */}
            <div className="flex flex-col space-y-2 py-5">
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField type="text" name="first_name" required />
                <FormInputField type="text" name="last_name" required />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                {/* <FormInputField title="DOB" type="date" name="dob" required /> */}
                <FormInputField
                  title="Gender"
                  type="select"
                  name="gender"
                  options={[
                    { name: "Male", value: "male" },
                    { name: "Female", value: "female" },
                  ]}
                />
                <FormInputField
                  type="email"
                  name="email"
                  required
                  disabled={!!user_id}
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  title="NPI Number"
                  type="number"
                  name="npi_number"
                  inputProps={{
                    onKeyDown: (event) => {
                      // console.log(props.values.npi_number.toString().length);
                      // Check if the pressed key is an alphabet or special character
                      const isAlphabetOrSpecialCharacter =
                        /[a-z]/i.test(event.key) ||
                        /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(event.key);

                      // Check if the pressed key is the backspace key
                      const isBackspace = event.key === "Backspace";

                      if (
                        (props.values.npi_number.toString().length === 10 &&
                          !isBackspace) ||
                        (!isBackspace && isAlphabetOrSpecialCharacter)
                      ) {
                        event.preventDefault();
                      }
                    },
                  }}
                  required
                />
                <FormInputField
                  // className="w-1/2"
                  type="autocomplete"
                  disabled={false}
                  name="client_id"
                  title="Link Client"
                  required
                  autocompleteProps={{
                    setFieldValue: props.setFieldValue,
                    valueKey: "id",
                    renderCustomLabel: (item) => `${item.name}`,
                    items: clients || [],
                    loading: clientLoading,
                    value: primPhysicianSearchQuery,
                    setValue: setPrimPhysicianSearchQuery,
                    onSelect: setSelectedPrimaryPhysician,
                    renderAfterType: false,
                  }}
                  inputProps={{
                    placeholder: "Search Client",
                  }}
                />
              </div>

              <div className="flex flex-row justify-between space-x-10">
                {/* <FormInputField
                  type="text"
                  name="accession_prefix"
                  required
                  disabled={!!user_id}
                /> */}

                <FormInputField
                  className="hidden"
                  title="Role"
                  type="radio"
                  name="role_id"
                  options={_.map(roles, (role) => ({
                    name: _.startCase(role.name),
                    value: `${role.id}`,
                  }))}
                  required
                />
              </div>
              <div className="flex flex-row justify-between space-x-10">
                <FormInputField
                  title="Phone #"
                  type="number"
                  name="phone"
                  required
                />
                <div className="flex-1"></div>
              </div>
            </div>

            {/* <FieldArray
              name="user_addresses_attributes"
              render={(arrayHelpers) => (
                <div>
                  {props.values.user_addresses_attributes.map(
                    (address, index) => (
                      <div
                        key={index}
                        className="flex flex-col bg-theme-gray p-6 mt-10 bg-opacity-50 rounded-lg"
                      >
                        <div className="flex flex-row justify-between">
                          <span>Address #{index + 1}</span>
                          {actionButton(
                            props.values.user_addresses_attributes,
                            address,
                            index,
                            arrayHelpers
                          )}
                        </div>

                        <div className="flex flex-col  py-5">
                          <FormInputField
                            type="text"
                            title="Location Name"
                            name={`user_addresses_attributes[${index}].address_attributes.location_name`}
                            required
                          />
                          <FormInputField
                            type="text"
                            title="Street 1"
                            name={`user_addresses_attributes[${index}].address_attributes.street1`}
                            required
                          />
                          <FormInputField
                            type="text"
                            title="Street 2"
                            name={`user_addresses_attributes[${index}].address_attributes.street2`}
                            required
                          />
                          <div className="flex flex-row space-x-5">
                            <FormInputField
                              type="text"
                              title="City"
                              name={`user_addresses_attributes[${index}].address_attributes.city`}
                              required
                            />
                            <FormInputField
                              type="text"
                              title="State"
                              name={`user_addresses_attributes[${index}].address_attributes.state`}
                              required
                            />
                            <FormInputField
                              type="text"
                              title="Zipcode"
                              name={`user_addresses_attributes[${index}].address_attributes.zipcode`}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <button
                    type="button"
                    className="text-primary my-4"
                    onClick={() => arrayHelpers.push(adddressFieldItems)}
                  >
                    + Add another address
                  </button>
                </div>
              )}
            /> */}

            <div className="flex flex-row justify-between py-5">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const headerData = {
    states: user_id
      ? [
          { title: "Provider List", link: "/dashboard/referring-providers" },
          {
            title: `Provider #${user_id}`,
            link: `/dashboard/referring-providers/view/${user_id}`,
          },
          { title: "Edit" },
        ]
      : [
          { title: "Provider List", link: "/dashboard/referring-providers" },
          { title: `Add` },
        ],
    title: pageTitle,
  };

  const errorData =
    userError || roleError
      ? {
          show: true,
          subtitle: roleError
            ? "Unable to fetch roles"
            : "Unbable to fetch physician details",
          ctaOnClick: () => (roleError ? mutateRole() : mutatePhysician()),
        }
      : null;

  const loadingData =
    roleError || userError ? false : rolesLoading() || userLoading();

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        title="Are you sure?"
        cancelTitle="Cancel"
        {...dialogData}
        onPressCancel={() => setIsOpen(false)}
      />
      <DashboardView
        headerData={headerData}
        content={content}
        loading={loadingData}
        errorData={errorData}
      />
    </>
  );
}
