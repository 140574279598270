import { format, parse, parseISO } from "date-fns";
import React, { createContext, useEffect, useMemo, useState } from "react";

const DiagnosisContext = createContext();

function DiagnosisFormProvider({ children }) {
  const [selectedMacroCodeDetails, setSelectedMacroCodeDetails] = useState({});
  const [diagnosesAtt, setDiagnosesAtt] = useState([]);
  const [needRefresh, setNeedRefresh] = useState(false);

  function extractDiagnoses(data) {
    if (data?.specimen_demographics && data?.specimen_demographics.length > 0) {
      const diagnosesArray = data.specimen_demographics.map((e, index) => {
        if (index < data.diagnoses.length) {
          const diagnosis = data.diagnoses[index];

          const grossingDescription =
            diagnosis.grossing_description?.length > 0
              ? diagnosis.grossing_description
              : (data.grossings && data.grossings[index]?.description) ?? "";
          return {
            title: diagnosis.title ?? "",
            id: diagnosis.id,
            grossing_description: grossingDescription,
            code: diagnosis.code ?? "",
            description: diagnosis.description ?? "",
            cpt_codes: diagnosis.cpts,
            icd10_codes: diagnosis.icds,
            // visit_id: diagnosis?.visit_id,
            notes: diagnosis.notes ?? "",
            addendum_comment: data?.addendum_comment || "",
            amendment_comment: data.amendment_comment || "",
            diagnosis_date:
              diagnosis.diagnosis_date?.length > 0
                ? parse(
                    format(parseISO(diagnosis.diagnosis_date), "MM-dd-yyyy"),
                    "MM-dd-yyyy",
                    new Date()
                  )
                : parse(
                    format(new Date(), "MM-dd-yyyy"),
                    "MM-dd-yyyy",
                    new Date()
                  ),
          };
        } else {
          return {
            title: "",
            cpt_codes: [],
            icd10_codes: [],
            description: "",
            notes: "",
            addendum_comment: "",
            amendment_comment: "",
            grossing_description:
              (data.grossings && data.grossings[index]?.description) ?? "",
            code: "",
            diagnosis_date: parse(
              format(new Date(), "MM-dd-yyyy"),
              "MM-dd-yyyy",
              new Date()
            ),
            id: null,
          };
        }
      });

      // Flatten the array of objects
      const diagnosesAtt = [].concat(...diagnosesArray);
      return diagnosesAtt;
    } else {
      // Return an empty array if either data.specimen_demographics or data.diagnoses is empty or undefined
      return [];
    }
  }

  function modifyDiagnosesArray(newValue, index, all = false) {
    // If 'all' flag is true, update all elements with 'newValue'
    if (all) {
      setDiagnosesAtt((prevDiagnoses) =>
        prevDiagnoses?.map((diagnosis) => ({ ...diagnosis, ...newValue }))
      );
    } else {
      // Update only the element at the specified index
      setDiagnosesAtt((prevDiagnoses) =>
        prevDiagnoses?.map((diagnosis, i) =>
          i === index ? { ...diagnosis, ...newValue } : diagnosis
        )
      );
    }

    setTimeout(() => {
      setNeedRefresh(false);
    }, 100);
  }

  useEffect(() => {
    setDiagnosesAtt(extractDiagnoses(selectedMacroCodeDetails));
  }, [selectedMacroCodeDetails]);

  // Use useMemo to memoize the value provided by the context
  const value = useMemo(
    () => ({
      selectedMacroCodeDetails,
      setSelectedMacroCodeDetails,
      diagnosesAtt,
      setDiagnosesAtt,
      modifyDiagnosesArray,
      needRefresh,
      setNeedRefresh,
    }),
    [selectedMacroCodeDetails, diagnosesAtt, needRefresh, setNeedRefresh]
  );

  return (
    <DiagnosisContext.Provider value={value}>
      {children}
    </DiagnosisContext.Provider>
  );
}

export { DiagnosisContext, DiagnosisFormProvider };
