import useSWR from "swr";

import { useEffect } from "react";
import { toast } from "../components/Toast";
import { FETCHER } from "./API";
import { CurrentUser } from "../service/Auth";

const getError = (error) => {
  if (!error) return error;
  const { response } = error;
  if (response) {
    const { data } = response;

    if (data.status === "500" || response?.status === 500) {
      return {
        message: "Unable to connect with server. Try again after sometime",
      };
    } else if (data.status === 401 || response?.status === 401) {
      CurrentUser.logout();
      return {
        message: "Unauthorized user",
      };
    } else {
      return { message: data.message, status: data.status };
    }
  } else {
    const { message } = error;
    return { message };
  }
};

const swrConfig = {
  onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
    const { response } = error;
    if (retryCount >= 3) return;
    if (
      (response && response.status === 404) ||
      (response && response.status === 500)
    )
      return;
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  },
};

export function useAPI(url, { showError = true }, config = {}) {
  const { data, error, mutate, isValidating } = useSWR(url, FETCHER, {
    ...swrConfig,
    ...config,
  });
  const customError = getError(error);
  useEffect(() => {
    if (customError && showError) {
      toast.error(customError.message ?? "Network Error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    data,
    error: customError,
    loading: !error && !data,
    mutate,
    isValidating,
  };
}
