import React from "react";
import { Router } from "@reach/router";

import "../assets/main.css";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import CaseView from "../pages/CaseView";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import ChangePassword from "../pages/Auth/ChangePassword";
import PhysicalPortal from "../pages/PhysicianPortal";
import CaseDetail from "../pages/CaseDetail";
import PackageDetails from "../pages/PackageDetails";
import { CurrentUser } from "../service/Auth";

function Routes() {
  if (CurrentUser.isLoggedIn()) {
    return (
      <Router>
        <Home path="/" />
        <Profile path="/profile" />
        <CaseView path="/case-view" />
        <PackageDetails path="/packages/:packageId" />
        <CaseDetail path="/case-view/:id" />
        <Dashboard path="/dashboard/*"></Dashboard>
        <Login path="/login" />
        <ForgotPassword path="/forgot-password" />
        <ChangePassword path="/change-password" />
        <PhysicalPortal path="/physician/*" />
      </Router>
    );
  } else {
    return (
      <Router>
        <ForgotPassword path="/forgot-password" />
        <ResetPassword path="/reset-password/*" />
        <Login path="/*" />
      </Router>
    );
  }
}

export default Routes;
