import React, { useState, useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, navigate, useLocation } from "@reach/router";

import { Button } from "./Button";
import CONSTANTS from "../helpers/constants";
import { GlobalVariablesContext } from "../providers/GlobalProvider";
import { useCurrentProfile } from "../service/Fetchers";
import {
  Admin,
  CaseList,
  Loader,
  ProfilePlaceholder,
  Warning,
  Location,
  Staff,
  WrittingTool,
  Surgery,
  Microscope,
} from "../service/Icon";
import { CurrentUser } from "../service/Auth";

const NavLink = ({ className, ...props }) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: `${className} hover:opacity-100 text-white tracking-wide hover:scale-105 transform duration-100 ${
          isCurrent ? "opacity-90 font-bold" : "opacity-70 text-blue-100"
        }`,
      };
    }}
  />
);

function Header({ company, leftLinks, rightLinks, subLogo, ...rest }) {
  const {
    user: currentUser,
    loading,
    error: userError,
    mutate,
    isValidating,
  } = useCurrentProfile({ showError: false });

  const { pathname } = useLocation();

  const renderUserInfo = () => {
    if (userError)
      return (
        <button
          className="p-1 text-red-400 bg-red-50 rounded-full flex flex-row px-4 space-x-2 items-center"
          onClick={mutate}
        >
          <Warning />
          <div className="flex flex-col">
            <span className="text-xs font-normal">Unable to load user</span>
            <p className="text-xxs font-light text-gray-600">Click to retry</p>
          </div>
        </button>
      );

    if (currentUser)
      return (
        <a
          href="/profile"
          className="flex flex-row items-center justify-center"
          tabIndex={-1}
        >
          {currentUser?.s3_avatar_url ? (
            <img
              alt={`${currentUser?.first_name} ${currentUser?.last_name}`}
              src={currentUser?.s3_avatar_url}
              className="w-8 h-8 mx-3 rounded-full"
            />
          ) : (
            <ProfilePlaceholder className="w-8 h-8 opacity-75 mx-3" />
          )}
          <span className="text-white text-shadow">{`${currentUser?.first_name} ${currentUser?.last_name}`}</span>
        </a>
      );

    if (loading || isValidating) return <Loader className="text-white" />;
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const {
    setOpenFilter,
    setHeaderHeight,
    setShowNavControls,
    showSlideViewer,
    showDiagnosisPreviewBtn,
    setShowSlideViewer,
    setShowPatientInfo,
    setShowDiagnosisPreview,
  } = useContext(GlobalVariablesContext);

  const headerRef = useRef(null);

  useEffect(() => {
    if (!headerRef.current) return;

    const observerCallback = (entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setHeaderHeight(headerRef?.current?.offsetHeight);
        // console.log("ResizeObserver");
      });
    };

    const resizeObserver = new ResizeObserver(observerCallback);

    resizeObserver?.observe(headerRef?.current);
    return () => resizeObserver?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef]);

  // previous code if anything breaks

  // useEffect(() => {
  //   if (!headerRef.current) return;

  //   const resizeObserver = new ResizeObserver(() => {
  //     setHeaderHeight(headerRef?.current?.offsetHeight);
  //     console.log("ResizeObserver");
  //   });

  //   console.log(headerRef?.current?.offsetHeight, "hello");

  //   resizeObserver?.observe(headerRef?.current);
  //   return () => resizeObserver?.disconnect();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [headerRef]);

  return (
    <div ref={headerRef} {...rest}>
      <div
        className={`w-full px-6 ${
          CurrentUser.isPathologist() ? "py-px" : "py-3"
        } lg:px-8 bg-gradient-to-r to-teal-300 from-blue-400 flex justify-center z-20 relative header-container`}
      >
        <div className="flex items-center w-full z-20 max-w-8xl">
          {/* Company Logo */}
          <div className="ml-10 pl-0 w-full flex justify-center lg:w-auto lg:justify-start">
            <div>
              <button
                tabIndex={-1}
                disabled={subLogo?.text ? true : false}
                onClick={() => !subLogo?.text && navigate(company.href)}
                target={`${company.openNewTab ? "_blank" : "_self"}`}
              >
                <img src={company.logo} className="h-5" alt="company-logo" />
              </button>
            </div>
          </div>

          {/* Sub Logo */}
          {subLogo?.text && (
            <div
              tabIndex={-1}
              className="ml-12 -mr-5 text-sm flex flex-col items-center justify-center text-white cursor-pointer"
              onClick={() => navigate("/case-view")}
            >
              {subLogo.icon}
              {subLogo.text}
            </div>
          )}

          {/* Link container */}
          <div className="ml-10 hidden lg:flex flex-1 justify-between items-center">
            <section id="left-icons" className="flex flex-row flex-1">
              {leftLinks?.map((link, index) => (
                <div key={index}>
                  {link.type === "link" && (
                    <div className="px-6 cursor-pointer">
                      <NavLink
                        className="text-md flex flex-col items-center justify-center text-sm"
                        target={`${company.openNewTab ? "_blank" : "_self"}`}
                        to={link.href}
                        onClick={link.onClick}
                        tabIndex={-1}
                      >
                        {link.icon}
                        {link.text}
                      </NavLink>
                    </div>
                  )}
                </div>
              ))}
              {!CurrentUser.isAccessionist() && pathname === "/case-view" ? (
                <div className="flex items-center space-x-4">
                  <div
                    className="px-2 py-px bg-white rounded-sm cursor-pointer"
                    onClick={() => setOpenFilter(true)}
                  >
                    Filters
                  </div>
                  {CurrentUser.isPathologist() ? (
                    <div
                      className="px-2 py-px bg-white rounded-sm cursor-pointer"
                      onClick={() => setShowPatientInfo((prev) => !prev)}
                    >
                      View Patient Info
                    </div>
                  ) : null}
                  {CurrentUser.isPathologist() ? (
                    <div
                      className="px-2 py-px bg-white rounded-sm cursor-pointer"
                      onClick={() => setShowDiagnosisPreview((prev) => !prev)}
                    >
                      Toggle Diagnosis Preview
                    </div>
                  ) : null}
                  {CurrentUser.isPathologist() ? (
                    <div
                      className="px-2 py-px bg-white rounded-sm cursor-pointer"
                      onClick={() => setShowSlideViewer((prev) => !prev)}
                    >
                      Toggle Slide Viewer
                    </div>
                  ) : null}
                  {/* {CurrentUser.isPathologist() && showSlideViewer ? (
                    <div
                      className="px-2 py-px bg-white rounded-sm cursor-pointer"
                      onClick={() => setShowNavControls((prev) => !prev)}
                    >
                      Controls
                    </div>
                  ) : null} */}
                </div>
              ) : null}
            </section>
            <section id="right-links" className="flex flex-row">
              {rightLinks &&
                rightLinks.map((link, index) => (
                  <div className="flex items-center" key={index}>
                    {link.type === "link" && (
                      <div className="px-6 text-white opacity-75 cursor-pointer hover:opacity-100">
                        <a
                          className="text-md flex flex-col items-center justify-center text-sm"
                          target={`${company.openNewTab ? "_blank" : "_self"}`}
                          href={link.href}
                          onClick={link.onClick}
                          tabIndex={-1}
                        >
                          {link.icon}
                          {link.text}
                        </a>
                      </div>
                    )}
                    {link.type === "button" && (
                      <Button
                        onClick={link.onClick}
                        size="default"
                        type="secondary"
                        tabIndex={-1}
                      >
                        {link.text}
                      </Button>
                    )}
                  </div>
                ))}
              {CurrentUser.isLoggedIn() && renderUserInfo()}
            </section>
          </div>

          {/* Side menu bar icons */}
          <div className="cursor-pointer h-6 w-6 lg:hidden">
            {openDrawer ? (
              <div onClick={() => setOpenDrawer(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x text-white stroke-current"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            ) : (
              <div onClick={() => setOpenDrawer(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <defs>
                    <filter id="0ki7udt2sa">
                      <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                      />
                    </filter>
                  </defs>
                  <g
                    fill="none"
                    fillRule="evenodd"
                    filter="url(#0ki7udt2sa)"
                    transform="translate(-331 -25)"
                  >
                    <g>
                      <path
                        d="M0 0L24 0 24 24 0 24z"
                        transform="translate(331 25)"
                      />
                      <path
                        stroke="#121212"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4 8L20 8M4 16L20 16"
                        transform="translate(331 25)"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Header.defaultProps = {
  company: {
    logo: CONSTANTS.HEADER_LOGO,
    href: "/",
    openNewTab: false,
  },
  subLogo: CurrentUser.isGrosser()
    ? {
        text: "Grossing",
        icon: <Surgery />,
      }
    : CurrentUser.isPathologist() || CurrentUser.isTranscriptionist()
    ? {
        text: "Pathology",
        icon: <Microscope />,
      }
    : CurrentUser.isAccessionist()
    ? {
        text: "Order Receiving",
        icon: <WrittingTool />,
      }
    : {},
  leftLinks: CurrentUser.isAdmin()
    ? [
        {
          type: "link",
          text: "Admin",
          href: "/dashboard",
          icon: <Admin />,
        },
        {
          type: "link",
          text: "Cases",
          href: "/case-view",
          icon: <CaseList />,
        },
      ]
    : // Header Links based on logged in physician is Admin
    CurrentUser.isPhysicianOrCLinicAdmin()
    ? [
        {
          type: "link",
          text: "Cases",
          href: "/physician/cases",
          icon: <CaseList />,
        },
        {
          type: "link",
          text: "Location",
          href: "/physician/location",
          icon: <Location />,
        },
        {
          type: "link",
          text: "Staff",
          href: "/physician/staff",
          icon: <Staff />,
        },
      ]
    : [],
};

Header.propTypes = {
  company: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    openNewTab: PropTypes.bool,
  }).isRequired,
  currentUser: PropTypes.shape({
    profileImageUrl: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  subLogo: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
  }),
  leftLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["link", "dropDown", "btn-dropDown"]).isRequired,
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          onClick: PropTypes.func,
          icon: PropTypes.node,
        })
      ),
    })
  ),
  rightLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["link", "dropDown", "btn-dropDown"]).isRequired,
      text: PropTypes.node.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          onClick: PropTypes.func,
          icon: PropTypes.node,
        })
      ),
    })
  ),
};

export default Header;
