import React, { useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import "../assets/main.css";

/**
 * Primary UI component for user interaction
 */

export const Button = React.forwardRef(
  (
    {
      type,
      size,
      secondaryColor,
      loading,
      children,
      disabled,
      className,
      formButton,
      ...props
    },
    buttonRef
  ) => {
    const [shakeClass, setShakeClass] = useState(false);
    let sizeClass =
      size === "small"
        ? "py-1 px-3 md:py-2 md:text-lg md:px-5"
        : "px-6 py-2 md:py-3 md:text-lg md:px-10";
    let typeClass = `ripple-bg-primary text-white rounded-md`;
    if (type === "secondary") {
      typeClass =
        "bg-transparent border-2 border-white text-white ripple-bg-gray-100";
    } else if (type === "warning") {
      typeClass =
        "bg-transparent border-2 border-red-500 text-red-500 ripple-bg-red-100 hover:text-white";
    } else if (type === "info") {
      typeClass =
        "bg-transparent border-2 rounded-md border-primary text-primary ripple-bg-primary hover:text-white";
    } else if (type === "icon") {
      typeClass = `${typeClass} rounded-full`;
      sizeClass = "p-4";
    }

    if (loading) {
      typeClass = `${typeClass} opacity-50 pointer-events-none font-medium `;
    }

    useImperativeHandle(buttonRef, () => ({
      shake() {
        if (shakeClass) return;
        setShakeClass(true);
        setTimeout(() => {
          setShakeClass(false);
        }, 1000);
      },
    }));

    return (
      <button
        type={formButton ? "submit" : "button"}
        ref={buttonRef}
        className={`${typeClass}  ${sizeClass} ${className} flex flex-row items-center ${
          disabled ? "opacity-50 pointer-events-none" : "opacity-100"
        } ${shakeClass && "animate-shake"}`}
        {...props}
      >
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {children}
      </button>
    );
  }
);

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  type: PropTypes.oneOf(["primary", "secondary", "warning", "info", "icon"]),

  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["small", "default"]),
  /**
   * should show loading icon?
   */
  loading: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Optional click handler
   */
  formButton: PropTypes.bool,
};

Button.defaultProps = {
  type: "primary",
  size: "default",
  loading: false,
  onClick: undefined,
  formButton: false,
};
