import React, { memo, useCallback, useState } from "react";
import Table from "../../stories/Table";
import { Link, useNavigate } from "@reach/router";
import { format } from "date-fns";
import { StatusTag } from "../../stories/StatusTag";
import { usePatientList } from "../../service/Fetchers";
import { getErrorData, getURLParams, handleQuery } from "../../service/Helper";
import { DashboardView } from "../../stories/DashboardView";

const perPageCount = 10;
const ListPatient = () => {
  const navigate = useNavigate();

  const initialPage = getURLParams("page") ?? 1;
  const searchQ = getURLParams("search") ?? "";

  const [searchQuery, setSearchQuery] = useState(searchQ || "");
  const [page, setPage] = useState(initialPage);

  const { patients, pageInfo, error, loading, isValidating, mutate } =
    usePatientList({
      currentPage: page,
      perPageCount,
      searchQuery: searchQ,
      list: "all",
    });

  const loadingData = loading || isValidating;

  const headerData = {
    constructiveCTA: {
      link: "/dashboard/patients/add",
      title: "Add Patient",
    },
    states: [
      {
        title: "Patient List",
      },
    ],
    title: "List of Patients",
    search: {
      show: true,
      searching: searchQuery?.length > 0 && loadingData,
      placeholder: "Search using Patient ID or Name...",
      onSearch: (values, helpers) => {
        setPage(1);
        handleQuery(navigate, 1, values.query, "patients");
        setSearchQuery(values.query);
      },
      onChange: (values) => {
        if (!values.query) {
          setPage(initialPage);
          setSearchQuery("");
        }
      },
    },
  };

  const Content = useCallback(
    () => (
      <Table
        headers={[
          "Patient ID",
          "Patient Name",
          "Date Added",
          "Status",
          "No of cases",
        ]}
        pagination={{
          currentpage: pageInfo?.current_page,
          itemsPerPage: pageInfo?.per_page,
          totalItems: pageInfo?.total_count,
          onPageChange: (page) => {
            handleQuery(navigate, page, searchQ, "patients");
          },
        }}
        loading={loadingData}
        rowContent={patients.map((patient) => [
          <p className="underline text-primary">
            <Link to={`/dashboard/patients/view/${patient.id}`}>
              {patient.uuid}
            </Link>
          </p>,
          <p>{`${patient.first_name} ${patient.last_name}`}</p>,
          <p>{format(new Date(patient.created_at), "dd-MMM-yyyy")}</p>,
          <StatusTag type={patient.deactivated ? "red" : "green"}>
            {patient.deactivated ? "Deactivated" : "Active"}
          </StatusTag>,
          <p>{patient?.patient_history?.visits.length}</p>,
        ])}
      />
    ),
    [pageInfo, patients, loadingData]
  );

  const errorData = getErrorData({
    error,
    searchQuery,
    data: patients,
    mutate,
    title: "Patients",
  });

  return (
    <DashboardView
      headerData={headerData}
      content={<Content />}
      errorData={errorData}
      loading={loadingData}
      loadingContent={null}
    />
  );
};

export default memo(ListPatient);
