import React, { useState, useRef } from "react";
import { Button } from "../../stories/Button";
import { Formik, Form } from "formik";
import { addPatientSchema } from "../../service/Validation";
import { API } from "../../network/API";
import { toast } from "../../components/Toast";
import { FormInputField } from "../../stories/FormInputField";
import { useProviders, usePatient } from "../../service/Fetchers";
import { DashboardView } from "../../stories/DashboardView";
import { navigate } from "@reach/router";

export default function AddPatient({ id: patient_id }) {
  const [loading, setLoading] = useState(false);
  const buttonTitle = patient_id ? "Update" : "Create Patient";
  const pageTitle = patient_id
    ? `Update patient #${patient_id}`
    : "Create new patient";
  const buttonRef = useRef(null);
  const { providers, error: roleError, mutate: mutateRole } = useProviders();
  const {
    patient,
    error: patientError,
    mutate: mutatePatient,
  } = usePatient({
    patient_id,
  });

  const initialFormValues = () => {
    return {
      id: patient?.id,
      first_name: patient?.first_name ?? "",
      last_name: patient?.last_name ?? "",
      gender:
        patient && patient.hasOwnProperty("gender")
          ? `${patient?.gender}`
          : "default",
      dob: patient?.dob ?? "",
      email: patient?.email ?? "",
      phone: patient?.phone ?? "",
      patient_insurance_attributes: {
        id: patient?.insurance_details?.id,
        patient_id: patient?.id,
        provider_id: patient?.insurance_details?.provider?.id ?? "",
        policy: patient?.insurance_details?.policy ?? "",
        patient_notes: patient?.insurance_details?.patient_notes ?? "",
      },
    };
  };

  const createOrUpdatePatient = async (values) => {
    if (patient_id) {
      return API.put(`/patients/${patient_id}`, { patient: values });
    }
    return API.post("/patients", { patient: values });
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    const { data, error } = await createOrUpdatePatient(values);
    if (error) {
      buttonRef.current.shake();
      toast.error(error?.message ?? "Network error");
    }
    if (data) {
      if (patient_id) {
        toast.success(`Updated ${values.first_name}!`);
        setTimeout(() => {
          navigate(`/dashboard/patients/view/${patient_id}`);
        }, 1500);
      } else {
        actions.resetForm({ values: initialFormValues() });
        toast.success(`Added ${values.first_name}!`);
      }
    }
    setLoading(false);
  };

  const providersLoaded = () => providers && providers.length;
  const patientLoaded = () => (patient_id ? !!patient : true);

  const content = (
    <Formik
      initialValues={initialFormValues()}
      validationSchema={addPatientSchema}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <Form className="flex-1">
            <div className="divide-y divide-gray-200 flex flex-col space-y-2">
              <div className="flex flex-col space-y-2">
                <div className="flex flex-row justify-between space-x-10">
                  <FormInputField type="text" name="first_name" required />
                  <FormInputField type="text" name="last_name" required />
                </div>
                <div className="flex flex-row justify-between space-x-10">
                  <FormInputField title="DOB" type="date" name="dob" required />
                  <FormInputField
                    type="select"
                    name="gender"
                    title="gender at birth"
                    options={[
                      { name: "Male", value: "male" },
                      { name: "Female", value: "female" },
                    ]}
                    required
                  />
                </div>
                <div className="flex flex-row justify-between space-x-10">
                  <FormInputField type="email" name="email" required />
                  <FormInputField
                    title="Phone #"
                    type="number"
                    name="phone"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2 pt-4">
                <div className="flex flex-row justify-between space-x-10">
                  <FormInputField
                    type="select"
                    name="patient_insurance_attributes.provider_id"
                    title="Payor"
                    options={providers.map((p) => ({
                      name: p.name,
                      value: p.id,
                    }))}
                    required
                  />
                  <FormInputField
                    type="text"
                    name="patient_insurance_attributes.policy"
                    title="Policy #"
                    required
                  />
                </div>
                <div className="flex flex-row justify-between space-x-10">
                  <FormInputField
                    title="Policy Notes"
                    type="textarea"
                    name="patient_insurance_attributes.patient_notes"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between mt-10">
              <Button ref={buttonRef} formButton loading={loading}>
                {buttonTitle}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  const headerData = {
    states: patient_id
      ? [
          { title: "Patient List", link: "/dashboard/patients" },
          {
            title: `Patient #${patient_id}`,
            link: `/dashboard/patients/view/${patient_id}`,
          },
          { title: "Edit" },
        ]
      : [
          { title: "Patient List", link: "/dashboard/patients" },
          { title: `Add` },
        ],
    title: pageTitle,
  };

  const errorData =
    patientError || roleError
      ? {
          show: true,
          subtitle: roleError
            ? "Unable to fetch roles"
            : "Unbable to fetch patient details",
          ctaOnClick: () => (roleError ? mutateRole() : mutatePatient()),
        }
      : null;

  const loadingData =
    patientError || roleError ? false : !(providersLoaded() && patientLoaded());

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      loading={loadingData}
      errorData={errorData}
    />
  );
}
