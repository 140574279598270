import React from "react";
import "../assets/main.css";
import toaster from "toasted-notes";
import { Close, CheckCircle, Warning, Info } from "../service/Icon";

/**
 * Primary UI component for user interaction
 */

const IconType = ({ type }) => {
  const commonClass =
    "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10 ";
  switch (type) {
    case "warning":
    case "error":
      return (
        <div className={`${commonClass} bg-red-100 text-red-400`}>
          <Warning />
        </div>
      );
    case "success":
      return (
        <div className={`${commonClass} bg-green-100 text-green-400`}>
          <CheckCircle />
        </div>
      );
    default:
      return (
        <div className={`${commonClass} bg-primary bg-opacity-25 text-primary`}>
          <Info />
        </div>
      );
  }
};

const showToast = (type = "info", title, options = {}) => {
  const { subtitle = null, position = "top-right", duration = 2000 } = options;

  toaster.notify(
    ({ onClose }) => (
      <div className="bg-white shadow-md overflow-hidden border border-gray-200 sm:rounded-lg m-4">
        <div className="relative">
          <div className="flex flex-row p-4 space-x-3 text-left">
            <IconType type={type} />
            <div
              className={`flex flex-col w-64 pr-4 ${
                subtitle ? "justify-start" : "justify-center"
              }`}
            >
              <span className="font-medium text-gray-700">{title}</span>
              {subtitle && (
                <span className="text-xs text-gray-500 font-light">
                  {subtitle}
                </span>
              )}
            </div>
            <div>
              <button
                onClick={onClose}
                className="text-gray-400 transform outline-none focus:outline-none flex-shrink-0"
              >
                <Close />
              </button>
            </div>
          </div>
        </div>
      </div>
    ),
    { position, duration }
  );
};

export const toast = {
  error: (title, options) => showToast("error", title, options),
  info: (title, options) => showToast("info", title, options),
  success: (title, options) => showToast("success", title, options),
};
