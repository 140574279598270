import React, { useState } from "react";
import { Link, navigate } from "@reach/router";
import Table from "../../../stories/Table";
import { getErrorData, getURLParams } from "../../../service/Helper";
import { DashboardView } from "../../../stories/DashboardView";
import { useGetAudit } from "../../../service/Api_services/GetAudit/useGetAudit";
import { format } from "date-fns";

export default function AuditView() {
  const perPageCount = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const currentPage = searchQuery?.length > 0 ? 1 : getURLParams("page") ?? 1;
  const {
    data: auditData,
    error,
    isValidating,
    loading,
    mutate,
    pageInfo,
  } = useGetAudit({
    currentPage,
    perPageCount,
    searchQuery,
  });

  const loadingData = loading || isValidating;

  const headerData = {
    states: [],
    title: "Audit List",
    search: {
      show: false,
      searching: searchQuery?.length && loadingData,
      placeholder: "ilter audit",
      onSearch: (values) => {
        setSearchQuery(values.query);
      },
      onChange: (values) => {
        if (!values.query) {
          setSearchQuery("");
        }
      },
    },
  };

  const content = (
    <Table
      headers={[
        "Case #",
        "username",
        "role",
        "client name/abbr",
        "viewed on timestamp",
      ]}
      pagination={{
        currentpage: pageInfo?.current_page,
        itemsPerPage: pageInfo?.per_page,
        totalItems: pageInfo?.total_count,
        onPageChange: (page) => {
          navigate(`?page=${page}`);
        },
      }}
      loading={loadingData}
      rowContent={auditData?.map((audit) => {
        return [
          <p className="underline text-primary">{audit?.visit_ucid}</p>,
          <p>
            {audit?.first_name} {audit?.last_name}
          </p>,
          <p className="uppercase">{audit?.role}</p>,
          <p>
            {audit?.client_name?.length ? audit?.client_name : "--"} /{" "}
            {audit?.client_abbr?.length ? audit?.client_abbr : "--"}
          </p>,
          <p>{format(new Date(audit?.created_at), "MM-dd-yyyy hh:mm a")}</p>,
        ];
      })}
    />
  );

  const errorData = getErrorData({
    error,
    searchQuery,
    data: auditData,
    mutate,
    title: "Audit",
  });

  return (
    <DashboardView
      headerData={headerData}
      content={content}
      errorData={errorData}
      loading={loadingData}
      loadingContent={null}
    />
  );
}
